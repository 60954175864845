import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon,
  Button,
  Gradient,
  Spin
} from "app/NativeComponents/common";

import { IconButton, PopoverMenu } from "app/NativeComponents/snippets";

import { store } from "app/store";
import { numberWithCommas } from "app/NativeActions";

class ListTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pinning: false
    };
  }

  render() {
    const {
      style,
      active,
      list,
      title,
      subtitle,
      mobile_title,
      loading,
      icon,
      fa_icon,
      label = null,
      label_tooltip = null,

      include_menu = false,
      onPress = () => {},
      onClose = () => {},
      onPin = () => {},
      onUnpin = () => {},
      number_of_tabs = 0
    } = this.props;
    const { pinning } = this.state;
    const colors = store.getState().settings.colors;
    const isMobile = store.getState().native.isMobile;
    const dark_mode = store.getState().settings.dark_mode;

    const text_color = active
      ? colors.actionable_text_color
      : colors.text_color;

    return (
      <Button
        tooltip={
          (number_of_tabs > 5 || title?.length > 15) && !!title ? title : ""
        }
        tooltipPlacement="top"
        style={{
          height: active ? 50 : 49,
          width: list?.id ? 200 : "auto",
          maxWidth: active ? 400 : 200,
          minWidth: 100,
          alignItems: "center",
          justifyContent: "center",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: active
            ? colors.actionable_text_color
            : colors.border_color,
          borderRightWidth: active ? 1 : 1,
          borderLeftWidth: active ? 1 : 0,
          borderBottomWidth: active ? 1 : 0,
          borderBottomColor: active ? colors.card_color : "transparent",
          borderBottomStyle: "solid",
          backgroundColor: active ? colors.card_color : colors.background_color,
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          overflow: "hidden",
          marginBottom: active ? -1 : 1,

          opacity: 1,
          ...style
        }}
        onPress={onPress}
      >
        {!list?.id ? (
          <Row>
            <Wrapper
              style={{
                paddingLeft: 15,
                paddingRight: list == "in_trash_leads" ? 0 : 15
              }}
            >
              <Copy
                style={{
                  color: text_color,
                  whiteSpace: "nowrap"
                }}
              >
                {active ? (
                  <Bold>
                    {isMobile && !!mobile_title ? mobile_title : title}
                  </Bold>
                ) : isMobile && !!mobile_title ? (
                  mobile_title
                ) : (
                  title
                )}
              </Copy>
            </Wrapper>
            {list == "in_trash_leads" ? (
              <IconButton icon="close" button_type="small" onPress={onClose} />
            ) : null}
          </Row>
        ) : (
          <Row
            style={{
              alignSelf: "stretch"
            }}
          >
            <Wrapper
              style={{
                paddingLeft:
                  !!icon || !!fa_icon || loading || pinning ? 10 : 15,
                paddingRight: (include_menu && !list.pinned) || active ? 0 : 15,
                flex: 1,
                overflow: "hidden"
              }}
            >
              <Row style={{ position: "relative" }}>
                {pinning ? (
                  <Wrapper style={{ marginRight: 5 }}>
                    <Spin
                      size="small"
                      color={text_color}
                      style={{ fontSize: 18 }}
                    />
                  </Wrapper>
                ) : !!icon || !!fa_icon ? (
                  <Icon
                    size={18}
                    icon={icon}
                    fa_icon={fa_icon}
                    style={{ marginRight: 5 }}
                    color={text_color}
                  />
                ) : !!label ? (
                  <Wrapper
                    style={{
                      marginRight: 10,
                      borderRadius: 20,
                      padding: 10,
                      paddingTop: 0,
                      paddingBottom: 0,
                      backgroundColor: colors.active_color_muted,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: colors.active_color
                    }}
                    tooltip={label_tooltip}
                    tooltipPlacement="top"
                  >
                    <Copy
                      style={{
                        color: colors.actionable_text_color,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontSize: 10
                      }}
                    >
                      <Bold>{label}</Bold>
                    </Copy>
                  </Wrapper>
                ) : null}
                <Wrapper>
                  {active ? (
                    <Copy
                      style={{
                        color: text_color,
                        whiteSpace: "nowrap",

                        overflow: "hidden"
                      }}
                    >
                      <Bold>
                        {isMobile && !!mobile_title ? mobile_title : title}
                      </Bold>
                    </Copy>
                  ) : (
                    <Copy
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      {isMobile && !!mobile_title ? mobile_title : title}
                    </Copy>
                  )}
                  {!!subtitle && !isMobile ? (
                    <Copy
                      style={{
                        fontSize: 10,
                        whiteSpace: "nowrap",
                        //maxWidth: 150,
                        overflow: "hidden",
                        color: active
                          ? colors.actionable_text_color
                          : colors.light_text_color
                      }}
                    >
                      {subtitle}
                    </Copy>
                  ) : null}
                </Wrapper>
              </Row>
            </Wrapper>
            {include_menu ? (
              active ? (
                <PopoverMenu
                  renderComponent={options => {
                    return (
                      <IconButton
                        icon="more-vert"
                        button_type="small"
                        noPress={true}
                        pressedIn={options.hovering || options.pressedIn}
                      />
                    );
                  }}
                  menu_items={[
                    {
                      title: "View List",
                      icon: "launch",
                      type: "normal",
                      select_type: "icon",
                      onPress: () => {
                        this.props.setEditListInfo({
                          id: list.id,
                          title: list.title,
                          date_created: list.date_created,
                          ...list
                        });
                        this.props.pushSidePanel({
                          slug: "edit_list",
                          id: list.id,
                          overlay: true
                        });
                      }
                    },
                    list.pinned
                      ? {
                          title: "Unpin Tab",
                          icon: "push-pin",
                          type: "normal",
                          select_type: "icon",

                          onPress: () => {
                            this.props.updateList({
                              token: this.props.token,
                              list_id: list.id,
                              type: "unpin_list",
                              onLoading: () => {
                                this.setState({ pinning: true });
                              },
                              onError: () => {
                                this.setState({ pinning: false });
                              },
                              onSuccess: () => {
                                this.setState({ pinning: false });
                              }
                            });
                          }
                        }
                      : {
                          title: "Pin Tab",
                          icon: "push-pin",
                          type: "normal",
                          select_type: "icon",

                          onPress: () => {
                            this.props.updateList({
                              token: this.props.token,
                              list_id: list.id,
                              type: "pin_list",
                              onLoading: () => {
                                this.setState({ pinning: true });
                              },
                              onError: () => {
                                this.setState({ pinning: false });
                              },
                              onSuccess: () => {
                                this.setState({ pinning: false });
                              }
                            });
                          }
                        },
                    !list.pinned
                      ? {
                          title: "Close Tab",
                          icon: "close",
                          select_type: "icon",
                          onPress: onClose
                        }
                      : null
                  ]}
                />
              ) : !list.pinned ? (
                <IconButton
                  icon="close"
                  button_type="small"
                  onPress={onClose}
                />
              ) : null
            ) : null}
          </Row>
        )}
      </Button>
    );
  }
}

export default ListTab;

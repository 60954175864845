import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Wrapper } from "app/NativeComponents/common";
import {
  appRedirect,
  updateConversation,
  getBulkEdits,
  reloadLeads,
  getBilling,
  updateBulkEdit,
  getOwnerInfoSuccess,
  reloadList,
  loadRoutes,
  setActiveTaskCount,
  setModal,
  toggleModal,
  getMyLocation,
  selectActiveTask,
  setTaskPanel,
  reloadWorkflowHistory,
  triggerLeadsReload,
  triggerLeadsCountReload,
  deleteLeads,
  updateListBuilderProgress,
  setUpdatedLeads,
  setUpdatedOwner,
  logout,
  getUser,
  getSourceOfTruth,
  updateDialerConversation,
  updateActiveCall,
  updateProgressBar,
  addProgressBar,
  completeProgressBar,
  getPhoneCall,
  setDialerConversation,
  setActiveDialerContact,
  updateCallSessionCount,
  aiListBuildScan,
  completeAiListBuilder,
  setUpdatedProperties
} from "app/NativeActions";
import PusherService from "app/Router/PusherService";

class PusherWrapper extends PureComponent {
  constructor(props) {
    super(props);

    // Enable pusher logging - don't include this in production
    //Pusher.logToConsole = true;
    this.handleEvent = this.handleEvent.bind(this);
  }

  componentDidMount() {
    if (this.props.user) {
      this.initPush(this.props.user);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.user && this.props.user) ||
      (prevProps.user && !this.props.user) ||
      (prevProps.user &&
        this.props.user &&
        prevProps.user.id !== this.props.user.id)
    ) {
      this.initPush(this.props.user);
    }
  }

  initPush(user) {
    if (this._pusher) {
      this._pusher.allChannels().forEach(channel => {
        this._pusher.unsubscribe(channel.name);
      });
    }

    if (user) {
      this._pusher = PusherService.getPusher();
      this._user_channel = this._pusher.subscribe(
        "user-" + user.user_ws_key + "-dealmachine"
      );
      this._channel = this._pusher.subscribe(
        "team-" + user.team_ws_key + "-dealmachine"
      );

      //dialer transcriptions from twilio
      this._user_channel.bind("inbound_transcription", data => {
        if (
          this.props.active_call?.call_status !== "call_ended" &&
          !!data?.message?.alternatives[0]?.transcript &&
          !!data?.message?.alternatives[0]?.transcript.trim() &&
          data?.call_id &&
          this.props.active_call?.call_id == data?.call_id &&
          (this.props.active_call?.call_status == "answered" ||
            this.props.active_call?.call_status == "connected")
        ) {
          this.props.updateDialerConversation({
            type: "inbound",
            message: data?.message?.alternatives[0]?.transcript,
            complete: data?.message?.isFinal,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15)
          });
        }
      });
      this._user_channel.bind("outbound_transcription", data => {
        if (
          this.props.active_call?.call_status !== "call_ended" &&
          !!data?.message?.alternatives[0]?.transcript &&
          !!data?.message?.alternatives[0]?.transcript.trim() &&
          data?.call_id &&
          this.props.active_call?.call_id == data?.call_id
        ) {
          this.props.updateDialerConversation({
            type: "outbound",
            message: data?.message?.alternatives[0]?.transcript,
            complete: data?.message?.isFinal,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15)
          });
        }
      });
      this._user_channel.bind("answered_call", data =>
        this.handleEvent(data, "answered_call")
      );
      this._user_channel.bind("machine_detected", data =>
        this.handleEvent(data, "machine_detected")
      );
      this._user_channel.bind("update_call_session_count", data =>
        this.handleEvent(data, "update_call_session_count")
      );

      this._user_channel.bind("ended_call", data =>
        this.handleEvent(data, "ended_call")
      );
      this._user_channel.bind("summarization_updated", data =>
        this.handleEvent(data, "summarization_updated")
      );

      this._user_channel.bind("ai_list_build_scan", data =>
        this.handleEvent(data, "ai_list_build_scan")
      );

      this._user_channel.bind("complete_ai_build_list", data =>
        this.handleEvent(data, "complete_ai_build_list")
      );

      this._user_channel.bind("reload_property", data =>
        this.handleEvent(data, "reload_property")
      );

      //all new sms message functions
      this._channel.bind("sms_message", data =>
        this.handleEvent(data, "sms_message")
      );
      this._channel.bind("owner", data => this.handleEvent(data, "owner"));
      this._channel.bind("leads", data => this.handleEvent(data, "leads"));

      this._channel.bind("bulk_edits", data =>
        this.handleEvent(data, "bulk_edits")
      );

      this._channel.bind("build_list", data =>
        this.handleEvent(data, "build_list")
      );

      this._channel.bind("update_build_list", data =>
        this.handleEvent(data, "update_build_list")
      );
      this._channel.bind("add_progress_bar", data =>
        this.handleEvent(data, "add_progress_bar")
      );
      this._channel.bind("progress_bar_update", data =>
        this.handleEvent(data, "progress_bar_update")
      );
      this._channel.bind("progress_bar_complete", data =>
        this.handleEvent(data, "progress_bar_complete")
      );

      this._channel.bind("workflow_event", data =>
        this.handleEvent(data, "workflow_event")
      );

      this._channel.bind("reload_list", data =>
        this.handleEvent(data, "reload_list")
      );

      this._channel.bind("delete_leads", data =>
        this.handleEvent(data, "delete_leads")
      );

      this._channel.bind("stripe_checkout_cancel", data =>
        this.handleEvent(data, "stripe_checkout_cancel")
      );

      this._channel.bind("stripe_checkout_success", data =>
        this.handleEvent(data, "stripe_checkout_success")
      );

      this._user_channel.bind("task_driving", data => {
        this.handleEvent(data, "task_driving");
      });
      this._user_channel.bind("task_count", data => {
        this.handleEvent(data, "task_count");
      });
      this._user_channel.bind("routes", data =>
        this.handleEvent(data, "routes")
      );
    }
  }

  handleEvent(data, socket_event) {
    switch (socket_event) {
      case "update_call_session_count":
        if (
          this.props.current_call_session &&
          this.props.current_call_session?.id == data?.call_session_id
        ) {
          this.props.updateCallSessionCount(
            parseInt(this.props.current_call_session?.total_items) +
              parseInt(data.add_phone_numbers_count)
          );
        }
        break;

      case "machine_detected":
        if (
          this.props.active_call &&
          this.props.active_call?.call_id == data?.call_id
        ) {
          this.props.updateActiveCall({
            ...this.props.active_call,
            answered_by: data.answered_by
          });
        }

        break;
      case "answered_call":
        if (
          this.props.active_call &&
          this.props.active_call?.call_id == data?.call_id &&
          this.props.rejected_call != data?.call_id &&
          this.props.active_call?.call_status != "answered" &&
          data?.call_type === "incoming"
        ) {
          this.props.getPhoneCall({
            token: this.props.token,
            type: "get_phone_call",
            call_id: data?.call_id,
            onLoading: () => {},
            onError: error => {},
            onSuccess: results => {
              if (results?.phone_call) {
                const phone_call = results?.phone_call;
                this.props.setActiveDialerContact({
                  contact: {
                    ...phone_call?.contact,
                    selected_phone: {
                      number: phone_call?.from_phone_number
                    },
                    associated_lead: phone_call?.property,
                    one_off_call: true,
                    incoming_call: true,
                    answered_on_another_device: true
                  },
                  current_conversation: phone_call?.transcription
                });

                this.props.updateActiveCall({
                  ...this.props.active_call,
                  call_id: phone_call?.call_id,
                  call_status: "answered"
                });
              }
            }
          });
        } else if (
          this.props.active_call &&
          this.props.active_call?.call_id == data?.call_id &&
          this.props.active_call?.call_status != "call_ended"
        ) {
          this.props.updateActiveCall({
            ...this.props.active_call,
            call_status: "answered"
          });
        }

        break;

      case "ended_call":
        if (
          this.props.active_call?.call_id == data?.call_id &&
          this.props.active_call?.call_status !== "call_ended"
        ) {
          this.props.updateActiveCall({
            ...this.props.active_call,
            call_status: "call_ended"
          });
        }
        break;

      case "summarization_updated":
        if (this.props.active_call?.call_id == data?.call_id) {
          if (data?.call_results) {
            this.props.updateActiveCall({
              ...this.props.active_call,
              call_results: data?.call_results
            });
          }

          this.props.getPhoneCall({
            token: this.props.token,
            type: "get_phone_call",
            call_id: this.props.active_call?.call_id,
            onLoading: () => {},
            onError: () => {},
            onSuccess: results => {
              if (results?.phone_call) {
                this.props.setDialerConversation({
                  call_id: results?.phone_call?.call_id,
                  call_results: results?.phone_call?.call_results,
                  call_note: results?.phone_call?.note
                });
              }
            }
          });
        }
        break;

      case "stripe_checkout_cancel":
        this.props.logout();
        break;

      case "stripe_checkout_success":
        this.props.getUser({
          token: this.props.token,
          device: this.props.device,
          onLoading: () => {},
          redirect: true
        });
        this.props.getSourceOfTruth({ token: this.props.token });
        break;

      case "sms_message":
        //get conversation from text message

        this.props.updateConversation({
          token: this.props.token,
          type: "active_conversation",
          conversation_id: data.conversation_id
        });
        break;

      case "owner":
        this.props.setUpdatedOwner({
          lead_id: data.lead_id,
          owners: data.owners
        });

        if (
          this.props.active_property &&
          this.props.active_property.deal &&
          this.props.active_property.deal.id == data.lead_id
        ) {
          this.props.getOwnerInfoSuccess({
            lead_id: data.lead_id,
            owners: data.owners
          });
        }

        break;

      case "reload_property":
        if (data?.property_id) {
          this.props.setUpdatedProperties([data?.property_id]);
        }
        break;

      case "leads":
      case "delete_leads":
        //update all leads on the front end...also get all bulk edits

        let leads = [];
        if (this.props.active_property && this.props.active_property.deal) {
          leads.push(this.props.active_property.deal.id);
        }
        if (this.props.map_properties && this.props.map_properties.length > 0) {
          for (let i = 0; i < this.props.map_properties.length; i++) {
            if (this.props.map_properties[i].deal) {
              leads.push(this.props.map_properties[i].deal.id);
            }
          }
        }
        if (
          this.props.list_properties &&
          this.props.list_properties.length > 0
        ) {
          for (let j = 0; j < this.props.list_properties.length; j++) {
            if (this.props.list_properties[j].deal) {
              leads.push(this.props.list_properties[j].deal.id);
            }
          }
        }
        var lead_ids = "";
        if (!!data?.lead_ids) {
          var data_lead_ids = data.lead_ids.toString().split(",");
          for (let k = 0; k < leads.length; k++) {
            for (let l = 0; l < data_lead_ids.length; l++) {
              if (leads[k] == data_lead_ids[l]) {
                if (lead_ids.length > 0) {
                  lead_ids += ",";
                }
                lead_ids += data_lead_ids[l];
              }
            }
          }

          this.props.setUpdatedLeads(data_lead_ids);
        } else {
          this.props.setUpdatedLeads(lead_ids);
          lead_ids = leads.join(",");
        }

        if (socket_event == "delete_leads") {
          this.props.deleteLeads(lead_ids);
          this.props.triggerLeadsCountReload();
        } else {
          if (!!lead_ids) {
            this.props.triggerLeadsCountReload();
            this.props.reloadLeads({
              token: this.props.token,
              type: "websocket",
              updating_type: data.updating_type,
              lead_ids
            });
          }

          //update bulk edit if id is included
          if (
            data &&
            !!data.bulk_edit_id &&
            this.props.bulk_edits &&
            this.props.bulk_edits.length > 0
          ) {
            for (let i = 0; i < this.props.bulk_edits.length; i++) {
              if (this.props.bulk_edits[i].id == data.bulk_edit_id) {
                const percent_complete = parseInt(
                  ((parseInt(this.props.bulk_edits[i].leads_updated) +
                    parseInt(this.props.bulk_edits[i].leads_failed) +
                    1) /
                    parseInt(this.props.bulk_edits[i].total)) *
                    100
                );

                const percent_remaining =
                  100 -
                  parseInt(
                    ((parseInt(this.props.bulk_edits[i].leads_updated) +
                      parseInt(this.props.bulk_edits[i].leads_failed) +
                      1) /
                      parseInt(this.props.bulk_edits[i].total)) *
                      100
                  );

                this.props.updateBulkEdit({
                  id: this.props.bulk_edits[i].id,
                  total: this.props.bulk_edits[i].total,
                  percent_complete,
                  percent_remaining,
                  leads_updated:
                    parseInt(this.props.bulk_edits[i].leads_updated) + 1,
                  leads_failed: this.props.bulk_edits[i].leads_failed
                });
              }
            }
          }
        }
        break;

      case "bulk_edits":
        //check status of bulk edits
        //get list update
        //data.list_id
        let matched_id = false;
        if (
          data &&
          !!data.id &&
          this.props.bulk_edits &&
          this.props.bulk_edits.length > 0
        ) {
          for (let i = 0; i < this.props.bulk_edits.length; i++) {
            if (this.props.bulk_edits[i].id == data.id) {
              matched_id = true;
              this.props.triggerLeadsCountReload();
              this.props.updateBulkEdit({
                id: data.id,
                completed: data.completed,
                total: data.total,
                percent_complete: data.percent_complete,
                percent_remaining: data.percent_remaining,
                leads_updated: data.leads_updated,
                leads_failed: data.leads_failed
              });
            }
          }
        }

        if (!matched_id) {
          this.props.getBulkEdits({ token: this.props.token });
          this.props.getBilling({
            token: this.props.token,
            type: "deal_credits"
          });
        }

        break;
      case "task_count":
        this.props.setActiveTaskCount({
          count: data.count
        });
        break;
      case "task_driving":
        this.props.setModal({
          title: "You have a new task",
          description: data.message,
          submit: "View My New Task",
          onPress: () => {
            if (this.props.isMobile) {
              getMyLocation({
                success: coordinates => {
                  this.props.selectActiveTask({
                    token: this.props.token,
                    task: { id: data.task_id },
                    user_location: coordinates
                  });
                  this.props.setTaskPanel("task_details");
                },
                onError: () => {
                  this.props.selectActiveTask({
                    token: this.props.token,
                    task: { id: data.task_id }
                  });
                  this.props.setTaskPanel("task_details");
                }
              });
            } else {
              this.props.selectActiveTask({
                token: this.props.token,
                task: { id: data.task_id }
              });
              this.props.setTaskPanel("task_details");
            }
          },
          cancel: "Ignore for later",
          onCancel: () => {
            this.props.toggleModal({ show: false });
          }
        });
        this.props.toggleModal({ show: true, type: "normal" });
        break;
      case "routes":
        fetch(data.routes, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        })
          .then(response => response.json())
          .then(myJson => this.props.loadRoutes({ routes: myJson }));

        break;
      case "build_list":
      case "reload_list":
        if (data && data.list) {
          this.props.triggerLeadsCountReload();

          this.props.reloadList({
            list: data?.list,
            lists_info_banner: data?.lists_info_banner
          });

          if (this.props.active_list?.id == data?.list?.id) {
            this.props.triggerLeadsReload(true);
          }
        }

        break;

      case "update_build_list":
        if (data && data.list) {
          this.props.triggerLeadsCountReload();
          this.props.updateListBuilderProgress({
            list: data.list,
            total: data?.total,
            success_counter: data?.success_counter,
            passed_counter: data?.passed_counter
          });
        }

        break;

      case "ai_list_build_scan":
        if (data && data?.ai_scan?.list_id) {
          this.props.aiListBuildScan({
            ai_scan: data.ai_scan,
            total_counter: data.total_counter,
            passed_counter: data.passed_counter,
            failed_counter: data.failed_counter
          });
        }

        break;

      case "complete_ai_build_list":
        console.log("complete_ai_build_list", data);

        if (data && data.list_id) {
          this.props.completeAiListBuilder(data.list);
        }

        break;

      case "add_progress_bar":
        if (data && data.id) {
          this.props.addProgressBar({
            id: data?.id,
            type: data.type,
            progress: 0,
            completed: 0,
            total_count: data.total_count,
            data: data?.data ? data?.data : null
          });
        }

      case "progress_bar_update":
        if (data && data.id) {
          this.props.updateProgressBar({
            id: data.id,
            progress: parseInt(data.completed_count)
          });
        }
        break;
      case "progress_bar_complete":
        if (data && data.id) {
          this.props.completeProgressBar({
            progress_bar_id: data.id,
            progress_bar_data: data?.data
          });
        }
        break;

      case "workflow_event":
        if (
          (this.props.active_workflow_history &&
            this.props.active_workflow_history?.id ==
              data.workflow_enrollment_log_id) ||
          (this.props.active_property &&
            this.props.active_property.deal &&
            this.props.active_property.deal.id == data.lead_id)
        ) {
          this.props.reloadWorkflowHistory({
            token: this.props.token,
            workflow_enrollment_log_id: data.workflow_enrollment_log_id
          });
        }

        break;

      default:
        break;
    }
  }
  render() {
    return <Wrapper />;
  }
}

const mapStateToProps = ({
  auth,
  property_map,
  lead,
  route,
  native,
  workflow,
  list,
  dialer,
  modal
}) => {
  const { token, user } = auth;
  const { active_property, map_properties } = property_map;
  const { list_properties, updated_properties, bulk_edits } = lead;
  const { map_routes } = route;
  const { isMobile, device } = native;
  const { active_workflow_history, workflow_history } = workflow;
  const { active_list_tab, active_list } = list;
  const {
    active_call,
    active_dialer_contact,
    rejected_call,
    current_call_session
  } = dialer;
  const { progress_bars } = modal;

  return {
    map_routes,
    token,
    user,
    active_property,
    map_properties,
    list_properties,
    updated_properties,
    bulk_edits,
    active_workflow_history,
    workflow_history,
    isMobile,
    active_list,
    active_list_tab,
    device,
    active_call,
    active_dialer_contact,
    rejected_call,
    progress_bars,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  updateConversation,
  getBulkEdits,
  getBilling,
  reloadLeads,
  updateBulkEdit,
  getOwnerInfoSuccess,
  reloadList,
  loadRoutes,
  setActiveTaskCount,
  setModal,
  toggleModal,
  selectActiveTask,
  setTaskPanel,
  reloadWorkflowHistory,
  triggerLeadsReload,
  triggerLeadsCountReload,
  deleteLeads,
  updateListBuilderProgress,
  setUpdatedLeads,
  setUpdatedOwner,
  logout,
  getUser,
  getSourceOfTruth,
  updateDialerConversation,
  updateActiveCall,
  updateProgressBar,
  addProgressBar,
  completeProgressBar,
  getPhoneCall,
  setDialerConversation,
  setActiveDialerContact,
  updateCallSessionCount,
  aiListBuildScan,
  completeAiListBuilder,
  setUpdatedProperties
})(PusherWrapper);

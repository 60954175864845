import React, { Component } from "react";
import { connect } from "react-redux";

import {
  setActiveDialerContact,
  updateActiveCall,
  replaceSidePanel,
  phoneCall,
  resetActiveCall,
  updateCallSession,
  updateCalledNumbers,
  getCallSession,
  showErrorMessage,
  getDialerStats,
  toTitleCase,
  killAllAudio
} from "app/NativeActions";

import DialerComponents from "app/NativeComponents/components/NativeDialer/DialerComponents";
import moment from "moment/moment";

class DialerInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      call_status: null,
      call_time: null,
      call_id: !!props?.call_id ? props.call_id : null,
      mic_permissions: null,
      countdown: props.user?.user_settings?.dialer_settings?.practice_mode
        ? 5
        : 3,
      call_canceled: false,
      bypass_countdown: false,
      start_call_loading: false,
      start_call_loading_id: null,
      audio_input_devices: [],
      audio_output_devices: [],
      selected_input_device: null,
      selected_output_device: null
    };

    this._call = null;

    this.handleDialer = this.handleDialer.bind(this);
    this.handleHangup = this.handleHangup.bind(this);
    this.acceptIncomingCall = this.acceptIncomingCall.bind(this);

    this.startCall = this.startCall.bind(this);

    this.setCallTime = this.setCallTime.bind(this);
    this.stopTimer = this.stopTimer.bind(this);

    this.closeSession = this.closeSession.bind(this);
    this.completeSession = this.completeSession.bind(this);

    this.updateCountdown = this.updateCountdown.bind(this);
    this.clearCountdown = this.clearCountdown.bind(this);
    this.bypassCountdown = this.bypassCountdown.bind(this);

    this.handleNumberChange = this.handleNumberChange.bind(this);

    this.getAudioDevices = this.getAudioDevices.bind(this);
    this.selectNewAudioInputDevice = this.selectNewAudioInputDevice.bind(this);
    this.selectNewAudioOutputDevice =
      this.selectNewAudioOutputDevice.bind(this);
    this.callError = this.callError.bind(this);
  }

  async getAudioDevices() {
    let media_stream;
    try {
      let audio_input_devices = [];
      let audio_output_devices = [];

      let default_input_device = null;
      let default_output_device = null;

      let inDevices = await this.props.telnyx_client.getAudioInDevices();
      inDevices.forEach((device, i) => {
        if (device.deviceId === "default") {
          default_input_device = {
            id: device.deviceId,
            label: device.label
          };
        }

        audio_input_devices.push({
          id: device.deviceId,
          label: device.label
        });
      });
      let outDevices = await this.props.telnyx_client.getAudioOutDevices();
      outDevices.forEach((device, i) => {
        if (device.deviceId === "default") {
          default_output_device = {
            id: device.deviceId,
            label: device.label
          };
        }

        audio_output_devices.push({
          id: device.deviceId,
          label: device.label
        });
      });

      this.setState({
        audio_input_devices,
        selected_input_device:
          !this.state.selected_input_device && default_output_device
            ? default_output_device
            : this.state.selected_input_device,
        audio_output_devices,
        selected_output_device:
          !this.state.selected_output_device && default_output_device
            ? default_output_device
            : this.state.selected_output_device
      });
    } catch (err) {
      /* handle the error */
    }
  }

  selectNewAudioInputDevice(audio_device) {
    if (this._call) {
      this._call.setAudioInDevice(audio_device).then(() => {
        this.setState({
          selected_input_device: audio_device
        });
        this.getAudioDevices();
      });
    } else {
      this.setState({
        selected_input_device: audio_device
      });
    }
  }
  selectNewAudioOutputDevice(audio_device) {
    if (this._call) {
      this._call
        .setAudioOutDevice(audio_device)
        .then(() => {
          this.setState({
            selected_output_device: audio_device
          });
          this.getAudioDevices();
        })
        .catch(error => {
          console.error("Error setting audio output device:", error);
        });
    } else {
      this.setState({
        selected_output_device: audio_device
      });
    }
  }

  getLoadingId() {
    //cerate a random id and set it to the state loading_id
    return Math.floor(Math.random() * 100000000000000000);
  }

  stopTimer() {
    this.setState(
      { call_start_time: null, isTimerActive: false, call_time: null },
      () => {
        clearTimeout(this._call_time_interval);
      }
    );
  }

  setCallTime() {
    if (this.state.call_start_time && this.state.isTimerActive) {
      let current_time = moment().format("YYYY-MM-DD HH:mm:ss");

      let ms = moment(current_time, "YYYY-MM-DD HH:mm:ss").diff(
        moment(this.state.call_start_time, "YYYY-MM-DD HH:mm:ss")
      );

      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      this.setState(
        {
          call_time: s
        },
        () => {
          clearTimeout(this._call_time_interval);
          this._call_time_interval = setTimeout(() => {
            this.setCallTime();
          }, 1000);
        }
      );
    }
  }

  updateCountdown() {
    this.setState(
      {
        countdown: this.state.countdown - 1 > 0 ? this.state.countdown - 1 : 0
      },
      () => {
        clearTimeout(this._countdown_interval);

        if (this.state.countdown > 0) {
          this._countdown_interval = setTimeout(() => {
            this.updateCountdown();
          }, 1000);
        } else if (
          this.props.active_call?.call_id &&
          this.props.active_call?.call_status === "loading" &&
          !this.state.call_canceled
        ) {
          this.handleDialer();
        }
      }
    );
  }

  clearCountdown(dont_cancel = false) {
    clearTimeout(this._countdown_interval);
    this.setState({
      countdown: 0,
      call_canceled: !dont_cancel,
      bypass_countdown: false
    });

    if (!dont_cancel && this.props.active_call?.call_id) {
      this.props.phoneCall({
        token: this.props.token,
        type: "cancel_call",
        call_id: this.props.active_call?.call_id
      });
    }
  }

  bypassCountdown() {
    this.setState(
      {
        countdown: 0,
        call_canceled: false,
        bypass_countdown: true
      },
      () => {
        if (
          this.props.active_call?.call_id &&
          this.props.active_call?.call_status === "loading" &&
          !this.state.call_canceled
        ) {
          this.handleDialer();
        }
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this._call_time_interval);
    clearTimeout(this._countdown_interval);
    clearTimeout(this._animation_timeout);
  }

  componentDidMount() {
    this.getAudioDevices();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.active_call?.call_status !==
        prevProps.active_call?.call_status &&
      this.props.active_call?.call_status === "calling"
    ) {
      this.getAudioDevices();
    }

    if (this.state.mic_permissions === "granted") {
      if (
        this.props.active_call.call_status !==
          prevProps.active_call.call_status &&
        (this.props.active_call.call_status === "calling" ||
          (this.props.device === "mobile" &&
            this.props.active_call.call_status === "connected")) &&
        !this.state.call_start_time &&
        !this.props.active_dialer_contact?.answered_on_another_device
      ) {
        this.setState(
          {
            call_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
            isTimerActive: true
          },
          () => {
            this.setCallTime();
          }
        );
      }

      if (
        ((this.props.active_call.call_status !==
          prevProps.active_call.call_status &&
          this.props.active_call.call_status === "call_ended") ||
          (this.props.active_call.answered_by !==
            prevProps.active_call.answered_by &&
            this.props.active_call.answered_by === "machine")) &&
        !this.state.show_more
      ) {
        this.setState({
          call_start_time: null
        });
      }

      if (
        this.props.active_call?.call_status === "call_ended" &&
        this.props.active_call?.call_status !==
          prevProps.active_call?.call_status
      ) {
        this.stopTimer();
      }

      if (
        !!this.props.active_dialer_contact?.selected_phone?.number &&
        this.props.active_dialer_contact?.selected_phone?.number !==
          prevProps.active_dialer_contact?.selected_phone?.number &&
        !this.props.active_dialer_contact?.incoming_call &&
        !this.props.active_dialer_contact?.answered_on_another_device
      ) {
        this.handleHangup(true);
        this.handleNumberChange({
          new_number: true,
          old_contact: prevProps.active_dialer_contact
        });
      } else if (
        !!this.props.active_dialer_contact?.selected_phone?.number &&
        this.props.active_dialer_contact?.selected_phone?.number !==
          prevProps.active_dialer_contact?.selected_phone?.number &&
        this.props.active_dialer_contact?.incoming_call &&
        this.state.call_canceled
      ) {
        //clear a canceled call
        this.setState({
          call_canceled: false
        });
      }
    }
  }

  handleNumberChange({
    first_number = false,
    new_number = false,
    old_contact = null,
    mic_permissions = null
  }) {
    if (!!mic_permissions) {
      this.setState({
        mic_permissions
      });
    }

    if (new_number) {
      clearTimeout(this._animation_timeout);
      this.setState(
        {
          newNumberAnimation: "exit"
        },
        () => {
          this._animation_timeout = setTimeout(() => {
            this.setState(
              {
                newNumberAnimation: "enter"
              },
              () => {
                this._animation_timeout = setTimeout(() => {
                  this.setState({
                    newNumberAnimation: null
                  });
                }, 250);
              }
            );
          }, 250);
        }
      );
    }

    //check if an item in this.props.called_numbers contains the number of the current contact
    let found_number = false;
    this.props.called_numbers.map((number, i) => {
      if (number.number == old_contact?.selected_phone?.number) {
        found_number = true;
      }
    });

    if (this.props.current_call_session && !first_number) {
      if (
        (!this.props.active_call?.call_status ||
          this.props.active_call?.call_status === "loading" ||
          this.props.active_call?.call_status === "calling" ||
          this.props.active_call?.call_status === "connected") &&
        !found_number
      ) {
        this.props.updateCallSession({
          token: this.props.token,
          type: "skip_call_session_item",
          call_id: this.props.active_call?.call_id,
          call_session_id: this.props.current_call_session?.id,
          to_phone_number: old_contact?.selected_phone?.number,
          skipped_contact_id: this.props.active_dialer_contact?.individual_key,
          skipped_property_id:
            this.props.active_dialer_contact?.associated_lead?.id
        });
      }
      if (!found_number) {
        this.props.updateCalledNumbers([
          ...this.props.called_numbers,
          {
            number: old_contact?.selected_phone?.number
          }
        ]);
      }
    }

    if (
      !this.props.active_dialer_contact?.incoming_call &&
      !this.props.active_dialer_contact?.answered_on_another_device
    ) {
      clearTimeout(this._countdown_interval);
      this.setState(
        {
          countdown: this.props.user?.user_settings?.dialer_settings
            ?.practice_mode
            ? 5
            : 3,
          call_canceled: false,
          start_call_loading: true
        },
        () => {
          this.startCall();
          clearTimeout(this._countdown_interval);
          this._countdown_interval = setTimeout(() => {
            this.updateCountdown();
          }, 1000);
        }
      );
    }
  }

  callError(error) {
    clearTimeout(this._countdown_interval);
    this.setState(
      {
        start_call_loading: false,
        countdown: 0,
        call_canceled: true
      },
      () => {
        this.props.updateActiveCall({
          ...this.props.active_call,
          dropped_voicemail: false,
          call_status: null,
          stop_mail: false
        });
      }
    );

    this.stopTimer();
    this.props.showErrorMessage(error, "Error");
  }

  startCall() {
    this.props.refreshTelnyxToken({
      onSuccess: () => {
        const call_loading_id = this.getLoadingId();
        this.setState(
          {
            start_call_loading_id: call_loading_id
          },
          () => {
            this.props.phoneCall({
              token: this.props.token,
              type: "start_outgoing_call",
              dialer_provider: "telnyx",
              to_phone_number:
                this.props.active_dialer_contact?.selected_phone?.number,
              phone_owner:
                this.props.active_dialer_contact?.selected_phone?.owner,
              phone_type:
                this.props.active_dialer_contact?.selected_phone?.type,
              do_not_call_flag:
                this.props.active_dialer_contact?.selected_phone
                  ?.do_not_call_flag,
              contact_id: this.props.active_dialer_contact?.individual_key,
              property_id:
                this.props.active_dialer_contact?.associated_lead?.id,
              call_session_id: this.props.current_call_session
                ? this.props.current_call_session?.id
                : null,
              practice_mode:
                this.props.user?.user_settings?.dialer_settings?.practice_mode,
              onLoading: () => {
                this.setState(
                  {
                    start_call_loading: true,
                    call_canceled: false
                  },
                  () => {
                    this.props.updateActiveCall({
                      ...this.props.active_call,
                      call_id: null,
                      dropped_voicemail: false,
                      stop_mail: false,
                      from_phone_number: null,
                      to_phone_number: null,
                      answered_by: null,
                      call_status: "loading"
                    });
                  }
                );
              },
              onError: error => {
                //handle error
                if (call_loading_id == this.state.start_call_loading_id) {
                  this.callError(error);
                }
              },
              onSuccess: results => {
                if (call_loading_id == this.state.start_call_loading_id) {
                  this.props.updateActiveCall({
                    ...this.props.active_call,
                    answered_by: null,
                    dropped_voicemail: false,
                    stop_mail: false,
                    from_phone_number: results.from_phone_number,
                    to_phone_number: results.to_phone_number,
                    call_id: results.call_id
                  });

                  this.setState(
                    {
                      start_call_loading: false
                    },
                    () => {
                      if (
                        this.state.countdown === 0 &&
                        !this.state.call_canceled
                      ) {
                        this.handleDialer();
                      } else if (this.state.call_canceled) {
                        this.props.phoneCall({
                          token: this.props.token,
                          type: "cancel_call",
                          call_id: results.call_id
                        });
                      }

                      if (
                        this.props.current_call_session &&
                        !this.props.current_call_session?.completed
                      ) {
                        this.props.getCallSession({
                          token: this.props.token,
                          type: "get_current_call_session",
                          onLoading: () => {},
                          onError: () => {},
                          onSuccess: results => {}
                        });
                      }

                      if (this.props.active_dialer_contact?.follow_up_queue) {
                        this.props.getDialerStats({
                          token: this.props.token,
                          filter_user: this.props.user?.id,
                          type: "my_follow_up_queue_count"
                        });
                      }
                    }
                  );
                }
              }
            });
          }
        );
      }
    });
  }

  acceptIncomingCall() {
    if (this._call) {
      if (this.props.active_call?.call_id) {
        this.props.phoneCall({
          token: this.props.token,
          type: "end_call",
          call_id: this.props.active_call?.call_id
        });
      }
      this.stopTimer();

      try {
        this._call.hangup();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
    }

    this.props.setActiveDialerContact({
      contact: {
        ...this.props.incoming_call_info?.contact,
        selected_phone: {
          number: this.props.incoming_call_info?.to_phone_number
        },
        associated_lead: this.props.incoming_call_info?.property,
        one_off_call: true,
        incoming_call: true
      },
      active_call: {
        dropped_voicemail: false,
        stop_mail: false,
        from_phone_number: null,
        to_phone_number: null,
        answered_by: "",
        note: "",
        call_id: this.props.incoming_call_info?.call_id,
        call_status: "answered"
      }
    });

    if (this.props.incoming_call) {
      this.props.incoming_call.accept();

      this.props.phoneCall({
        token: this.props.token,
        type: "answered_phone_call",
        call_id: this.props.incoming_call_info?.call_id
      });
    }

    this.setState({
      call_id: this.props.incoming_call_info?.call_id
    });

    this.props.updateActiveCall({
      ...this.props.active_call,
      answered_by: null,
      call_results: null,
      dropped_voicemail: false,
      call_id: this.props.incoming_call_info?.call_id,
      call_status: "answered"
    });

    //start timeer
    this.setState(
      {
        call_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        isTimerActive: true
      },
      () => {
        this.setCallTime();
      }
    );
  }

  async handleDialer() {
    this.props.updateActiveCall({
      ...this.props.active_call,
      call_status: "calling"
    });

    if (this.props.active_call.call_status !== "calling") {
      let encoded_params = {
        CallId: this.props.active_call.call_id,
        CallSource: "web",
        TeamId: this.props.user?.team_id,
        UserId: this.props.user?.id
      };

      //encode in base64
      encoded_params = btoa(JSON.stringify(encoded_params));

      this._call = this.props.telnyx_client.newCall({
        destinationNumber: this.props.active_call.to_phone_number,
        callerNumber: this.props.active_call.from_phone_number,
        clientState: encoded_params,
        useStereo: true,
        mediaSettings: {
          audio: true
        }
      });
    }
  }

  handleHangup(next_call = false) {
    if (this.props.incoming_call) {
      try {
        this.props.incoming_call.hangup();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }

      this.props.clearIncomingCall();
    }

    if (this._call) {
      try {
        this._call.hangup();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
    }

    this._kill_audio_timeout = setTimeout(() => {
      killAllAudio();
    }, 1000);
  }

  closeSession() {
    if (
      this.props.current_call_session?.id &&
      !this.props.active_dialer_contact?.one_off_call &&
      !this.props.active_dialer_contact?.incoming_call
    ) {
      this.props.updateCallSession({
        token: this.props.token,
        type: "check_and_end_call_session",
        call_session_id: this.props.current_call_session?.id,
        onLoading: () => {},
        onError: () => {},
        onSuccess: results => {
          if (results.call_session?.completed) {
            this.props.replaceSidePanel({
              slug: "call_session",
              overlay: true,
              data: {
                call_session: results.call_session
              }
            });
          }
        }
      });

      this.props.setActiveDialerContact({
        contact: null,
        queued_numbers: []
      });
    } else {
      this.props.setActiveDialerContact({
        contact: null,
        queued_numbers: []
      });
    }

    this.handleHangup();
  }

  completeSession(end_early = false) {
    if (
      this.props.current_call_session &&
      !this.props.active_dialer_contact?.one_off_call
    ) {
      this.props.updateCallSession({
        token: this.props.token,
        type: end_early ? "end_current_call_session" : "complete_call_session",
        call_session_id: this.props.current_call_session?.id,
        onLoading: () => {
          this.setState({
            complete_session_loading: true
          });
        },
        onError: () => {
          this.setState({
            complete_session_loading: false
          });
        },
        onSuccess: results => {
          this.setState(
            {
              complete_session_loading: false
            },
            () => {
              if (results.call_session?.completed) {
                this.props.replaceSidePanel({
                  slug: "call_session",
                  overlay: true,
                  data: {
                    call_session: results.call_session
                  }
                });
              }
            }
          );
          this.props.setActiveDialerContact({
            contact: null,
            queued_numbers: []
          });
          this.handleHangup();
        }
      });
    } else {
      this.handleHangup();
    }
  }

  render() {
    const { colors } = this.props;
    const { call_status } = this.props.active_call;

    return (
      <DialerComponents
        telnyx_client={this.props.telnyx_client}
        incoming_call_info={this.props.incoming_call_info}
        incoming_call={this.props.incoming_call}
        clearIncomingCall={this.props.clearIncomingCall}
        rejectIncomingCall={this.props.rejectIncomingCall}
        acceptIncomingCall={this.acceptIncomingCall}
        handleHangup={this.handleHangup}
        start_call_loading={this.state.start_call_loading}
        call_time={this.state.call_time}
        startCall={this.startCall}
        call_start_time={this.state.call_start_time}
        isTimerActive={this.state.isTimerActive}
        countdown={this.state.countdown}
        clearCountdown={this.clearCountdown}
        bypassCountdown={this.bypassCountdown}
        call_canceled={this.state.call_canceled}
        newNumberAnimation={this.state.newNumberAnimation}
        closeSession={this.closeSession}
        completeSession={this.completeSession}
        complete_session_loading={this.state.complete_session_loading}
        handleNumberChange={this.handleNumberChange}
        hidePurchaseUpsell={this.props.hidePurchaseUpsell}
        hide_purchase_upsell={this.props.hide_purchase_upsell}
        call={this._call}
        localstream={this.props.localstream}
        mute={this.props.mute}
        muteCall={this.props.muteCall}
        getAudioDevices={this.getAudioDevices}
        selectNewAudioInputDevice={this.selectNewAudioInputDevice}
        selectNewAudioOutputDevice={this.selectNewAudioOutputDevice}
        audio_input_devices={this.state.audio_input_devices}
        audio_output_devices={this.state.audio_output_devices}
        selected_input_device={this.state.selected_input_device}
        selected_output_device={this.state.selected_output_device}
        resetTelnyxDevice={this.props.resetTelnyxDevice}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, dialer, billing }) => {
  const { token, user } = auth;

  const { dark_mode, colors } = settings;
  const { device } = native;
  const { source_of_truth } = billing;
  const {
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers
  } = dialer;
  return {
    token,
    user,
    dark_mode,
    colors,
    device,
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  setActiveDialerContact,
  updateActiveCall,
  replaceSidePanel,
  phoneCall,
  resetActiveCall,
  updateCallSession,
  updateCalledNumbers,
  getCallSession,
  showErrorMessage,
  getDialerStats
})(DialerInner);

import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar,
  Scroll
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class HOALiens extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, refresh: false, offset: 0 };

    this.renderLien = this.renderLien.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState({ refresh: true }, () => {
        this.setState({ refresh: false });
      });
    }
  }

  getTransactionHelperDescription(transaction_type){
    switch (transaction_type) {
      case "LIS":
        return `Lis Pendens`;
      case "LN":
        return `Lien`;
      case "NOD":
        return `Notice Of Default`;
      default:
        return ``;
    }
  }

  renderLien(lien) {
    const { search } = this.props;

    let lien_amount = 0;
    if (!!lien?.hoa_lien_amount && lien?.hoa_lien_amount > 0) {
      lien_amount = lien?.hoa_lien_amount;
    }

    return (
      <Card label={lien?.label} style={{ paddingTop: 10, flex: 1 }}>
        <Row
          style={
            this.props.expanded
              ? {
                  flexDirection: "row",
                  alignItems: "stretch",
                  flexWrap: "wrap"
                }
              : { flexDirection: "column", alignItems: "stretch" }
          }
        >
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={search}
            item={lien?.doc_type}
            label={"Document type:"}
            format={"text"}
            hiddenFromNonSubs={true}
            helper={"lien_doc_category"}
          />
          {!!lien_amount ? (
            <InformationItem
              style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
              search={search}
              item={lien_amount}
              label={"Lien amount:"}
              format={"money"}
              hiddenFromNonSubs={true}
              helper={"lien_amount"}
            />
          ) : null}

          {this.state.more_info || !!search ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.doc_title}
                label={"Document title:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"lien_doc_title"}
              />

              {!!lien?.doc_numberlien_number ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={lien?.doc_number}
                  label={"Document number:"}
                  format={"number"}
                  hiddenFromNonSubs={true}
                  helper={"lien_document_number"}
                />
              ) : null}
              {!!lien?.transaction_type ? (
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={this.getTransactionHelperDescription(lien?.transaction_type)}
                label={"HOA Transaction title:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_transaction_title"}
              />
              ) : null}
              {!!lien?.document_date ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={lien?.document_date}
                  label={"Document date:"}
                  format={"date"}
                  hiddenFromNonSubs={true}
                  helper={"lien_filing_date"}
                />
              ) : null}

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.hoa_lien_name}
                label={"HOA Lien Name:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_lien_name"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.hoa_lien_agency}
                label={"HOA Lien Agency:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_lien_agency"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.hoa_contact_company}
                label={"HOA Contact Company:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_contact_company"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.hoa_contact_name}
                label={"HOA Contact Name:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_contact_name"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.hoa_contact_address_full}
                label={"HOA Contact Address:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_address_mailing"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.hoa_contact_phone}
                label={"HOA Contact Phone:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"hoa_contact_phone"}
              />
            </>
          ) : null}
        </Row>

        {!search && this.state.more_info ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: false
                },
                () => {
                  if (this.props.device === "desktop") {
                    this.props.scrollToSection({
                      id: "hoa-lien-information",
                      offset: this.state.offset
                    });
                  } else {
                    this._interval = setTimeout(() => {
                      this.props.scrollToSection({
                        id: "hoa-lien-information",
                        offset: this.state.offset
                      });
                    }, 50);
                  }
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-up"}
          >
            Less Info
          </InlineButton>
        ) : !search ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: true
                },
                () => {
                  this.props.scrollToSection({
                    id: "hoa-lien-information",
                    offset: this.state.offset
                  });
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-down"}
          >
            More Info
          </InlineButton>
        ) : null}
      </Card>
    );
  }

  render() {
    const { property, search, colors } = this.props;
    const liens = property?.hoalien_data;

    if (!this.state.refresh && liens && liens.length > 0) {
      return (
        <>
          {"HOA Liens".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          !search ? (
            <Wrapper
              id={"hoa-lien-information"}
              style={{ marginTop: 10 }}
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;
                this.setState({
                  offset: y
                });
              }}
            >
              {liens.length == 1 ? (
                <>{this.renderLien(liens[0])}</>
              ) : liens.length > 0 ? (
                <>
                  {this.props.expanded ? (
                    <>
                      <Scroll horizontal={true} style={{ width: 650 }}>
                        {liens.map((lien, index) => {
                          return (
                            <Wrapper
                              key={"mort_" + 1}
                              style={{
                                minWidth: 450,
                                maxWidth: 450,
                                width: "100%"
                              }}
                            >
                              {this.renderLien(lien)}
                            </Wrapper>
                          );
                        })}
                      </Scroll>
                    </>
                  ) : (
                    <Wrapper
                      style={
                        this.props.device === "mobile"
                          ? { marginTop: 10 }
                          : { marginBottom: 40, marginTop: 10 }
                      }
                    >
                      <Carousel
                        items={liens}
                        centerMode={true}
                        infinite={true}
                        dots={this.props.device === "mobile"}
                        renderItem={obj => {
                          const lien = obj?.item;
                          return (
                            <Wrapper
                              style={{ marginRight: 10, marginLeft: 10 }}
                            >
                              {this.renderLien(lien)}
                            </Wrapper>
                          );
                        }}
                        include_pagination={true}
                        dotColor={colors.text_color}
                      />
                    </Wrapper>
                  )}
                </>
              ) : null}
            </Wrapper>
          ) : null}
        </>
      );
    }
    return null;
  }
}

export default HOALiens;

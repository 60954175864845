import React, { Component } from "react";
import { Map as MapGL, Source, Layer, useControl } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawControl from "./DrawControl";

class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this._moveTimeout = null;
    this._zoomTimeout = null;
    this.state = {
      features: {}
    };
  }

  onDelete = e => {};

  componentWillUnmount() {
    clearInterval(this._movetimeout);
    clearInterval(this._zoomtimeout);
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.map_type !== this.props.map_type ||
      prevProps.dark_mode !== this.props.dark_mode ||
      prevProps.drawing !== this.props.drawing
    ) {
      return true;
    }
    return false;
  }

  sendCoordinates(features) {
    let drawing_coordinates = [];

    if (features && features.length > 0) {
      const geometry = features[0].geometry;
      if (geometry && geometry.coordinates.length > 0) {
        const coordinates = geometry.coordinates[0];

        for (let i = 0; i < coordinates.length; i++) {
          drawing_coordinates.push({
            latitude: coordinates[i][1],
            longitude: coordinates[i][0]
          });
        }
      }
    }

    if (drawing_coordinates.length > 2) {
      this.props.onDraw(drawing_coordinates);
    }
  }

  render() {
    const {
      user,
      scrollZoom = true,
      latitude,
      longitude,
      drawing,
      colors,
      dark_mode
    } = this.props;
    const { features } = this.state;

    const onDrawCreate = ({ features }) => {
      this.props.drawingCreated();
      this.sendCoordinates(features);
    };

    const onDrawUpdate = ({ features }) => {
      this.sendCoordinates(features);
    };

    const onClick = (map, e) => {
      this.props.onMapClick(map, e);
    };

    const getCenter = map => {
      if (map?.current) {
        map = map.current;
      }
      if (map) {
        if (map?.getCenter) {
          const coordinate = map?.getCenter();

          this.props.updateCenter({
            latitude: coordinate?.lat,
            longitude: coordinate?.lng
          });
        }

        this.props.updateBounds(this.mapRef.current);
      }
    };

    const onStyleLoad = map => {
      this.props.onStyleLoad();
      getCenter(map);
    };

    const calculateZoom = map => {
      if (map) {
        const zoom = map;
        this.props.updateZoomProperties({
          zoom: zoom
        });

        this.props.updateBounds(this.mapRef);
      }
    };
    const {
      starting_zoom,
      maxZoom,
      minZoom,
      starting_lng,
      starting_lat,
      mapIsMoving,
      toggleMapMoving,
      hidePopover,
      map_type
    } = this.props;

    return (
      <MapGL
        ref={this.mapRef}
        mapboxAccessToken="pk.eyJ1IjoiZGVhbG1hY2hpbmUiLCJhIjoiY2pyczBqMXQ4MDRiMDQzcnhldmpzbnZsYyJ9.YGxxBHBvL31H6K1EBMuIog"
        mapStyle={
          map_type === "satellite"
            ? "mapbox://styles/dealmachine/cm48lzqp9002k01qo2km1hsz6?optimize=true"
            : dark_mode === "dark_mode"
            ? "mapbox://styles/dealmachine/cm47dri0x00zj01qqd9qodyt3?optimize=true"
            : "mapbox://styles/dealmachine/cm47adykc00ty01qr4v17bqmu?optimize=true"
        }
        initialViewState={{
          longitude: parseFloat(starting_lng),
          latitude: parseFloat(starting_lat),
          zoom: parseInt(starting_zoom)
        }}
        maxZoom={parseInt(maxZoom)}
        minZoom={parseInt(minZoom)}
        style={{
          width: "100%",
          height: "100%",
          position: "relative"
        }}
        scrollZoom={scrollZoom}
        onZoomStart={() => {
          clearTimeout(this._zoomTimeout);
          toggleMapMoving(true);
          hidePopover();
        }}
        onZoomEnd={event => {
          clearTimeout(this._zoomTimeout);
          toggleMapMoving(false);
          this._zoomTimeout = setTimeout(() => {
            if (!mapIsMoving) {
              calculateZoom(event.viewState.zoom);
            }
          }, 500);
        }}
        onMoveStart={() => {
          toggleMapMoving(true);
          clearTimeout(this._moveTimeout);
          hidePopover();
        }}
        onMoveEnd={event => {
          toggleMapMoving(false);
          clearTimeout(this._moveTimeout);
          const map = this.mapRef;

          this._moveTimeout = setTimeout(() => {
            if (!mapIsMoving) {
              getCenter(map);
            }
          }, 500);
        }}
        onLoad={event => {
          if (this.props.onRef) {
            this.props.onRef(this.mapRef.current);
          }

          const map = this.mapRef.current;
          onStyleLoad(map);
          const mapper = this.mapRef.current.getMap();
          map.on('styleimagemissing', () => {
            if (!mapper.hasImage('lead')) {
              mapper.loadImage(
                "/assets/images/active_pin.png",
                (error, image) => {
                  if (error) throw error;
                  if (!mapper.hasImage('lead'))
                    mapper.addImage('lead', image);
                }
              );
            }
          if (!mapper.hasImage('active_lead')) {
            mapper.loadImage(
              "/assets/images/orange_pin.png",
              (error, image) => {
                if (error) throw error;
                if (!mapper.hasImage('active_lead'))
                  mapper.addImage('active_lead', image);
              }
            );
          }
          if (!mapper.hasImage('lead_white')) {
            mapper.loadImage(
              "/assets/images/white_pin.png",
              (error, image) => {
                if (error) throw error;
                if (!mapper.hasImage('lead_white'))
                  mapper.addImage('lead_white', image);
              }
            );
          }
          if (!mapper.hasImage('person_pin')) {
            mapper.loadImage(
              "/assets/images/person_pin_orange.png",
              (error, image) => {
                if (error) throw error;
                if (!mapper.hasImage('person_pin'))
                  mapper.addImage('person_pin', image);
              }
            );
          }
        });


          mapper.loadImage("/assets/images/active_pin.png", (error, image) => {
            if (error) {
              console.log("ERROR ON LOADING IMAGE", error);
              return;
            }
            mapper.addImage("lead", image);
          });
          mapper.loadImage("/assets/images/white_pin.png", (error, image) => {
            if (error) return;
            mapper.addImage("lead_white", image);
          });
          mapper.loadImage("/assets/images/orange_pin.png", (error, image) => {
            if (error) return;
            mapper.addImage("active_lead", image);
          });
          mapper.loadImage(
            "/assets/images/person_pin_orange.png",
            (error, image) => {
              if (error) return;
              mapper.addImage("person_pin", image);
            }
          );
        }}
        onClick={onClick}
        logoPosition="bottom-left"
        attributionControl={false}
      >
        <Source
          id="all_borders_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="properties_on_map_fill"
            type="fill"
            minzoom={16}
            paint={{
              "fill-color": colors.border_color,
              "fill-outline-color": colors.text_color,
              "fill-opacity": 0.2
            }}
          />
        </Source>
        <Source
          id="borders_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="highlights_on_map_fill"
            type="fill"
            minzoom={16}
            paint={{
              "fill-color": [
                "match",
                ["get", "active_status"],
                "active_viewing",
                colors.orange_color_muted,
                "viewing",
                colors.active_color_muted,
                "active_highlighted",
                colors.orange_color_muted,
                "highlighted",
                colors.actionable_text_color,
                colors.border_color
              ],
              "fill-outline-color": [
                "match",
                ["get", "active_status"],
                "active_viewing",
                colors.orange_color,
                "viewing",
                colors.actionable_text_color,
                "active_highlighted",
                colors.orange_color,
                "highlighted",
                colors.actionable_text_color,
                colors.border_color
              ],
              "fill-opacity": [
                "match",
                ["get", "active_status"],
                "viewing",
                0.3,
                "active_highlighted",
                0.3,
                "highlighted",
                this.props.main_map_location?.map_type === "satellite"
                  ? 0.3
                  : 0.15,
                0.15
              ]
            }}
          />
        </Source>
        <Source
          id="locations_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="locations_on_map_fill"
            type="fill"
            paint={{
              "fill-color": colors.actionable_text_color,
              "fill-outline-color": colors.actionable_text_color,
              "fill-opacity": 0.05
            }}
          />
          <Layer
            id="locations_on_map_line"
            type="line"
            paint={{
              "line-color": colors.actionable_text_color,
              "line-width": 3,
              "line-opacity": 0.7
            }}
          />
        </Source>

        <Source
          id="routes_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="routes_on_map_line"
            type="line"
            minzoom={12}
            paint={{
              "line-color": [
                "match",
                ["get", "route_status"],
                "tier_1",
                colors.active_color,
                "tier_2",
                colors.orange_color_light,
                "tier_3",
                colors.error_color,
                "rgba(255, 255, 255, 0)"
              ],
              "line-width": 3,
              "line-opacity": 0.7
            }}
          />
        </Source>
        <Source
          id="active_route_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="active_route_on_map_line"
            type="line"
            minzoom={6}
            paint={{
              "line-color": colors.orange_color,
              "line-width": 3,
              "line-opacity": 1
            }}
          />
        </Source>

        <Source
          id="current_route_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="current_route_on_map_line"
            type="line"
            minzoom={6}
            paint={{
              "line-color": colors.orange_color,
              "line-width": 3,
              "line-opacity": 1
            }}
          />
        </Source>

        <Source
          id="properties_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="properties_on_map_circle"
            type="circle"
            minzoom={9}
            paint={{
              "circle-color": [
                "match",
                ["get", "active_status"],
                "active",
                "rgba(242, 99, 58, 1)",
                "active_highlighted",
                "rgba(242, 99, 58, 1)",
                "viewing",
                "rgba(242, 99, 58, 1)",
                "lead_status",
                "rgba(242, 99, 58, 1)",
                "highlighted",
                colors.active_color_muted,
                dark_mode === "dark_mode"
                  ? "rgba(255, 255, 255, 0.5)"
                  : "rgba(44, 44, 44, 0.75)"
              ],
              "circle-opacity": [
                "match",
                ["get", "lead_status"],
                "active_lead",
                0,
                "viewing_lead",
                0,
                "lead",
                0,
                1
              ],
              "circle-radius": [
                "match",
                ["get", "active_status"],
                "active",
                3,
                "viewing",
                0,
                "lead",
                0,
                3
              ],
              "circle-stroke-width": [
                "match",
                ["get", "active_status"],
                "active_viewing",
                1,
                "viewing",
                1,
                "lead",
                0,
                1
              ],
              "circle-stroke-color": [
                "match",
                ["get", "active_status"],
                "active",
                colors.card_color,
                "active_highlighted",
                colors.card_color,
                "viewing",
                colors.card_color,
                "highlighted",
                colors.actionable_text_color,
                "active_viewing",
                colors.card_color,
                colors.card_color
              ]
            }}
          />
          {
            <Layer
              minzoom={16}
              id="properties_on_map_text"
              type="symbol"
              layout={{
                "text-field": ["get", "property_label"],
                "text-size": 12,
                "text-anchor": "top",
                "text-justify": "center",
                "text-offset": [0, 0.5]
              }}
              paint={{
                "text-color":
                  this.props.map_type == "satellite"
                    ? colors.white_text_color
                    : colors.text_color,
                "text-halo-width": 3,
                "text-halo-color":
                  dark_mode == "dark_mode" || this.props.map_type == "satellite"
                    ? "rgba(0, 0, 0, 0.5)"
                    : "rgba(255, 255, 255, 0.75)"
              }}
            />
          }
          <Layer
            id="leads_on_map_symbol"
            type="symbol"
            layout={{
              "icon-image": [
                "match",
                ["get", "lead_status"],
                "active_lead",
                "active_lead",
                "viewing_lead",
                "active_lead",
                "lead",
                "lead",
                ""
              ],
              "icon-size": 0.3,
              "icon-anchor": "bottom"
            }}
          />
        </Source>

        <Source
          id="street_view_marker"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            id="street_view_marker_symbol"
            type="symbol"
            minzoom={10}
            layout={{
              "icon-image": "person_pin",
              "icon-size": 0.5,
              "icon-anchor": "bottom"
            }}
          />
        </Source>
        {drawing ? (
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            defaultMode="draw_polygon"
            onCreate={onDrawCreate}
            onUpdate={onDrawUpdate}
            minZoom={this.props.minZoom}
            maxZoom={this.props.maxZoom}
            styles={[
              {
                id: "gl-draw-line",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "LineString"],
                  ["!=", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-dasharray": [0.2, 2],
                  "line-width": 2
                }
              },
              // polygon fill
              {
                id: "gl-draw-polygon-fill",
                type: "fill",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["!=", "mode", "static"]
                ],
                paint: {
                  "fill-color": colors.actionable_text_color,
                  "fill-outline-color": colors.actionable_text_color,
                  "fill-opacity": 0.1
                }
              },
              // polygon mid points
              {
                id: "gl-draw-polygon-midpoint",
                type: "circle",
                filter: [
                  "all",
                  ["==", "$type", "Point"],
                  ["==", "meta", "midpoint"]
                ],
                paint: {
                  "circle-radius": 3,
                  "circle-color": colors.actionable_text_color
                }
              },
              {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["!=", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-dasharray": [0.2, 2],
                  "line-width": 2
                }
              },
              // vertex point halos
              {
                id: "gl-draw-polygon-and-line-vertex-halo-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"]
                ],
                paint: {
                  "circle-radius": 5,
                  "circle-color": colors.card_color
                }
              },
              // vertex points
              {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"]
                ],
                paint: {
                  "circle-radius": 3,
                  "circle-color": colors.actionable_text_color
                }
              },

              // INACTIVE (static, already drawn)
              // line stroke
              {
                id: "gl-draw-line-static",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "LineString"],
                  ["==", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-width": 3
                }
              },
              // polygon fill
              {
                id: "gl-draw-polygon-fill-static",
                type: "fill",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["==", "mode", "static"]
                ],
                paint: {
                  "fill-color": colors.actionable_text_color,
                  "fill-outline-color": colors.actionable_text_color,
                  "fill-opacity": 0.1
                }
              },
              // polygon outline
              {
                id: "gl-draw-polygon-stroke-static",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["==", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-width": 2
                }
              }
            ]}
          />
        ) : null}
      </MapGL>
    );
  }
}

export default Map;

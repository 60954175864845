import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Button, PrimaryButton } from "app/NativeComponents/common";
import { ToggleSwitch } from "app/NativeComponents/snippets";

import {
  pushSidePanel,
  replaceSidePanel,
  triggerHapticFeedback,
  showErrorMessage,
  popSidePanel,
  logMarketingEvent
} from "app/NativeActions";

class FeatureUnlockButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  onLockPress() {}

  onLockPressOverride() {}

  render() {
    const {
      type,
      user,
      meta_slug,
      onPress = () => {},
      trackingId,
      onLockPress = null,
      onLockPressOverride = null,
      force_lock = false,
      force_unlock = false,
      disabled,
      marketing_slug = null,
      marketing_data = null,
      toggleMenu = null
    } = this.props;

    const { all_feature_metadata, source_of_truth, source_of_truth_loading } =
      this.props;

    const { hovering } = this.state;

    let has_feature = false;
    let current_limit = 0;
    let feature_upsell_info = null;

    //don't display locks while loading
    if (source_of_truth_loading && !source_of_truth) {
      has_feature = true;
    } else if (source_of_truth && source_of_truth?.has_subscription) {
      for (const [key, value] of Object.entries(
        source_of_truth?.current_limits
      )) {
        if (value?.meta_slug == meta_slug) {
          current_limit = value?.amount;
        }
      }
      for (let i = 0; i < all_feature_metadata.length; i++) {
        if (all_feature_metadata[i].slug == meta_slug) {
          if (all_feature_metadata[i].unlock_type == "limit") {
            if (
              source_of_truth?.subscription?.metadata[meta_slug] &&
              parseInt(source_of_truth?.subscription?.metadata[meta_slug]) ==
                parseInt(all_feature_metadata[i].unlock_limit)
            ) {
              feature_upsell_info = all_feature_metadata[i];
            } else if (!feature_upsell_info) {
              feature_upsell_info = all_feature_metadata[i];
            }
          } else {
            feature_upsell_info = all_feature_metadata[i];
          }
        }
      }

      if (source_of_truth?.subscription?.metadata) {
        const metadata = source_of_truth?.subscription?.metadata;

        if (metadata && metadata[meta_slug]) {
          if (feature_upsell_info?.unlock_type == "limit") {
            if (
              source_of_truth?.subscription?.metadata[meta_slug] ==
                "unlimited" ||
              parseInt(current_limit) <
                parseInt(source_of_truth?.subscription?.metadata[meta_slug])
            ) {
              has_feature = true;
            }
          } else if (feature_upsell_info?.unlock_type == "feature") {
            has_feature = true;
          }
        }
      }
    }

    if (!this.props.user) {
      has_feature = false;
    }

    if (force_lock) {
      has_feature = false;
    } else if (force_unlock) {
      has_feature = true;
    }

    //don't allow features until all onboarding is complete

    let onboarding_slug = "";
    let onboarding_data = "";

    if (meta_slug == "export_limits_upgrade") {
      if (user.can_export_data == 0 && user.team_owner != 1) {
        has_feature = false;
      }
    } else if (meta_slug == "skip_trace") {
      if (!source_of_truth?.has_stripe_card_on_file) {
        has_feature = false;
      } else {
        has_feature = true;
      }
    } else if (meta_slug == "mail") {
      if (!user?.team_name || !user?.team_phone || !user?.address) {
        has_feature = false;
        onboarding_slug = "signature_onboarding";
        onboarding_data = {
          description:
            "We need a little more information before we can send mail. We'll use this info to send mail on your behalf. Enter your information below.",
          buttonText: source_of_truth?.has_stripe_card_on_file
            ? "Save Information"
            : "Next Step",
          onSuccess: source_of_truth?.has_stripe_card_on_file
            ? () => {
                this.props.popSidePanel();
              }
            : () => {
                this.props.pushSidePanel({
                  slug: "add_card_prompt",
                  overlay_modal: true,
                  data: {
                    title:
                      "Start sending mail and get in touch with REAL property owners.",
                    dismiss_button:
                      "No thanks. Let me continue without sending mail."
                  },
                  locked: true
                });
              }
        };
      } else if (!source_of_truth?.has_stripe_card_on_file) {
        has_feature = false;
        onboarding_slug = "add_card_prompt";
        onboarding_data = {
          title:
            "Start sending mail and get in touch with REAL property owners.",
          dismiss_button: "No thanks. Let me continue without sending mail."
        };
      } else {
        has_feature = true;
      }
    }

    return (
      <Button
        style={this.props.style}
        onHover={() => {
          this.setState({
            hovering: true
          });
        }}
        trackingId={trackingId}
        onHoverOut={() => {
          this.setState({
            hovering: false
          });
        }}
        onPress={() => {
          if (!source_of_truth_loading && !disabled) {
            if (this.props.primary) {
              triggerHapticFeedback({ type: "impactHeavy" });
            } else {
              triggerHapticFeedback({ type: "impactLight" });
            }
            if (has_feature) {
              this.props.onPress();

              if (!!marketing_slug) {
                logMarketingEvent({
                  slug: marketing_slug,
                  event_data: marketing_data
                });
              }
            } else {
              //let's figure out an event eventually for pressing a lock button

              if (onLockPress) {
                onLockPress();
              }

              if (onLockPressOverride) {
                onLockPressOverride();
              } else {
                if (!this.props.user) {
                  this.props.pushSidePanel({
                    slug: "create_an_account",
                    overlay: true
                  });
                } else if (!source_of_truth?.has_subscription) {
                  this.props.pushSidePanel({
                    slug: "start_trial",
                    overlay: true
                  });
                } else if (meta_slug == "skip_trace" || meta_slug == "mail") {
                  if (
                    this.props.user?.team_clearance_level >= 2 ||
                    this.props.user?.team_owner == 1
                  ) {
                    this.props.pushSidePanel({
                      slug: onboarding_slug,
                      overlay_modal: true,
                      data: onboarding_data,
                      locked: true
                    });
                  } else if (!disabled) {
                    //show alert with error
                    this.props.showErrorMessage(
                      "Your team owner has not completed onboarding for this account. Talk to your team owner to access.",
                      "Error"
                    );
                  }
                } else {
                  if (
                    !source_of_truth?.has_stripe_card_on_file &&
                    !!feature_upsell_info?.upsell_adapty_paywall
                  ) {
                    this.props.pushSidePanel({
                      slug: "start_trial",
                      overlay: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "unlock_feature_modal",
                      overlay: true,
                      data: {
                        feature_upsell_info
                      }
                    });
                  }
                }
                if (!!toggleMenu) {
                  toggleMenu(false);
                }
              }
            }
          }
        }}
      >
        {has_feature
          ? this.props.renderChildren({ hovering })
          : this.props.renderLockedChildren({ hovering })}
      </Button>
    );
  }
}

const mapStateToProps = ({ auth, native, billing }) => {
  const { token, user } = auth;
  const { device, platform } = native;
  const { source_of_truth, source_of_truth_loading, all_feature_metadata } =
    billing;

  return {
    token,
    user,
    device,
    platform,
    source_of_truth,
    source_of_truth_loading,
    all_feature_metadata
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  replaceSidePanel,
  popSidePanel,
  showErrorMessage
})(FeatureUnlockButton);

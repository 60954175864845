import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from "app/NativeComponents/common";
import Map from "./Map";
import { store } from "app/store";
import { isPointInPolygon, getBounds } from "geolib";
import { PopoverMenu } from "app/NativeComponents/snippets";
import { findClosestProperty } from "../NativeMainMap/MapActions";

class NativeListBuilderMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map_ready: false
    };
    this.updateCenter = this.updateCenter.bind(this);
    this.updateBounds = this.updateBounds.bind(this);
    this.hidePopover = this.hidePopover.bind(this);

    this.updateZoomProperties = this.updateZoomProperties.bind(this);
    this.onCompClick = this.onCompClick.bind(this);
    this.drawingCreated = this.drawingCreated.bind(this);
    this.onDraw = this.onDraw.bind(this);
  }
  onDraw(drawing_coordinates) {
    this.props.onDraw(drawing_coordinates);
  }
  drawingCreated() {
    this.props.drawingCreated();
  }
  hidePopover() {
    this.props.togglePopover(false);
  }
  updateCenter(coordinate) {
    this.props.updateCenter(coordinate);
  }
  updateBounds(map) {
    if (map && this._map) {
      const bounds = map?.getBounds();
      const coordinate = map?.getCenter();

      this.props.updateBounds({
        westLng: bounds._sw.lng,
        southLat: bounds._sw.lat,
        eastLng: bounds._ne.lng,
        northLat: bounds._ne.lat,
        centerLat: coordinate.lat,
        centerLng: coordinate.lng
      });
    }
  }

  updateZoomProperties({ zoom }) {
    if (zoom < this.props.minZoom) {
      //this.drawControl.draw.changeMode("simple_select");
      //this.drawControl.draw.deleteAll();
      //this.props.toggleDrawing(false);
      //this.drawControl.draw.changeMode("static");
    } else if (zoom > this.props.maxZoom) {
      //this.drawControl.draw.changeMode("simple_select");
      //this.drawControl.draw.deleteAll();
      //this.props.toggleDrawing(false);
      //this.drawControl.draw.changeMode("static");
    } else if (this.props.drawing) {
      if (this.props.drawing_coordinates?.length > 0) {
        // this.drawControl.draw.changeMode("simple_select");
      } else {
        // this.drawControl.draw.changeMode("draw_polygon");
      }
    }

    this.props.updateZoomProperties({ zoom });
  }
  onCompClick(e) {
    let property = null;
    if (e?.features?.length > 0) {
      if(e?.features[0].properties?.property) {
        property = e.features[0].properties.property;
      } else {
        property = e?.features[0].properties[0];
      }
      this.props.togglePopover(false);

      if (property) {
        const coordinates = {
          latitude: e.lngLat.lat,
          longitude: e.lngLat.lng
        };

        this.props.togglePopover(true, {
          coordinates,
          point: {
            x: e.point.x,
            y: e.point.y
          },
          property: JSON.parse(property)
        });
      }
    }
  }

  updateCompsOnMapSource(map) {

    if (map) {
      let comps_data = [];
      const { comps_on_map } = this.props;
      for (let i = 0; i < comps_on_map.length; i++) {
        let label = "--";
        let num = parseInt(comps_on_map[i].saleprice);
        if (num >= 1000000) {
          label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
          label = "$" + label;
        } else if (num >= 1000) {
          label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
          label = "$" + label;
        }
        comps_data.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(comps_on_map[i].longitude),
              parseFloat(comps_on_map[i].latitude)
            ]
          },
          properties: {
            property_label: label,
            property: comps_on_map[i],
            active_status:
              this.props.active_comp &&
              comps_on_map[i].property_id == this.props.active_comp?.property_id
                ? "active"
                : "false"
          }
        });
      }
      const source_data = {
        type: "FeatureCollection",
        features: comps_data
      };
      const source = {
        type: "geojson",
        data: source_data
      };
      if (map) {
        if (!map.getSource("comps_on_map")) {
          map.addSource("comps_on_map", source);
        } else {
          map.getSource("comps_on_map").setData(source_data);
        }
      }
    }
  }

  updateActiveLeadOnMapSource(map) {
    if (map) {
      let data = [];
      data.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(this.props.longitude),
            parseFloat(this.props.latitude)
          ]
        }
      });

      const source_data = {
        type: "FeatureCollection",
        features: data
      };
      const source = {
        type: "geojson",
        data: source_data
      };
      if (map && map.isStyleLoaded()) {
        if (!map.getSource("active_lead_on_map")) {
          map.addSource("active_lead_on_map", source);
        } else {
          map.getSource("active_lead_on_map").setData(source_data);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.show_popover !== this.props.show_popover ||
      prevProps.popover_info !== this.props.popover_info ||
      this.props.dark_mode !== prevProps.dark_mode
    ) {
      this.setState({ refresh_map: true }, () => {
        this.setState({ refresh_map: false });
      });
    }

    if (this.state.map_ready) {
      if (prevProps.drawing !== this.props.drawing) {
        if (this.drawControl) {
          if (this.props.drawing) {
            this.drawControl.draw.changeMode("draw_polygon");
          } else {
            this.drawControl.draw.changeMode("simple_select");
            this.drawControl.draw.deleteAll();
          }
        }
      }

      if (this._map) {
        const map = this._map;
        if (this.props.active_comp !== prevProps.active_comp) {
          if (this.props.show_comps_on_map) {
            this.updateCompsOnMapSource(map);
          }
        }

        if (
          prevProps.show_comps_on_map !== this.props.show_comps_on_map ||
          JSON.stringify(prevProps.comps_on_map) !==
            JSON.stringify(this.props.comps_on_map)
        ) {
          if (!this.props.show_comps_on_map) {
            map.getSource("comps_on_map").setData({
              type: "FeatureCollection",
              features: []
            });
          } else {
            let all_coords = [];
            for (let i = 0; i < this.props.comps_on_map?.length; i++) {
              all_coords.push({
                latitude: this.props.comps_on_map[i]?.latitude,
                longitude: this.props.comps_on_map[i]?.longitude
              });
            }

            if (all_coords && all_coords.length > 0) {
              all_coords.push({
                latitude: this.props.latitude,
                longitude: this.props.longitude
              });
              const new_bounds = getBounds(all_coords);
              map.fitBounds([
                [
                  parseFloat(new_bounds.minLng) - 0.001,
                  parseFloat(new_bounds.minLat) - 0.001
                ], // southwestern corner of the bounds
                [
                  parseFloat(new_bounds.maxLng) + 0.001,
                  parseFloat(new_bounds.maxLat) + 0.001
                ] // northeastern corner of the bounds
              ]);
            }

            this.updateCompsOnMapSource(map);
          }
        }

        if (
          prevProps.latitude !== this.props.latitude ||
          prevProps.longitude !== this.props.longitude ||
          prevProps.zoom !== this.props.zoom ||
          prevProps.paddingBottom !== this.props.paddingBottom
        ) {
          this.updateActiveLeadOnMapSource(map);
          const padding = {};
          padding["bottom"] = this.props.paddingBottom;
          map.easeTo({
            center: [
              parseFloat(this.props.longitude),
              parseFloat(this.props.latitude)
            ],
            zoom: this.props.zoom,
            padding: padding,
            duration: 500
          });
        }
        const mapStyle = map.getMap();
        if (prevProps.map_type !== this.props.map_type) {
          if (this.props.map_type == "satellite") {
            mapStyle.setStyle(
              "mapbox://styles/dealmachine/cm48lzqp9002k01qo2km1hsz6?optimize=true"
            );
          } else {
            const dark_mode = store.getState().settings.dark_mode;
            if (dark_mode == "dark_mode") {
              mapStyle.setStyle(
                "mapbox://styles/dealmachine/cm47dri0x00zj01qqd9qodyt3?optimize=true"
              );
            } else {
              mapStyle.setStyle(
                "mapbox://styles/dealmachine/cm47adykc00ty01qr4v17bqmu?optimize=true"
              );
            }
          }
        }
      }
    }
  }

  render() {
    const { latitude, longitude, height, refresh_map = false } = this.props;

    if (true) {
      return (
        <Wrapper
          style={{
            height,
            position: "relative",
            overflow: "hidden"
          }}
        >
          <Map
            onRef={map => {
              this._map = map;
            }}
            onDrawRef={drawControl => {
              this.drawControl = drawControl;
            }}
            drawingCreated={this.drawingCreated}
            onDraw={this.onDraw}
            starting_lat={parseFloat(latitude)}
            starting_lng={parseFloat(longitude)}
            drawing={this.props.drawing}
            starting_zoom={this.props.zoom}
            minZoom={this.props.minZoom || 11.5}
            maxZoom={this.props.maxZoom || 17}
            updateCenter={this.updateCenter}
            updateBounds={this.updateBounds}
            updateZoomProperties={this.updateZoomProperties}
            map_type={this.props.map_type}
            comps_on_map={this.props.comps_on_map}
            onStyleLoad={map => {
              if (map && this._map) {
                this.setState(
                  {
                    map_ready: true
                  },
                  () => {
                    this.updateActiveLeadOnMapSource(map);
                    this.updateCompsOnMapSource(map);
                  }
                );
              }
            }}
            dragPan={false}
            dragRotate={false}
            pitchWithRotate={false}
            scrollZoom={true}
            touchZoomRotate={false}
            onCompClick={this.onCompClick}
            hidePopover={this.hidePopover}
          />
          {true && true ? (
            <Wrapper
              style={{
                position: "absolute",
                top: this.props.popover_info?.point?.y || 0,
                left: this.props.popover_info?.point?.x || 0
              }}
            >
              <PopoverMenu
                show={this.props.show_popover}
                no_swipe={true}
                no_cancel={true}
                hideWithOutsideClick={false}
                onShow={s => {

                }}
                containerStyle={{ zIndex: 1 }}
                popover_width={400}
                //popoverPlacement={"right"}
                renderComponent={options => {
                  return null;
                }}
                renderMenu={() => {
                  return this.props.renderPropertyPopover(
                    this.props.popover_info
                  );
                }}
                menu_items={null}
              />
            </Wrapper>
          ) : null}
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;

  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {})(NativeListBuilderMap);

import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  List,
  InlineButton,
  IconButton,
  SelectItem,
  Tag,
  ConfirmLabel,
  SpinWrapper
} from "app/NativeComponents/snippets";
import {
  updateLead,
  getLists,
  toTitleCase,
  getListStackColor,
  addListToListTabs,
  pushSidePanel,
  renderDate
} from "app/NativeActions";

class ManageListsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false,
      hide_unselected: false,
      selected_lists: props.property?.deal?.lists || [],
      stacked_lists: props.property?.deal?.stacked_lists || [],
      loading_options: []
    };

    this._scroll_view = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { property, lists, lists_loading } = this.props;

    if (prevState.show !== this.state.show && lists?.length == 0) {
      this.props.getLists({
        token: this.props.token,
        load_type: lists.length == 0 ? "load" : "reload",
        no_count: true,
        type: "all_lists",
        begin: 0
      });
    }

    if (
      property?.property_id !== prevProps.property?.property_id ||
      (property?.deal?.lists !== prevProps.property?.deal?.lists &&
        this.state.loading_options.length == 0) ||
      (this.state.show && prevState.show !== this.state.show)
    ) {
      this.setState({
        selected_lists:
          property && property.deal && property.deal.lists
            ? property.deal.lists
            : [],
        loading_options: []
      });
    }

    if (
      this.state.selected_lists !== prevState.selected_lists &&
      this.props.onLoading
    ) {
      this.props.onLoading({
        number_of_lists: this.state.selected_lists.length,
        number_of_stacked_lists: this.state.stacked_lists.length,
        lists: this.state.selected_lists
      });
    }

    if (
      this.state.selected_lists?.length !== prevProps.selected_lists?.length &&
      this.state.selected_lists.length == 0 &&
      this.state.hide_unselected == true
    ) {
      this.setState({
        hide_unselected: false
      });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }
  noExactMatches(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (options[i].label?.toLowerCase() == search?.toLowerCase()) {
        return false;
      }
    }

    return true;
  }

  onRemoveOption(option) {
    const { property } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "remove_leads_from_list",
      deal_ids: property?.deal.id,
      list_ids: option.id,
      no_loading: true,
      onLoading: () => {
        const { selected_lists, stacked_lists, loading_options } = this.state;

        this.setState({
          stacked_lists: stacked_lists.filter(({ id }) => id !== option.id),
          selected_lists: selected_lists.filter(({ id }) => id !== option.id),
          loading_options: [...loading_options, option]
        });
        this.scrollToBottom();
      },
      onSuccess: results => {
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : property
          );
        }

        const { selected_lists, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      },
      onError: () => {
        const { selected_lists, stacked_lists, loading_options } = this.state;

        this.setState({
          stacked_lists: option.included_in_stack_count
            ? [...stacked_lists, option]
            : stacked_lists,
          selected_lists: [...selected_lists, option],
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  onAddOption(option) {
    const { property } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "add_leads_to_lists",
      deal_ids: property?.deal.id,
      list_ids: option.id,
      no_loading: true,
      onLoading: () => {
        const { selected_lists, stacked_lists, loading_options } = this.state;

        this.setState({
          stacked_lists: option.included_in_stack_count
            ? [...stacked_lists, option]
            : stacked_lists,
          selected_lists: [...selected_lists, option],
          loading_options: [...loading_options, option]
        });

        this.scrollToBottom();
      },
      onSuccess: results => {
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : property
          );
        }

        const { selected_lists, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      },
      onError: () => {
        const { selected_lists, stacked_lists, loading_options } = this.state;

        this.setState({
          stacked_lists: option.included_in_stack_count
            ? stacked_lists.filter(({ id }) => id !== option.id)
            : stacked_lists,
          selected_lists: selected_lists.filter(({ id }) => id !== option.id),
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  onCreateOption(new_list) {
    const { property } = this.props;
    if (property) {
      let option = {
        label: toTitleCase(new_list),
        title: toTitleCase(new_list),
        value: "new_list",
        id: "new_list"
      };

      this.props.updateLead({
        token: this.props.token,
        type: "add_leads_to_lists",
        deal_ids: property?.deal.id,
        new_list_name: new_list,
        no_loading: true,
        onLoading: () => {
          const { selected_lists, loading_options } = this.state;

          this.setState({
            search: "",
            selected_lists: [...selected_lists, option],
            loading_options: [...loading_options, option]
          });

          this.scrollToBottom();
        },
        onSuccess: results => {
          if (this.props.propertyUpdated) {
            this.props.propertyUpdated(
              results.properties && results.properties?.length > 0
                ? results.properties[0]
                : property
            );
          }

          const { selected_lists, loading_options } = this.state;

          this.setState({
            loading_options: loading_options.filter(
              ({ id }) => id !== option.id
            ),
            selected_lists: results?.new_list_id
              ? selected_lists.map(option => {
                  if (option.id == "new_list") {
                    return {
                      ...option,
                      value: results.new_list_id,
                      id: results.new_list_id
                    };
                  }
                  return option;
                })
              : selected_lists
          });
        },
        onError: () => {
          const { selected_lists, loading_options } = this.state;

          this.setState({
            selected_lists: selected_lists.filter(({ id }) => id !== option.id),
            loading_options: loading_options.filter(
              ({ id }) => id !== option.id
            )
          });
        }
      });
    }
  }

  scrollToBottom() {
    const { device } = this.props;

    if (device == "desktop" && this._scroll_view && this._scroll_view.current) {
      this._scroll_view.current.scrollTop =
        this._scroll_view.current.scrollHeight + 20;
    } else if (
      device == "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      this._scroll_view.current.scrollToEnd
    ) {
      this._scroll_view.current.scrollToEnd({ animated: true });
    }
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      lists,
      lists_loading,
      style,
      property,
      popoverPlacement = "bottom",
      renderComponent = () => {},
      renderComponentLoading = () => {},
      componentStyle = {}
    } = this.props;
    const {
      selected_lists,
      stacked_lists,
      loading_options,
      search,
      show,
      hide_unselected
    } = this.state;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={400}
        popover_height={450}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={
          property
            ? "Manage lists for " + property.property_address
            : "My Lists"
        }
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <Wrapper
              wrapper_ref={this._popover}
              style={{ flex: device == "mobile" ? 0 : 1 }}
            >
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {lists_loading ? (
                <SpinWrapper text="Loading Lists..." />
              ) : (
                <>
                  <Wrapper
                    style={{ alignSelf: "stretch", alignItems: "flex-start" }}
                  >
                    <ConfirmLabel
                      style={{
                        alignSelf: "stretch",
                        backgroundColor: getListStackColor(stacked_lists.length)
                      }}
                      icon="playlist-add-check"
                      label={
                        stacked_lists.length == 1
                          ? "1 list matching stack count"
                          : stacked_lists.length + " lists matching stack count"
                      }
                      description={
                        selected_lists.length > stacked_lists.length
                          ? selected_lists.length == 1
                            ? "1 list selected"
                            : selected_lists.length + " total lists selected"
                          : ""
                      }
                      renderRight={() => {
                        return (
                          <IconButton
                            tooltip="Manage List Stacking"
                            tooltipPlacement="top"
                            icon={"settings"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "list_stack_settings",
                                overlay: true
                              });
                              this.setState({
                                show: false
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </Wrapper>
                  <Wrapper style={{ alignSelf: "stretch" }}>
                    <SearchBar
                      style={{
                        margin: 10
                      }}
                      title="Search Lists"
                      onChange={value => {
                        this.setState({
                          search: value
                        });
                      }}
                      value={search}
                    />
                  </Wrapper>
                  <List
                    style={{ alignSelf: "stretch" }}
                    items={this.filterOptions(lists)}
                    listHeader={() => {
                      return (
                        <>
                          {/*!!search && this.noExactMatches(lists) ? (
                              <SelectItem
                                select_type="icon"
                                select_icon="add"
                                primary={true}
                                onPress={() => {
                                  this.onCreateOption(search);
                                }}
                              >
                                Add "{search}"
                              </SelectItem>
                            ) : null*/}

                          {loading_options &&
                            loading_options.map((option, i) => {
                              if (option.id == "new_list") {
                                return (
                                  <SelectItem
                                    key={"option_" + i}
                                    loading={true}
                                    selected={true}
                                    onPress={() => {}}
                                  >
                                    {option.label}
                                  </SelectItem>
                                );
                              }
                              return null;
                            })}
                        </>
                      );
                    }}
                    itemStructure={({ item, index }) => {
                      let in_selected_lists = false;

                      for (let i = 0; i < selected_lists.length; i++) {
                        if (item.value == selected_lists[i].value) {
                          in_selected_lists = true;
                        }
                      }

                      let in_loading_options = false;
                      for (let i = 0; i < loading_options.length; i++) {
                        if (item.value == loading_options[i].value) {
                          in_loading_options = true;
                        }
                      }
                      if (
                        (hide_unselected && in_selected_lists) ||
                        !hide_unselected
                      ) {
                        return (
                          <SelectItem
                            key={"option_" + index}
                            select_type={"check"}
                            select_disabled={
                              item.list_type == "build_list" ||
                              item.list_type == "ai_build_list" ||
                              item.list_type == "bulk_import"
                            }
                            select_tooltip={
                              item.list_type == "build_list"
                                ? "You cannot add or remove leads from a smart list or AI Vision list"
                                : item.list_type == "ai_build_list"
                                ? "You cannot add or remove leads from an AI Vision list"
                                : item.list_type == "bulk_import"
                                ? "You cannot add or remove leads from a imported list"
                                : ""
                            }
                            tooltip={
                              !item.included_in_stack_count
                                ? "This list is not included in your stack count"
                                : ""
                            }
                            tooltipPlacement={"top"}
                            hover_icon={""}
                            hover_icon_color={colors.orange_color}
                            selected={in_selected_lists}
                            loading={in_loading_options}
                            onPress={() => {
                              if (
                                item.list_type != "build_list" &&
                                item.list_type != "ai_build_list" &&
                                item.list_type != "bulk_import"
                              ) {
                                //add or remove
                                if (!in_loading_options && property) {
                                  if (in_selected_lists) {
                                    this.onRemoveOption(item);
                                  } else {
                                    this.onAddOption(item);
                                  }
                                }
                              }
                            }}
                            subtext={renderDate(item.date_created)}
                          >
                            {item.label}
                          </SelectItem>
                        );
                      }
                      return null;
                    }}
                  />
                </>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, list, feature_toggle }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors, dark_mode } = settings;
  const { lists, lists_loading } = list;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode,
    lists,
    lists_loading
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getLists,
  addListToListTabs,
  pushSidePanel
})(ManageListsButton);

import React, { Component } from "react";

import { Card, Row } from "app/NativeComponents/common";
import { InformationItem, InlineButton } from "app/NativeComponents/snippets";

class ProfessionalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      more_info: false
    };
  }

  render() {
    const { person } = this.props;

    return (
      <Card
        label={"Consumer Information:"}
        id={"consumer-information"}
        style={{ paddingTop: 10, marginTop: 15 }}
        onLayout={event => {
          const { x, y, width, height } = event?.nativeEvent?.layout;
          this.setState({
            offset: y,
            height: height
          });
        }}
      >
        <Row
          style={
            this.props.expanded
              ? {
                  flexDirection: "row",
                  alignItems: "stretch",
                  flexWrap: "wrap"
                }
              : { flexDirection: "column", alignItems: "stretch" }
          }
        >
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={""}
            item={person?.consumer_type}
            label={"Consumer Type:"}
            hiddenFromNonSubs={true}
            helper={"consumer_type"}
          />
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={""}
            item={person?.spender_type}
            label={"Spender Type:"}
            hiddenFromNonSubs={true}
            helper={"spender_type"}
          />
          {this.state.more_info ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.card_balance}
                label={"Card Balance:"}
                hiddenFromNonSubs={true}
                helper={"card_balance"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.investment_type}
                label={"Investment Type:"}
                hiddenFromNonSubs={true}
                helper={"investment_type"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.buying_power}
                label={"Buying Power:"}
                hiddenFromNonSubs={true}
                helper={"buying_power"}
              />
              {/*
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.agg_credit_tier}
                label={"Estimated Credit:"}
                hiddenFromNonSubs={true}
                helper={"agg_credit_tier"}
              />
              */}
            </>
          ) : null}
        </Row>

        {this.state.more_info ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: false
                },
                () => {
                  if (this.props.device === "desktop") {
                    this.props.scrollToSection({
                      id: "consumer-information",
                      offset: this.state.offset
                    });
                  } else {
                    this._interval = setTimeout(() => {
                      this.props.scrollToSection({
                        id: "consumer-information",
                        offset: this.state.offset
                      });
                    }, 50);
                  }
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-up"}
          >
            Less Info
          </InlineButton>
        ) : (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: true
                },
                () => {
                  this.props.scrollToSection({
                    id: "consumer-information",
                    offset: this.state.offset
                  });
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-down"}
          >
            More Info
          </InlineButton>
        )}
      </Card>
    );
  }
}

export default ProfessionalInformation;

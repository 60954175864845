import {
  BUILD_LIST,
  BUILD_LIST_FAIL,
  BUILD_LIST_SUCCESS,
  CREATE_LIST,
  CREATE_LIST_FAIL,
  CREATE_LIST_SUCCESS,
  ERROR_MESSAGE,
  GET_LISTS,
  GET_LISTS_FAIL,
  GET_LISTS_SUCCESS,
  LOAD_MORE_LISTS,
  LOAD_MORE_LISTS_SUCCESS,
  RELOAD_LISTS,
  GET_SINGLE_LIST_ITEM,
  GET_SINGLE_LIST_ITEM_SUCCESS,
  IS_LOADING,
  LIST_COUNT,
  LIST_COUNT_FAIL,
  LIST_COUNT_SUCCESS,
  REFRESH_LISTS,
  REFRESH_SINGLE_LIST_ITEM,
  REMOVE_LIST_SUCCESS,
  RESET_LIST_MODAL,
  SET_ACTIVE_LIST,
  SET_LIST_MODAL,
  TOGGLE_LIST_MODAL,
  SUCCESS_MESSAGE,
  TRIGGER_LOGOUT,
  UPDATE_LIST,
  UPDATE_LIST_FAIL,
  UPDATE_LIST_SEARCH,
  UPDATE_LIST_SUCCESS,
  UPDATE_USER_LIST_SETTINGS,
  UPDATE_USER_LIST_SETTINGS_FAIL,
  UPDATE_USER_LIST_SETTINGS_SUCCESS,
  EDIT_ACTIVE_LIST,
  UPDATE_NEW_COLUMN_WIDTH,
  RELOAD_LIST,
  ADD_LIST_TO_LIST_TABS,
  REMOVE_LIST_FROM_LIST_TABS,
  SET_ACTIVE_LIST_TAB,
  REORDER_LIST_TABS,
  GET_PINNED_LISTS_SUCCESS,
  UPDATE_LIST_BUILDER_PROGRESS,
  GET_LIST_BUILDER_TEMPLATES_SUCCESS,
  UPDATE_LIST_BUILDER_LOCATION,
  SET_EDIT_LIST_INFO,
  AI_LIST_BUILD_SCAN,
  COMPLETE_AI_LIST_BUILDER,
  DISMISS_AI_LIST_BUILDER,
  AI_LIST_BUILD_START,
  REMOVE_AI_LIST_BUILD_SCAN,
  GET_CURRENT_AI_LIST_BUILD
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";
import { store } from "app/store";

import api from "app/DealMachineCore/apis/DealMachineAPIV2";

const dm_api = api.create();

export const addListToListTabs = list => {
  return {
    type: ADD_LIST_TO_LIST_TABS,
    payload: { list }
  };
};

export const removeListFromListTabs = list => {
  return {
    type: REMOVE_LIST_FROM_LIST_TABS,
    payload: { list }
  };
};

export const setActiveListTab = list => {
  return {
    type: SET_ACTIVE_LIST_TAB,
    payload: { list }
  };
};

export const reorderListTabs = tabs => {
  return {
    type: REORDER_LIST_TABS,
    payload: tabs
  };
};

export const reloadList = ({ list, lists_info_banner }) => {
  return {
    type: RELOAD_LIST,
    payload: { list, lists_info_banner }
  };
};

export const getLists = ({
  token,
  load_type,
  search = "",
  sort_by,
  filter,
  type,
  no_count = false,
  list_id = null,
  begin = 0,
  limit = 25,
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    switch (load_type) {
      default:
        dispatch({ type: GET_LISTS });
        break;
      case "pinned_lists":
        break;
      case "load_more":
        dispatch({ type: LOAD_MORE_LISTS });
        break;
      case "reload":
        dispatch({ type: RELOAD_LISTS });
        break;

      case "refresh":
        dispatch({ type: REFRESH_LISTS });
        break;

      case "single_list":
        dispatch({ type: GET_SINGLE_LIST_ITEM });
        break;

      case "refresh_single_list":
        dispatch({ type: REFRESH_SINGLE_LIST_ITEM });
        break;
    }
    dm_api
      .getLists({
        token,
        search,
        sort_by,
        filter,
        no_count,
        type,
        begin,
        list_id,
        limit
      })
      .then(response => {
        if (response.problem != null) {
          onError();

          dispatch({ type: GET_LISTS_FAIL });
        } else if (response.data.error != false) {
          onError();
          dispatch({ type: GET_LISTS_FAIL });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);

          switch (load_type) {
            default:
              dispatch({
                type: GET_LISTS_SUCCESS,
                payload: response.data.results
              });
              break;

            case "load_more":
              dispatch({
                type: LOAD_MORE_LISTS_SUCCESS,
                payload: response.data.results
              });
              break;

            case "pinned_lists":
              dispatch({
                type: GET_PINNED_LISTS_SUCCESS,
                payload: response.data.results
              });
              break;

            case "single_list":
            case "refresh_single_list":
              dispatch({
                type: GET_SINGLE_LIST_ITEM_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const resetListModal = () => {
  return {
    type: RESET_LIST_MODAL
  };
};
export const setActiveList = list => {
  return {
    type: SET_ACTIVE_LIST,
    payload: list
  };
};
export const setListModal = ({
  title,
  description,
  type,
  selected_leads,
  selected_lists,
  modalAction = null,
  cancelAction = null,
  fieldsUpdated = null,
  popoverTarget = null,
  popoverPlacement = "right"
}) => {
  return {
    type: SET_LIST_MODAL,
    payload: {
      title,
      description,
      type,
      selected_leads,
      selected_lists,
      modalAction,
      cancelAction,
      fieldsUpdated,
      popoverTarget,
      popoverPlacement
    }
  };
};

export const updateListSearch = search => {
  return {
    type: UPDATE_LIST_SEARCH,
    payload: search
  };
};

export const updateList = ({
  token,
  type,
  title,
  smart_list,
  start_marketing,
  list_id,
  list_ids,
  location_type,
  zip,
  city,
  state,
  drawing_coordinates = [],
  preset,
  filters,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    switch (type) {
      default:
        if (onLoading) {
          onLoading();
        } else {
          dispatch({ type: IS_LOADING, payload: true });
        }
        dispatch({ type: UPDATE_LIST });
        break;

      case "build_list_count":
        dispatch({ type: LIST_COUNT });

        break;
    }

    dm_api
      .updateList({
        token,
        type: type,
        list_id: list_id,
        list_ids,
        title,
        smart_list,
        start_marketing,
        location_type,
        zip,
        city,
        state,
        drawing_coordinates: drawing_coordinates
          ? JSON.stringify(drawing_coordinates)
          : "",
        filters: filters
      })
      .then(response => {
        if (response.problem != null) {
          switch (type) {
            default:
              dispatch({ type: UPDATE_LIST_FAIL });

              if (onError) {
                onError();
              } else {
                dispatch({
                  type: ERROR_MESSAGE,
                  payload: { message: response.problem, title: "Error" }
                });
              }

              break;

            case "build_list_count":
              dispatch({ type: LIST_COUNT_FAIL });

              break;
          }
        } else if (response.data.error != false) {
          switch (type) {
            default:
              dispatch({ type: UPDATE_LIST_FAIL });
              if (onError) {
                onError();
              } else {
                dispatch({
                  type: ERROR_MESSAGE,
                  payload: { message: response.data.error, title: "Error" }
                });
              }

              break;

            case "build_list_count":
              dispatch({ type: LIST_COUNT_FAIL });

              break;
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (type) {
            default:
              dispatch({
                type: UPDATE_LIST_SUCCESS,
                payload: response.data.results
              });

              if (onSuccess) {
                onSuccess();
              } else {
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've successfully updated your list",
                    title: "Success!"
                  }
                });
              }

              break;

            case "build_list_count":
              dispatch({
                type: LIST_COUNT_SUCCESS,
                payload: response.data.results
              });
              break;

            case "remove_list":
              dispatch({ type: REMOVE_LIST_SUCCESS, payload: list_id });
              if (onSuccess) {
                onSuccess();
              } else {
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've successfully removed your list",
                    title: "Success!"
                  }
                });

                appRedirect({
                  dispatch,
                  redirect: "goBack",
                  payload: { type: "list_builder" }
                });
              }

              break;
          }
        }
      });
  };
};

export const buildList = ({
  token,
  title,
  list_type,
  smart_list,
  start_marketing,
  list_preset,
  list_filters = {},
  list_area_type,
  list_area,
  list_area_2,
  list_geo_fence,
  estimated_count,
  create_task = false
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: BUILD_LIST });

    dm_api
      .updateList({
        token,
        type: "build_list",
        title,
        list_type,
        smart_list,
        start_marketing,
        list_preset,
        list_filters: JSON.stringify(list_filters),
        list_area_type,
        list_area,
        list_area_2,
        list_geo_fence,
        estimated_count
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({ type: BUILD_LIST_FAIL });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          dispatch({ type: BUILD_LIST_FAIL });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: BUILD_LIST_SUCCESS,
            payload: response.data.results
          });

          dispatch({
            type: SUCCESS_MESSAGE,
            payload: {
              message:
                "Your list is being built. You will receive an email upon completion.",
              title: "Success!"
            }
          });

          if (response.data.results.lists) {
            appRedirect({
              dispatch,
              redirect: "list_item_replace",
              payload: {
                id: response.data.results.lists[0].id,
                create_task: create_task
              }
            });
          } else {
            appRedirect({
              dispatch,
              redirect: "goBack",
              payload: { type: "list_builder" }
            });
          }
        }
      });
  };
};

export const createList = ({
  token,
  title,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: CREATE_LIST });

    dm_api
      .updateList({
        token,
        type: "create_list",
        title
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({ type: CREATE_LIST_FAIL });
          if (onError) {
            onError();
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          }
        } else if (response.data.error != false) {
          dispatch({ type: CREATE_LIST_FAIL });
          if (onError) {
            onError();
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: CREATE_LIST_SUCCESS,
            payload: response.data.results
          });

          if (onSuccess) {
            onSuccess(response.data.results.lists[0].id);
            dispatch({ type: IS_LOADING, payload: false });
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message: "You've successfully created a new list",
                title: "Success!"
              }
            });
          } else {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message: "You've successfully created a new list",
                title: "Success!"
              }
            });

            appRedirect({
              dispatch,
              redirect: "goBack",
              payload: { remove: "create-list" }
            });
          }
        }
      });
  };
};

export const editActiveList = ({ prop, value }) => {
  return {
    type: EDIT_ACTIVE_LIST,
    payload: { prop, value }
  };
};

export const updateNewColumnWidth = ({ slug, width }) => {
  return {
    type: UPDATE_NEW_COLUMN_WIDTH,
    payload: { slug, width }
  };
};

export const updateUserListSettings = ({
  token,
  user_list_columns,
  type,
  onSuccess = () => {},
  onLoading = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    dispatch({ type: UPDATE_USER_LIST_SETTINGS });

    onLoading();

    const list_columns = JSON.stringify(user_list_columns);

    dm_api
      .updateListSettings({
        token,
        type: "list_settings",
        user_list_columns: list_columns
      })
      .then(response => {
        if (response.problem != null) {
          onError();

          dispatch({ type: UPDATE_USER_LIST_SETTINGS_FAIL });

          if (type !== "in_background") {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          }
        } else if (response.data.error != false) {
          onError();

          dispatch({ type: UPDATE_USER_LIST_SETTINGS_FAIL });

          if (type !== "in_background") {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          }
        } else {
          dispatch({
            type: UPDATE_USER_LIST_SETTINGS_SUCCESS,
            payload: {
              results: response.data.results,
              type: type
            }
          });
          if (type !== "in_background") {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message: "You've successfully saved your options",
                title: "Success!"
              }
            });
          }

          onSuccess();
        }
      });
  };
};

export const getListStackColor = (number_of_lists, muted = true) => {
  ///we'll get a legit formual soon
  const colors = store.getState().settings.colors;
  const user = store.getState().auth.user;
  if (user && user.team_lead_scoring_criteria) {
    if (!muted) {
      if (
        parseInt(number_of_lists) >=
        parseInt(user.team_lead_scoring_criteria.list_stacking_level_3)
      ) {
        return colors.orange_color;
      } else if (
        parseInt(number_of_lists) >=
        parseInt(user.team_lead_scoring_criteria.list_stacking_level_2)
      ) {
        return colors.success_color;
      } else if (
        parseInt(number_of_lists) >=
        parseInt(user.team_lead_scoring_criteria.list_stacking_level_1)
      ) {
        return colors.active_color;
      }

      return colors.light_text_color;
    }
    if (
      parseInt(number_of_lists) >=
      parseInt(user.team_lead_scoring_criteria.list_stacking_level_3)
    ) {
      return colors.orange_color_muted;
    } else if (
      parseInt(number_of_lists) >=
      parseInt(user.team_lead_scoring_criteria.list_stacking_level_2)
    ) {
      return colors.success_color_muted;
    } else if (
      parseInt(number_of_lists) >=
      parseInt(user.team_lead_scoring_criteria.list_stacking_level_1)
    ) {
      return colors.active_color_muted;
    }
  }
  return colors.gray_selected_color;
};

export const getEstimatedListCount = ({
  token,
  list_filters,
  location_type,
  zip,
  city,
  fips,
  state,
  route_plan = false,
  drawing_coordinates = [],
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .estimatedListCount({
        token,
        type: route_plan ? "build_list_count_rp" : "build_list_count",
        list_filters: list_filters ? JSON.stringify(list_filters) : null,
        location_type,
        zip,
        city,
        fips,
        state,
        drawing_coordinates: drawing_coordinates
          ? JSON.stringify(drawing_coordinates)
          : null
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const startBuildingListv2 = ({
  token,
  type = "build_list_v2",
  title,
  list_filters,
  location_type,
  zip,
  city,
  fips,
  state,
  drawing_coordinates,
  estimated_count,
  property_flags,
  property_types,
  property_flags_and_or,
  value_range_min,
  value_range_max,
  price_type,
  beds_min,
  baths_min,
  use_beds_exact = false,
  search_locations,
  prompt,
  variance,
  attached_property_ids = null,
  use_vision = false,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .buildListv2({
        token,
        title,
        type,
        list_filters: list_filters ? JSON.stringify(list_filters) : null,
        location_type,
        zip,
        city,
        fips,
        state,
        drawing_coordinates: drawing_coordinates
          ? JSON.stringify(drawing_coordinates)
          : null,
        estimated_count,
        property_flags,
        property_types,
        property_flags_and_or,
        value_range_min,
        value_range_max,
        price_type,
        beds_min,
        baths_min,
        use_beds_exact,
        search_locations: search_locations
          ? JSON.stringify(search_locations)
          : null,
        prompt,
        variance,
        attached_property_ids,
        use_vision
      })
      .then(response => {
        if (response?.problem != null) {
          onError();

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response?.data?.error != false) {
          onError();
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response?.data?.error, title: "Error" }
          });
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const startBuildingList = ({
  token,
  title,
  list_filters,
  location_type,
  zip,
  city,
  fips,
  state,
  drawing_coordinates,
  estimated_count,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .buildList({
        token,
        title,
        type: "build_list",
        list_filters: list_filters ? JSON.stringify(list_filters) : null,
        location_type,
        zip,
        city,
        fips,
        state,
        drawing_coordinates: drawing_coordinates
          ? JSON.stringify(drawing_coordinates)
          : null,
        estimated_count
      })
      .then(response => {
        if (response.problem != null) {
          onError();

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          onError();

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getListBuilderTemplates = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getListBuilderTemplates({
        token
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          dispatch({
            type: GET_LIST_BUILDER_TEMPLATES_SUCCESS,
            payload: response.data.results
          });
          onSuccess(response.data.results);
        }
      });
  };
};

export const completeAiListBuilder = list => {
  return {
    type: COMPLETE_AI_LIST_BUILDER,
    payload: list
  };
};
export const dismissAiListBuilder = () => {
  return {
    type: DISMISS_AI_LIST_BUILDER
  };
};

export const aiListBuildStart = ({ list, chunk_size = 50 }) => {
  return {
    type: AI_LIST_BUILD_START,
    payload: { list, chunk_size }
  };
};
export const aiListBuildScan = ({
  ai_scan,
  total_counter,
  passed_counter,
  failed_counter
}) => {
  return {
    type: AI_LIST_BUILD_SCAN,
    payload: {
      ai_scan,
      total_counter,
      passed_counter,
      failed_counter
    }
  };
};

export const removeAiListBuildScan = scan => {
  return {
    type: REMOVE_AI_LIST_BUILD_SCAN,
    payload: scan
  };
};

export const updateListBuilderProgress = ({
  list,
  total,
  success_counter,
  passed_counter
}) => {
  return {
    type: UPDATE_LIST_BUILDER_PROGRESS,
    payload: { list, total, success_counter, passed_counter }
  };
};

export const updateListBuilderLocation = location => {
  return {
    type: UPDATE_LIST_BUILDER_LOCATION,
    payload: location
  };
};

export const getListsOnMap = ({
  token,
  bounds,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getListsOnMap({
        token,
        bounds
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getLeadsOnMapForListBuilder = ({
  token,
  bounds,
  dont_include,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getLeadsOnMapForListBuilder({
        token,
        bounds,
        dont_include
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};
export const getHighlightsOnMapForListBuilder = ({
  token,
  filters,
  bounds,
  dont_include,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getHighlightsOnMapForListBuilder({
        token,
        filters: filters ? JSON.stringify(filters) : null,
        bounds,
        dont_include
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const updateStackedLists = ({
  token,
  list_ids,
  list_stacking_level_1,
  list_stacking_level_2,
  list_stacking_level_3,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .updateStackedLists({
        token,
        list_ids,
        list_stacking_level_1,
        list_stacking_level_2,
        list_stacking_level_3
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);

          dispatch({
            type: GET_LISTS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const setEditListInfo = info => {
  return {
    type: SET_EDIT_LIST_INFO,
    payload: info
  };
};

export const getListHistory = ({
  token,
  list_id,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getLists({
        token,
        list_id,
        type: "list_history"
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getRecentPrompts = ({
  token,
  load_type,
  search = "",
  begin = 0,
  limit = 25,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getRecentPrompts({
        token,
        search,
        begin,
        limit
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getCurrentAIBuildingList = ({
  token,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getCurrentAIBuildingList({
        token
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (response.data.results?.current_ai_building_list) {
            dispatch({
              type: GET_CURRENT_AI_LIST_BUILD,
              payload: response.data.results?.current_ai_building_list
            });
          }

          onSuccess(response.data.results);
        }
      });
  };
};

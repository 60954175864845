import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Icon,
  Copy,
  Bold,
  Row,
  Card,
  ExternalImage,
  Animation,
  Button
} from "app/NativeComponents/common";
import { IconButton } from "app/NativeComponents/snippets";

import {
  pushSidePanel,
  removeAiListBuildScan,
  formatAddress
} from "app/NativeActions";

class ScannedProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animation_type: "fadeInUp",
      image_type: props.scan?.has_street_view ? "street_view" : "satellite"
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  startTimer() {
    if (this.props.ai_list_building?.list?.building == 1) {
      clearTimeout(this._timeout);
      clearTimeout(this._timeout2);
      this._timeout = setTimeout(() => {
        this.setState({
          animation_type: "fadeOutDown"
        });

        this._timeout2 = setTimeout(() => {
          this.props.removeAiListBuildScan(this.props.scan);
        }, 250);
      }, 3000);
    } else {
      this.setState({
        animation_type: "do_not_show"
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
    clearTimeout(this._timeout2);
  }

  render() {
    const { colors, scan } = this.props;

    const formatted_address = formatAddress({
      address: {
        address: scan.property_address,
        address2: scan.property_address2,
        address_city: scan.property_address_city,
        address_state: scan.property_address_state,
        address_zip: scan.property_address_zip,
        latitude: scan.latitude,
        longitude: scan.longitude
      }
    });

    return (
      <>
        <Animation type={this.state.animation_type}>
          <Button
            className="scanned_property"
            onHover={() => {
              clearTimeout(this._timeout);
            }}
            onHoverOut={() => {
              this.startTimer();
            }}
            onPress={() => {
              this.props.pushSidePanel({
                slug: "property",
                id: scan?.property_id,
                focus_side_panel:
                  this.props.user?.user_settings?.property_expanded ===
                  "not_expanded"
                    ? false
                    : true,
                overlay: true
              });
            }}
            style={{ height: 120, margin: 8 }}
          >
            <Card style={{ width: 240, borderRadius: 10, margin: 0 }}>
              <Wrapper
                style={{
                  position: "relative",

                  borderWidth: 3,
                  width: 240,
                  height: 120,
                  borderColor: scan?.has_passed_criteria
                    ? colors.success_color
                    : colors.error_color,
                  borderStyle: "solid",
                  borderRadius: 10
                }}
              >
                <ExternalImage
                  style={{
                    width: 240,
                    height: 120,
                    alignSelf: "stretch",
                    borderRadius: 5,
                    backgroundColor: this.props.colors.gray_color
                  }}
                  no_lazy={true}
                  use_full={true}
                  image={
                    scan.has_street_view &&
                    this.state.image_type === "street_view"
                      ? scan.street_view_image
                      : this.state.image_type === "map"
                      ? scan.map_image
                      : scan.satellite_image
                  }
                />
                <Wrapper
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    width: 240,
                    height: 120,
                    borderRadius: 5
                  }}
                >
                  <Wrapper style={{ flex: 1, justifyContent: "space-between" }}>
                    <Wrapper style={{ padding: 10 }}>
                      {scan?.has_passed_criteria ? (
                        <Row style={{ justifyContent: "space-between" }}>
                          <Row>
                            <Icon
                              icon={"check-circle"}
                              color={colors.white_color}
                              style={{ marginRight: 5 }}
                              size={12}
                            />
                            <Copy
                              style={{
                                color: colors.white_color,
                                fontSize: 12
                              }}
                            >
                              <Bold>Matched</Bold>
                            </Copy>
                          </Row>

                          <Row>
                            <Copy
                              style={{
                                color: colors.white_color,
                                fontSize: 12
                              }}
                            >
                              <Bold>{scan.score}</Bold>
                            </Copy>
                            <Wrapper
                              tooltip={scan.score_reason}
                              tooltipPlacement="top"
                            >
                              <Icon
                                icon={"help"}
                                color={colors.white_color}
                                size={10}
                                noPress={true}
                                style={{ marginLeft: 5 }}
                              />
                            </Wrapper>
                          </Row>
                        </Row>
                      ) : (
                        <Row style={{ justifyContent: "space-between" }}>
                          <Row>
                            <Copy
                              style={{
                                color: colors.white_color,
                                fontSize: 12
                              }}
                            >
                              <Bold>Not Matched</Bold>
                            </Copy>
                          </Row>

                          <Row>
                            <Copy
                              style={{
                                color: colors.white_color,
                                fontSize: 12
                              }}
                            >
                              <Bold>{scan.score}</Bold>
                            </Copy>
                            <Wrapper
                              tooltip={scan.score_reason}
                              tooltipPlacement="top"
                            >
                              <Icon
                                icon={"help"}
                                color={colors.white_color}
                                size={10}
                                noPress={true}
                                style={{ marginLeft: 5 }}
                              />
                            </Wrapper>
                          </Row>
                        </Row>
                      )}
                    </Wrapper>

                    <Row>
                      <Wrapper style={{ padding: 10, flex: 1 }}>
                        <Copy
                          style={{ color: colors.white_color, fontSize: 12 }}
                        >
                          {formatted_address.line1}
                        </Copy>
                        <Copy
                          style={{ color: colors.white_color, fontSize: 12 }}
                        >
                          {formatted_address.line2}
                        </Copy>
                      </Wrapper>
                      <IconButton
                        onPress={() => {
                          this.setState({
                            image_type:
                              this.state.image_type === "street_view"
                                ? "satellite"
                                : this.state.image_type === "satellite"
                                ? "map"
                                : this.props.scan?.has_street_view
                                ? "street_view"
                                : "satellite"
                          });
                        }}
                        tooltip={
                          this.state.image_type === "street_view"
                            ? "Street View"
                            : this.state.image_type === "satellite"
                            ? "Satellite View"
                            : "Map View"
                        }
                        icon={
                          this.state.image_type === "street_view"
                            ? "house"
                            : this.state.image_type === "satellite"
                            ? "satellite-alt"
                            : "map"
                        }
                        icon_color={colors.white_color}
                        button_type="small"
                        style={{ margin: 0 }}
                      />
                    </Row>
                  </Wrapper>
                </Wrapper>
              </Wrapper>
              {/*
              <Wrapper style={{ padding: 15, flex: 1 }}>
                <Copy>{scan.score_reason}</Copy>
              </Wrapper>
                */}
            </Card>
          </Button>
        </Animation>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, list }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { ai_list_building } = list;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    ai_list_building
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  removeAiListBuildScan
})(ScannedProperty);

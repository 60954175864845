import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Copy,
  Icon,
  Row,
  Wrapper
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  AttentionBox,
  ConfirmLabel,
  SmallHeader
} from "app/NativeComponents/snippets";

import {
  setAppliedHighlight,
  popSidePanel,
  updateSavedFilters,
  updateMapFilters,
  getHelperDescription
} from "app/NativeActions";
import HighlightTemplates from "./HighlightTemplates";
import HighlightFilters from "./HighlightFilters";
import PropertyFlags from "app/DealMachineCore/components/Property/EstimatedValue/PropertyFlags";

class Highlights extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    this.state = {
      show_highlights: true,
      step_slug:
        props.applied_highlight?.data?.length > 0 ? "filters" : "templates",
      filter_id: "",
      filter_name: "",
      default_filter: false,
      filters:
        props.applied_highlight?.data?.length > 0
          ? props.applied_highlight
          : {
              data: [],
              andor_type: "or"
            },
      selected_template: "",
      confirmed_template: false,
      page_length: props?.map_side_panel_pages?.length,
      open_filter: panel_data?.open_filter
    };

    this.nextStep = this.nextStep.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.checkIfNeedsToApply = this.checkIfNeedsToApply.bind(this);
    this.checkIfThereAreFilters = this.checkIfThereAreFilters.bind(this);

    this.renderRangeLabel = this.renderRangeLabel.bind(this);
    this.renderBedAndBathLabel = this.renderBedAndBathLabel.bind(this);
    this.renderPriceRange = this.renderPriceRange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    /*
    if (
      JSON.stringify(prevState.filters) !==
        JSON.stringify(this.state.filters) &&
      !this.props.isMobile
    ) {
      this.props.setAppliedHighlight({
        applied_highlight: this.state.filters
      });
    }
    */
  }

  nextStep({ step_slug, filters, filter_id, filter_name, default_filter }) {
    this.setState({
      step_slug,
      filter_id: filter_id,
      filter_name: filter_name,
      default_filter: default_filter ? default_filter : false,
      filters: filters ? filters : this.state.filters
    });
  }

  updateFilters({ filters, filter_id, filter_name, default_filter }) {
    this.setState({
      filter_id: filter_id ? filter_id : this.state.filter_id,
      filter_name: filter_name ? filter_name : this.state.filter_name,
      filters: filters ? filters : this.state.filters,
      default_filter: default_filter ? default_filter : false
    });
  }

  checkIfNeedsToApply() {
    if (
      JSON.stringify(this.props.applied_highlight) !==
        JSON.stringify(this.state.filters) &&
      (this.props.applied_highlight?.data?.length > 0 ||
        this.state.filters?.data?.length > 0)
    ) {
      return true;
    }
    return false;
  }

  checkIfThereAreFilters() {
    const {
      property_types,
      property_flags,
      value_range_max,
      value_range_min,
      beds_min,
      baths_min
    } = this.props.map_filters;
    if (
      property_flags.length > 0 ||
      property_types.length > 0 ||
      !!value_range_min ||
      !!value_range_max ||
      !!beds_min ||
      !!baths_min
    ) {
      return true;
    }

    return false;
  }
  renderPriceRange(price) {
    let label = price;
    let num = parseInt(price);
    if (num >= 1000000) {
      label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1000) {
      label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return label;
  }

  renderRangeLabel() {
    const { value_range_min, value_range_max } = this.props.map_filters;

    return !!value_range_min && !value_range_max
      ? "$" + this.renderPriceRange(value_range_min) + "+"
      : !!value_range_max && !value_range_min
      ? "Up to $" + this.renderPriceRange(value_range_max)
      : !!value_range_max && !!value_range_min
      ? "$" +
        this.renderPriceRange(value_range_min) +
        " - " +
        "$" +
        this.renderPriceRange(value_range_max)
      : "";
  }

  renderBedAndBathLabel() {
    const { beds_min, baths_min, use_beds_exact } = this.props.map_filters;

    return !!beds_min && !baths_min
      ? use_beds_exact
        ? beds_min + " bd, 0+ ba"
        : beds_min + "+ bd, 0+ ba"
      : !!baths_min && !beds_min
      ? " 0+ bd, " + baths_min + "+ ba"
      : !!baths_min && !!beds_min
      ? use_beds_exact
        ? beds_min + " bd, " + baths_min + "+ ba"
        : beds_min + "+ bd, " + baths_min + "+ ba"
      : "";
  }

  render() {
    const { colors, isMobile } = this.props;

    const {
      property_flags,
      property_flags_and_or,
      property_types,
      value_range_min,
      value_range_max,
      beds_min,
      baths_min
    } = this.props.map_filters;

    return (
      <Container style={{ backgroundColor: colors.card_color }}>
        <NewHeader
          title={"More Filters"}
          leftButton={{
            icon: this.props.device == "desktop" ? "close" : "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        {this.checkIfThereAreFilters() ? (
          <>
            {property_flags && property_flags.length > 0 ? (
              <>
                <SmallHeader
                  style={{ height: 30 }}
                  title={
                    property_flags_and_or == "and"
                      ? "Quick filters (matching all): "
                      : "Quick filters (matching any): "
                  }
                />

                <Row
                  style={{
                    flexWrap: "wrap",
                    paddingRight: 25,
                    paddingLeft: 25
                  }}
                >
                  {property_flags &&
                    property_flags.length > 0 &&
                    property_flags.map((flag, i) => {
                      return (
                        <Wrapper
                          key={"flag" + i}
                          style={{
                            backgroundColor: colors.active_color_muted,
                            borderRadius: 5,
                            padding: 5,
                            paddingRight: 15,
                            paddingLeft: 15,
                            marginRight: 5,
                            marginBottom: 5
                          }}
                        >
                          <Row>
                            <Copy style={{ fontSize: 12 }}>{flag?.label}</Copy>

                            {this.props.device === "desktop" ? (
                              <Wrapper
                                tooltipPlacement={"top"}
                                tooltip={getHelperDescription(flag?.value)}
                                style={{ marginLeft: 5 }}
                              >
                                <Icon
                                  icon={"help"}
                                  color={colors.light_text_color}
                                  size={12}
                                />
                              </Wrapper>
                            ) : null}
                          </Row>
                        </Wrapper>
                      );
                    })}
                </Row>
              </>
            ) : null}

            {property_types && property_types.length > 0 ? (
              <>
                <SmallHeader
                  style={{ height: 30 }}
                  title={"Property types: "}
                />
                <Row
                  style={{
                    flexWrap: "wrap",
                    paddingRight: 25,
                    paddingLeft: 25
                  }}
                >
                  {property_types &&
                    property_types.length > 0 &&
                    property_types.map((p_type, i) => {
                      return (
                        <Wrapper
                          key={"p_type" + i}
                          style={{
                            backgroundColor: colors.active_color_muted,
                            borderRadius: 5,
                            padding: 5,
                            paddingRight: 15,
                            paddingLeft: 15,
                            marginRight: 5,
                            marginBottom: 5
                          }}
                        >
                          <Copy style={{ fontSize: 12 }}>{p_type?.label}</Copy>
                        </Wrapper>
                      );
                    })}
                </Row>
              </>
            ) : null}

            {!!value_range_min || !!value_range_max ? (
              <>
                <SmallHeader style={{ height: 30 }} title={"Price range: "} />
                <Row style={{ paddingRight: 25, paddingLeft: 25 }}>
                  <Wrapper
                    style={{
                      backgroundColor: colors.active_color_muted,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 15,
                      paddingLeft: 15,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                  >
                    <Copy style={{ fontSize: 12 }}>
                      {this.renderRangeLabel()}
                    </Copy>
                  </Wrapper>
                </Row>
              </>
            ) : null}

            {!!beds_min || baths_min ? (
              <>
                <SmallHeader style={{ height: 30 }} title={"Beds & Baths: "} />
                <Row style={{ paddingRight: 25, paddingLeft: 25 }}>
                  <Wrapper
                    style={{
                      backgroundColor: colors.active_color_muted,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 15,
                      paddingLeft: 15,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                  >
                    <Copy style={{ fontSize: 12 }}>
                      {this.renderBedAndBathLabel()}
                    </Copy>
                  </Wrapper>
                </Row>
              </>
            ) : null}
          </>
        ) : null}

        <>
          <HighlightFilters
            nextStep={this.nextStep}
            updateFilters={this.updateFilters}
            filters={this.state.filters}
            filter_id={this.state.filter_id}
            filter_name={this.state.filter_name}
            default_filter={this.state.default_filter}
            {...this.props}
            checkIfNeedsToApply={this.checkIfNeedsToApply}
            map_filters={this.props.map_filters}
            updateMapFilters={this.props.updateMapFilters}
            has_map_filters={this.checkIfThereAreFilters()}
            open_filter={this.state.open_filter}
          />
        </>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, property_map }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { applied_highlight, map_filters } = property_map;
  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    applied_highlight,
    map_filters
  };
};

export default connect(mapStateToProps, {
  setAppliedHighlight,
  popSidePanel,
  updateSavedFilters,
  updateMapFilters
})(Highlights);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper, Row, KeyboardView } from "app/NativeComponents/common";
import {
  SelectItem,
  InlineButton,
  List,
  IconButton,
  BottomNavBar,
  PopoverMenu,
  UpsellButton
} from "app/NativeComponents/snippets";
import SelectedLayer from "./SelectedLayer";
import {
  setMailerView,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";
class Sidebar extends Component {
  constructor() {
    super();

    this.state = {
      selected_layer: null
    };
  }
  render() {
    const { isMobile, colors, device } = this.props;
    let blayer = [];
    const {
      side_tab,
      front_layers,
      back_layers,
      selected_layer,
      source_of_truth,
      template_type
    } = this.props;
    if (back_layers === null) {
      blayer = [];
    } else {
      blayer = back_layers;
    }

    if (selected_layer) {
      return (
        <>
          <Row
            style={
              isMobile
                ? { justifyContent: "space-between", paddingTop: 15 }
                : {}
            }
          >
            <InlineButton
              icon={"keyboard-arrow-left"}
              onPress={() => {
                this.props.selectLayer(null);
              }}
            >
              Return To Layers
            </InlineButton>
            {isMobile ? (
              <InlineButton
                onPress={() => {
                  this.props.setMailerView({
                    flipped: side_tab == "front" ? false : true,
                    mailer: {
                      template_type: template_type,
                      front_height: this.props.card_height,
                      front_width: this.props.card_width,
                      back_height: this.props.card_height_back,
                      back_width: this.props.card_width_back,
                      front_layers: this.props.front_layers,
                      back_layers: this.props.back_layers,
                      bracket_variables: this.props.bracket_variables
                    }
                  });
                }}
              >
                View Design Preview
              </InlineButton>
            ) : null}
          </Row>
          <SelectedLayer
            layer={selected_layer}
            bracket_variables={this.props.bracket_variables}
            updateLayer={this.props.updateLayer}
            card_width={this.props.card_width}
            card_height={this.props.card_height}
            card_width_back={this.props.card_width_back}
            card_height_back={this.props.card_height_back}
            advanced_editing_mode={this.props.advanced_editing_mode}
            toggleAdvancedEditingMode={this.props.toggleAdvancedEditingMode}
            template_type={template_type}
          />
        </>
      );
    }

    const current_layers = side_tab == "front" ? front_layers : blayer;

    return (
      <>
        <SelectItem
          style={{
            backgroundColor: colors.active_color_muted
          }}
          noPress={true}
          select_type={"none"}
          selected={true}
          renderRight={() => {
            return (
              <InlineButton
                onPress={() => {
                  this.props.toggleSide(side_tab == "front" ? "back" : "front");
                }}
              >
                {template_type == "handwritten"
                  ? side_tab == "front"
                    ? "Switch to Envelope"
                    : "Switch to Letter"
                  : side_tab == "front"
                  ? "Switch to Back"
                  : "Switch to Front"}
              </InlineButton>
            );
          }}
        >
          {template_type == "handwritten"
            ? side_tab == "front"
              ? "Customize the LETTER of this mail"
              : "Customize the ENVELOPE of this mail"
            : side_tab == "front"
            ? "Customize the FRONT of this postcard"
            : "Customize the BACK of this postcard"}
        </SelectItem>
        {isMobile ? (
          <InlineButton
            button_type={"full"}
            onPress={() => {
              this.props.setMailerView({
                flipped: side_tab == "front" ? false : true,
                mailer: {
                  template_type: template_type,
                  front_height: this.props.card_height,
                  front_width: this.props.card_width,
                  back_height: this.props.card_height_back,
                  back_width: this.props.card_width_back,
                  front_layers: this.props.front_layers,
                  back_layers: this.props.back_layers,
                  bracket_variables: this.props.bracket_variables
                }
              });
            }}
          >
            View Design Preview
          </InlineButton>
        ) : null}
        <List
          style={{ flex: 1 }}
          items={current_layers}
          reverse={true}
          itemStructure={({ item, index }) => {
            return (
              <SelectItem
                key={"layer_" + index}
                onPress={() => {
                  if (!item.locked) {
                    this.props.selectLayer(item);
                  }
                }}
                select_type={"none"}
                description={
                  item.layer_type === "image" ? "Image" : item.content
                }
                renderRight={
                  template_type !== "handwritten"
                    ? () => {
                        return (
                          <Row style={{ paddingRight: 0 }}>
                            {source_of_truth?.subscription?.metadata
                              ?.mail_designer ? (
                              <PopoverMenu
                                renderComponent={options => {
                                  return (
                                    <IconButton
                                      noPress={true}
                                      pressedIn={
                                        options.hovering || options.pressed_in
                                      }
                                      icon={item.locked ? "lock" : "more-vert"}
                                    />
                                  );
                                }}
                                menu_items={
                                  item.locked
                                    ? [
                                        {
                                          title: "Unlock Contents",
                                          type: "normal",
                                          onPress: () => {
                                            this.props.updateLayer({
                                              prop: "locked",
                                              value: false,
                                              layer: item
                                            });
                                          }
                                        }
                                      ]
                                    : [
                                        index > 0
                                          ? {
                                              title: "Move Above",
                                              icon: "arrow-upward",

                                              type: "normal",
                                              onPress: () => {
                                                this.props.moveLayerAbove({
                                                  layer: item,
                                                  index
                                                });
                                              }
                                            }
                                          : null,
                                        index < current_layers.length - 1
                                          ? {
                                              title: "Move Below",
                                              icon: "arrow-downward",
                                              type: "normal",
                                              onPress: () => {
                                                this.props.moveLayerBelow({
                                                  layer: item,
                                                  index
                                                });
                                              }
                                            }
                                          : null,
                                        {
                                          title: "Lock Contents",
                                          type: "normal",
                                          onPress: () => {
                                            this.props.updateLayer({
                                              prop: "locked",
                                              value: true,
                                              layer: item
                                            });
                                          }
                                        },
                                        {
                                          title: "Delete Layer",
                                          type: "normal",
                                          onPress: () => {
                                            this.props.setModal({
                                              title: "Delete Layer",
                                              description:
                                                "Are you sure you want to delete this layer?",
                                              icon: "",
                                              submit: "Delete",
                                              buttonType: "destroy",
                                              onPress: () =>
                                                this.props.removeLayer(item),
                                              cancel: "Cancel",
                                              onCancel: () => {}
                                            });
                                            this.props.toggleModal({
                                              show: true,
                                              type: "normal"
                                            });
                                          }
                                        }
                                      ]
                                }
                              />
                            ) : null}

                            {!item.locked ? (
                              <IconButton
                                noPress={true}
                                icon={"keyboard-arrow-right"}
                                style={{ marginLeft: 0 }}
                              />
                            ) : null}
                          </Row>
                        );
                      }
                    : null
                }
              >
                {item.title || "Untitled Layer"}
              </SelectItem>
            );
          }}
        />

        <BottomNavBar>
          <Row
            style={
              template_type !== "handwritten"
                ? { justifyContent: "space-between" }
                : {}
            }
          >
            {template_type !== "handwritten" ? (
              <>
                {checkIfUserHasMetadata("free_plan") &&
                !checkIfUserHasMetadata("mail_designer") ? (
                  <InlineButton
                    button_type={"full"}
                    primary={true}
                    onPress={() => {
                      if (
                        checkIfUserHasMetadata("free_plan") &&
                        !checkIfUserHasMetadata("mail_designer")
                      ) {
                        this.props.pushSidePanel({
                          slug: "purchase_plan_prompt",
                          overlay_modal: true,
                          locked: true,
                          data: {
                            title:
                              "Create Your Owner Designs With DealMachine Pro.",
                            dismiss_button:
                              "No thanks. Let me continue without Pro.",
                            no_free: true
                          },
                          locked: true
                        });
                      } else {
                        this.props.createNewLayer();
                      }
                    }}
                    icon={
                      checkIfUserHasMetadata("free_plan") &&
                      !checkIfUserHasMetadata("mail_designer")
                        ? "lock"
                        : "add"
                    }
                  >
                    Add New Layer
                  </InlineButton>
                ) : (
                  <UpsellButton
                    meta_slug="mail_designer"
                    onPress={this.props.createNewLayer}
                    renderLockedChildren={({ hovering }) => {
                      return (
                        <InlineButton
                          icon="lock"
                          noPress={true}
                          pressedIn={hovering}
                          primary={true}
                        >
                          Add New Layer
                        </InlineButton>
                      );
                    }}
                    renderChildren={({ hovering }) => {
                      return (
                        <InlineButton
                          icon="add"
                          noPress={true}
                          pressedIn={hovering}
                          primary={true}
                        >
                          Add New Layer
                        </InlineButton>
                      );
                    }}
                  />
                )}
              </>
            ) : null}

            <InlineButton
              button_type={template_type === "handwritten" ? "full" : ""}
              onPress={() => {
                this.props.toggleSignatures(true);
              }}
            >
              Signature
            </InlineButton>
          </Row>
        </BottomNavBar>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  setMailerView,
  pushSidePanel,
  checkIfUserHasMetadata
})(Sidebar);

import React, { Component } from "react";
import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  ProgressBar,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  InlineButton,
  SmallLabel,
  BottomNavBar,
  AttentionBox,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import { connect } from "react-redux";
import {
  getSourceOfTruth,
  popSidePanel,
  pushSidePanel,
  numberWithCommas,
  openUrl,
  AppConfig,
  displayIntercom,
  manageSubscription,
  toggleChurnBuster,
  popAllSidePanels,
  renderDate,
  showSuccess
} from "app/NativeActions";

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelLoading: false,
      reactivateLoading: false
    };

    this.resetCustomerInfo = this.resetCustomerInfo.bind(this);
  }

  resetCustomerInfo() {
    this.props.popAllSidePanels();
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });
  }

  handleBack() {
    this.props.popSidePanel();
  }
  checkIfIAP(meta_slug) {
    const { source_of_truth } = this.props;
    if (source_of_truth && source_of_truth?.subscription?.products) {
      for (let i = 0; i < source_of_truth?.subscription?.products.length; i++) {
        if (
          source_of_truth?.subscription?.products[i]?.product_slug ==
            meta_slug &&
          source_of_truth?.subscription?.products[i]?.payment_type == "iap"
        ) {
          return true;
        }
      }
    }

    return false;
  }
  renderSubText(meta_slug) {
    const { source_of_truth } = this.props;
    if (source_of_truth && source_of_truth?.subscription?.products) {
      for (let i = 0; i < source_of_truth?.subscription?.products.length; i++) {
        if (
          source_of_truth?.subscription?.products[i]?.product_slug == meta_slug
        ) {
          if (
            source_of_truth?.subscription?.products[i]?.payment_type == "iap"
          ) {
            return "Purchased Through App Store";
          } else {
            return "Purchased";
          }
        }
      }
    }

    return "";
  }

  handleConfirmCancel = () => {
    const { token, source_of_truth } = this.props;
    this.props.manageSubscription({
      token,
      type: "cancel_plan",
      stripe_sub_id: source_of_truth?.subscription?.stripe_id,
      onLoading: () => {
        this.setState({
          cancelLoading: true
        });
      },
      onError: () => {
        this.setState({
          cancelLoading: false
        });
      },
      onSuccess: () => {
        this.setState(
          {
            cancelLoading: false
          },
          () => {
            this.handleBack();
          }
        );
      }
    });
  };

  startCancellationFlow = () => {
    const { token, source_of_truth } = this.props;

    if (this.props.device === "desktop") {
      window.churnkey.init("show", {
        appId: "rivg5b42h",
        authHash: this.props.source_of_truth?.churn_key_hash,
        customerId: this.props.source_of_truth?.stripe_customer_id,
        subscriptionId: this.props.source_of_truth?.subscription?.stripe_id,
        billingProvider: "stripe", // or chargebee, braintree, paddle, paddling-billing
        mode: "live", // or 'test',
        record: true, // session playback recording
        preview: false, // disables any billing actions if true
        report: true, // enable/disable including this sessions results in analytics dashboard

        // listener callbacks
        onPause: () => {
          this.props.manageSubscription({
            token: this.props.token,
            type: "pause_subscription",
            stripe_sub_id: this.props.source_of_truth?.subscription?.stripe_id,
            no_messaging: false,
            onLoading: () => {
              this.setState({
                cancelLoading: true
              });
            },
            onError: () => {
              this.setState({
                cancelLoading: false
              });
            },
            onSuccess: () => {
              this.setState(
                {
                  cancelLoading: false
                },
                () => {
                  this.resetCustomerInfo();
                }
              );
            }
          });
        },
        onCancel: () => {
          this.props.manageSubscription({
            token: this.props.token,
            type: "cancel_all_plans",
            stripe_sub_id: this.props.source_of_truth?.subscription?.stripe_id,
            no_messaging: false,
            onLoading: () => {
              this.setState({
                cancelLoading: true
              });
            },
            onError: () => {
              this.setState({
                cancelLoading: false
              });
            },
            onSuccess: () => {
              this.setState(
                {
                  cancelLoading: false
                },
                () => {
                  this.resetCustomerInfo();
                }
              );
            }
          });
        },
        onDiscount: this.resetCustomerInfo,
        onTrialExtension: this.resetCustomerInfo,
        onPlanChange: this.resetCustomerInfo,
        onClose: () => {}
      });
    } else {
      this.props.popAllSidePanels();
      this.props.toggleChurnBuster(true);
    }

    /*
    if (window?.profitwell) {
      window
        .profitwell("init_cancellation_flow", {
          subscription_id: source_of_truth?.subscription?.stripe_id
        })
        .then(result => {
          if (result.status == "chose_to_cancel") {
            this.handleConfirmCancel();
          } else {
            return;
          }
        })
        .catch(e => console.log(e));
    }
    */
  };

  render() {
    const { source_of_truth, all_feature_metadata, platform } = this.props;
    return (
      <Container>
        <NewHeader
          title={"Plan Details"}
          leftButton={{
            icon: "arrow-back",
            onPress: () => this.handleBack()
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <SelectItem
            select_type="none"
            noPress={true}
            selected={true}
            renderBottom={
              source_of_truth?.subscription?.metadata &&
              source_of_truth?.subscription?.metadata?.total_number_of_leads !=
                "unlimited"
                ? () => {
                    const current_limit = parseInt(
                      source_of_truth?.subscription?.metadata
                        ?.total_number_of_leads
                    );
                    let count = parseInt(
                      source_of_truth?.current_limits?.total_number_of_leads
                        ?.amount
                    );
                    if (count > current_limit) {
                      count = current_limit;
                    }

                    return (
                      <>
                        <Wrapper
                          style={{
                            paddingRight: 25,
                            paddingLeft: 25,
                            marginTop: -15,
                            paddingBottom: 25
                          }}
                        >
                          <ProgressBar
                            color={this.props.colors.success_color}
                            width={200}
                            progress={
                              parseInt(count) > 0
                                ? parseInt(count) / parseInt(current_limit)
                                : 0
                            }
                          />
                          <Copy style={{ fontSize: 10 }}>
                            {numberWithCommas(count)} /{" "}
                            {numberWithCommas(current_limit)}{" "}
                            {"Total managed leads with live updates"}
                          </Copy>
                        </Wrapper>
                        {source_of_truth?.subscription?.cancel_at_period_end ? (
                          <Wrapper>
                            <SmallLabel>
                              Your subscription is set to end on{" "}
                              {renderDate(
                                source_of_truth?.subscription
                                  ?.current_period_end,
                                true
                              )}
                            </SmallLabel>
                          </Wrapper>
                        ) : null}
                      </>
                    );
                  }
                : source_of_truth?.subscription?.cancel_at_period_end
                ? () => (
                    <Wrapper>
                      <SmallLabel>
                        Your subscription is set to end on{" "}
                        {renderDate(
                          source_of_truth?.subscription?.current_period_end,
                          true
                        )}
                      </SmallLabel>
                    </Wrapper>
                  )
                : null
            }
            renderRight={() => {
              if (
                source_of_truth?.subscription?.metadata &&
                source_of_truth?.subscription?.metadata
                  ?.total_number_of_leads != "unlimited" &&
                !this.checkIfIAP("dealmachine")
              ) {
                let feature_upsell_info = null;
                for (let i = 0; i < all_feature_metadata?.length; i++) {
                  if (
                    all_feature_metadata[i].slug == "total_number_of_leads" &&
                    all_feature_metadata[i].unlock_limit ==
                      source_of_truth?.subscription?.metadata
                        ?.total_number_of_leads
                  ) {
                    feature_upsell_info = all_feature_metadata[i];
                  }
                }
                if (feature_upsell_info) {
                  return (
                    <>
                      <Row>
                        <InlineButton
                          primary={true}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "unlock_feature_modal",
                              overlay: true,
                              data: {
                                feature_upsell_info
                              }
                            });
                          }}
                        >
                          Upgrade
                        </InlineButton>
                      </Row>
                    </>
                  );
                }
              }
              return null;
            }}
            subtext={this.renderSubText("dealmachine")}
          >
            {!!source_of_truth?.subscription?.metadata?.main_plan_name
              ? source_of_truth?.subscription?.metadata.main_plan_name
              : "DealMachine Plan"}
          </SelectItem>
        </KeyboardView>
        {source_of_truth?.subscription?.metadata.enterprise ? (
          <BottomNavBar>
            <InlineButton
              textStyle={{ whiteSpace: "wrap" }}
              button_type={"full"}
              onPress={() => displayIntercom()}
              id="intercom_button"
            >
              To change or cancel your account, reach out to our support team.
            </InlineButton>
          </BottomNavBar>
        ) : source_of_truth?.subscription?.cancel_at_period_end &&
          source_of_truth?.subscription?.billing_source !== "iap" ? (
          <BottomNavBar>
            <InlineButton
              textStyle={{ whiteSpace: "wrap" }}
              button_type={"full"}
              primary={true}
              loading={this.state.reactivateLoading}
              onPress={() => {
                this.props.manageSubscription({
                  token: this.props.token,
                  type: "reactivate_all_plans",
                  onLoading: () => {
                    this.setState({
                      reactivateLoading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      reactivateLoading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        reactivateLoading: false
                      },
                      () => {
                        this.props.showSuccess(
                          "You've reactivated your subscription.",
                          "Welcome back!"
                        );
                        this.handleBack();
                      }
                    );
                  }
                });
              }}
            >
              Reactivate Plan
            </InlineButton>
          </BottomNavBar>
        ) : !source_of_truth?.subscription?.cancel_at_period_end &&
          source_of_truth?.subscription?.billing_source !== "iap" &&
          (this.props.user?.team_clearance_level > 1 ||
            this.props.user?.team_owner == 1) ? (
          <BottomNavBar>
            <ConfirmInlineButton
              textStyle={{ whiteSpace: "wrap" }}
              button_type="full"
              confirm_text="Continue with cancelation?"
              marketing_slug={"started_canceling_button"}
              onPress={() => this.startCancellationFlow()}
            >
              Cancel Plan
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : source_of_truth?.subscription?.billing_source === "iap" ? (
          <BottomNavBar>
            <Wrapper style={{ textAlign: "center", padding: 20 }}>
              <Copy style={{ textAlign: "center" }}>
                Navigate to your phone's settings to manage your account.
              </Copy>
            </Wrapper>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing, marketing }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { colors } = settings;
  const { source_of_truth, all_feature_metadata } = billing;
  const { visitor } = marketing;
  return {
    token,
    user,
    device,
    platform,
    colors,
    source_of_truth,
    all_feature_metadata,
    isMobile,
    visitor
  };
};

export default connect(mapStateToProps, {
  getSourceOfTruth,
  popSidePanel,
  pushSidePanel,
  toggleChurnBuster,
  popAllSidePanels,
  manageSubscription,
  showSuccess
})(PlanDetails);

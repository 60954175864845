import React, { Component } from "react";
import InputMask from "react-input-mask";

import { hotjar } from "react-hotjar";
import { store } from "app/store";

import { Icon } from "app/NativeComponents/common";

class Input extends Component {
  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();

    this.adjustHeight = this.adjustHeight.bind(this);
  }
  componentDidMount() {
    if (this.props.autogrow) {
      this.adjustHeight();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value != prevProps.value && this.props.autogrow) {
      this.adjustHeight();
    }
  }

  adjustHeight() {
    const textarea = this.props.input_ref
      ? this.props.input_ref.current
      : this.textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      if (textarea?.scrollHeight <= 36) {
        textarea.style.height = `18px`;
      } else {
        textarea.style.height = `${textarea?.scrollHeight}px`;
      }
    }
  }

  handleKeyPress(ev, onSubmitEditing) {}

  renderIcon() {
    if (this.props.icon) {
      if (this.props.icon == "blank") {
        return (
          <div
            className="flex-item"
            style={{
              width: 80,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            &nbsp;
          </div>
        );
      }

      const colors = store.getState().settings.colors;

      return (
        <div
          className="flex-item"
          style={{ width: 80, alignItems: "center", justifyContent: "center" }}
        >
          <Icon
            icon={this.props.icon}
            color={this.props.iconColor || colors.text_color}
            size={26}
          />
        </div>
      );
    }
  }

  render() {
    const {
      input_ref,
      size,
      name,
      placeholder,
      value,
      type,
      onChange,
      onSubmitEditing = null,
      className,
      mask,
      stripe,
      hideFloating = false,
      editable = true,
      inputStyle,
      onFocus = () => {},
      onBlur = () => {},
      maxLength = null,
      containerStyle = {},
      autoFocus = false,
      input_style = "dm-input",
      forceLabel = false,
      forceLabelPlaceholder = "",
      autoComplete = "off",
      autogrow = false,
      maxHeight = 140
    } = this.props;
    var val = value;
    if (!val) {
      val = "";
    }

    const colors = store.getState().settings.colors;

    return (
      <div className={input_style} style={containerStyle}>
        {this.renderIcon()}

        <div style={{ flex: 1, position: "relative" }}>
          {!hideFloating ? (
            <div
              className={
                (val.length > 0 && value != null) || forceLabel
                  ? "floating-label active animated fadeInUp"
                  : "floating-label animated fadeOutDown"
              }
            >
              <label
                style={{
                  color: colors.light_text_color
                }}
              >
                {placeholder}
              </label>
            </div>
          ) : null}
          {!!mask ? (
            <InputMask
              ref={input_ref ? input_ref : this.textareaRef}
              name={name}
              readOnly={!editable}
              className={
                val.length > 0 && !hideFloating
                  ? "active " + className
                  : className
              }
              style={{
                ...inputStyle,
                color: colors.text_color
              }}
              type={type}
              onChange={ev => onChange(ev.target.value)}
              onKeyDown={e => {
                if (e?.key === "Tab" || e?.key === "Enter") {
                  if (this.props.onSubmitEditing) {
                    e.preventDefault();
                    this.props.onSubmitEditing();
                  }
                }
              }}
              onKeyPress={ev => this.handleKeyPress(ev, onSubmitEditing)}
              value={value}
              placeholder={forceLabel ? forceLabelPlaceholder : placeholder}
              autoComplete={autoComplete}
              mask={mask}
              alwaysShowMask={false}
              maskChar=" "
              data-stripe={stripe}
              onFocus={() => {
                onFocus();
              }}
              onBlur={onBlur}
              maxLength={maxLength}
              autoFocus={autoFocus}
            />
          ) : type == "multiline" ? (
            <textarea
              ref={input_ref ? input_ref : this.textareaRef}
              readOnly={!editable}
              className={
                val.length > 0 && !hideFloating
                  ? "active " + className
                  : className
              }
              placeholder={forceLabel ? forceLabelPlaceholder : placeholder}
              onChange={ev => {
                if (autogrow) {
                  this.adjustHeight();
                }

                onChange(ev.target.value);
              }}
              onKeyDown={e => {
                if (e?.key === "Tab" || e?.key === "Enter") {
                  if (this.props.onSubmitEditing) {
                    e.preventDefault();
                    this.props.onSubmitEditing();
                  }
                }
              }}
              autoComplete={autoComplete}
              onFocus={() => {
                onFocus();
              }}
              onBlur={onBlur}
              maxLength={maxLength ? parseInt(maxLength) : maxLength}
              autoFocus={autoFocus}
              value={value}
              style={
                autogrow
                  ? {
                      ...inputStyle,
                      color: colors.text_color,
                      resize: "none",
                      maxHeight: maxHeight
                    }
                  : {
                      ...inputStyle,
                      color: colors.text_color
                    }
              }
            />
          ) : (
            <input
              ref={input_ref ? input_ref : this.textareaRef}
              name={name}
              readOnly={!editable}
              className={
                val.length > 0 && !hideFloating
                  ? "active " + className
                  : className
              }
              style={{
                ...inputStyle,
                color: colors.text_color
              }}
              type={type}
              onChange={ev => onChange(ev.target.value)}
              onKeyDown={e => {
                if (e?.key === "Tab" || e?.key === "Enter") {
                  if (this.props.onSubmitEditing) {
                    e.preventDefault();
                    this.props.onSubmitEditing();
                  }
                }
              }}
              onKeyPress={ev => this.handleKeyPress(ev, onSubmitEditing)}
              value={value}
              placeholder={forceLabel ? forceLabelPlaceholder : placeholder}
              autoComplete={autoComplete}
              maskchar=" "
              data-stripe={stripe}
              onFocus={() => {
                onFocus();
              }}
              onBlur={onBlur}
              maxLength={maxLength ? parseInt(maxLength) : maxLength}
              autoFocus={autoFocus}
            />
          )}
        </div>
      </div>
    );
  }
}
export { Input };

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  Wrapper,
  KeyboardView,
  ExternalImage,
  Copy,
  Row,
  Icon
} from "app/NativeComponents/common";
import {
  NewHeader,
  SmallLabel,
  ConfirmInlineButton,
  BottomNavBar,
  InputBox,
  ConfirmLabel,
  InlineTabs,
  SpinWrapper,
  SelectItem,
  List,
  SmallHeader,
  InlineButton
} from "app/NativeComponents/snippets";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";

import {
  popSidePanel,
  pushSidePanel,
  updateList,
  getListHistory,
  numberWithCommas,
  renderDate,
  getHelperDescription
} from "app/NativeActions";

class ListBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "info",
      list_title: props.edit_list_info?.title || "",
      loading: false,
      deleting: false,
      converting: false,
      list_history_loading: "",
      list_history: [],
      show_more_prompt: false
    };

    this._list_title = React.createRef();
  }

  checkIfThereAreFilters() {
    const {
      property_types,
      property_flags,
      value_range_max,
      value_range_min,
      beds_min,
      baths_min
    } = this.props.edit_list_info;
    if (
      property_flags?.length > 0 ||
      property_types?.length > 0 ||
      !!value_range_min ||
      !!value_range_max ||
      !!beds_min ||
      !!baths_min
    ) {
      return true;
    }

    return false;
  }
  renderPriceRange(price) {
    let label = price;
    let num = parseInt(price);
    if (num >= 1000000) {
      label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1000) {
      label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return label;
  }

  renderRangeLabel() {
    const { value_range_min, value_range_max } = this.props.edit_list_info;

    return !!value_range_min && !value_range_max
      ? "$" + this.renderPriceRange(value_range_min) + "+"
      : !!value_range_max && !value_range_min
      ? "Up to $" + this.renderPriceRange(value_range_max)
      : !!value_range_max && !!value_range_min
      ? "$" +
        this.renderPriceRange(value_range_min) +
        " - " +
        "$" +
        this.renderPriceRange(value_range_max)
      : "";
  }

  renderBedAndBathLabel() {
    const { beds_min, baths_min, use_beds_exact } = this.props.edit_list_info;

    return !!beds_min && !baths_min
      ? use_beds_exact
        ? beds_min + " bd, 0+ ba"
        : beds_min + "+ bd, 0+ ba"
      : !!baths_min && !beds_min
      ? " 0+ bd, " + baths_min + "+ ba"
      : !!baths_min && !!beds_min
      ? use_beds_exact
        ? beds_min + " bd, " + baths_min + "+ ba"
        : beds_min + "+ bd, " + baths_min + "+ ba"
      : "";
  }

  componentDidMount() {
    const { edit_list_info } = this.props;
    this.props.getListHistory({
      token: this.props.token,
      list_id: edit_list_info.id,
      onLoading: () => {
        this.setState({
          list_history_loading: true
        });
      },
      onError: () => {
        this.setState({
          list_history_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          list_history_loading: false,
          list_history: results?.list_history || []
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edit_list_info.id !== this.props.edit_list_info.id) {
      this.setState({
        list_title: this.props.edit_list_info?.title || ""
      });
      const { edit_list_info } = this.props;
      this.props.getListHistory({
        token: this.props.token,
        list_id: edit_list_info.id,
        onLoading: () => {
          this.setState({
            list_history_loading: true
          });
        },
        onError: () => {
          this.setState({
            list_history_loading: false
          });
        },
        onSuccess: results => {
          this.setState({
            list_history_loading: false,
            list_history: results?.list_history || []
          });
        }
      });
    }
  }
  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    const { edit_list_info } = this.props;

    const { list_title } = this.state;
    if (!!list_title && list_title !== edit_list_info.title) {
      return true;
    }

    return false;
  }

  render() {
    const { edit_list_info, colors } = this.props;
    const {
      list_title,
      loading,
      deleting,
      converting,
      list_history_loading,
      list_history
    } = this.state;

    const {
      property_flags_full,
      property_flags_and_or,
      property_types_full,
      value_range_min,
      value_range_max,
      beds_min,
      baths_min
    } = edit_list_info;

    return (
      <>
        <NewHeader
          title={"View List"}
          subtitle={""}
          leftButton={{
            icon:
              this.props.device == "mobile" ||
              this.props.side_panel_views.length > 1
                ? "arrow-back"
                : "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <>
          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="playlist-add-check"
              label={"Changes made"}
              confirmButton={"Confirm Changes"}
              loading={loading}
              primary={true}
              disabled={false}
              onPress={() => {
                this.props.updateList({
                  token: this.props.token,
                  type: "update_list",
                  title: list_title,
                  list_id: edit_list_info?.id,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                    this.handleBack();
                  }
                });
              }}
            />
          ) : null}

          <InlineTabs
            tab_type="underline"
            selected_tab={this.state.tab}
            tabs={[
              {
                onPress: () => {
                  this.setState({ tab: "info" });
                },
                slug: "info",
                title: "Info"
              },
              {
                onPress: () => {
                  this.setState({ tab: "history" });
                },
                slug: "history",
                title: "History"
              }
            ]}
          />

          <KeyboardView style={{ flex: 1 }}>
            {this.state.tab == "info" ? (
              <>
                <InputBox
                  input_ref={this._list_title}
                  autoFocus={false}
                  name="list_title"
                  disabled={loading}
                  returnKeyType="done"
                  placeholder={
                    !!list_title ? "List name" : "Enter a name for this list"
                  }
                  onFocus={() => {}}
                  onChange={value => {
                    this.setState({
                      list_title: value
                    });
                  }}
                  blurOnSubmit={true}
                  value={list_title}
                  input_type="text"
                  onSubmitEditing={() => {}}
                />
                <Wrapper style={{ padding: 25, paddingTop: 15 }}>
                  <SmallLabel
                    style={{
                      paddingRight: 0,
                      paddingLeft: 0,
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                  >
                    List type:
                  </SmallLabel>
                  <Wrapper>
                    {edit_list_info.is_ai_list ? (
                      <Copy>AI Vision Builder List</Copy>
                    ) : edit_list_info.list_type == "build_list" &&
                      edit_list_info.using_new_filters ? (
                      <Copy>Smart List</Copy>
                    ) : edit_list_info.list_type == "bulk_import" ? (
                      <Copy>Imported list</Copy>
                    ) : (
                      <Copy>Static list</Copy>
                    )}
                  </Wrapper>
                  {edit_list_info.date_converted ? (
                    <>
                      <SmallLabel
                        style={{
                          marginTop: 25,

                          paddingRight: 0,
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Converted to static date:
                      </SmallLabel>
                      <Wrapper>
                        <Copy>
                          {moment(edit_list_info.date_converted).format(
                            "MM/DD/YYYY"
                          )}
                        </Copy>
                      </Wrapper>
                    </>
                  ) : null}

                  <SmallLabel
                    style={{
                      marginTop: 25,

                      paddingRight: 0,
                      paddingLeft: 0,
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                  >
                    Leads in list:{" "}
                  </SmallLabel>
                  <Wrapper>
                    <Copy>{numberWithCommas(edit_list_info.lead_count)}</Copy>
                  </Wrapper>

                  {edit_list_info.is_ai_list && !!edit_list_info.ai_prompt ? (
                    <>
                      <SmallLabel
                        style={{
                          marginTop: 25,

                          paddingRight: 0,
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        AI Prompt:
                      </SmallLabel>
                      <Wrapper>
                        <Copy>
                          {edit_list_info.ai_prompt.length > 200 &&
                          !this.state.show_more_prompt
                            ? `${edit_list_info.ai_prompt.slice(0, 200)}...`
                            : edit_list_info.ai_prompt}
                        </Copy>
                        {edit_list_info.ai_prompt.length > 200 ? (
                          <InlineButton
                            style={{ margin: 0 }}
                            onPress={() => {
                              this.setState({
                                show_more_prompt: !this.state.show_more_prompt
                              });
                            }}
                            icon_right={
                              this.state.show_more_prompt
                                ? "keyboard-arrow-up"
                                : "keyboard-arrow-down"
                            }
                          >
                            {this.state.show_more_prompt
                              ? "Show less"
                              : "Show more"}
                          </InlineButton>
                        ) : null}
                      </Wrapper>
                    </>
                  ) : null}

                  {edit_list_info.is_ai_list &&
                  edit_list_info.attached_properties?.length > 0 ? (
                    <>
                      <SmallLabel
                        style={{
                          marginTop: 25,

                          paddingRight: 0,
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Attached properties:
                      </SmallLabel>
                      <Wrapper>
                        <Copy>
                          {edit_list_info.attached_properties
                            .map((property, i) => {
                              return property.property_address_full;
                            })
                            .join(", ")}
                        </Copy>
                      </Wrapper>
                    </>
                  ) : null}

                  {this.checkIfThereAreFilters() ? (
                    <>
                      {property_flags_full && property_flags_full.length > 0 ? (
                        <>
                          <SmallLabel
                            style={{
                              marginTop: 25,
                              paddingRight: 0,
                              paddingLeft: 0,
                              paddingTop: 0,
                              paddingBottom: 0
                            }}
                          >
                            {property_flags_and_or == "and"
                              ? "Quick filters (matching all): "
                              : "Quick filters (matching any): "}
                          </SmallLabel>

                          <Row
                            style={{
                              flexWrap: "wrap"
                            }}
                          >
                            {property_flags_full &&
                              property_flags_full.length > 0 &&
                              property_flags_full.map((flag, i) => {
                                return (
                                  <Wrapper
                                    key={"flag" + i}
                                    style={{
                                      backgroundColor:
                                        colors.active_color_muted,
                                      borderRadius: 5,
                                      padding: 5,
                                      paddingRight: 15,
                                      paddingLeft: 15,
                                      marginRight: 5,
                                      marginBottom: 5
                                    }}
                                  >
                                    <Row>
                                      <Copy style={{ fontSize: 12 }}>
                                        {flag?.label}
                                      </Copy>

                                      {this.props.device === "desktop" ? (
                                        <Wrapper
                                          tooltipPlacement={"top"}
                                          tooltip={getHelperDescription(
                                            flag?.value
                                          )}
                                          style={{ marginLeft: 5 }}
                                        >
                                          <Icon
                                            icon={"help"}
                                            color={colors.light_text_color}
                                            size={12}
                                          />
                                        </Wrapper>
                                      ) : null}
                                    </Row>
                                  </Wrapper>
                                );
                              })}
                          </Row>
                        </>
                      ) : null}

                      {property_types_full && property_types_full.length > 0 ? (
                        <>
                          <SmallLabel
                            style={{
                              marginTop: 25,
                              paddingRight: 0,
                              paddingLeft: 0,
                              paddingTop: 0,
                              paddingBottom: 0
                            }}
                          >
                            Property types:
                          </SmallLabel>
                          <Row
                            style={{
                              flexWrap: "wrap"
                            }}
                          >
                            {property_types_full &&
                              property_types_full.length > 0 &&
                              property_types_full.map((p_type, i) => {
                                return (
                                  <Wrapper
                                    key={"p_type" + i}
                                    style={{
                                      backgroundColor:
                                        colors.active_color_muted,
                                      borderRadius: 5,
                                      padding: 5,
                                      paddingRight: 15,
                                      paddingLeft: 15,
                                      marginRight: 5,
                                      marginBottom: 5
                                    }}
                                  >
                                    <Copy style={{ fontSize: 12 }}>
                                      {p_type?.label}
                                    </Copy>
                                  </Wrapper>
                                );
                              })}
                          </Row>
                        </>
                      ) : null}
                      {!!value_range_min || !!value_range_max ? (
                        <>
                          <SmallLabel
                            style={{
                              marginTop: 25,
                              paddingRight: 0,
                              paddingLeft: 0,
                              paddingTop: 0,
                              paddingBottom: 0
                            }}
                          >
                            Price range:
                          </SmallLabel>
                          <Wrapper>
                            <Copy>{this.renderRangeLabel()}</Copy>
                          </Wrapper>
                        </>
                      ) : null}

                      {!!beds_min || baths_min ? (
                        <>
                          <SmallLabel
                            style={{
                              marginTop: 25,
                              paddingRight: 0,
                              paddingLeft: 0,
                              paddingTop: 0,
                              paddingBottom: 0
                            }}
                          >
                            Beds & Baths:
                          </SmallLabel>
                          <Wrapper>
                            <Copy>{this.renderBedAndBathLabel()}</Copy>
                          </Wrapper>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {edit_list_info.list_filters?.data &&
                  edit_list_info.list_filters?.data?.length > 0 ? (
                    <>
                      <SmallLabel
                        style={{
                          marginTop: 25,

                          paddingRight: 0,
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        More filters:
                      </SmallLabel>
                      <ShowcaseFilters
                        filters={edit_list_info.list_filters?.data}
                        andor_type={edit_list_info.list_filters?.andor_type}
                        editable={false}
                        text_only={true}
                      />
                    </>
                  ) : null}
                  {edit_list_info.list_type == "build_list" ||
                  edit_list_info.list_type == "ai_build_list" ? (
                    <>
                      <SmallLabel
                        style={{
                          marginTop: 25,
                          paddingRight: 0,
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Area:
                      </SmallLabel>
                      <Wrapper style={{ height: 200, position: "relative" }}>
                        <ExternalImage
                          style={{
                            height: 200,
                            borderRadius: 5
                          }}
                          image={edit_list_info.location_image}
                        />
                        <Wrapper
                          style={{
                            position: "absolute",
                            bottom: 5,
                            left: 5,
                            borderRadius: 5,
                            padding: 5,
                            backgroundColor: "rgba(0,0,0,0.3)"
                          }}
                        >
                          {edit_list_info.list_area_type == "zip" ? (
                            <Copy style={{ color: colors.white_text_color }}>
                              {edit_list_info.list_area}
                            </Copy>
                          ) : edit_list_info.list_area_type == "city" ? (
                            <Copy style={{ color: colors.white_text_color }}>
                              {edit_list_info.list_area},{" "}
                              {edit_list_info.list_area_2}
                            </Copy>
                          ) : (
                            <Copy style={{ color: colors.white_text_color }}>
                              Drawn area
                            </Copy>
                          )}
                        </Wrapper>
                      </Wrapper>
                    </>
                  ) : null}
                </Wrapper>
              </>
            ) : (
              <>
                {list_history_loading ? (
                  <SpinWrapper text="Loading History" />
                ) : (
                  <List
                    style={{ alignSelf: "stretch" }}
                    items={list_history}
                    itemStructure={({ item, index }) => {
                      let change_text = "";

                      switch (item.change_type) {
                        case "add_to_list":
                        default:
                          if (item.number_of_leads == 1) {
                            change_text = "Added 1 lead to list";
                          } else {
                            change_text =
                              "Added " +
                              numberWithCommas(item.number_of_leads) +
                              " leads to list";
                          }
                          break;

                        case "remove_from_list":
                          if (item.number_of_leads == 1) {
                            change_text = "Removed 1 lead from list";
                          } else {
                            change_text =
                              "Removed " +
                              numberWithCommas(item.number_of_leads) +
                              " leads from list";
                          }
                          break;
                      }

                      switch (item.source_type) {
                        case "normal":
                        default:
                          change_text += "";
                          break;
                        case "bulk_import":
                          change_text += " from an imported list.";
                          break;
                        case "build_list":
                          change_text += " from List Builder.";
                          break;
                        case "ai_build_list":
                          change_text += " from AI Vision Builder.";
                          break;
                        case "smart_list":
                          change_text += " via smart updates.";
                          break;

                        case "api":
                          change_text += " from the API.";
                          break;

                        case "workflow":
                          change_text += " from a workflow.";
                          break;
                      }

                      return (
                        <SelectItem
                          key={"history_item_" + index}
                          select_type="none"
                          subtext={renderDate(item.history_date, true)}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "view_list_history_item",
                              overlay: true,
                              data: {
                                list_history_item: item,
                                subtitle: change_text
                              }
                            });
                          }}
                          icon={"keyboard-arrow-right"}
                        >
                          {change_text}
                        </SelectItem>
                      );
                    }}
                  />
                )}
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <Row
              style={{
                justifyContent:
                  (this.props.edit_list_info.using_new_filters||
                    this.props.edit_list_info.smart_list == 1) && !this.props.edit_list_info.is_ai_list
                    ? "space-between"
                    : "center"
              }}
            >
              {(this.props.edit_list_info.using_new_filters||
              this.props.edit_list_info.smart_list == 1) && !this.props.edit_list_info.is_ai_list  ? (
                <ConfirmInlineButton
                  button_type="full"
                  confirm_text="Really Convert to Static List?"
                  confirm_description="Your list will no longer update with new leads."
                  confirm_icon="refresh"
                  loading_text="Converting to Static List"
                  loading={converting}
                  onPress={() => {
                    this.props.updateList({
                      token: this.props.token,
                      type: "convert_to_static_list",
                      list_id: this.props.edit_list_info.id,
                      onLoading: () => {
                        this.setState({
                          converting: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          converting: false
                        });
                      },
                      onSuccess: () => {
                        this.setState({
                          converting: false
                        });

                        this.handleBack();
                      }
                    });
                  }}
                >
                  Convert to Static List
                </ConfirmInlineButton>
              ) : null}
              <ConfirmInlineButton
                button_type="full"
                confirm_text="Really Delete?"
                confirm_description="Your leads will not be deleted."
                confirm_icon="delete"
                loading_text="Deleting"
                loading={deleting}
                onPress={() => {
                  this.props.updateList({
                    token: this.props.token,
                    type: "remove_list",
                    list_id: this.props.edit_list_info.id,
                    onLoading: () => {
                      this.setState({
                        deleting: true
                      });
                    },
                    onError: () => {
                      this.setState({
                        deleting: false
                      });
                    },
                    onSuccess: () => {
                      this.setState({
                        deleting: false
                      });

                      this.handleBack();
                    }
                  });
                }}
              >
                Delete List
              </ConfirmInlineButton>
            </Row>
          </BottomNavBar>
        </>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, list, modal }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { edit_list_info } = list;
  const { side_panel_views } = modal;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    side_panel_views,
    edit_list_info
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getListHistory,
  updateList
})(ListBody);

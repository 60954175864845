import {
  ERROR_MESSAGE,
  GET_LIST_PROPERTIES,
  GET_LIST_PROPERTIES_FAIL,
  GET_LIST_PROPERTIES_SUCCESS,
  GET_PROPERTY,
  GET_PROPERTY_COUNT,
  GET_PROPERTY_COUNT_FAIL,
  GET_PROPERTY_COUNT_SUCCESS,
  GET_PROPERTY_FAIL,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_WITH_ROUTE_INFO,
  GET_PROPERTY_WITH_ROUTE_INFO_FAIL,
  GET_PROPERTY_WITH_ROUTE_INFO_SUCCESS,
  IS_LOADING,
  LOAD_MORE_PROPERTIES,
  LOAD_MORE_PROPERTIES_SUCCESS,
  REFRESH_LIST_PROPERTIES,
  SET_PROPERTIES_LIMIT,
  SET_PROPERTIES_PAGE,
  SET_TRACKING_EVENT,
  SOFT_REFRESH_PROPERTY,
  SUCCESS_MESSAGE,
  TOGGLE_LEAD_IMAGES,
  TRIGGER_ACTIVITY_UPDATE,
  TRIGGER_DEAL_CREDIT_RELOAD,
  TRIGGER_LEADS_RELOAD,
  TRIGGER_LEADS_COUNT_RELOAD,
  TRIGGER_LOGOUT,
  UPDATE_LEAD,
  UPDATE_LEAD_FAIL,
  UPDATE_LEAD_SUCCESS,
  RELOAD_LEADS_SUCCESS,
  RELOAD_LEADS,
  GET_BULK_EDITS_SUCCESS,
  UPDATE_BULK_EDIT,
  GET_MAIL_FAILED_DEALS,
  GET_MAIL_FAILED_DEALS_FAIL,
  GET_MAIL_FAILED_DEALS_SUCCESS,
  LOAD_MORE_MAIL_FAILED_DEALS,
  LOAD_MORE_MAIL_FAILED_DEALS_SUCCESS,
  REFRESH_MAIL_FAILED_DEALS,
  SET_SKIP_TRACE_PAGE,
  GET_ESTIMATED_INVOICE_INFO,
  GET_ESTIMATED_INVOICE_INFO_FAIL,
  GET_ESTIMATED_INVOICE_INFO_SUCCESS,
  GET_PROPERTY_IMAGES,
  GET_PROPERTY_IMAGES_FAIL,
  GET_PROPERTY_IMAGES_SUCCESS,
  UPDATE_LEAD_PHOTO_START,
  UPDATE_LEAD_PHOTO_PROGRESS,
  UPDATE_LEAD_PHOTO_COMPLETE,
  UPDATE_LEAD_PHOTO_ERROR,
  UPDATE_PROPERTY_IMAGE,
  UPDATE_PROPERTY_IMAGE_FAIL,
  UPDATE_PROPERTY_IMAGE_SUCCESS,
  SET_PHOTO_GALLERY,
  TOGGLE_LEAD_PHOTO_MANAGER,
  SET_EXPORT_MODAL,
  REMOVE_LEADS_SUCCESS,
  UPDATE_COMPS_SETTINGS,
  SET_UPDATED_LEADS,
  SET_UPDATED_PROPERTIES,
  SET_UPDATED_OWNER,
  UPDATE_MANUAL_PROPERTY_STATE,
  RESET_MANUAL_PROPERTY_STATE
} from "app/DealMachineCore/types";

import { AppConfig, appRedirect } from "app/NativeActions";
import { store } from "app/store";

import api from "app/DealMachineCore/apis/DealMachineAPIV2";
import moment from "moment";
const dm_api = api.create();

export const setExportModal = ({
  title,
  description,
  type,
  select_all,
  selected_leads,
  selected_columns,
  total_count,
  new_filters,
  filters,
  property_flags,
  property_flags_and_or,
  using_old_filters,
  search,
  list_id,
  list_history_id,
  deal_ids,
  modalAction = null,
  cancelAction = null,
  fieldsUpdated = null,
  popoverTarget = null,
  popoverPlacement = "right"
}) => {
  return {
    type: SET_EXPORT_MODAL,
    payload: {
      title,
      description,
      type,
      select_all,
      selected_leads,
      total_count,
      new_filters,
      filters,
      property_flags,
      property_flags_and_or,
      using_old_filters,
      search,
      list_id,
      list_history_id,
      deal_ids,
      selected_columns,
      modalAction,
      cancelAction,
      fieldsUpdated,
      popoverTarget,
      popoverPlacement
    }
  };
};

export const setSkipTracePage = ({
  title,
  subtitle,
  selected_lead,
  lead_ids,
  select_all,
  number_of_leads,
  filters,
  filter_lists,
  new_filters,
  search,
  list_history_id,
  list_id,
  onSuccess = null
}) => {
  return {
    type: SET_SKIP_TRACE_PAGE,
    payload: {
      title,
      subtitle,
      selected_lead,
      lead_ids,
      number_of_leads,
      select_all,
      filters,
      filter_lists,
      new_filters,
      search,
      list_history_id,
      list_id,
      onSuccess
    }
  };
};

export const setPhotoGallery = ({
  lead_id,
  property,
  images,
  propertyUpdated
}) => {
  return {
    type: SET_PHOTO_GALLERY,
    payload: {
      photo_gallery: {
        lead_id,
        property,
        images,
        propertyUpdated
      }
    }
  };
};

export const toggleLeadPhotoManager = toggle => {
  return {
    type: TOGGLE_LEAD_PHOTO_MANAGER,
    payload: toggle
  };
};

export const deleteLeads = lead_ids => {
  return {
    type: REMOVE_LEADS_SUCCESS,
    payload: {
      leads: lead_ids.split(",")
    }
  };
};

export const updatePropertyImage = ({
  token,
  property_image_id,
  lead_id,
  type,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    }
    dispatch({ type: UPDATE_PROPERTY_IMAGE });
    dm_api
      .updatePropertyImage({
        token,
        lead_id,
        property_image_id,
        type
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError({
              property_image_id,
              lead_id
            });
          }
          dispatch({
            type: UPDATE_PROPERTY_IMAGE_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          if (onError) {
            onError({
              property_image_id,
              lead_id
            });
          }
          dispatch({
            type: UPDATE_PROPERTY_IMAGE_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }
          dispatch({
            type: UPDATE_PROPERTY_IMAGE_SUCCESS,
            payload: { ...response.data.results, lead_id, property_image_id }
          });
        }
      });
  };
};

export const getPropertyImages = ({ token, lead_id, no_loading = false }) => {
  return dispatch => {
    if (!no_loading) {
      dispatch({ type: GET_PROPERTY_IMAGES });
    }
    dm_api
      .getPropertyImages({
        token,
        lead_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_PROPERTY_IMAGES_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_PROPERTY_IMAGES_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_PROPERTY_IMAGES_SUCCESS,
            payload: { ...response.data.results, lead_id }
          });
        }
      });
  };
};

export const getEstimatedInvoiceInfo = ({
  token,
  invoice_type,
  mailer_campaign_id,
  lead_id,
  number_of_leads,
  number_of_addresses
}) => {
  return dispatch => {
    dispatch({ type: GET_ESTIMATED_INVOICE_INFO });
    dm_api
      .getBilling({
        token,
        type: "estimated_invoice_info",
        invoice_type,
        mailer_campaign_id,
        lead_id,
        number_of_leads,
        number_of_addresses
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_ESTIMATED_INVOICE_INFO_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_ESTIMATED_INVOICE_INFO_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_ESTIMATED_INVOICE_INFO_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getBulkEdits = ({ token }) => {
  return dispatch => {
    dm_api
      .getBulkEdits({
        token
      })
      .then(response => {
        if (response.problem != null) {
        } else if (response.data.error != false) {
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_BULK_EDITS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const updateBulkEdit = ({
  id,
  total,
  percent_complete,
  percent_remaining,
  leads_updated,
  leads_failed
}) => {
  return {
    type: UPDATE_BULK_EDIT,
    payload: {
      id,
      total,
      percent_complete,
      percent_remaining,
      leads_updated,
      leads_failed
    }
  };
};

export const setUpdatedLeads = lead_ids => {
  return {
    type: SET_UPDATED_LEADS,
    payload: lead_ids
  };
};
export const setUpdatedProperties = property_ids => {
  return {
    type: SET_UPDATED_PROPERTIES,
    payload: property_ids
  };
};
export const setUpdatedOwner = ({ lead_id, owners }) => {
  return {
    type: SET_UPDATED_OWNER,
    payload: { lead_id, owners }
  };
};

export const reloadLeads = ({
  token,
  lead_ids,
  updating_type,
  onSuccess = null
}) => {
  return dispatch => {
    /*
    const leads = lead_ids.split(",");
    let updating_title = "Updating...";
    switch (updating_type) {
      default:
        updating_title = "Updating...";
        break;

      case "skip_trace_leads":
      case "skip_trace":
      case "skip_trace_lead":
        updating_title = "Skip Tracing...";
        break;
    }

    dispatch({
      type: RELOAD_LEADS,
      payload: {
        leads,
        updating_title,
        updating_type
      }
    });
    */

    dm_api
      .listProperties({
        token,
        lead_ids,
        begin: 0,
        limit: 1000
      })
      .then(response => {
        if (response.problem != null) {
        } else if (response.data.error != false) {
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }
          dispatch({
            type: RELOAD_LEADS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getListProperties = ({
  token,
  load_type,
  sort_by,
  lead_ids,
  filter_lists,
  filters,
  begin,
  limit,
  page = 1
}) => {
  return dispatch => {
    switch (load_type) {
      default:
        dispatch({ type: GET_LIST_PROPERTIES, payload: { page: page } });
        break;

      case "load_more":
        dispatch({ type: LOAD_MORE_PROPERTIES, payload: { page: page } });
        break;

      case "refresh":
        dispatch({ type: REFRESH_LIST_PROPERTIES, payload: { page: page } });
        break;
    }
    dm_api
      .listProperties({
        token,
        sort_by,
        lead_ids,
        filter_lists,
        filters,
        begin,
        limit
      })
      .then(response => {
        if (response.problem != null) {
          getListPropertiesFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          getListPropertiesFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getListPropertiesSuccess({
            dispatch,
            results: response.data.results,
            load_type
          });
        }
      });
  };
};

export const getLeads = ({
  token,
  load_type,
  sort_by,
  list_id,
  begin,
  limit,
  search,
  search_type,
  filters = null,
  old_filters = null,
  get_updated_data = false,
  list_history_id = null,
  page = 1,
  property_flags,
  property_flags_and_or,
  noredux = false,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    if (!noredux) {
      switch (load_type) {
        default:
          dispatch({ type: GET_LIST_PROPERTIES, payload: { page: page } });
          break;

        case "load_more":
          dispatch({ type: LOAD_MORE_PROPERTIES, payload: { page: page } });
          break;

        case "refresh":
        case "search":
          dispatch({ type: REFRESH_LIST_PROPERTIES, payload: { page: page } });
          break;
      }
    }

    dm_api
      .getLeads({
        token,
        sort_by,
        list_id,
        begin,
        filters: filters ? JSON.stringify(filters) : null,
        old_filters: old_filters ? JSON.stringify(old_filters) : null,
        search,
        search_type,
        get_updated_data,
        list_history_id,
        limit,
        property_flags,
        property_flags_and_or
      })
      .then(response => {
        if (typeof response !== "undefined") {
          if (response.problem != null) {
            onError();
            if (!noredux) {
              getListPropertiesFail({ dispatch, error: response.problem });
            }
          } else if (response.data.error != false) {
            onError();
            if (!noredux) {
              getListPropertiesFail({ dispatch, error: response.data.error });
            }
            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            onSuccess(response.data.results);
            if (!noredux) {
              getListPropertiesSuccess({
                dispatch,
                results: response.data.results,
                load_type
              });
            }
          }
        }
      });
  };
};

const getListPropertiesFail = ({ dispatch, error }) => {
  dispatch({ type: GET_LIST_PROPERTIES_FAIL, payload: error });
  /*
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
  */
};

const getListPropertiesSuccess = ({ dispatch, results, load_type }) => {
  const state = store.getState();
  const { native } = state;
  const { isMobile } = native;
  if (load_type === "load_more") {
    dispatch({
      type: LOAD_MORE_PROPERTIES_SUCCESS,
      payload: { ...results, isMobile: isMobile }
    });
  } else {
    dispatch({
      type: GET_LIST_PROPERTIES_SUCCESS,
      payload: { ...results, isMobile: isMobile }
    });
  }
};

export const getProperty = ({ token, type, property_id, deal_id }) => {
  return dispatch => {
    switch (type) {
      case "soft_refresh":
        dispatch({ type: SOFT_REFRESH_PROPERTY });
        break;

      case "route":
        dispatch({ type: GET_PROPERTY_WITH_ROUTE_INFO });
        break;

      default:
        dispatch({ type: GET_PROPERTY });
        break;
    }

    dm_api
      .getProperty({
        token,
        property_id,
        deal_id,
        type
      })
      .then(response => {
        if (response.problem != null) {
          switch (type) {
            default:
              dispatch({ type: GET_PROPERTY_FAIL, payload: response.problem });
              appRedirect({
                dispatch,
                redirect: "goBack",
                payload: { remove: "property", property_id: property_id }
              });

              break;

            case "route":
              dispatch({
                type: GET_PROPERTY_WITH_ROUTE_INFO_FAIL,
                payload: response.problem
              });
              break;
          }

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          switch (type) {
            default:
              dispatch({
                type: GET_PROPERTY_FAIL,
                payload: response.data.error
              });
              break;

            case "route":
              dispatch({
                type: GET_PROPERTY_WITH_ROUTE_INFO_FAIL,
                payload: response.data.error
              });
              break;
          }

          appRedirect({
            dispatch,
            redirect: "goBack",
            payload: { remove: "property", property_id: property_id }
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (type) {
            default:
              dispatch({
                type: GET_PROPERTY_SUCCESS,
                payload: response.data.results
              });
              break;

            case "route":
              dispatch({
                type: GET_PROPERTY_WITH_ROUTE_INFO_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const toggleLeadImages = toggle => {
  return {
    type: TOGGLE_LEAD_IMAGES,
    payload: toggle
  };
};

export const exportLeadsFromEmail = ({
  token,
  select_all = 0,
  total_count = null,
  new_filters,
  emails,
  include_all_columns,
  export_type,
  include_likely_owners,
  include_family,
  include_likely_renters,
  include_potential_property_owners,
  scrub_dnc,
  scrub_landline,
  scrub_wireless,
  deduplicate,
  export_file_name,
  list_id,
  search,
  search_type,
  list_history_id,
  deal_ids,
  selected_columns,
  onSuccess = null,
  onError = null,
  onLoading = null,
  property_flags,
  property_flags_and_or
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    }

    dm_api
      .exportLeads({
        token,
        select_all,
        total_count,
        new_filters: new_filters ? JSON.stringify(new_filters) : null,
        emails,
        include_all_columns,
        export_type,
        include_likely_owners,
        include_family,
        include_likely_renters,
        include_potential_property_owners,
        scrub_dnc,
        scrub_landline,
        scrub_wireless,
        deduplicate,
        export_file_name,
        list_id,
        list_history_id,
        search,
        search_type,
        deal_ids,
        selected_columns,
        property_flags,
        property_flags_and_or
      })
      .then(response => {
        if (response?.problem != null) {
          if (onError) {
            onError();
          }

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response?.problem, title: "Error" }
          });
        } else if (response?.data?.error != false) {
          if (onError) {
            onError();

            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response?.data?.error, title: "Error" }
            });
          }
          if (response?.data?.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          /*
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: {
              message:
                "Your exported leads will be sent to the provided email(s).",
              title: "Export started."
            }
          });
          */

          if (onSuccess) {
            onSuccess();
          }
        }
      });
  };
};

export const getExportActualCount = ({
  token,
  select_all = 0,
  total_count = null,
  new_filters,
  list_id,
  search,
  list_history_id,
  deal_ids,
  onSuccess = null,
  onError = null,
  onLoading = null,
  property_flags,
  property_flags_and_or
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    }

    dm_api
      .getExportActualCount({
        token,
        select_all,
        total_count,
        new_filters: new_filters ? JSON.stringify(new_filters) : null,
        list_id,
        list_history_id,
        search,
        deal_ids,
        property_flags,
        property_flags_and_or
      })
      .then(response => {
        if (response?.problem != null) {
          if (onError) {
            onError();
          }
        } else if (response?.data?.error != false) {
          if (onError) {
            onError();
          }
          if (response?.data?.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response?.data?.results);
          }
        }
      });
  };
};

export const updateLead = ({
  token,
  type,
  no_loading = false,
  select_all = 0,
  total_count = null,
  filters,
  new_filters,
  using_old_filters = false,
  filter_lists,
  list_id,
  selected_columns,
  search,
  deal_ids,
  deal_status,
  deal_status_slug,
  list_ids,
  tag_ids,
  campaign_id,
  mail_template_id,
  resend_freq,
  resend_limit,
  start_mailers,
  new_list_name = null,
  new_tag_name = null,
  closed_date = null,
  purchase_profit = null,
  purchase_price = null,
  purchase_exit_strategy = null,
  offer_accepted_date = null,
  purchase_notes = null,
  resale_price = null,
  closing_fees = null,
  other_expenses = null,
  onSuccess = null,
  onError = null,
  onLoading = null,
  credit = 0,
  credit_update_type,
  custom_field_id,
  value,
  assign_to_id,
  property_data_id,
  accept_new_owner,
  list_history_id,
  get_updated_data,
  property_flags,
  property_flags_and_or,
  call_results
}) => {
  return dispatch => {
    if (
      select_all == 1 &&
      type !== "export_leads" &&
      type !== "accept_data_changes" &&
      !list_history_id
    ) {
      const list_properties = store.getState().lead.list_properties;
      const leads = [];
      if (list_properties && list_properties.length > 0) {
        for (let j = 0; j < list_properties.length; j++) {
          if (list_properties[j].deal) {
            leads.push(list_properties[j].deal.id);
          }
        }
      }
      dispatch({
        type: RELOAD_LEADS,
        payload: {
          leads: leads,
          updating_title:
            type == "skip_trace_leads_v2" ? "Skip tracing..." : "Updating...",
          updating_text: ""
        }
      });

      switch (type) {
        case "edit_status_for_lead":
          /*
          appRedirect({
            dispatch,
            redirect: "goBack",
            payload: { remove: "lead-status" }
          });
          */
          break;
        case "add_leads_to_lists_from_messages":
          break;

        case "add_leads_to_lists":
        case "edit_lists_for_lead":
          appRedirect({
            dispatch,
            redirect: "goBack",
            payload: { remove: "lists" }
          });
          break;

        case "edit_tags_for_lead":
          appRedirect({
            dispatch,
            redirect: "goBack",
            payload: { remove: "tags" }
          });
          break;

        case "edit_mailing_options_for_leads":
          appRedirect({
            dispatch,
            redirect: "goBack",
            payload: { remove: "mailing-options" }
          });
          break;

        default:
          break;
      }
    } else if (type == "skip_trace_leads_v2") {
      if (!no_loading) {
        dispatch({ type: IS_LOADING, payload: true });
      } else {
        dispatch({
          type: RELOAD_LEADS,
          payload: {
            leads: [deal_ids.split(",")],
            updating_title: "Skip Tracing...",
            updating_text: ""
          }
        });
      }
    } else if (!no_loading && !onLoading) {
      dispatch({ type: IS_LOADING, payload: true });
    }

    if (onLoading) {
      onLoading();
    }

    dispatch({ type: UPDATE_LEAD });

    let post_type = type;
    if (post_type == "add_leads_to_lists_from_messages") {
      post_type = "add_leads_to_lists";
    }
    if (type == "edit_tags_for_route_lead") {
      post_type = "edit_tags_for_lead";
    }
    dm_api
      .updateLead({
        token,
        type: post_type,
        select_all,
        total_count,
        filters: filters ? filters : {},
        new_filters: new_filters ? JSON.stringify(new_filters) : null,
        using_old_filters,
        filter_lists,
        list_id,
        search,
        deal_ids,
        selected_columns,
        deal_status,
        deal_status_slug,
        list_ids,
        tag_ids,
        start_mailers,
        campaign_id,
        mail_template_id,
        resend_freq,
        resend_limit,
        new_list_name,
        new_tag_name,
        closed_date,
        purchase_profit,
        purchase_price,
        purchase_exit_strategy,
        offer_accepted_date,
        purchase_notes,
        resale_price,
        closing_fees,
        other_expenses,
        credit,
        credit_update_type,
        custom_field_id,
        value,
        assign_to_id,
        property_data_id,
        accept_new_owner,
        list_history_id,
        get_updated_data,
        property_flags,
        property_flags_and_or,
        call_results
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({ type: UPDATE_LEAD_FAIL });
          if (!no_loading && !onError) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          }

          if (onError) {
            onError(response.problem);
          }
        } else if (response.data.error != false) {
          dispatch({ type: UPDATE_LEAD_FAIL });
          if (!no_loading && !onError) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });
          }

          if (onError) {
            onError(response.data.error);
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            if (type == "export_leads") {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message:
                    "Your exported leads will be sent to the provided email(s).",
                  title: "Export started."
                }
              });
            }

            onSuccess(response.data.results);
          }
          if (!no_loading) {
            dispatch({
              type: TRIGGER_ACTIVITY_UPDATE,
              payload: true
            });

            //go back if we need to

            dispatch({ type: IS_LOADING, payload: false });

            if (response.data.results.bulk_edit || select_all == 1) {
              let success_title = "Success!";
              let success_message =
                "Bulk edit started. You'll be emailed when this job is complete.";

              if (type == "export_leads") {
                success_message =
                  "Export started. Your exported leads will be sent to the provided email(s).";
              } else if (type == "skip_trace_leads_v2") {
                success_message =
                  "Bulk skip tracing started. You'll be emailed when this job is complete.";
              } else if (type == "start_mailers") {
                success_message =
                  "Mail started. You'll be emailed when these are processed.";
              }
              /*
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: success_message,
                  title: success_title,
                  change_log: response.data.results.change_log
                    ? response.data.results.change_log
                    : null
                }
              });
              
                */
            } else {
              switch (type) {
                case "edit_status_for_lead":
                  break;
                case "add_leads_to_lists_from_messages":
                  break;

                case "add_leads_to_lists":
                case "edit_lists_for_lead":
                  appRedirect({
                    dispatch,
                    redirect: "goBack",
                    payload: { remove: "lists" }
                  });
                  break;

                case "edit_tags_for_lead":
                  appRedirect({
                    dispatch,
                    redirect: "goBack",
                    payload: { remove: "tags" }
                  });
                  break;

                case "edit_mailing_options_for_leads":
                  appRedirect({
                    dispatch,
                    redirect: "goBack",
                    payload: { remove: "mailing-options" }
                  });
                  break;
                default:
                  break;
              }

              dispatch({ type: IS_LOADING, payload: false });

              switch (type) {
                default:
                  dispatch({
                    type: SUCCESS_MESSAGE,
                    payload: {
                      message: "Your lead(s) have been updated.",
                      title: "Success!"
                    }
                  });
                  break;

                case "export_leads":
                  dispatch({
                    type: SUCCESS_MESSAGE,
                    payload: {
                      message: "Export started",
                      title: "Your export will be emailed shortly."
                    }
                  });
                  break;

                case "start_mailers":
                  /*
                  dispatch({
                    type: SUCCESS_MESSAGE,
                    payload: {
                      message: "Your mail sequence(s) have been started.",
                      title: "Success!"
                    }
                  });
                  */
                  break;
                case "pause_mailers":
                  break;
                case "skip_trace_leads_v2":
                  dispatch({
                    type: RELOAD_LEADS,
                    payload: {
                      leads: [deal_ids.split(",")],
                      updating_title: "Skip Tracing...",
                      updating_text: ""
                    }
                  });
                  dispatch({ type: IS_LOADING, payload: false });

                  break;

                case "permanently_delete":
                  dispatch({
                    type: SUCCESS_MESSAGE,
                    payload: {
                      message: "Your lead(s) have been deleted.",
                      title: "Success!"
                    }
                  });
                  break;

                case "remove_leads_from_this_list":
                  dispatch({
                    type: SUCCESS_MESSAGE,
                    payload: {
                      message: "Your lead(s) have been removed.",
                      title: "Success!"
                    }
                  });
                  break;
              }
              if (
                type == "permanently_delete" ||
                type == "remove_leads_from_this_list"
              ) {
                dispatch({
                  type: REMOVE_LEADS_SUCCESS,
                  payload: {
                    leads: response.data.results?.lead_ids
                      ? response.data.results.lead_ids.split(",")
                      : deal_ids
                      ? deal_ids?.split(",")
                      : []
                  }
                });
              } else {
                dispatch({
                  type: UPDATE_LEAD_SUCCESS,
                  payload: response.data.results
                });
              }

              if (
                type == "skip_trace_leads_v2" ||
                type == "start_mailers" ||
                type == "pause_mailers"
              ) {
                dispatch({
                  type: TRIGGER_DEAL_CREDIT_RELOAD,
                  payload: true
                });
              }
            }
          } else {
            dispatch({
              type: UPDATE_LEAD_SUCCESS,
              payload: response.data.results
            });
          }
        }
      });
  };
};

export const bulkUpdateLeads = ({
  token,
  type,
  select_all = 0,
  total_count = null,
  filters,
  list_id,
  list_history_id,
  selected_columns,
  search,
  search_type,
  lead_ids,
  deal_status,
  deal_status_slug,
  list_ids,
  tag_ids,
  campaign_id,
  mail_template_id,
  resend_freq,
  resend_limit,
  start_mailers,
  new_list_name = null,
  new_tag_name = null,
  onSuccess = () => {},
  onError = () => {},
  onLoading = () => {},
  custom_field_id,
  value,
  assign_to_id,
  property_flags,
  property_flags_and_or,
  credit_update_type,
  credit
}) => {
  return dispatch => {
    onLoading();

    dispatch({ type: UPDATE_LEAD });

    dm_api
      .bulkUpdateLeads({
        token,
        type,
        select_all,
        total_count,
        filters: filters ? JSON.stringify(filters) : null,
        list_id,
        list_history_id,
        search,
        search_type,
        lead_ids,
        selected_columns,
        deal_status,
        deal_status_slug,
        list_ids,
        tag_ids,
        start_mailers,
        campaign_id,
        mail_template_id,
        resend_freq,
        resend_limit,
        new_list_name,
        new_tag_name,
        custom_field_id,
        value,
        assign_to_id,
        property_flags,
        property_flags_and_or,
        credit_update_type,
        credit
      })
      .then(response => {
        if (response?.problem != null) {
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });

          onError(response.problem);
        } else if (response.data.error != false) {
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response?.data?.error, title: "Error" }
          });

          onError(response.data.error);

          if (response?.data?.valid === "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else if (response?.data?.results) {
          dispatch({
            type: UPDATE_LEAD_SUCCESS,
            payload: response.data.results
          });

          onSuccess(response.data.results);
        }
      });
  };
};

export const setPropertiesPage = page => {
  return {
    type: SET_PROPERTIES_PAGE,
    payload: page
  };
};

export const setPropertiesLimit = limit => {
  return {
    type: SET_PROPERTIES_LIMIT,
    payload: limit
  };
};
export const triggerLeadsReload = trigger => {
  return {
    type: TRIGGER_LEADS_RELOAD,
    payload: trigger
  };
};
export const triggerLeadsCountReload = trigger => {
  return {
    type: TRIGGER_LEADS_COUNT_RELOAD,
    payload: trigger
  };
};

export const updateLeadPhotoUpload = ({
  type,
  lead_id,
  unique_string,
  file = null,
  progress = 0,
  property = null,
  error = null
}) => {
  switch (type) {
    case "update":
    default:
      return {
        type: UPDATE_LEAD_PHOTO_PROGRESS,
        payload: {
          lead_id,
          unique_string,
          progress
        }
      };

    case "start":
      return {
        type: UPDATE_LEAD_PHOTO_START,
        payload: {
          lead_id,
          unique_string,
          file
        }
      };

    case "error":
      return {
        type: UPDATE_LEAD_PHOTO_ERROR,
        payload: {
          lead_id,
          unique_string,
          error
        }
      };

    case "complete":
      return {
        type: UPDATE_LEAD_PHOTO_COMPLETE,
        payload: {
          lead_id,
          unique_string,
          property
        }
      };
  }
};

export const getTotalPropertyCount = ({ token, filter_lists, filters }) => {
  return dispatch => {
    dispatch({ type: GET_PROPERTY_COUNT });
    dm_api
      .listProperties({
        token,
        type: "count",
        filter_lists,
        filters
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_PROPERTY_COUNT_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_PROPERTY_COUNT_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_PROPERTY_COUNT_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getTotalLeadsCount = ({
  token,
  filters = null,
  old_filters = null,
  list_id,
  search = "",
  search_type = "",
  get_updated_data = false,
  list_history_id = null,
  property_flags,
  property_flags_and_or,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  noCancel = false
}) => {
  return dispatch => {
    onLoading();
    dm_api
      .getLeads({
        token,
        type: "count",
        search,
        search_type,
        filters: filters ? JSON.stringify(filters) : null,
        old_filters: old_filters ? JSON.stringify(old_filters) : null,
        list_id,
        get_updated_data,
        list_history_id,
        property_flags,
        property_flags_and_or,
        noCancel
      })
      .then(response => {
        if (typeof response !== "undefined") {
          if (response.problem != null) {
            onError();
          } else if (response.data.error != false) {
            onError();

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            if (onSuccess) {
              onSuccess(response.data?.results?.total_lead_count);
              dispatch({
                type: GET_PROPERTY_COUNT_SUCCESS,
                payload: response.data.results
              });
            } else {
              dispatch({
                type: GET_PROPERTY_COUNT_SUCCESS,
                payload: response.data.results
              });
            }
          }
        }
      });
  };
};

export const getMailFailedDeals = ({ token, begin = 0, load_type }) => {
  return dispatch => {
    switch (load_type) {
      default:
      case "load":
        dispatch({ type: GET_MAIL_FAILED_DEALS });

        break;

      case "refresh":
        dispatch({ type: REFRESH_MAIL_FAILED_DEALS });

        break;

      case "load_more":
        dispatch({ type: LOAD_MORE_MAIL_FAILED_DEALS });
        break;
    }

    dm_api.getMailFailedDeals({ token, begin }).then(response => {
      if (response.problem != null) {
        dispatch({
          type: GET_MAIL_FAILED_DEALS_FAIL,
          payload: response.problem
        });
      } else if (response.data.error != false) {
        dispatch({
          type: GET_MAIL_FAILED_DEALS_FAIL,
          payload: response.data.error
        });

        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        getMailFailedDealsSuccess(dispatch, response.data.results, load_type);
      }
    });
  };
};
const getMailFailedDealsSuccess = (dispatch, results, load_type) => {
  if (load_type == "load_more") {
    dispatch({ type: LOAD_MORE_MAIL_FAILED_DEALS_SUCCESS, payload: results });
  } else {
    dispatch({ type: GET_MAIL_FAILED_DEALS_SUCCESS, payload: results });
  }
};

export const exportLeads = ({
  token,
  deal_ids,
  select_all,
  sort_by,
  filter_lists = [],
  filters
}) => {
  return dispatch => {
    let query_filter = filters;
    for (let [key, value] of Object.entries(query_filter)) {
      if (!value || value === null) {
        query_filter[key] = "";
      }
    }

    let get_exact_filter_list = null;
    if (filter_lists.length == 0) {
      if (query_filter.list_ids) {
        get_exact_filter_list = query_filter.list_ids
          .map(list => {
            return list.id;
          })
          .join(",");
      }
    } else {
      get_exact_filter_list = filter_lists
        .map(list => {
          return list.id;
        })
        .join(",");
    }

    /*TODO add const queryString = require('query-string');
    queryString.stringify({
      ...
      total_value_empty,
      min_mortgage_amount,
      max_mortgage_amount,
      ...
    })
    */

    let export_url =
      AppConfig().api_url +
      "v2/list/?token=" +
      token +
      "&sort_by=" +
      sort_by +
      "&select_all=" +
      select_all +
      "&deal_ids=" +
      deal_ids +
      "&type=export" +
      "&filter_list_ids=" +
      get_exact_filter_list +
      "&deal_status_ids=" +
      query_filter.deal_status_ids +
      "&mail_template_ids=" +
      query_filter.mail_template_ids +
      "&campaign_ids=" +
      query_filter.campaign_ids +
      "&campaign_step=" +
      query_filter.campaign_step +
      "&campaign_status=" +
      query_filter.campaign_status +
      "&lead_source=" +
      query_filter.lead_source +
      "&added_by_ids=" +
      query_filter.added_by_ids +
      "&lead_owner_ids=" +
      query_filter.lead_owner_ids +
      "&added_type=" +
      query_filter.added_type +
      "&filter_tag_ids=" +
      query_filter.tag_ids +
      "&min_date_added=" +
      query_filter.min_date_added +
      "&max_date_added=" +
      query_filter.max_date_added +
      "&date_added_dropdown=" +
      query_filter.date_added_dropdown +
      "&min_last_exported_date=" +
      query_filter.min_last_exported_date +
      "&max_last_exported_date=" +
      query_filter.max_last_exported_date +
      "&last_exported_date_dropdown=" +
      query_filter.last_exported_date_dropdown +
      "&times_mailed_min=" +
      query_filter.times_mailed_min +
      "&times_mailed_max=" +
      query_filter.times_mailed_max +
      "&did_skip_trace=" +
      query_filter.did_skip_trace +
      "&skip_traced_by=" +
      query_filter.skip_traced_by +
      "&min_date_skip_traced=" +
      query_filter.min_date_skip_traced +
      "&max_date_skip_traced=" +
      query_filter.max_date_skip_traced +
      "&scheduled_to_send_min=" +
      query_filter.scheduled_to_send_min +
      "&scheduled_to_send_max=" +
      query_filter.scheduled_to_send_max +
      "&include_image=" +
      query_filter.include_image +
      "&owner_status=" +
      query_filter.owner_status +
      "&filter_property_address=" +
      query_filter.filter_property_address +
      "&filter_property_city=" +
      query_filter.filter_property_city +
      "&filter_property_state=" +
      query_filter.filter_property_state +
      "&filter_property_zip=" +
      query_filter.filter_property_zip +
      "&filter_owner_name=" +
      query_filter.filter_owner_name +
      "&filter_owner_address=" +
      query_filter.filter_owner_address +
      "&filter_owner_city=" +
      query_filter.filter_owner_city +
      "&filter_owner_state=" +
      query_filter.filter_owner_state +
      "&filter_owner_zip=" +
      query_filter.filter_owner_zip +
      "&min_saleprice=" +
      query_filter.min_saleprice +
      "&max_saleprice=" +
      query_filter.max_saleprice +
      "&saleprice_empty=" +
      query_filter.saleprice_empty +
      "&min_saledate=" +
      query_filter.min_saledate +
      "&max_saledate=" +
      query_filter.max_saledate +
      "&saledate_empty=" +
      query_filter.saledate_empty +
      "&saledate_dropdown=" +
      query_filter.saledate_dropdown +
      "&saledate_dropdown_empty=" +
      query_filter.saledate_dropdown_empty +
      "&min_total_value=" +
      query_filter.min_total_value +
      "&max_total_value=" +
      query_filter.max_total_value +
      "&total_value_empty=" +
      query_filter.total_value_empty +
      "&min_mortgage_amount=" +
      query_filter.min_mortgage_amount +
      "&max_mortgage_amount=" +
      query_filter.max_mortgage_amount +
      "&mortgage_amount_empty=" +
      query_filter.mortgage_amount_empty +
      "&min_building_size=" +
      query_filter.min_building_size +
      "&max_building_size=" +
      query_filter.max_building_size +
      "&building_size_empty=" +
      query_filter.building_size_empty +
      "&min_lot_acreage=" +
      query_filter.min_lot_acreage +
      "&max_lot_acreage=" +
      query_filter.max_lot_acreage +
      "&lot_acreage_empty=" +
      query_filter.lot_acreage_empty +
      "&min_units_count=" +
      query_filter.min_units_count +
      "&max_units_count=" +
      query_filter.max_units_count +
      "&units_count_empty=" +
      query_filter.units_count_empty +
      "&min_year_built=" +
      query_filter.min_year_built +
      "&max_year_built=" +
      query_filter.max_year_built +
      "&year_built_empty=" +
      query_filter.year_built_empty +
      "&min_bedrooms=" +
      query_filter.min_bedrooms +
      "&max_bedrooms=" +
      query_filter.max_bedrooms +
      "&bedrooms_empty=" +
      query_filter.bedrooms_empty +
      "&min_bathrooms=" +
      query_filter.min_bathrooms +
      "&max_bathrooms=" +
      query_filter.max_bathrooms +
      "&bathrooms_empty=" +
      query_filter.bathrooms_empty +
      "&min_equity_percent=" +
      query_filter.min_equity_percent +
      "&max_equity_percent=" +
      query_filter.max_equity_percent +
      "&equity_percent_empty=" +
      query_filter.equity_percent_empty +
      "&vacancy_type=" +
      query_filter.vacancy_type +
      "&tax_delinquency=" +
      query_filter.tax_delinquency +
      "&preforeclosure_type=" +
      query_filter.preforeclosure_type +
      "&custom_fields=" +
      JSON.stringify(query_filter.custom_fields);

    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", export_url, true); // true for asynchronous
    xmlHttp.send(null);

    dispatch({
      type: SUCCESS_MESSAGE,
      payload: {
        message: "Your exported leads will be sent to the provided email(s).",
        title: "Export started."
      }
    });
  };
};

export const updateCompsSettings = data => {
  return {
    type: UPDATE_COMPS_SETTINGS,
    payload: data
  };
};

export const getComps = ({
  token,
  property_id,
  latitude,
  longitude,
  sale_date_min,
  sale_date_max,
  range_miles = 3,
  sqft_min = null,
  sqft_max = null,
  price_min = 100,
  limit = 100,
  bedroom_min = null,
  bedroom_max = null,
  bathroom_min = null,
  bathroom_max = null,
  year_built_min = null,
  year_built_max = null,
  sort_by = "distance",
  source_type = "mls+county_records",
  dominant_source = "mls",
  drawing_coordinates = null,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    if (year_built_min === "") {
      year_built_min = null;
    }
    if (year_built_max === "") {
      year_built_max = null;
    }
    dm_api
      .getComps({
        token,
        property_id,
        latitude,
        longitude,
        sale_date_min,
        sale_date_max,
        range_miles,
        sqft_min,
        sqft_max,
        price_min,
        bedroom_min,
        bedroom_max,
        bathroom_min,
        bathroom_max,
        year_built_min,
        year_built_max,
        limit,
        sort_by,
        source_type,
        dominant_source,
        drawing_coordinates: drawing_coordinates ? drawing_coordinates : null
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess({ ...response.data.results, property_id });
        }
      });
  };
};

export const getDefaultCompsArray = ({ property, comps_settings }) => {
  let sale_date_min = moment().subtract(6, "months").format("YYYY-MM-DD");

  switch (comps_settings?.date_range_default) {
    case "1_month":
      sale_date_min = moment().subtract(1, "months").format("YYYY-MM-DD");
      break;
    case "2_months":
      sale_date_min = moment().subtract(2, "months").format("YYYY-MM-DD");
      break;
    case "3_months":
      sale_date_min = moment().subtract(3, "months").format("YYYY-MM-DD");
      break;
    case "4_months":
      sale_date_min = moment().subtract(4, "months").format("YYYY-MM-DD");
      break;
    case "5_months":
      sale_date_min = moment().subtract(5, "months").format("YYYY-MM-DD");
      break;
    default:
    case "6_months":
      sale_date_min = moment().subtract(6, "months").format("YYYY-MM-DD");
      break;
    case "1_year":
      sale_date_min = moment().subtract(1, "years").format("YYYY-MM-DD");
      break;
    case "1_year_6_months":
      sale_date_min = moment().subtract(18, "months").format("YYYY-MM-DD");
      break;
    case "2_years":
      sale_date_min = moment().subtract(2, "years").format("YYYY-MM-DD");
      break;
  }

  const sqft_min =
    Math.round(
      Math.ceil(
        Number(property?.building_square_feet) *
          (1 - parseInt(comps_settings?.sqft_range_default) / 100)
      ) / 100
    ) * 100;
  const sqft_max =
    Math.round(
      Math.ceil(
        Number(property?.building_square_feet) *
          (1 + parseInt(comps_settings?.sqft_range_default) / 100)
      ) / 100
    ) * 100;
  const comps_array = {
    sale_date_min,
    sale_date_max: moment().format("YYYY-MM-DD"),
    sqft_min: sqft_min > 0 ? sqft_min : "",
    sqft_max: sqft_max > 0 ? sqft_max : "",
    year_built_min:
      comps_settings?.year_built_range_default &&
      comps_settings?.year_built_range_default != "none"
        ? parseInt(property?.year_built) -
          parseInt(comps_settings?.year_built_range_default)
        : "",
    year_built_max:
      comps_settings?.year_built_range_default &&
      comps_settings?.year_built_range_default != "none"
        ? parseInt(property?.year_built) +
          parseInt(comps_settings?.year_built_range_default)
        : "",
    /*
    bedroom_min:
      comps_settings?.bedroom_range_default &&
      comps_settings?.bedroom_range_default != "none"
        ? Math.ceil(
            parseFloat(property?.total_bedrooms) -
              parseFloat(comps_settings?.bedroom_range_default)
          )
        : "",
    bedroom_max:
      comps_settings?.bedroom_range_default &&
      comps_settings?.bedroom_range_default != "none"
        ? parseFloat(property?.total_bedrooms) +
          parseFloat(comps_settings?.bedroom_range_default)
        : "",
    bathroom_min:
      comps_settings?.bathroom_range_default &&
      comps_settings?.bathroom_range_default != "none"
        ? Math.ceil(
            parseFloat(property?.total_baths) -
              parseFloat(comps_settings?.bathroom_range_default)
          )
        : "",
    bathroom_max:
      comps_settings?.bathroom_range_default &&
      comps_settings?.bathroom_range_default != "none"
        ? parseFloat(property?.total_baths) +
          parseFloat(comps_settings?.bathroom_range_default)
        : "",
      */
    bedroom_min: "",
    bedroom_max: "",
    bathroom_min: "",
    bathroom_max: "",
    range_miles: comps_settings?.range_miles_default || 3,
    dominant_source: comps_settings?.dominant_source || "mls"
  };

  return comps_array;
};

export const getLeadUpdates = ({
  token,
  type,
  begin,
  limit,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    dm_api
      .getLeadUpdates({
        token,
        type,
        begin,
        limit
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess({ ...response.data.results });
          dispatch({
            type: GET_PROPERTY_COUNT_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getMLSImages = ({
  token,
  property_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    dm_api
      .getMLSImages({
        token,
        property_id
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess({ ...response.data.results });
        }
      });
  };
};

export const updateManualPropertyState = state => ({
  type: UPDATE_MANUAL_PROPERTY_STATE,
  payload: state
});

export const resetManualPropertyState = () => ({
  type: RESET_MANUAL_PROPERTY_STATE
});

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  logout,
  startRedirect,
  manageSubscription,
  popSidePanel
} from "app/NativeActions";

import PayloadBody from "../PreTrialOnboarding/SelectTrialOnboarding/PayloadBody";
class PurchasePlanPrompt extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      show_prompt: false,
      dismiss_button: panel_data?.dismiss_button,
      no_free: panel_data?.no_free || props.no_free,
      title: panel_data?.title
    };
  }

  componentDidMount() {
    if (
      this.props.source_of_truth &&
      !this.props.source_of_truth?.has_subscription &&
      !!this.props.special
    ) {
      this.props.popSidePanel();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.source_of_truth &&
      prevProps.source_of_truth?.has_subscription !==
        this.props.source_of_truth?.has_subscription &&
      !this.props.source_of_truth?.has_subscription &&
      !!this.props.special
    ) {
      this.props.popSidePanel();
    }
  }

  render() {
    return (
      <PayloadBody
        {...this.props}
        prompt={true}
        token={this.props.token}
        colors={this.props.colors}
        platform={this.props.platform}
        device={this.props.device}
        signup_funnel={this.props.signup_funnel}
        qualified_lead={this.props.qualified_lead}
        needs_lists={this.props.needs_lists}
        needs_list={this.props.needs_list}
        desktopMobile={this.props.desktopMobile}
        startRedirect={this.props.startRedirect}
        visitor={this.props.visitor}
        user={this.props.user}
        manageSubscription={this.props.manageSubscription}
        popSidePanel={this.props.popSidePanel}
        dismiss_button={this.state.dismiss_button}
        no_free={this.state.no_free}
        title={this.state.title}
        special={this.props.special}
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native, marketing, billing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { device, platform, initialRedirect, desktopMobile } = native;
  const { visitor, signup_funnel, user_info } = marketing;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    device,
    platform,
    visitor,
    initialRedirect,
    signup_funnel,
    desktopMobile,
    user_info,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  manageSubscription,
  popSidePanel
})(PurchasePlanPrompt);

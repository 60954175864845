import React, { Component } from "react";

class ChurnKeyWrapper extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return null;
  }
}

export default ChurnKeyWrapper;

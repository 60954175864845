import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Row,
  Button,
  Scroll,
  Icon,
  Title,
  Bold,
  InnerCopy
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  SelectItem,
  GhostButton,
  IconButton,
  CloseButton,
  Tag,
  InlineButton,
  UpsellButton
} from "app/NativeComponents/snippets";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import LeadPhotoManager from "app/DealMachineCore/reuseable/LeadPhotoManager";
import Tags from "./Tags";
import {
  addDeal,
  getPropertyNew,
  getReverseGeocode,
  determineMainOwnerInfo,
  updateLead,
  setStartMailerCampaignPage,
  pushSidePanel,
  replaceSidePanel,
  setPhotoGallery,
  togglePhotoGallery,
  selectActiveProperty,
  setStreetViewCoords,
  determineDisplayProperty,
  checkIfUserHasBillingAddon,
  checkIfHasGoogleStreetPic,
  getTags,
  triggerNextPlannedProperty,
  popSidePanel,
  completePopSidePanel,
  nextOnboardingStep,
  toggleVirtualDriveStart,
  startTrackedRoute,
  setOnboardingLayout,
  checkIfUserHasSubscription,
  randomString,
  updateLeadPhotoUpload,
  uploadFile,
  updateUser,
  getSourceOfTruth,
  numberWithCommas,
  formatAddress
} from "app/NativeActions";
import { getGreatCircleBearing } from "geolib";
import moment from "moment";
import PropertyFlags from "app/DealMachineCore/components/Property/EstimatedValue/PropertyFlags";

class PropertyView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      updating: false,
      property: props?.property,
      parsed_address: null,
      checked_pic: false,
      failedGoogleStreetPic: false,
      street_view_data: null,
      selected_options: [],
      loading_options: [],
      added_lead: false,
      started_mailers: false
    };

    this.checkGoogleForStreetPicData =
      this.checkGoogleForStreetPicData.bind(this);

    this.getData = this.getData.bind(this);
    this.propertyUpdated = this.propertyUpdated.bind(this);
    this.formatBathsAndBeds = this.formatBathsAndBeds.bind(this);
  }

  formatBathsAndBeds(number = null) {
    if (!!number) {
      let num = parseFloat(number);

      if (Number.isInteger(num)) {
        return num; // Return the number as is if it's already an integer
      } else {
        return num?.toFixed(1); // Round to one decimal place if there's a decimal part
      }
    }
    return 0;
  }

  renderPhoto({ image_size = 100, no_photo = false }) {
    const { colors, coordinates } = this.props;
    const { property, parsed_address, street_view_data } = this.state;

    return (
      <Wrapper
        style={{
          paddingLeft: 15,
          paddingTop: 15,
          paddingBottom: 15,
          position: "relative"
        }}
      >
        {property?.deal &&
        !property?.deal?.removed &&
        !this.props.select_drive_start &&
        !this.props.no_photo ? (
          <LeadPhotoManager
            property={this.state.property}
            propertyUpdated={this.propertyUpdated}
            renderComponent={() => {
              return (
                <Wrapper style={{ position: "relative" }}>
                  <LeadMainPhoto
                    property={property}
                    height={parseInt(image_size)}
                    style={{ width: parseInt(image_size), margin: 0 }}
                  />

                  {property?.deal && property?.deal?.removed == 0 ? (
                    <Wrapper
                      style={{
                        backgroundColor: colors.active_color,
                        width: 30,
                        height: 30,
                        borderRadius: 60,
                        position: "absolute",
                        bottom: -10,
                        right: -10,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                      tooltip={"Added Lead"}
                      tooltipPlacement={"top"}
                    >
                      <Icon
                        icon={"check"}
                        size={14}
                        color={colors.white_text_color}
                      />
                    </Wrapper>
                  ) : null}

                  {!this.props.onboarding_map ? (
                    <Wrapper
                      style={{
                        position: "absolute",
                        height: "100%",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        top: 0,
                        borderRadius: 5,
                        textAlign: "center",
                        backgroundColor: "rgba(0,0,0,0.1)",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <IconButton
                        noPress={true}
                        icon="add-a-photo"
                        size={22}
                        icon_color={colors.white_text_color}
                      />
                    </Wrapper>
                  ) : null}
                </Wrapper>
              );
            }}
          />
        ) : this.state.checked_pic &&
          !this.state.failedGoogleStreetPic &&
          this.props.show_street_button &&
          !this.props.select_drive_start &&
          !this.props.chat_interface ? (
          <UpsellButton
            type="plan"
            slug="driving"
            tier={1}
            contentful_slug="unlock_feature"
            meta_slug={"virtual_driving"}
            force_unlock={true}
            onLockPress={null}
            onPress={() => {
              if (street_view_data) {
                const heading = getGreatCircleBearing(
                  {
                    latitude: parseFloat(street_view_data?.location?.lat),
                    longitude: parseFloat(street_view_data?.location?.lng)
                  },
                  {
                    latitude: parseFloat(coordinates?.latitude),
                    longitude: parseFloat(coordinates?.longitude)
                  }
                );

                if (this.props.onStreetViewPress) {
                  this.props.onStreetViewPress({ street_view_data, heading });
                }
              }
            }}
            renderLockedChildren={({ hovering }) => {
              return (
                <Wrapper style={{ position: "relative" }}>
                  <LeadMainPhoto
                    property={
                      property
                        ? property
                        : {
                            property_address: parsed_address.address,
                            property_address2: parsed_address.address2,
                            property_address_city: parsed_address.city,
                            property_address_state: parsed_address.state,
                            property_address_zip: parsed_address.zip,
                            property_address_full: parsed_address.full_address,
                            street_view_image: parsed_address.streetview_image,
                            satellite_image: parsed_address.satellite_image
                          }
                    }
                    height={parseInt(image_size)}
                    style={{ width: parseInt(image_size), margin: 0 }}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      height: "100%",
                      bottom: 0,
                      right: 0,
                      left: 0,
                      top: 0,
                      borderRadius: 5,
                      textAlign: "center",
                      backgroundColor: "rgba(0,0,0,0.1)",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    tooltipPlacement="top"
                    tooltip="Virtual Drive"
                  >
                    <IconButton
                      noPress={true}
                      icon="lock"
                      size={22}
                      icon_color={colors.white_text_color}
                    />
                  </Wrapper>
                </Wrapper>
              );
            }}
            renderChildren={({ hovering }) => {
              return (
                <Wrapper style={{ position: "relative" }}>
                  <LeadMainPhoto
                    property={
                      property
                        ? property
                        : {
                            property_address: parsed_address.address,
                            property_address2: parsed_address.address2,
                            property_address_city: parsed_address.city,
                            property_address_state: parsed_address.state,
                            property_address_zip: parsed_address.zip,
                            property_address_full: parsed_address.full_address,
                            street_view_image: parsed_address.streetview_image,
                            satellite_image: parsed_address.satellite_image
                          }
                    }
                    height={parseInt(image_size)}
                    style={{ width: parseInt(image_size), margin: 0 }}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      height: "100%",
                      bottom: 0,
                      right: 0,
                      left: 0,
                      top: 0,
                      borderRadius: 5,
                      textAlign: "center",
                      backgroundColor: "rgba(0,0,0,0.1)",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    tooltipPlacement="top"
                    tooltip="Virtual Drive"
                  >
                    <IconButton
                      noPress={true}
                      icon="view-in-ar"
                      size={22}
                      icon_color={colors.white_text_color}
                    />
                  </Wrapper>
                </Wrapper>
              );
            }}
          />
        ) : (
          <LeadMainPhoto
            property={
              property
                ? property
                : {
                    property_address: parsed_address.address,
                    property_address2: parsed_address.address2,
                    property_address_city: parsed_address.city,
                    property_address_state: parsed_address.state,
                    property_address_zip: parsed_address.zip,
                    property_address_full: parsed_address.full_address,
                    street_view_image: parsed_address.streetview_image,
                    satellite_image: parsed_address.satellite_image
                  }
            }
            height={parseInt(image_size)}
            style={{ width: parseInt(image_size), margin: 0 }}
          />
        )}
        {/*property?.deal && !property?.deal?.removed ? (
          <Wrapper
            style={{
              position: "absolute",
              bottom: 5,
              right: -10,
              width: 20,
              height: 20,
              borderRadius: 10,
              backgroundColor: colors.success_color,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Icon color={colors.white_text_color} icon="check" size={14} />
          </Wrapper>
        ) : null*/}
      </Wrapper>
    );
  }

  checkGoogleForStreetPicData({ address, coordinates }) {
    if (this.props.show_street_button) {
      checkIfHasGoogleStreetPic({
        address,
        coordinates,
        onSuccess: results => {
          this.setState({
            checked_pic: true,
            failedGoogleStreetPic: results.status == "OK" ? false : true,
            street_view_data: results
          });
        }
      });
    }
  }

  componentDidMount() {
    this.getData();
    if (this.props.all_tags.length == 0 && this.props.user) {
      this.props.getTags(this.props.token);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.property &&
        this.props.property?.property_id !== prevProps.property?.property_id) ||
      (!this.props.property &&
        this.props.coordinates &&
        (this.props.coordinates?.latitude !== prevProps.coordinates?.latitude ||
          this.props.coordinates?.longitude !==
            prevProps.coordinates?.longitude)) ||
      (this.props.property?.deal?.approved !==
        prevProps.property?.deal?.approved &&
        prevProps.property?.deal)
    ) {
      this.setState({
        added_lead: false,
        parsed_address: null,
        checked_pic: false,
        failedGoogleStreetPic: false
      });
      this.getData();
    }

    if (
      (this.props.property?.deal && !prevProps?.property?.deal) ||
      this.props.property?.deal?.approved !==
        prevProps.property?.deal?.approved ||
      this.props.property?.deal?.paused !== prevProps.property?.deal?.paused ||
      this.props.property?.deal?.image !== prevProps.property?.deal?.image ||
      this.props.property?.deal?.closed !== prevProps.property?.deal?.closed ||
      this.props.property?.deal?.deal_status_id !==
        prevProps.property?.deal?.deal_status_id
    ) {
      this.getData();
    }
  }

  getData() {
    const { property, coordinates, no_refresh = false } = this.props;
    this.setState({
      property: this.props.property
    });

    if (property) {
      this.checkGoogleForStreetPicData({
        coordinates: {
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    } else if (coordinates) {
      this.checkGoogleForStreetPicData({ coordinates: coordinates });
    }

    if (!no_refresh) {
      if (property) {
        this.props.getPropertyNew({
          token: this.props.token,
          property_id: property?.property_id,
          onLoading: () => {
            this.setState({
              loading: true
            });
          },
          onError: () => {
            this.setState({
              loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              loading: false,
              property: {
                ...results.property,
                ...this.state.property
              }
            });
          }
        });
      } else if (coordinates) {
        this.props.getReverseGeocode({
          token: this.props.token,
          coordinate: coordinates,
          onLoading: () => {
            this.setState({
              loading: true
            });
          },
          onError: () => {
            this.setState({
              loading: false
            });
          },
          onSuccess: results => {
            if (results.parsed_address || results.properties) {
              if (results?.properties?.length > 0) {
                this.setState({
                  loading: false
                });

                this.propertyUpdated(results.properties[0]);
              } else {
                this.setState({
                  loading: false,
                  parsed_address: results.parsed_address
                });
              }
            } else {
              this.setState({
                loading: false
              });
            }
          }
        });
      }
    }
  }

  propertyUpdated(property) {
    this.setState({
      property: property
        ? {
            ...this.state.property,
            ...property,
            highlighted: this.state.property?.highlighted,
            active: this.state.property?.active
          }
        : this.state.property
    });

    if (this.props.propertyUpdated) {
      this.props.propertyUpdated(
        property
          ? {
              ...this.state.property,
              ...property,
              highlighted: this.state.property?.highlighted,
              active: this.state.property?.active
            }
          : this.state.property
      );
    }
  }

  addProperty = async (
    property,
    parsed_address,
    user,
    token,
    isMobile,
    current_route
  ) => {
    let image_uri = "";
    let file;
    if (this.props.includeImageOnPropertyPress) {
      file = await this.props.includeImageOnPropertyPress();
      image_uri = file?.uri;
      this.setState({
        property: {
          ...property,
          uploading_image: image_uri
        }
      });

      if (this.props.propertyUpdated) {
        this.props.propertyUpdated({
          ...property,
          uploading_image: image_uri
        });
      }
    }

    this.props.addDeal({
      token: token,
      property: property ? property : parsed_address?.full_address,
      route_id: current_route ? current_route.route_id : 0,
      address: property ? property.property_address : parsed_address.address,
      address2: property ? property.property_address2 : parsed_address.address2,
      city: property ? property.property_address_city : parsed_address.city,
      state: property ? property.property_address_state : parsed_address.state,
      zip: property ? property.property_address_zip : parsed_address.zip,
      devicetype: isMobile ? "mobile_map" : "desktop_map",
      add_type: "no_active",
      onSuccess: results => {
        this.setState({
          added_lead: true
        });
        let new_property = null;
        if (!!image_uri && !!file) {
          if (results?.deal) {
            new_property = {
              ...results,
              deal: {
                ...results.deal,
                uploading_image: image_uri
              }
            };

            if (this.props.propertyUpdated) {
              this.props.propertyUpdated(new_property);
            }

            let d = new Date();
            let unique_string = d.getTime();
            unique_string = unique_string + randomString(10) + ".jpeg";

            this.props.uploadFile({
              acceptedFiles: file,
              token,
              type: "lead_photo",
              payload: {
                lead_id: results?.deal?.id,
                unique_string
              },
              onLoading: () => {
                this.props.updateLeadPhotoUpload({
                  lead_id: results.deal.id,
                  unique_string,
                  file: file,
                  type: "start"
                });
              },
              onSuccess: r => {
                this.props.updateLeadPhotoUpload({
                  lead_id: property.deal.id,
                  unique_string,
                  type: "complete",
                  property: r.property
                });
                if (this.props.propertyUpdated) {
                  this.props.propertyUpdated(r.property);
                }
              },
              onError: error => {
                this.props.updateLeadPhotoUpload({
                  lead_id: results.deal.id,
                  unique_string,
                  type: "error",
                  error
                });
              },
              onProgress: progress => {
                this.props.updateLeadPhotoUpload({
                  lead_id: results.deal.id,
                  unique_string,
                  type: "progress",
                  progress
                });
              }
            });
          }
        } else {
          new_property = {
            ...results
          };
        }

        this.propertyUpdated(new_property ? new_property : this.state.property);
        if (this.props.onboarding_map) {
          this.props.updateUser({
            token: this.props.token,
            type: "onboarding_stepthrough_step",
            payload: {
              onboarding_stepthrough_current_step: "alma_step"
            },

            onLoading: () => {
              this.setState({
                onboarding_loading: true
              });
            },
            onError: () => {
              this.setState({
                onboarding_loading: false
              });

              //show some kind of error here
            },
            onSuccess: () => {
              if (this.props.device == "mobile") {
                this.props.popSidePanel();
              } else {
                this.props.toggleVirtualDriveStart(false);
              }
            },
            no_loading: true
          });
        }
        if (
          this.props.onboarding &&
          this.props.onboarding.currentStep?.slug == "add_property_to_leads"
        ) {
          if (this.props.platform != "android") {
            this.props.replaceSidePanel({
              slug: "property",
              overlay: true,
              focus_side_panel:
                this.props.user?.user_settings?.property_expanded ===
                "not_expanded"
                  ? false
                  : true,
              data: {
                property: {
                  ...property,
                  ...new_property
                },
                propertyUpdated: this.propertyUpdated
              }
            });
          } else {
            this.props.pushSidePanel({
              slug: "property",
              overlay: true,
              focus_side_panel:
                this.props.user?.user_settings?.property_expanded ===
                "not_expanded"
                  ? false
                  : true,
              data: {
                property: {
                  ...property,
                  ...new_property
                },
                propertyUpdated: this.propertyUpdated
              }
            });
          }
          this.props.nextOnboardingStep(
            token,
            this.props.onboarding.steps,
            this.props.onboarding.currentStep
          );
        }
      },
      onError: error => {
        const upsell_info = error?.upsell_info;
        this.props.pushSidePanel({
          slug: "unlock_feature_modal",
          overlay: true,
          data: {
            feature_upsell_info: upsell_info.feature_metadata,
            renderContent: () => {
              return (
                <Wrapper style={{ padding: 25 }}>
                  <Copy>
                    You have added{" "}
                    <Bold>
                      {numberWithCommas(upsell_info.current_count)} leads
                    </Bold>{" "}
                    Adding this property will exceed your limit of{" "}
                    <Bold>
                      {numberWithCommas(
                        upsell_info.subscription.metadata.total_number_of_leads
                      )}
                    </Bold>
                    . Permanently delete a lead or upgrade to continue.
                  </Copy>
                </Wrapper>
              );
            },
            buttonText: "Upgrade And Add Lead",
            confirmText: "Confirm Upgrade?",
            onSuccess: () => {
              this.props.popSidePanel();
              const {
                property,
                parsed_address,
                user,
                token,
                isMobile,
                current_route
              } = this.props;
              this.addProperty(
                property,
                parsed_address,
                user,
                token,
                isMobile,
                current_route
              );
            }
          }
        });
      }
    });
  };

  renderTagsAndButton() {
    const {
      colors,
      user,
      device,
      isMobile,
      add_deal_loading,
      current_route,
      no_add = false,
      driving_info_banner,
      onboarding,
      token,
      add_lead_marketing_data = null,
      start_mail_marketing_data = null
    } = this.props;
    const { loading, property, parsed_address } = this.state;
    const { steps, currentStep, isOnboardingActive } = onboarding;

    let is_adding = false;
    if (property) {
      is_adding =
        add_deal_loading &&
        add_deal_loading.some(p => p?.property_id == property?.property_id);
    } else if (parsed_address) {
      is_adding =
        add_deal_loading &&
        add_deal_loading.some(p => p == parsed_address.full_address);
    }

    if (this.props.no_action_button) {
      return null;
    }
    return (
      <>
        <Row>
          {this.props.select_drive_start ? (
            <Wrapper style={{ flex: 1 }}>
              <GhostButton
                primary={true}
                loading={
                  this.props.select_drive_loading || this.state.route_loading
                }
                disabled={
                  this.state.loading ||
                  !this.state.street_view_data ||
                  this.state.failedGoogleStreetPic ||
                  !this.state.checked_pic
                }
                onPress={() => {
                  const { street_view_data } = this.state;
                  const { coordinates } = this.props;

                  if (street_view_data) {
                    const heading = getGreatCircleBearing(
                      {
                        latitude: parseFloat(street_view_data?.location?.lat),
                        longitude: parseFloat(street_view_data?.location?.lng)
                      },
                      {
                        latitude: parseFloat(coordinates?.latitude),
                        longitude: parseFloat(coordinates?.longitude)
                      }
                    );

                    if (
                      device === "mobile" &&
                      this.props.user &&
                      !this.props.onboarding_map
                    ) {
                      this.props.startTrackedRoute({
                        token: this.props.token,
                        track_route_type: "virtual",
                        drive_type: "virtual",
                        coordinates: [
                          {
                            latitude: coordinates?.latitude,
                            longitude: coordinates?.longitude
                          }
                        ],
                        onLoading: () => {
                          this.setState({
                            route_loading: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            route_loading: false
                          });
                        },
                        onSuccess: () => {
                          this.setState({
                            route_loading: false
                          });
                          this.props.selectActiveProperty(null, null);
                          this.props.toggleVirtualDriveStart(false);
                          if (this.props.onStreetViewPress) {
                            this.props.onStreetViewPress({
                              street_view_data,
                              heading
                            });
                          }
                        }
                      });
                    } else {
                      this.props.toggleVirtualDriveStart(false);
                      if (this.props.onStreetViewPress) {
                        this.props.onStreetViewPress({
                          street_view_data,
                          heading
                        });
                      }
                    }
                  }
                }}
                button_type="full"
                style={{
                  marginLeft: 20,
                  marginRight: this.props.next_property ? 5 : 20
                }}
              >
                {"Start Driving"}
              </GhostButton>
            </Wrapper>
          ) : this.state.started_mailers ? (
            <Wrapper style={{ flex: 1 }}>
              <GhostButton
                primary={false}
                disabled={this.state.loading}
                loading={this.state.updating}
                noPress={true}
                button_type="full"
                icon="check"
                style={{
                  marginLeft: 20,
                  marginRight: this.props.next_property ? 5 : 20
                }}
                innerStyle={{
                  backgroundColor: colors.active_color_muted,
                  borderColor: colors.actionable_text_color
                }}
                pressedIn={false}
              >
                {"Started Mail"}
              </GhostButton>
            </Wrapper>
          ) : !this.props.onboarding_map &&
            property?.deal &&
            !property?.deal?.removed &&
            !property?.deal?.approved &&
            !property?.deal?.campaign_complete &&
            property?.deal?.deal_status_type !== "inactive_status" &&
            property?.deal?.deal_status_type !== "marketing_complete_status" &&
            property?.deal?.deal_status_type !== "not_interested_status" &&
            property?.deal?.deal_status_type !== "unqualified_status" &&
            (this.props.user?.team_clearance_level > 1 ||
              (this.props.user?.can_approve_mail == 1 &&
                this.props.user?.team_clearance_level > 0)) ? (
            <Wrapper style={{ flex: 1 }}>
              <UpsellButton
                primary={true}
                meta_slug="mail"
                marketing_data={start_mail_marketing_data}
                onPress={() => {
                  if (user.team_default_credit_reload > 0) {
                    this.props.updateLead({
                      token: this.props.token,
                      select_all: false,
                      total_count: 1,
                      deal_ids: property?.deal?.id,
                      campaign_id: property?.deal?.campaign_id,
                      type: "start_mailers",
                      no_loading: true,
                      onLoading: () => {
                        this.setState({
                          updating: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          updating: false
                        });
                      },
                      onSuccess: results => {
                        this.setState({
                          updating: false,
                          started_mailers: true
                        });
                        this.propertyUpdated(
                          results.properties && results.properties?.length > 0
                            ? results.properties[0]
                            : this.state.property
                        );
                      }
                    });
                  } else {
                    this.props.setStartMailerCampaignPage({
                      title: "Select Mail Options",
                      subtitle: property?.property_address,
                      lead_ids: property?.deal?.id,
                      number_of_leads: 1
                      //number_of_addresses: parseInt(deal?.additional_mailing_addresses?.length) + 1
                    });
                    this.props.pushSidePanel({
                      slug: "start_mail_campaign",
                      overlay: true
                    });
                  }
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <>
                      <GhostButton
                        primary={true}
                        disabled={this.state.loading}
                        loading={this.state.updating}
                        noPress={true}
                        pressedIn={hovering}
                        button_type="full"
                        style={{
                          marginLeft: 20,
                          marginRight: this.props.next_property ? 5 : 20
                        }}
                      >
                        {property?.deal?.paused == 1
                          ? "Resume Mail"
                          : "Start Mail"}
                      </GhostButton>
                    </>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <>
                      <GhostButton
                        primary={true}
                        disabled={this.state.loading}
                        loading={this.state.updating}
                        noPress={true}
                        pressedIn={hovering}
                        button_type="full"
                        style={{
                          marginLeft: 20,
                          marginRight: this.props.next_property ? 5 : 20
                        }}
                      >
                        {property?.deal?.paused == 1
                          ? "Resume Mail"
                          : "Start Mail"}
                      </GhostButton>
                    </>
                  );
                }}
              />
            </Wrapper>
          ) : (!property?.deal ||
              property?.deal?.removed ||
              this.props.onboarding_map) &&
            !this.props.chat_interface ? (
            <Wrapper
              style={{
                flex: 1,
                borderWidth:
                  this.props.platform == "android" &&
                  currentStep?.slug == "add_property_to_leads"
                    ? 5
                    : 0,
                borderColor:
                  this.props.platform == "android" &&
                  currentStep?.slug == "add_property_to_leads"
                    ? "#FFA500"
                    : "transparent"
              }}
              onLayout={event => {
                this.props.setOnboardingLayout({
                  componentName: "property-popover",
                  layout: event.nativeEvent.layout
                });
              }}
            >
              <UpsellButton
                type="plan"
                slug="driving"
                tier={0}
                style={{
                  marginTop: this.props.platform == "android" ? -20 : 0,
                  marginBottom: this.props.platform == "android" ? -20 : 0
                }}
                primary={true}
                meta_slug="leads_added_from_map"
                contentful_slug="unlock_feature"
                marketing_data={add_lead_marketing_data}
                trackingId={isOnboardingActive ? "add_lead" : ""}
                onPress={() => {
                  const {
                    property,
                    parsed_address,
                    user,
                    token,
                    isMobile,
                    current_route
                  } = this.props;
                  if (!is_adding) {
                    this.addProperty(
                      property,
                      parsed_address,
                      user,
                      token,
                      isMobile,
                      current_route
                    );
                  }
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <>
                      <GhostButton
                        primary={true}
                        noPress={true}
                        pressedIn={hovering}
                        button_type="full"
                        icon={
                          checkIfUserHasSubscription(
                            this.props.user,
                            this.props.source_of_truth
                          )
                            ? "lock"
                            : "add"
                        }
                        style={{
                          marginLeft: 20,
                          marginRight: this.props.next_property ? 5 : 20
                        }}
                      >
                        Add Lead
                      </GhostButton>
                    </>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <>
                      <GhostButton
                        id="property-popover"
                        primary={true}
                        loading={is_adding}
                        disabled={this.state.loading || is_adding}
                        noPress={true}
                        pressedIn={hovering}
                        button_type="full"
                        icon={
                          this.props.onboarding_map && this.state.added_lead
                            ? "check"
                            : "add"
                        }
                        style={{
                          marginLeft: 20,
                          marginRight: this.props.next_property ? 5 : 20
                        }}
                        innerStyle={
                          this.props.onboarding_map && this.state.added_lead
                            ? {
                                backgroundColor: colors.active_color_muted,
                                borderColor: colors.actionable_text_color
                              }
                            : {}
                        }
                        textColor={
                          this.props.onboarding_map && this.state.added_lead
                            ? colors.actionable_text_color
                            : null
                        }
                      >
                        {this.props.onboarding_map && this.state.added_lead
                          ? "Added Lead"
                          : is_adding
                          ? "Adding Lead"
                          : "Add Lead"}
                      </GhostButton>
                    </>
                  );
                }}
              />
            </Wrapper>
          ) : null}
          {this.props.next_property &&
          (!this.props.planned_route_info?.task_id ||
            (this.props.planned_route_info?.task_id &&
              this.props.route_plan_distance_left <= 0.03)) ? (
            <Wrapper
              style={{
                flex: 1,
                marginTop: this.props.platform == "android" ? -20 : 0,
                marginBottom: this.props.platform == "android" ? -20 : 0
              }}
            >
              <GhostButton
                primary={false}
                loading={this.props.next_planned_property_loading}
                onPress={() => {
                  this.props.triggerNextPlannedProperty(
                    property?.property_data_id
                  );
                }}
                button_type="full"
                style={{
                  marginLeft:
                    this.props.select_drive_start ||
                    (property?.deal &&
                      !property?.deal?.removed &&
                      !property?.deal?.approved &&
                      !property?.deal?.campaign_complete) ||
                    this.state.started_mailers ||
                    ((!property?.deal || property?.deal?.removed) && !no_add)
                      ? 5
                      : 20,
                  marginRight: 20
                }}
              >
                {this.props.next_planned_property_loading
                  ? "Loading"
                  : this.props.route_plan_distance_left <= 0.03 ||
                    this.state.added_lead
                  ? "Next Property"
                  : "Skip Property"}
              </GhostButton>
            </Wrapper>
          ) : null}
        </Row>
      </>
    );
  }

  renderTags() {
    const { property } = this.state;

    return (
      <>
        {!this.props.onboarding_map &&
        property?.deal &&
        !property?.deal?.removed &&
        this.props.include_tags &&
        (this.state.added_lead ||
          this.props.user?.team_clearance_level == 0) ? (
          <Tags
            property={this.state.property}
            propertyUpdated={this.propertyUpdated}
          />
        ) : null}
      </>
    );
  }

  render() {
    const { colors } = this.props;
    const { loading, property } = this.state;
    if (property /*|| parsed_address*/) {
      const display_property = determineDisplayProperty(property);

      const { line1, line2 } = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });

      let owner_name = property
        ? property.owner_name
        : "Add lead to search owner";
      let owner_type = property
        ? !!property?.owner_type
          ? property?.owner_type
          : ""
        : "";

      if (!loading && property && !owner_type) {
        const owner_info = determineMainOwnerInfo(property);
        owner_name = owner_info.owner_name;
        if (owner_info.owner_status_info) {
          owner_type = owner_info?.owner_status_info?.text;
        }
      }
      let alert_text = "";
      let price = 0;
      if (!!property?.market_status?.value) {
        if (property.market_status?.value.startsWith("Active")) {
          alert_text += "For Sale";
          price = property.mls?.current_listing_price;
        } else if (property.market_status?.value.startsWith("Contingent")) {
          alert_text += "Contingent";
          price = property.mls?.current_listing_price;
        } else if (property.market_status?.value.startsWith("Sold")) {
          alert_text += "Sold";
          price = property.mls?.sold_price;
        }
      }
      if (!!alert_text && !!price) {
        let alert_action_text = "";
        let num = parseInt(price);
        if (num >= 1000000) {
          alert_action_text =
            (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
        } else if (num >= 1000) {
          alert_action_text = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
        }
        alert_action_text = "$" + alert_action_text;

        alert_text += " " + alert_action_text;
      }

      if (property?.is_vacant == 1) {
        if (!!alert_text) {
          alert_text += " / Vacant";
        } else {
          alert_text += "Vacant";
        }
      }
      if (["FHA", "VA"].indexOf(property?.mortgage_loan_type) >= 0) {
        if (!!alert_text) {
          alert_text += " / Assumable\u00A0Mortgage";
        } else {
          alert_text += "Assumable\u00A0Mortgage";
        }
      }
      if (property?.senior_owner == "T") {
        if (!!alert_text) {
          alert_text += " / Senior\u00A0Owner";
        } else {
          alert_text += "Senior\u00A0Owner";
        }
      }

      if (!!property?.preforeclosure_type) {
        if (!!alert_text) {
          alert_text += " / " + property.preforeclosure_type_text;
        } else {
          alert_text = property.preforeclosure_type_text;
        }
      }

      let display_value = !!property?.EstimatedValue
        ? "$" + numberWithCommas(property?.EstimatedValue)
        : "--";
      let estimated_value = !!property?.EstimatedValue
        ? "$" + numberWithCommas(property?.EstimatedValue)
        : null;
      let display_value_label = "Est. Value";
      let show_estimated_value = false;

      let description = "";
      if (!!property?.market_status?.value) {
        if (
          property.market_status?.value.startsWith("Active") ||
          property.market_status?.value.startsWith("Contingent")
        ) {
          if (property.mls?.current_listing_price) {
            show_estimated_value = true;
            display_value_label = "Listed Price";
            display_value =
              "$" + numberWithCommas(property.mls?.current_listing_price);
          }
        } else if (property?.market_status?.value.startsWith("Sold")) {
          if (property.mls?.sold_price) {
            show_estimated_value = true;
            display_value_label = "Sale Price";
            display_value = "$" + numberWithCommas(property.mls?.sold_price);
          }
          if (property?.mls.sold_date) {
            description =
              "Sold on " +
              moment(property?.mls.sold_date).format("MMM Do, YYYY");
          }
        }
      }

      return (
        <Wrapper
          style={{
            alignSelf: "stretch",
            position: "relative"
          }}
          onLayout={event => {
            const { x, y, width, height } = event.nativeEvent.layout;
            if (this.props.onPropertyResize) {
              this.props.onPropertyResize({ height });
            }
          }}
        >
          <SelectItem
            style={{ marginTop: this.props.platform !== "ios" ? -10 : 0 }}
            renderLeft={() => {
              return this.renderPhoto({ image_size: 80 });
            }}
            renderTop={this.props.renderTop}
            onPress={() => {
              if (property && !this.props.select_drive_start) {
                //this.props.selectActiveProperty(null, property);
                if (this.props.device !== "mobile") {
                  this.props.completePopSidePanel();
                  this.props.replaceSidePanel({
                    slug: "property",
                    id: property?.property_id,
                    focus_side_panel:
                      this.props.user?.user_settings?.property_expanded ===
                      "not_expanded"
                        ? false
                        : true,
                    overlay: this.props.property_overlay || false,
                    data: {
                      property
                    }
                  });
                } else {
                  this.props.pushSidePanel({
                    slug: "property",
                    id: property?.property_id,
                    focus_side_panel:
                      this.props.user?.user_settings?.property_expanded ===
                      "not_expanded"
                        ? false
                        : true,
                    overlay: this.props.property_overlay || false,
                    data: {
                      property
                    }
                  });
                }

                if (this.props.onPress) {
                  this.props.onPress();
                }
              }
            }}
            selected={true}
            select_type="none"
            noPress={
              !this.props.select_drive_start &&
              property &&
              !this.props.onboarding_map &&
              !this.state.loading
                ? false
                : true
            }
            icon={
              !this.props.select_drive_start &&
              property &&
              !this.props.expanded &&
              !this.props.onboarding_map
                ? "keyboard-arrow-right"
                : ""
            }
            renderRight={
              this.props.expanded
                ? () => {
                    return (
                      <Row>
                        {this.renderTagsAndButton()}
                        <IconButton
                          icon={"keyboard-arrow-right"}
                          noPress={true}
                        />
                      </Row>
                    );
                  }
                : null
            }
            renderContent={() => {
              return (
                <Wrapper style={{ flex: 1 }}>
                  <Row>
                    <Title style={{ color: colors.actionable_text_color }}>
                      <Bold>{display_value}</Bold>
                    </Title>

                    <Copy
                      style={{
                        color: colors.light_text_color,
                        fontSize: 12,
                        marginLeft: 10
                      }}
                    >
                      {display_value_label}
                    </Copy>
                  </Row>

                  <Wrapper style={{ paddingTop: 5, paddingBottom: 10 }}>
                    <Copy style={{ marginBottom: 5 }}>
                      {line1}
                      {!!line2 ? (
                        <InnerCopy style={{ marginBottom: 5 }}>
                          {", " + line2}
                        </InnerCopy>
                      ) : null}
                    </Copy>
                    <Row>
                      {this.formatBathsAndBeds(property?.total_bedrooms) > 0 ? (
                        <Wrapper>
                          <Copy>
                            <Bold>
                              {this.formatBathsAndBeds(
                                property?.total_bedrooms
                              )}
                            </Bold>{" "}
                            bds
                          </Copy>
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          <Copy>-- bds</Copy>
                        </Wrapper>
                      )}

                      {this.formatBathsAndBeds(property?.total_baths) > 0 ? (
                        <Wrapper style={{ paddingLeft: 15 }}>
                          <Copy>
                            <Bold>
                              {this.formatBathsAndBeds(property?.total_baths)}
                            </Bold>{" "}
                            ba
                          </Copy>
                        </Wrapper>
                      ) : (
                        <Wrapper style={{ paddingLeft: 10 }}>
                          <Copy>-- ba</Copy>
                        </Wrapper>
                      )}

                      {property?.building_square_feet > 0 ? (
                        <Wrapper style={{ paddingLeft: 10 }}>
                          <Copy>
                            <Bold>
                              {numberWithCommas(property.building_square_feet)}
                            </Bold>{" "}
                            sqft
                          </Copy>
                        </Wrapper>
                      ) : (
                        <Wrapper style={{ paddingLeft: 10 }}>
                          <Copy>-- sqft</Copy>
                        </Wrapper>
                      )}
                    </Row>
                  </Wrapper>
                  {this.props.route_plan_active ? null : (
                    <PropertyFlags
                      property={property}
                      colors={colors}
                      round={true}
                    />
                  )}
                </Wrapper>
              );
            }}
          ></SelectItem>
          {!this.props.expanded ? this.renderTagsAndButton() : null}
          <Wrapper
            onLayout={event => {
              const { x, y, width, height } = event.nativeEvent.layout;
              if (this.props.onTagsResize) {
                this.props.onTagsResize({ height });
              }
            }}
          >
            {this.renderTags()}
          </Wrapper>
        </Wrapper>
      );
    }

    if (this.state.loading) {
      return (
        <Wrapper
          onLayout={event => {
            const { x, y, width, height } = event.nativeEvent.layout;
            if (this.props.onPropertyResize) {
              this.props.onPropertyResize({ height });
            }
          }}
          style={
            this.props.loading_height
              ? {
                  alignSelf: "stretch",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  height: this.props.loading_height
                }
              : {
                  alignSelf: "stretch",
                  flex: 1,
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center"
                }
          }
        >
          <SpinWrapper text="Loading..." />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        onLayout={event => {
          const { x, y, width, height } = event.nativeEvent.layout;
          if (this.props.onPropertyResize) {
            this.props.onPropertyResize({ height });
          }
        }}
        style={
          this.props.loading_height
            ? {
                alignSelf: "stretch",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                height: this.props.loading_height,
                padding: 25
              }
            : {
                alignSelf: "stretch",
                flex: 1,
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                padding: 25
              }
        }
      >
        <Copy style={{ textAlign: "center" }}>
          <Bold>No property found.</Bold>
        </Copy>
        <Copy style={{ textAlign: "center" }}>
          Try zooming in and pressing a property marker.
        </Copy>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  property_map,
  route,
  deal,
  billing,
  onboarding
}) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile, platform } = native;
  const { add_deal_loading, active_property, next_planned_property_loading } =
    property_map;
  const {
    current_route,
    planned_route_id,
    planned_route_info,
    route_plan_active
  } = route;
  const {
    billing_addons,
    driving_info_banner,
    source_of_truth,
    all_feature_metadata
  } = billing;
  const { all_tags } = deal;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    active_property,
    add_deal_loading,
    current_route,
    billing_addons,
    source_of_truth,
    all_feature_metadata,
    all_tags,
    next_planned_property_loading,
    driving_info_banner,
    onboarding,
    planned_route_id,
    planned_route_info,
    platform,
    route_plan_active
  };
};

export default connect(mapStateToProps, {
  addDeal,
  getPropertyNew,
  updateLead,
  setStartMailerCampaignPage,
  pushSidePanel,
  replaceSidePanel,
  setPhotoGallery,
  togglePhotoGallery,
  selectActiveProperty,
  setStreetViewCoords,
  getReverseGeocode,
  getTags,
  triggerNextPlannedProperty,
  popSidePanel,
  completePopSidePanel,
  nextOnboardingStep,
  toggleVirtualDriveStart,
  startTrackedRoute,
  setOnboardingLayout,
  updateLeadPhotoUpload,
  uploadFile,
  updateUser,
  getSourceOfTruth
})(PropertyView);

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Button,
  Icon,
  Copy,
  DraggableList
} from "app/NativeComponents/common";
import {
  UpsellButton,
  InlineButton,
  IconButton,
  PopoverMenu
} from "app/NativeComponents/snippets";

import OpenListsButton from "app/DealMachineCore/reuseable/OpenListsButton";
import {
  appRedirect,
  setActiveListTab,
  removeListFromListTabs,
  addListToListTabs,
  setEditListInfo,
  pushSidePanel,
  reorderListTabs,
  updateList,
  getLists,
  changeTab,
  setActiveList,
  tabNavigation,
  renderDate
} from "app/NativeActions";
import ListTab from "./ListTab";

class ListTabs extends Component {
  constructor(props) {
    super(props);

    this._scroll_view = React.createRef();
    this.scrollToEnd = this.scrollToEnd.bind(this);
  }

  componentDidMount() {
    //get pinned lists!
    if (this.props.list_tabs.length == 0) {
      this.props.getLists({
        token: this.props.token,
        filter: "pinned",
        load_type: "pinned_lists"
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.list_tabs &&
      prevProps.list_tabs &&
      this.props.list_tabs.length > prevProps.list_tabs.length
    ) {
      this.scrollToEnd();
    }
  }

  scrollToEnd() {
    const { device } = this.props;
    if (device == "desktop" && this._scroll_view && this._scroll_view.current) {
      this._scroll_view.current.scrollLeft =
        this._scroll_view.current.scrollWidth -
        this._scroll_view.current.clientWidth;
    }
  }

  reorderItems({ previousIndex, nextIndex }) {
    const { list_tabs } = this.props;
    const reordered_items = JSON.parse(JSON.stringify(list_tabs));

    const [removed] = reordered_items.splice(previousIndex, 1);
    reordered_items.splice(nextIndex, 0, removed);
    this.props.reorderListTabs(reordered_items);

    //go through all tabs...find pinned tabs and send request to save the order of just those tabs
    let pinned_tabs = [];
    for (let i = 0; i < reordered_items.length; i++) {
      if (reordered_items[i].pinned == true) {
        pinned_tabs.push(reordered_items[i]);
      }
    }

    //save pinned tabs
    this.props.updateList({
      token: this.props.token,
      type: "reorder_pinned_lists",
      list_ids: pinned_tabs
        .map(list => {
          return list.id;
        })
        .join(),
      onLoading: () => {},
      onError: () => {},
      onSuccess: () => {}
    });
  }

  render() {
    const { list_tabs, active_list_tab, colors, isMobile } = this.props;
    return (
      <Wrapper>
        <Row
          style={{
            borderBottomColor: colors.actionable_text_color,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            alignItems: "flex-end"
          }}
        >
          <ListTab
            title={"All Leads"}
            mobile_title={"Leads"}
            icon={""}
            active={active_list_tab == "all_leads"}
            onPress={() => {
              this.props.setActiveListTab("all_leads");
            }}
            list={"all_leads"}
            setEditListInfo={this.props.setEditListInfo}
            pushSidePanel={this.props.pushSidePanel}
            style={{ marginLeft: isMobile ? 5 : 25 }}
          />
          <Wrapper
            wrapper_ref={this._scroll_view}
            style={{
              overflow: "hidden",
              overflowX: "auto",
              marginBottom: -1
            }}
          >
            <Row>
              <DraggableList
                items={list_tabs}
                reverse={false}
                direction="horizontal"
                noHandle={true}
                renderItem={({ item, wrappedHandle }) => {
                  let title = item.title;
                  if (item == "in_trash_leads") {
                    title = "In Trash";
                  }

                  return (
                    <ListTab
                      title={title}
                      mobile_title={title}
                      subtitle={
                        item?.date_created ? renderDate(item.date_created) : ""
                      }
                      active={
                        active_list_tab?.id && item?.id
                          ? active_list_tab?.id == item.id
                          : active_list_tab == item
                      }
                      onPress={() => {
                        this.props.setActiveListTab(item);
                      }}
                      include_menu={true}
                      number_of_tabs={list_tabs.length}
                      token={this.props.token}
                      updateList={this.props.updateList}
                      onClose={() => this.props.removeListFromListTabs(item)}
                      list={item}
                      icon={item?.pinned ? "push-pin" : ""}
                      loading={item?.building == 1}
                      setEditListInfo={this.props.setEditListInfo}
                      pushSidePanel={this.props.pushSidePanel}
                      label={item?.is_ai_list ? "AI" : null}
                      label_tooltip={
                        item?.is_ai_list
                          ? "This list was built using AI Vision Builder"
                          : null
                      }
                    />
                  );
                }}
                onReorder={({ previousIndex, nextIndex }) => {
                  this.reorderItems({
                    previousIndex,
                    nextIndex
                  });
                }}
              />
            </Row>
          </Wrapper>
          <Wrapper style={{ flex: 1 }}>
            <Row>
              <OpenListsButton
                popoverPlacement="bottom"
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <InlineButton
                      icon="menu-open"
                      pressedIn={pressedIn || hovering}
                      noPress={true}
                      onPress={() => {}}
                    >
                      {isMobile ? "Lists" : "Open Lists"}
                    </InlineButton>
                  );
                }}
              />

              <PopoverMenu
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <InlineButton
                      onPress={() => {}}
                      pressedIn={pressedIn || hovering}
                      noPress={true}
                      style={{ marginLeft: 0 }}
                      icon="add"
                    >
                      Add Leads
                    </InlineButton>
                  );
                }}
                menu_items={[
                  {
                    title: "Manually Add Lead",
                    icon: "add",
                    type: "normal",
                    select_type: "icon",

                    onPress: () => {
                      this.props.pushSidePanel({
                        slug: "manually_add_lead",
                        overlay: true
                      });
                    }
                  },
                  {
                    title: "Import List",
                    icon: "file-upload",
                    type: "normal",
                    select_type: "icon",

                    onPress: () => {
                      this.props.pushSidePanel({
                        slug: "import_list",
                        overlay: true
                      });
                    }
                  }
                ]}
              />
              {/*
              <UpsellButton
                type="plan"
                slug="lists"
                contentful_slug="unlock_list_builder"
                tier={0}
                meta_slug="list_builder"
                onLockPressOverride={() => {
                  this.props.pushSidePanel({
                    slug: "list_builder",
                    focus_modal: true,
                    overlay: true,
                    locked: true
                  });
                }}
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "list_builder",
                    focus_modal: true,
                    overlay: true,
                    locked: true
                  });
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      icon="lock"
                      noPress={true}
                      pressedIn={hovering}
                      primary={true}
                    >
                      {"List Builder"}
                    </InlineButton>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      noPress={true}
                      pressedIn={hovering}
                      primary={true}
                      icon="playlist-add"
                    >
                      {"List Builder"}
                    </InlineButton>
                  );
                }}
              />
              */}
            </Row>
          </Wrapper>
        </Row>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, list, feature_toggle }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { active_list_tab, list_tabs } = list;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    active_list_tab,
    list_tabs
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setActiveListTab,
  removeListFromListTabs,
  addListToListTabs,
  setEditListInfo,
  pushSidePanel,
  reorderListTabs,
  updateList,
  getLists,
  changeTab,
  setActiveList,
  tabNavigation
})(ListTabs);

import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Scroll } from "app/NativeComponents/common";
import {
  IconButton,
  SearchBar,
  PopoverMenu,
  AttentionBox,
  SpinWrapper,
  List,
  SelectItem
} from "app/NativeComponents/snippets";

import { getRecentPrompts, renderDate } from "app/NativeActions";

class MorePrompts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_recent_prompts: false,
      prompt_search: "",
      prompts: [],
      begin: 0,
      limit: 25,
      prompts_loading: false,
      prompts_loaded_all: false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData({ load_type: "refresh" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.prompt_search !== this.state.prompt_search) {
      this.triggerSearch();
    }

    if (
      prevState.show_recent_prompts !== this.state.show_recent_prompts &&
      this.state.show_recent_prompts
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  triggerSearch() {
    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      this.getData({
        load_type: "refresh"
      });
    }, 750);
  }

  getData({ load_type = "refresh" }) {
    let new_state = {};

    switch (load_type) {
      case "refresh":
        new_state = {
          prompts: [],
          begin: 0,
          prompts_loaded_all: false
        };
        break;
    }

    this.setState(new_state, () => {
      this.props.getRecentPrompts({
        token: this.props.token,
        begin: this.state.begin,
        limit: this.state.limit,
        search: this.state.prompt_search,
        onLoading: () => {
          this.setState({ prompts_loading: true });
        },
        onError: () => {
          this.setState({ prompts_loading: false });
        },
        onSuccess: results => {
          this.setState({
            prompts_loading: false,
            prompts: [...this.state.prompts, ...results?.prompts],
            prompts_loaded_all: results?.prompts?.length < this.state.limit,
            begin: this.state.begin + this.state.limit
          });
        }
      });
    });
  }

  render() {
    const { isMobile, colors, source_of_truth } = this.props;

    return (
      <>
        <PopoverMenu
          show={this.state.show_recent_prompts}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.setState({
              show_recent_prompts: s,
              prompt_search: ""
            });
          }}
          includeCloseButton={true}
          popover_title={"Recent Prompts"}
          popover_width={450}
          popoverSheetTop={"75%"}
          popover_height={450}
          disabled={this.props.disabled}
          renderComponent={({ hovering }) => {
            return (
              <IconButton
                noPress={true}
                pressedIn={this.state.show_recent_prompts || hovering}
                disabled={this.props.disabled}
                icon={"history"}
                tooltip={"Recent Prompts"}
                tooltipPlacement={"top"}
                icon_color={colors.light_text_color}
                style={{ margin: 0, marginLeft: 5 }}
              />
            );
          }}
          renderMenu={() => {
            return (
              <>
                <Wrapper
                  style={{
                    alignSelf: "stretch"
                  }}
                >
                  <SearchBar
                    title="Search Prompts"
                    value={this.state.prompt_search}
                    onChange={value => {
                      this.setState({ prompt_search: value });
                    }}
                    style={{ margin: 10 }}
                  />
                </Wrapper>
                {this.state.prompts_loading &&
                this.state.prompts.length == 0 ? (
                  <Wrapper style={{ flex: 1 }}>
                    <SpinWrapper text="Loading Prompts..." />
                  </Wrapper>
                ) : (
                  <List
                    rowNumber={1}
                    style={{ flex: 1 }}
                    items={this.state.prompts}
                    infiniteScroll={true}
                    itemStructure={({ item, index }) => {
                      return (
                        <SelectItem
                          onPress={() => {
                            this.props.setPrompt(
                              item.ai_prompt,
                              item.use_vision,
                              item.attached_properties
                            );
                          }}
                          select_type="none"
                          icon="keyboard-arrow-right"
                          //description={renderDate(item.date_created)}
                        >
                          {item.ai_prompt.length > 200
                            ? `${item.ai_prompt.slice(0, 197)}...`
                            : item.ai_prompt}
                        </SelectItem>
                      );
                    }}
                    canRefresh={false}
                    onRefresh={() => {
                      this.getData({
                        load_type: "refresh"
                      });
                    }}
                    is_refreshing={false}
                    canLoadMore={
                      !this.state.prompts_loaded_all &&
                      !this.state.prompts_loading &&
                      this.state.prompts.length > 0
                    }
                    isLoadingMore={
                      this.props.activity_prompts_loading &&
                      this.state.leads.length > 0
                    }
                    onLoadMore={() => {
                      if (
                        !this.state.prompts_loaded_all &&
                        this.state.prompts_loading != true
                      ) {
                        this.getData({
                          load_type: "load_more"
                        });
                      }
                    }}
                  />
                )}
              </>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile
  };
};

export default connect(mapStateToProps, { getRecentPrompts })(MorePrompts);

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Scroll,
  KeyboardView,
  Spin,
  Gradient
} from "app/NativeComponents/common";
import {
  PullUpSheet,
  CircleButton,
  PopoverMenu,
  SpinWrapper,
  SelectItem,
  SmallLabel,
  InlineButton,
  InlineTabs,
  DateBox,
  InputBox,
  IconButton,
  List,
  CloseButton,
  GhostButton
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  updateSidePanel,
  getComps,
  numberWithCommas,
  updateCompsSettings,
  updateUserSettings,
  renderDate,
  formatAddress,
  getDefaultCompsArray,
  displayIntercom,
  checkIfUserHasSubscription
} from "app/NativeActions";
import NativeCompsMap from "app/NativeComponents/components/NativeCompsMap";
import moment from "moment";
import CompsProperties from "./CompsProperties";
import CompPopover from "./CompPopover";
import CompsAverages from "./CompsAverages";

class Comps extends Component {
  constructor(props) {
    super(props);

    const comps_array = getDefaultCompsArray({
      property: props.property,
      comps_settings: props.comps_settings
    });
    this.state = {
      show_comps_on_map: true,
      zoom: 12,
      updated_latitude: props.property?.location?.latitude,
      updated_longitude: props.property?.location?.longitude,
      updated_zoom: 12,
      updated_pitch: 0,
      updated_heading: 0,
      ...comps_array,
      sort_by: props.comps_settings?.sort_by || "distance",
      source_type: props.comps_settings?.source_type || "mls+county_records",
      dominant_source: props.comps_settings?.dominant_source || "mls",
      comps_on_map: [],
      unselected_comps_on_map: [],
      estimated_value: 0,
      average_sqft: 0,
      average_price: 0,
      comps_on_map_loading: false,
      saving_comps_settings: false,
      slideup_comps_handle_height: 1,
      show_popover: false,
      popover_info: null,
      maxZoom: 17,
      minZoom: 10,
      drawing_coordinates: [],
      drawing_created: false,
      drawing: false,
      refresh_map: false,
      active_comp: null
    };

    this.updateCenter = this.updateCenter.bind(this);
    this.updateBounds = this.updateBounds.bind(this);
    this.updateZoomProperties = this.updateZoomProperties.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.updateMainState = this.updateMainState.bind(this);
    this.onDraw = this.onDraw.bind(this);
    this.drawingCreated = this.drawingCreated.bind(this);
    this.toggleDrawing = this.toggleDrawing.bind(this);

    this._bottomSheetRef = React.createRef();
  }

  updateMainState(updates) {
    this.setState(updates);
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.property?.deal?.id !== prevProps?.property?.deal?.id ||
      JSON.stringify(this.props.property) !== JSON.stringify(prevProps.property)
    ) {
      const comps_array = getDefaultCompsArray({
        property: this.props.property,
        comps_settings: this.props.comps_settings
      });
      this.setState(
        {
          ...comps_array
        },
        () => {
          this.getData({ load_type: "refresh" });
        }
      );
    } else if (
      this.state.sale_date_min !== prevState.sale_date_min ||
      this.state.sale_date_max !== prevState.sale_date_max ||
      this.state.sqft_min !== prevState.sqft_min ||
      this.state.sqft_max !== prevState.sqft_max ||
      this.state.bedroom_min !== prevState.bedroom_min ||
      this.state.bedroom_max !== prevState.bedroom_max ||
      this.state.bathroom_min !== prevState.bathroom_min ||
      this.state.bathroom_max !== prevState.bathroom_max ||
      (this.state.year_built_min !== prevState.year_built_min &&
        (this.state.year_built_min.length == 4 ||
          this.state.year_built_min.length == 0)) ||
      (this.state.year_built_max !== prevState.year_built_max &&
        (this.state.year_built_max.length == 4 ||
          this.state.year_built_max.length == 0)) ||
      this.state.sort_by !== prevState.sort_by ||
      this.state.source_type !== prevState.source_type ||
      this.state.dominant_source !== prevState.dominant_source ||
      this.state.range_miles !== prevState.range_miles ||
      JSON.stringify(this.state.drawing_coordinates) !==
        JSON.stringify(prevState.drawing_coordinates)
    ) {
      this.getData({ load_type: "refresh" });
      const new_comps_settings = {
        ...this.props.comps_settings,
        sale_date_min: this.state.sale_date_min,
        sale_date_max: this.state.sale_date_max,
        sqft_min: this.state.sqft_min,
        sqft_max: this.state.sqft_max,
        bedroom_min: this.state.bedroom_min,
        bedroom_max: this.state.bedroom_max,
        bathroom_min: this.state.bathroom_min,
        bathroom_max: this.state.bathroom_max,
        year_built_min: this.state.year_built_min,
        year_built_max: this.state.year_built_max,
        sort_by: this.state.sort_by,
        source_type: this.state.source_type,
        dominant_source: this.state.dominant_source,
        range_miles: this.state.range_miles,
        drawing_coordinates: this.state.drawing_coordinates
      };

      this.props.updateCompsSettings(new_comps_settings);
      if (
        checkIfUserHasSubscription(
          this.props.user,
          this.props.source_of_truth
        ) &&
        !this.state.saving_comps_settings
      ) {
        this.props.updateUserSettings({
          token: this.props.token,
          type: "comps_settings",
          value: new_comps_settings,
          onLoading: () => {
            this.setState({
              saving_comps_settings: true
            });
          },
          onError: () => {
            this.setState({
              saving_comps_settings: false
            });
          },
          onSuccess: () => {
            this.setState({
              saving_comps_settings: false
            });
          }
        });
      }
    }
    if (
      JSON.stringify(this.state.comps_on_map) !==
        JSON.stringify(prevState.comps_on_map) ||
      JSON.stringify(this.state.unselected_comps_on_map) !==
        JSON.stringify(prevState.unselected_comps_on_map)
    ) {
      this.calculateTotals();
    }

    if (this.props.scrollOffset !== prevProps.scrollOffset) {
      this.togglePopover(false);
    }

    if (
      this.state.refresh_map &&
      this.state.refresh_map !== prevState.refresh_map
    ) {
      this.setState({
        refresh_map: false
      });
    }

    if (
      (this.props.showing_expanded_comps &&
        (this.state.comps_on_map_loading !== prevState.comps_on_map_loading ||
          this.state.sort_by !== prevState.sort_by ||
          JSON.stringify(this.state.unselected_comps_on_map) !==
            JSON.stringify(prevState.unselected_comps_on_map) ||
          JSON.stringify(this.state.comps_on_map) !==
            JSON.stringify(prevState.comps_on_map))) ||
      this.state.estimated_value !== prevState.estimated_value ||
      this.state.average_sqft !== prevState.average_sqft ||
      this.state.average_price !== prevState.average_price
    ) {
      this.props.updateSidePanel({
        index: this.props.panel_index + 1,
        data: {
          comps_on_map_loading: this.state.comps_on_map_loading,
          sort_by: this.state.sort_by,
          unselected_comps_on_map: this.state.unselected_comps_on_map,
          comps_on_map: this.state.comps_on_map,
          estimated_value: this.state.estimated_value,
          average_sqft: this.state.average_sqft,
          average_price: this.state.average_price,
          updateMainState: this.updateMainState
        }
      });
    }
  }
  getData({ load_type = "load" }) {

    this.props.getComps({
      token: this.props.token,
      property_id: this.props.property?.property_data_id,
      latitude: this.props.property?.location?.latitude,
      longitude: this.props.property?.location?.longitude,
      sale_date_min: this.state.sale_date_min,
      sale_date_max: this.state.sale_date_max,
      sqft_min: this.state.sqft_min,
      sqft_max: this.state.sqft_max,
      range_miles: this.state.range_miles,
      bedroom_min: this.state.bedroom_min,
      bedroom_max: this.state.bedroom_max,
      year_built_min: this.state.year_built_min,
      year_built_max: this.state.year_built_max,
      bathroom_min: this.state.bathroom_min,
      bathroom_max: this.state.bathroom_max,
      sort_by: this.state.sort_by,
      source_type: this.state.source_type,
      dominant_source: this.state.dominant_source,
      drawing_coordinates:
        this.state.drawing_coordinates.length > 0
          ? JSON.stringify(this.state.drawing_coordinates)
          : null,
      onLoading: () => {
        this.setState({
          comps_on_map: [],
          comps_on_map_loading: true
        });
      },
      onError: () => {
        this.setState({
          comps_on_map_loading: false
        });
      },
      onSuccess: results => {
        if (results.property_id == this.props.property?.property_data_id) {
          this.setState({
            comps_on_map:
              results?.comps.filter(
                ({ comp }) => comp?.multi_parcel_flag !== 1
              ) || [],
            comps_on_map_loading: false
          });
        } else {
          this.setState({
            comps_on_map_loading: false
          });
        }
      }
    });
  }
  onDraw(coordinates) {
    this.setState({
      drawing_coordinates: coordinates
    });
  }
  drawingCreated(coordinates) {
    this.setState({
      drawing_created: true,
      drawing: this.props.device == "mobile" ? false : this.state.drawing
    });
  }

  toggleDrawing(toggle) {
    this.setState({
      drawing_created: false,
      drawing: toggle
    });
  }

  calculateTotals() {
    const { unselected_comps_on_map, comps_on_map } = this.state;
    const { property } = this.props;
    let averagePrice = 0;
    let averageDollarPerSqft = 0;
    let estimatedValue = 0;

    let comps = [];

    for (let i = 0; i < comps_on_map.length; i++) {
      let dont_include = false;
      for (let j = 0; j < unselected_comps_on_map.length; j++) {
        if (
          unselected_comps_on_map[j].property_id == comps_on_map[i].property_id
        ) {
          dont_include = true;
        }
      }
      if (!dont_include) {
        comps.push(comps_on_map[i]);
      }
    }

    if (comps.length > 0) {
      averagePrice = parseInt(
        comps.reduce((a, c) => (a += Number(c.saleprice)), 0) / comps.length
      );

      averageDollarPerSqft = parseInt(
        comps
          .map(c => Number(c.saleprice) / Number(c.building_square_feet))
          .reduce((a, c) => (a += c), 0) / comps.length
      );

      estimatedValue = parseInt(
        averageDollarPerSqft * Number(property.building_square_feet)
      );
    }

    this.setState({
      average_price: averagePrice || 0,
      average_sqft: averageDollarPerSqft || 0,
      estimated_value: estimatedValue || 0
    });
  }

  updateCenter(coordinate) {
    this.setState({
      updated_latitude: coordinate.latitude,
      updated_longitude: coordinate.longitude
    });
  }

  updateBounds(bounds, force = false) {
    //if not already loading...get list builder previously drawn lists
    this.setState({
      bounds
    });
  }

  updateZoomProperties({ zoom, pitch, heading }) {
    this.setState({
      updated_zoom: zoom
    });
  }

  getSqftFilterCopy() {
    const { sqft_min, sqft_max } = this.state;
    if (!sqft_min)
      return (
        <>
          with up to <Bold>{sqft_min}</Bold> sqft
        </>
      );
    if (!sqft_max)
      return (
        <>
          with more than <Bold>{sqft_max}</Bold> sqft
        </>
      );
    return (
      <>
        with between <Bold>{sqft_min}</Bold> and <Bold>{sqft_max}</Bold> sqft
      </>
    );
  }

  togglePopover(show_popover, popover_info = null) {
    this.setState({
      show_popover,
      popover_info
    });
  }

  render() {
    const { colors, isMobile, device, property, height } = this.props;
    const {
      comps_on_map,
      unselected_comps_on_map,
      comps_on_map_loading,
      sort_by
    } = this.state;
    let top = 0;

    if (device == "desktop") {
      const slideup_comps_handle = document.getElementById(
        "slideup_comps_handle"
      );
      top =
        this.props.window_height -
        parseInt(slideup_comps_handle?.clientHeight) -
        350;
    } else {
      top =
        this.props.window_height - this.state.slideup_comps_handle_height - 400;
    }

    let comps = [];

    for (let i = 0; i < comps_on_map.length; i++) {
      let dont_include = false;
      for (let j = 0; j < unselected_comps_on_map.length; j++) {
        if (
          unselected_comps_on_map[j].property_id == comps_on_map[i].property_id
        ) {
          dont_include = true;
        }
      }
      if (!dont_include) {
        comps.push(comps_on_map[i]);
      }
    }

    return (
      <>
        {/*
        <Wrapper
          style={{
            padding: 5,
            backgroundColor: colors.popover_color,
            borderRadius: 5,
            margin: 5
          }}
        >
          <Copy>
            Showing properties sold between{" "}
            <Bold>
              {moment(this.state.sale_date_min).format("MMM Do, YYYY")}
            </Bold>{" "}
            and{" "}
            <Bold>
              {moment(this.state.sale_date_max).format("MMM Do, YYYY")}
            </Bold>{" "}
            {this.getSqftFilterCopy()} within{" "}
            <Bold>
              {this.state.range_miles == 1 ? "1 mile" : this.state.range_miles + " miles"}.
            </Bold>
          </Copy>
        </Wrapper>
        */}
        <Wrapper style={this.props.expanded ? { flexDirection: "row" } : {}}>
          <Wrapper
            style={
              this.props.expanded
                ? { flex: 1, position: "relative" }
                : { position: "relative" }
            }
          >
            <NativeCompsMap
              height={height}
              setMapDragging={this.props.setMapDragging}
              refresh_map={this.state.refresh_map}
              comps_on_map={comps}
              show_comps_panel={this.props.show_comps_panel}
              paddingBottom={this.props.show_comps_panel ? top + 100 : 0}
              latitude={property?.location?.latitude}
              longitude={property?.location?.longitude}
              zoom={this.state.zoom}
              minZoom={this.state.minZoom}
              maxZoom={this.state.maxZoom}
              updateCenter={this.updateCenter}
              updateBounds={this.updateBounds}
              updateZoomProperties={this.updateZoomProperties}
              show_comps_on_map={this.state.show_comps_on_map}
              togglePopover={this.togglePopover}
              show_popover={this.state.show_popover}
              popover_info={this.state.popover_info}
              drawing={this.state.drawing}
              onDraw={this.onDraw}
              drawingCreated={this.drawingCreated}
              drawing_coordinates={this.state.drawing_coordinates}
              active_comp={this.state.active_comp}
              selectActiveComp={comp => {
                if (!this.props.show_comps_panel) {
                  this.setState({
                    active_comp: comp
                  });
                }
              }}
              renderPropertyPopover={popover_info => {
                return (
                  <CompPopover
                    popover_info={popover_info}
                    unselected_comps_on_map={unselected_comps_on_map}
                    updateMainState={this.updateMainState}
                    togglePopover={this.togglePopover}
                    onClose={() => {
                      this.setState({
                        active_comp: null
                      });
                    }}
                    colors={colors}
                    isMobile={isMobile}
                    device={this.props.device}
                    pushSidePanel={this.props.pushSidePanel}
                    user={this.props.user}
                    source_of_truth={this.props.source_of_truth}
                  />
                );
              }}
            />
            {!this.props.show_comps_panel || this.props.device == "desktop" ? (
              <Wrapper
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0
                }}
              >
                <CircleButton
                  loading={this.state.current_location_loading}
                  //textColor={this.props.colors.text_color}
                  icon={"place"}
                  onPress={() => {
                    this.setState(
                      {
                        show_comps_on_map: false,
                        active_comp: null
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            show_comps_on_map: true
                          });
                        },500);

                      }
                    );
                  }}
                />
                <Wrapper
                  style={{
                    backgroundColor: this.props.colors.popover_color,
                    borderRadius: 30,
                    borderColor: this.props.colors.border_color,
                    borderWidth: 1,
                    borderStyle: "solid",
                    margin: 5
                  }}
                >
                  {this.state.comps_on_map_loading ? (
                    <Wrapper
                      style={{
                        width: 46,
                        height: 46,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Spin
                        color={this.props.colors.actionable_text_color}
                        size="small"
                      />
                    </Wrapper>
                  ) : (
                    <IconButton
                      style={{
                        width: 36,
                        height: 36
                      }}
                      onPress={() => {
                        this.getData({ load_type: "refresh" });
                      }}
                      size={22}
                      icon_color={this.props.colors.actionable_text_color}
                      icon="refresh"
                    />
                  )}
                </Wrapper>
              </Wrapper>
            ) : null}

            {this.state.drawing &&
            this.state.drawing_coordinates?.length == 0 ? (
              <Wrapper
                style={{ position: "absolute", top: 0, left: 75, right: 75 }}
              >
                <Wrapper
                  style={{
                    margin: 5,
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor: colors.orange_color_muted
                  }}
                >
                  <Copy style={{ textAlign: "center", fontSize: 12 }}>
                    {this.props.device == "desktop"
                      ? "Click on the map to add points to your drawing. Click on a previous point to complete your drawing."
                      : "Draw with your finger to filter your comps."}
                  </Copy>
                </Wrapper>
              </Wrapper>
            ) : null}
            <Wrapper style={{ position: "absolute", top: 0, left: 0 }}>
              <CircleButton
                onPress={() => {
                  this.setState({
                    drawing:
                      this.state.drawing_coordinates?.length > 0
                        ? false
                        : !this.state.drawing,
                    drawing_created: false,
                    drawing_coordinates: [],
                    active_comp: null
                  });

                  this.togglePopover(false);
                }}
                icon={
                  this.state.drawing_coordinates?.length > 0
                    ? "delete-forever"
                    : this.state.drawing
                    ? "delete-forever"
                    : this.props.device == "desktop"
                    ? "draw"
                    : "gesture"
                }
                disabled={
                  this.state.updated_zoom > this.state.maxZoom ||
                  this.state.updated_zoom < this.state.minZoom
                }
                tooltip={
                  this.state.drawing
                    ? this.state.drawing_coordinates?.length == 0
                      ? "Cancel Drawing"
                      : "Clear Drawing"
                    : this.state.updated_zoom < this.state.minZoom
                    ? "Zoom in to activate drawing tool"
                    : "Start Drawing"
                }
                tooltipPlacement={"top"}
              />
              {!this.props.showing_comps_filters ? (
                <CircleButton
                  tooltip={"Filter Comps"}
                  tooltipPlacement={"top"}
                  icon={"tune"}
                  disabled={this.props.showing_comps_filters}
                  pressedIn={this.props.showing_comps_filters}
                  onPress={() => {
                    this.setState(
                      {
                        refresh_map: true
                      },
                      () => {
                        this.togglePopover(false);
                        if (this.props.showing_expanded_comps) {
                          this.props.replaceSidePanel({
                            slug: "filter_comps",
                            expanded_push: !this.props.expanded ? true : false,
                            overlay: true,
                            data: {
                              property,
                              comps_on_map_loading:
                                this.state.comps_on_map_loading,
                              sale_date_min: this.state.sale_date_min,
                              sale_date_max: this.state.sale_date_max,
                              sqft_min: this.state.sqft_min,
                              sqft_max: this.state.sqft_max,
                              bedroom_min: this.state.bedroom_min,
                              bedroom_max: this.state.bedroom_max,
                              bathroom_min: this.state.bathroom_min,
                              bathroom_max: this.state.bathroom_max,
                              year_built_min: this.state.year_built_min,
                              year_built_max: this.state.year_built_max,
                              range_miles: this.state.range_miles,
                              source_type: this.state.source_type,
                              dominant_source: this.state.dominant_source,
                              updateMainState: this.updateMainState,
                              handleBack: () => {
                                this.setState({
                                  refresh_map: true
                                });
                              }
                            },
                            onSuccess: () => {
                              this.setState({
                                active_comp: null
                              });
                            }
                          });
                        } else {
                          this.props.pushSidePanel({
                            slug: "filter_comps",
                            expanded_push: !this.props.expanded ? true : false,
                            overlay: true,
                            data: {
                              property,
                              comps_on_map_loading:
                                this.state.comps_on_map_loading,
                              sale_date_min: this.state.sale_date_min,
                              sale_date_max: this.state.sale_date_max,
                              sqft_min: this.state.sqft_min,
                              sqft_max: this.state.sqft_max,
                              bedroom_min: this.state.bedroom_min,
                              bedroom_max: this.state.bedroom_max,
                              bathroom_min: this.state.bathroom_min,
                              bathroom_max: this.state.bathroom_max,
                              year_built_min: this.state.year_built_min,
                              year_built_max: this.state.year_built_max,
                              range_miles: this.state.range_miles,
                              source_type: this.state.source_type,
                              dominant_source: this.state.dominant_source,
                              updateMainState: this.updateMainState,
                              handleBack: () => {
                                this.setState({
                                  refresh_map: true
                                });
                              }
                            },
                            onSuccess: () => {
                              this.setState({
                                active_comp: null
                              });
                            }
                          });
                        }
                      }
                    );
                  }}
                />
              ) : null}
            </Wrapper>
          </Wrapper>
          {this.props.expanded && !this.props.showing_expanded_comps ? (
            <Wrapper
              style={{
                width: "calc(90vw - 800px)",
                maxWidth: 400,
                height: height,
                overflow: "hidden",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
                borderLeftColor: colors.border_color
              }}
            >
              {comps_on_map_loading ? (
                <Wrapper>
                  <SpinWrapper text={"Loading Comps"} />
                </Wrapper>
              ) : (
                <>
                  <CompsAverages
                    {...this.props}
                    estimated_value={this.state.estimated_value}
                    average_sqft={this.state.average_sqft}
                    average_price={this.state.average_price}
                  />
                  <KeyboardView style={{ flex: 1 }}>
                    <CompsProperties
                      unselected_comps_on_map={unselected_comps_on_map}
                      comps_on_map={comps_on_map}
                      sort_by={sort_by}
                      updateMainState={this.updateMainState}
                      colors={colors}
                      isMobile={isMobile}
                      pushSidePanel={this.props.pushSidePanel}
                      expanded={this.props.expanded}
                    />
                  </KeyboardView>
                </>
              )}
            </Wrapper>
          ) : null}
        </Wrapper>
        {!this.props.showing_expanded_comps && !this.props.expanded ? (
          <PullUpSheet
            sheet_ref={this._bottomSheetRef}
            show={this.props.show_comps_panel}
            comps={true}
            onShow={() => {
              this.togglePopover(false);
              this.props.updateMainState({
                show_comps_panel: true
              });
            }}
            onHide={() => {
              this.togglePopover(false);
              this.props.updateMainState({
                show_comps_panel: false
              });
            }}
            increase_height_on_keyboard={false}
            scroll_to_hide={false}
            desktop_bottom={0}
            bottom={
              this.props.device == "desktop" ||
              this.state.slideup_comps_handle_height <= 0
                ? 1
                : this.state.active_comp
                ? this.state.slideup_comps_handle_height
                : this.state.slideup_comps_handle_height + 40
            }
            top={"70%"}
            desktop_top={top}
            noHandle={
              this.state.active_comp ||
              !checkIfUserHasSubscription(
                this.props.user,
                this.props.source_of_truth
              )
                ? true
                : false
            }
            enablePanDownToClose={false}
            renderHandle={() => {
              return (
                <Wrapper
                  id="slideup_comps_handle"
                  style={{
                    backgroundColor: colors.popover_color,
                    borderTopLeftRadius:
                      this.props.device == "mobile" && !this.state.active_comp
                        ? 0
                        : 15,
                    borderTopRightRadius:
                      this.props.device == "mobile" && !this.state.active_comp
                        ? 0
                        : 15
                  }}
                  onLayout={event => {
                    const { x, y, width, height } = event?.nativeEvent?.layout;
                    this.setState({
                      slideup_comps_handle_height: height
                    });
                  }}
                >
                  <>
                    {this.props.device == "desktop" && !this.props.expanded ? (
                      <Wrapper
                        style={{
                          padding: 10,
                          alignItems: "center",
                          justifyContent: "center",
                          borderTopLeftRadius: 30,
                          borderTopRightRadius: 30,
                          backgroundColor: colors.popover_color,
                          marginBottom: -1
                        }}
                      >
                        <Wrapper
                          style={{
                            height: 8,
                            width: 60,
                            borderRadius: 4,
                            backgroundColor:
                              this.props.dark_mode == "dark_mode"
                                ? colors.card_color
                                : colors.gray_color
                          }}
                        />
                      </Wrapper>
                    ) : null}

                    {this.state.active_comp && this.props.device == "mobile" ? (
                      <>
                        <CompPopover
                          user={this.props.user}
                          source_of_truth={this.props.source_of_truth}
                          popover_info={{
                            property: this.state.active_comp
                          }}
                          unselected_comps_on_map={unselected_comps_on_map}
                          updateMainState={this.updateMainState}
                          togglePopover={this.togglePopover}
                          onClose={() => {
                            this.setState({
                              active_comp: null
                            });
                          }}
                          colors={colors}
                          isMobile={isMobile}
                          device={this.props.device}
                          pushSidePanel={this.props.pushSidePanel}
                        />
                      </>
                    ) : comps_on_map_loading ? (
                      <SpinWrapper />
                    ) : (
                      <>
                        <CompsAverages
                          {...this.props}
                          estimated_value={this.state.estimated_value}
                          average_sqft={this.state.average_sqft}
                          average_price={this.state.average_price}
                        />
                      </>
                    )}
                    {this.props.device == "desktop" &&
                    !isMobile &&
                    checkIfUserHasSubscription(
                      this.props.user,
                      this.props.source_of_truth
                    ) ? (
                      <Wrapper
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0
                        }}
                      >
                        <IconButton
                          icon="launch"
                          tooltip="Expand Properties"
                          tooltipPlacement="top"
                          style={{}}
                          onPress={() => {
                            this.setState(
                              {
                                refresh_map: true,
                                show_comps_panel: false
                              },
                              () => {
                                this.togglePopover(false);
                                if (this.props.showing_comps_filters) {
                                  this.props.replaceSidePanel({
                                    slug: "show_comps",
                                    expanded_push: !this.props.expanded
                                      ? true
                                      : false,
                                    overlay: true,
                                    data: {
                                      property,
                                      comps_on_map_loading:
                                        this.state.comps_on_map_loading,
                                      comps_on_map: this.state.comps_on_map,
                                      unselected_comps_on_map:
                                        this.state.unselected_comps_on_map,
                                      sort_by: this.state.sort_by,
                                      updateMainState: this.updateMainState,
                                      estimated_value:
                                        this.state.estimated_value,
                                      average_sqft: this.state.average_sqft,
                                      average_price: this.state.average_price,
                                      handleBack: () => {
                                        this.setState({
                                          refresh_map: true,
                                          show_comps_panel: false
                                        });
                                      }
                                    },
                                    onSuccess: () => {}
                                  });
                                } else {
                                  this.props.pushSidePanel({
                                    slug: "show_comps",
                                    expanded_push: !this.props.expanded
                                      ? true
                                      : false,
                                    overlay: true,
                                    data: {
                                      property,
                                      comps_on_map_loading:
                                        this.state.comps_on_map_loading,
                                      comps_on_map: this.state.comps_on_map,
                                      unselected_comps_on_map:
                                        this.state.unselected_comps_on_map,
                                      sort_by: this.state.sort_by,
                                      updateMainState: this.updateMainState,
                                      estimated_value:
                                        this.state.estimated_value,
                                      average_sqft: this.state.average_sqft,
                                      average_price: this.state.average_price,
                                      handleBack: () => {
                                        this.setState({
                                          refresh_map: true,
                                          show_comps_panel: false
                                        });
                                      }
                                    },
                                    onSuccess: () => {}
                                  });
                                }
                              }
                            );
                          }}
                        />
                      </Wrapper>
                    ) : null}
                  </>
                </Wrapper>
              );
            }}
          >
            {this.state.active_comp && this.props.device == "mobile" ? (
              <></>
            ) : this.state.comps_on_map.length > 0 &&
              checkIfUserHasSubscription(
                this.props.user,
                this.props.source_of_truth
              ) ? (
              <>
                <CompsProperties
                  unselected_comps_on_map={unselected_comps_on_map}
                  comps_on_map={comps_on_map}
                  sort_by={sort_by}
                  updateMainState={this.updateMainState}
                  colors={colors}
                  isMobile={isMobile}
                  pushSidePanel={this.props.pushSidePanel}
                />
              </>
            ) : !this.state.comps_on_map_loading &&
              checkIfUserHasSubscription(
                this.props.user,
                this.props.source_of_truth
              ) ? (
              <Wrapper
                style={{
                  padding: 25,
                  margin: 5,
                  borderRadius: 5
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  There are no comps matching your filters.
                </Copy>
              </Wrapper>
            ) : null}
          </PullUpSheet>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = ({ auth, native, settings, lead, billing }) => {
  const { token, user } = auth;
  const { isMobile, device, isIphoneX, window_height } = native;
  const { colors, dark_mode } = settings;
  const { comps_settings } = lead;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode,
    isIphoneX,
    window_height,
    comps_settings,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  updateSidePanel,
  updateCompsSettings,
  updateUserSettings,
  getComps
})(Comps);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalOverlay,
  Container,
  Scroll,
  Wrapper,
  KeyboardView,
  Copy,
  Bold
} from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";
import Body from "./Body";
import {
  addDeal,
  appRedirect,
  getAutocompleteMap,
  selectActiveProperty,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  dismissMobileKeyboard,
  updateManualPropertyState,
  resetManualPropertyState,
  numberWithCommas
} from "app/NativeActions";

class ManuallyAddProperty extends Component {
  constructor(props) {
    super(props);

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.clearAutocomplete = this.clearAutocomplete.bind(this);
    this.addProperty = this.addProperty.bind(this);
  }

  componentDidMount() {
    this.props.resetManualPropertyState();
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  clearAutocomplete() {
    this.props.updateManualPropertyState({
      autocomplete_loading: false,
      autocomplete_items: []
    });
  }

  addProperty() {
    this.props.addDeal({
      token: this.props.token,
      property: this.props.manual_property_state.property
        ? this.props.manual_property_state.property
        : {
            from_data_source_id: "manual",
            from_data_source: "address"
          },
      add_type: "manual",
      address: this.props.manual_property_state.address,
      address2: this.props.manual_property_state.address2,
      city: this.props.manual_property_state.city,
      state: this.props.manual_property_state.state,
      zip: this.props.manual_property_state.zip,
      devicetype: this.props.device + "_manual",
      onLoading: () => {
        this.props.updateManualPropertyState({ loading: true });
      },
      onSuccess: property => {
        if (this.props.device == "mobile") {
          this.props.popSidePanel();
          this.props.pushSidePanel({
            slug: "property",
            focus_side_panel:
              this.props.user?.user_settings?.property_expanded ===
              "not_expanded"
                ? false
                : true,
            id: property.property_id,
            data: {
              property
            }
          });
        } else {
          this.props.replaceSidePanel({
            slug: "property",
            focus_side_panel:
              this.props.user?.user_settings?.property_expanded ===
              "not_expanded"
                ? false
                : true,
            id: property?.property_id,
            data: {
              property
            }
          });
        }
      },
      onError: error => {
        this.props.updateManualPropertyState({ loading: false });
        const upsell_info = error?.upsell_info;
        this.props.pushSidePanel({
          slug: "unlock_feature_modal",
          overlay: true,
          data: {
            feature_upsell_info: upsell_info.feature_metadata,
            renderContent: () => {
              return (
                <Wrapper style={{ padding: 25 }}>
                  <Copy>
                    You have added{" "}
                    <Bold>
                      {numberWithCommas(upsell_info.current_count)} leads
                    </Bold>{" "}
                    Adding this property will exceed your limit of{" "}
                    <Bold>
                      {numberWithCommas(
                        upsell_info.subscription.metadata.total_number_of_leads
                      )}
                    </Bold>
                    . Permanently delete a lead or upgrade to continue.
                  </Copy>
                </Wrapper>
              );
            },
            buttonText: "Upgrade And Add Lead",
            confirmText: "Confirm Upgrade?",
            onSuccess: () => {
              this.props.popSidePanel();
              const { property, token, isMobile, current_route } = this.props;
              this.props.addDeal({
                token: this.props.token,
                property: this.props.manual_property_state.property
                  ? this.props.manual_property_state.property
                  : {
                      from_data_source_id: "manual",
                      from_data_source: "address"
                    },
                add_type: "manual",
                address: this.props.manual_property_state.address,
                address2: this.props.manual_property_state.address2,
                city: this.props.manual_property_state.city,
                state: this.props.manual_property_state.state,
                zip: this.props.manual_property_state.zip,
                devicetype: this.props.device + "_manual",
                onLoading: () => {
                  this.props.updateManualPropertyState({ loading: true });
                },
                onSuccess: property => {
                  if (this.props.device == "mobile") {
                    this.props.popSidePanel();
                    this.props.pushSidePanel({
                      slug: "property",
                      focus_side_panel:
                        this.props.user?.user_settings?.property_expanded ===
                        "not_expanded"
                          ? false
                          : true,
                      id: property.property_id,
                      data: {
                        property
                      }
                    });
                  } else {
                    this.props.replaceSidePanel({
                      slug: "property",
                      focus_side_panel:
                        this.props.user?.user_settings?.property_expanded ===
                        "not_expanded"
                          ? false
                          : true,
                      id: property?.property_id,
                      data: {
                        property
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
  }

  updateAddressState({ prop, value }) {
    this.props.updateManualPropertyState({ [prop]: value });
  }

  autocompleteAddress() {}

  componentWillUnmount() {
    clearInterval(this._autocomplete_interval);
    clearInterval(this._blur_interval);
  }

  triggerAutocomplete(value) {
    this.clearAutocomplete();
    if (
      value.length > 3 &&
      !this.props.manual_property_state.autocomplete_loading
    ) {
      clearInterval(this._autocomplete_interval);
      this._autocomplete_interval = setTimeout(() => {
        this.props.getAutocompleteMap({
          token: this.props.token,
          search: value,
          centerLat: this.props.main_map_location?.latitude,
          centerLng: this.props.main_map_location?.longitude,
          types: "address",
          onLoading: () => {
            this.props.updateManualPropertyState({
              autocomplete_loading: true,
              autocomplete_items: []
            });
          },
          onError: () => {
            this.props.updateManualPropertyState({
              autocomplete_loading: false,
              autocomplete_items: []
            });
          },
          onSuccess: results => {
            this.props.updateManualPropertyState({
              autocomplete_loading: false,
              autocomplete_items: results?.properties || []
            });
          }
        });
      }, 750);
    }
  }

  setAddressFields({ address, address2, city, state, zip, property }) {
    this.props.updateManualPropertyState({
      address: address,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
      property: property
    });
  }
  checkIfNeedsToSave() {
    const { address, city, state, zip } = this.props.manual_property_state;
    if (!!address && !!city && !!state && !!zip) {
      return true;
    }
    return false;
  }

  renderBody() {
    const { device, colors, manual_property_state } = this.props;
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title="Add Lead"
          subtitle="Manually enter the address of your lead."
          leftButton={{
            icon: device == "mobile" ? "arrow-back" : "close",
            onPress: this.handleBack.bind(this),
            disabled: manual_property_state.loading
          }}
          rightButtonIcon="check"
          rightButtonAction={this.checkLimitsAndAddProperty}
        />
        <KeyboardView>
          <Body
            {...this.props}
            loading={manual_property_state.loading}
            triggerAutocomplete={this.triggerAutocomplete.bind(this)}
            clearAutocomplete={this.clearAutocomplete}
            setAddressFields={this.setAddressFields.bind(this)}
            addProperty={this.addProperty}
            updateAddressState={this.updateAddressState.bind(this)}
            address={manual_property_state.address}
            address2={manual_property_state.address2}
            city={manual_property_state.city}
            state={manual_property_state.state}
            zip={manual_property_state.zip}
            property={manual_property_state.property}
            checkIfNeedsToSave={this.checkIfNeedsToSave}
            autocomplete_items={manual_property_state.autocomplete_items}
            autocomplete_loading={manual_property_state.autocomplete_loading}
          />
        </KeyboardView>
      </Container>
    );
  }

  render() {
    if (this.props.user) {
      return this.renderBody();
    }
    return null;
  }
}

const mapStateToProps = ({
  auth,
  native,
  property_map,
  settings,
  modal,
  billing,
  lead
}) => {
  const { token, user } = auth;
  const { states, colors } = settings;
  const { device } = native;
  const { source_of_truth, all_feature_metadata } = billing;
  const { main_map_location } = property_map;
  const { lead_modal } = modal;
  const { manual_property_state } = lead;
  return {
    token,
    user,
    states,
    device,
    colors,
    lead_modal,
    main_map_location,
    source_of_truth,
    all_feature_metadata,
    manual_property_state
  };
};

export default connect(mapStateToProps, {
  addDeal,
  getAutocompleteMap,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  appRedirect,
  selectActiveProperty,
  updateManualPropertyState,
  resetManualPropertyState
})(ManuallyAddProperty);

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {useControl} from 'react-map-gl';

export default function DrawControl(props) {
  useControl(
    function() {
      return new MapboxDraw(props);
    },
    function(args) {
      let map = args.map;
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.delete', props.onDelete);
    },
    function(args) {
      let map = args.map;
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
    },
    {
      position: props.position
    }
  );

  return null;
}

DrawControl.defaultProps = {
  onCreate: function() {},
  onUpdate: function() {},
  onDelete: function() {}
};


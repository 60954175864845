import React, { Component } from "react";

import { Copy, Title, Row, Bold, Wrapper } from "app/NativeComponents/common";
import { store } from "app/store";
import Addon from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/Addon";
class DisplayAddons extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const colors = store.getState().settings.colors;
    const desktopMobile = store.getState().native.desktopMobile;
    const device = store.getState().native.device;
    const token = store.getState().auth.token;

    const { contentful_content, plan_interval = false } = this.props;

    return (
      <Wrapper style={{ alignSelf: "stretch" }}>
        <Row
          style={
            !desktopMobile
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap"
                }
              : {
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }
          }
        >
          {contentful_content?.contentItems &&
            contentful_content?.contentItems.map((item, i) => {
              const plan = item?.fields;
              if (plan?.featured && desktopMobile) {
                return (
                  <>
                    <Addon
                      key={"plan_" + i}
                      colors={colors}
                      desktopMobile={desktopMobile}
                      device={device}
                      marketing_slug={plan?.marketing_event_slug}
                      token={token}
                      title={plan?.title}
                      stripe_price_id_month={plan?.stripePriceIdMonthly}
                      stripe_price_id_year={plan?.stripePriceIdYearly}
                      featured={plan?.featured}
                      price={plan?.monthPrice}
                      year_price={plan?.yearPrice}
                      plan_interval={this.props.plan_interval}
                      buttonText={plan?.buttonText}
                      includes_items={
                        plan?.includesItems ? plan.includesItems : null
                      }
                      description={plan?.description}
                      subtext={plan?.subtext}
                      manageSubscription={this.props.manageSubscription}
                      onSuccess={this.props.onSuccess}
                      offer_promo={plan?.promo}
                      offer_type={plan?.offer_type}
                      force_interval={plan?.force_interval}
                    />
                  </>
                );
              }
              return null;
            })}

          {contentful_content?.contentItems &&
            contentful_content?.contentItems.map((item, i) => {
              const plan = item?.fields;
              if (!plan?.featured || !desktopMobile) {
                return (
                  <Addon
                    key={"plan_" + i}
                    colors={colors}
                    desktopMobile={desktopMobile}
                    device={device}
                    marketing_slug={plan?.marketing_event_slug}
                    token={token}
                    title={plan?.title}
                    stripe_price_id_month={plan?.stripePriceIdMonthly}
                    stripe_price_id_year={plan?.stripePriceIdYearly}
                    featured={plan?.featured}
                    price={plan?.monthPrice}
                    year_price={plan?.yearPrice}
                    plan_interval={this.props.plan_interval}
                    buttonText={plan?.buttonText}
                    includes_items={
                      plan?.includesItems ? plan.includesItems : null
                    }
                    description={plan?.description}
                    subtext={plan?.subtext}
                    manageSubscription={this.props.manageSubscription}
                    onSuccess={this.props.onSuccess}
                    offer_promo={plan?.promo}
                    offer_type={plan?.offer_type}
                    force_interval={plan?.force_interval}
                  />
                );
              }
              return null;
            })}
        </Row>
      </Wrapper>
    );
  }
}

export default DisplayAddons;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Wrapper } from "app/NativeComponents/common";
import { saveData, pushSidePanel } from "app/NativeActions";

const queryString = require("query-string");

class PartnerWrapper extends Component {
  getQueryParams(location) {
    if (location) {
      const parsed = queryString.parse(location.search);

      if (!!parsed.special) {
        this.props.updateMarketingUser({
          prop: "special",
          value: parsed.special
        });
        window.localStorage.setItem("special", parsed.special);
      }

      if (!!parsed.promo) {
        this.props.updateMarketingUser({
          prop: "promoText",
          value: parsed.promo
        });
        window.localStorage.setItem("promo", parsed.promo);
        window.localStorage.setItem("promoText", parsed.promo);
      } else if (!!parsed.fpr) {
        if (Array.isArray(parsed.fpr)) {
          const fpr = parsed.fpr[0];
          if (fpr?.match(/^-?\d+$/)) {
            const fpr_promo = fpr.toUpperCase();

            this.props.updateMarketingUser({
              prop: "promoText",
              value: fpr_promo
            });
            window.localStorage.setItem("promo", fpr_promo);
            window.localStorage.setItem("promoText", fpr_promo);
          }
        } else if (!parsed?.fpr?.match(/^-?\d+$/)) {
          const fpr_promo = parsed.fpr.toUpperCase();

          this.props.updateMarketingUser({
            prop: "promoText",
            value: fpr_promo
          });
          window.localStorage.setItem("promo", fpr_promo);
          window.localStorage.setItem("promoText", fpr_promo);
        }
      }

      if (parsed.link_type) {
        this.props.updateMarketingUser({
          prop: "signup_type",
          value: parsed.link_type
        });

        if (parsed.link_type == "team_invite") {
          this.props.updateMarketingUser({
            prop: "signup_type",
            value: parsed.link_type
          });
          if (parsed.email) {
            this.props.updateMarketingUser({
              prop: "email",
              value: parsed.email
            });
          }

          if (parsed.team) {
            this.props.updateMarketingUser({
              prop: "team_id",
              value: parsed.team
            });
          }
          if (parsed.team_name) {
            this.props.updateMarketingUser({
              prop: "team_name",
              value: parsed.team_name
            });
          }
          if (parsed.team_company) {
            this.props.updateMarketingUser({
              prop: "team_company",
              value: parsed.team_company
            });
          }
        } else if (parsed.link_type == "team_open_invite") {
          if (parsed.team) {
            this.props.updateMarketingUser({
              prop: "team_id",
              value: parsed.team
            });
          }
          if (parsed.team_name) {
            this.props.updateMarketingUser({
              prop: "team_name",
              value: parsed.team_name
            });
          }
          if (parsed.team_company) {
            this.props.updateMarketingUser({
              prop: "team_company",
              value: parsed.team_company
            });
          }
          if (parsed.branch_link) {
            this.props.updateMarketingUser({
              prop: "branch_link",
              value: parsed.branch_link
            });
          }
        }
      }

      if (parsed.is_partner) {
        if (parsed.is_partner == "true") {
          if (parsed.partner) {
            this.props.updateMarketingPartner({
              prop: "partner_id",
              value: parsed.partner
            });
            window.localStorage.setItem("partner_id", parsed.partner);
          }

          if (parsed.partner_logo) {
            this.props.updateMarketingPartner({
              prop: "partner_logo",
              value: parsed.partner_logo
            });
            window.localStorage.setItem("partner_logo", parsed.partner_logo);
          }

          if (parsed.partner_logo_white) {
            this.props.updateMarketingPartner({
              prop: "partner_logo_white",
              value: parsed.partner_logo_white
            });
            window.localStorage.setItem(
              "partner_logo_white",
              parsed.partner_logo_white
            );
          }
        }
      }
    }
  }

  getBranchParams() {
    if (window.branch) {
      window.branch.init(
        "key_live_mouUBnE38UD8MqbrKUpdNiomBvkD4HWb",
        (err, data) => {
          if (data) {
            const params = data.data_parsed;
            if (params) {
              if (params["~id"]) {
                if (params["~id"] != "") {
                  this.props.updateMarketingUser({
                    prop: "branch_id",
                    value: params["~id"]
                  });
                  window.localStorage.setItem("branch_id", params["~id"]);
                }
              }

              if (params["$promo"]) {
                if (params["$promo"] != "") {
                  this.props.updateMarketingUser({
                    prop: "promoText",
                    value: params["$promo"]
                  });
                  window.localStorage.setItem("promo", params["$promo"]);
                  window.localStorage.setItem("promoText", params["$promo"]);
                }
              }

              if (params["$type"]) {
                if (params["$type"] == "team_invite") {
                  this.props.updateMarketingUser({
                    prop: "signup_type",
                    value: params["$type"]
                  });

                  if (params["$email"]) {
                    this.props.updateMarketingUser({
                      prop: "email",
                      value: params["$email"]
                    });
                    window.localStorage.setItem("email", params["$email"]);
                  }
                  if (params["$team_name"]) {
                    this.props.updateMarketingUser({
                      prop: "team_name",
                      value: params["$team_name"]
                    });
                  }
                  if (params["$team_company"]) {
                    this.props.updateMarketingUser({
                      prop: "team_company",
                      value: params["$team_company"]
                    });
                  }
                }
              }

              if (params["$is_partner"]) {
                if (params["$is_partner"] == "true") {
                  if (params["$partner"]) {
                    this.props.updateMarketingPartner({
                      prop: "partner_id",
                      value: params["$partner"]
                    });
                    window.localStorage.setItem("partner", params["$partner"]);
                  }
                  if (params["$partner_logo"]) {
                    this.props.updateMarketingPartner({
                      prop: "partner_logo",
                      value: params["$partner_logo"]
                    });
                    window.localStorage.setItem(
                      "partner_logo",
                      params["$partner_logo"]
                    );
                  }
                  if (params["$partner_logo_white"]) {
                    this.props.updateMarketingPartner({
                      prop: "partner_logo_white",
                      value: params["$partner_logo_white"]
                    });
                    window.localStorage.setItem(
                      "partner_logo_white",
                      params["$partner_logo_white"]
                    );
                  }
                }
              }
            }
          }
        }
      );
    }
  }

  updateFromLocalStorage() {
    const special = window.localStorage.getItem("special");
    if (special != null) {
      this.props.updateMarketingUser({ prop: "special", value: special });
    }

    const saved_promo = window.localStorage.getItem("promoText");
    if (saved_promo != null) {
      this.props.updateMarketingUser({ prop: "promoText", value: saved_promo });
    }

    const saved_partner = window.localStorage.getItem("partner");
    if (saved_partner != null) {
      this.props.updateMarketingPartner({
        prop: "partner_id",
        value: saved_partner
      });
    }
    const saved_partner_logo = window.localStorage.getItem("partner_logo");
    if (saved_partner_logo != null) {
      this.props.updateMarketingPartner({
        prop: "partner_logo",
        value: saved_partner_logo
      });
    }
    const saved_partner_logo_white =
      window.localStorage.getItem("partner_logo_white");
    if (saved_partner_logo_white != null) {
      this.props.updateMarketingPartner({
        prop: "partner_logo_white",
        value: saved_partner_logo_white
      });
    }
  }

  componentDidMount() {
    if (this.props.location) {
      this.getQueryParams(this.props.location);
    }

    // init Branch
    this.getBranchParams();
    this.updateFromLocalStorage();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.partner_info.partner_id != prevProps.user_info.partner_id &&
      this.props.user_info.partner_id != null
    ) {
      window.localStorage.setItem(
        "partner",
        this.props.partner_info.partner_id
      );
      window.localStorage.setItem(
        "partner_logo",
        this.props.partner_info.partner_logo
      );
      window.localStorage.setItem(
        "partner_logo_white",
        this.props.partner_info.partner_logo_white
      );
    }
    if (prevProps.match.path !== this.props.match.path && this.props.location) {
      this.getQueryParams(this.props.location);
    }

    if (
      (this.props.user_info.promoText != prevProps.user_info.promoText &&
        this.props.user_info.promoText != null) ||
      this.props.user?.promo_code != prevProps.user?.promo_code
    ) {
      this.props.getPartnerFromPromo(
        !!this.props.user?.promo_code
          ? this.props.user.promo_code
          : this.props.user_info.promoText
      );
    }

    if (this.props.user?.id !== prevProps?.user?.id && this.props.user?.id) {
      const parsed = queryString.parse(this.props.location?.search);

      if (parsed.special == "blackfriday2024") {
        this.props.pushSidePanel({
          slug: "blackfridayspecial",
          overlay_modal: true,
          locked: true
        });
      }
    }
  }

  render() {
    return <Wrapper />;
  }
}

export default withRouter(connect(null, { pushSidePanel })(PartnerWrapper));

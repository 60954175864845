import React, { Component } from "react";
import { connect } from "react-redux";

import { SelectItem } from "app/NativeComponents/snippets";

import {
  checkIfUserHasMetadata,
  toTitleCase,
  encodeStringToInt,
  pushSidePanel
} from "app/NativeActions";

class LinkedCompany extends Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }
  render() {
    const { property, search, colors, company } = this.props;

    return (
      <SelectItem
        select_type={"none"}
        select_icon_style={{ marginRight: 10 }}
        onPress={() => {
          if (checkIfUserHasMetadata("free_plan")) {
            this.props.pushSidePanel({
              slug: "purchase_plan_prompt",
              overlay_modal: true,
              data: {
                title: "Get Unlimited Contact Info With DealMachine Pro.",
                dismiss_button:
                  "No thanks. Let me continue without contact info.",
                no_free: true
              },
              locked: true
            });
          } else {
            this.props.pushSidePanel({
              slug: "company",
              expanded_side_panel: this.props.expanded,
              overlay: true,
              id: encodeStringToInt(
                company?.company_number + "+" + company?.jurisdiction_code
              ),
              data: {
                company: company,
                property: this.props.property,
                expanded: this.props.expanded,
                propertyUpdated: this.props.propertyUpdated
              }
            });
          }
        }}
        style={{
          marginRight: 0,
          marginLeft: 0,

          justifyContent: "center"
        }}
        innerStyle={{
          padding: 0
        }}
        selected={true}
      >
        {toTitleCase(company.company_name)}
      </SelectItem>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, { pushSidePanel })(LinkedCompany);

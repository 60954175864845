import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Bold,
  Copy,
  Card,
  InternalImage,
  Spin,
  ProgressBar,
  Icon,
  Title,
  AlmaOrb,
  Scroll
} from "app/NativeComponents/common";
import {
  InlineButton,
  IconButton,
  InputBox,
  ConfirmInlineButton,
  UpsellButton,
  SelectItem,
  SearchBar,
  PopoverMenu,
  AttentionBox,
  CloseButton,
  SpinWrapper
} from "app/NativeComponents/snippets";

import AddLocation from "../AddLocation";

import {
  numberWithCommas,
  startBuildingListv2,
  setActiveList,
  tabNavigation,
  addListToListTabs,
  getSourceOfTruth,
  pushSidePanel,
  renderDate,
  showErrorMessage,
  popAllSidePanels,
  renderPrice,
  formatAddress,
  getChatPrompts,
  updateUserSettings,
  toTitleCase,
  aiListBuildStart,
  getCurrentAIBuildingList
} from "app/NativeActions";

import AttachExampleProperty from "./AttachExampleProperty";
import AISettings from "./AISettings";
import MorePrompts from "./MorePrompts";

import BuildingList from "./BuildingList";

class ListBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: null,
      editing_title: "",
      prompt: "",
      variance: props?.user?.user_settings?.ai_list_builder_settings
        ? props?.user?.user_settings?.ai_list_builder_settings?.variance
        : 75,
      show_ai_search:
        props.user?.team_owner != 1 && props.user?.team_clearance_level == 0
          ? false
          : props?.user?.user_settings?.ai_list_builder_settings
          ? props?.user?.user_settings?.ai_list_builder_settings?.show_ai_search
          : false,
      attached_properties: [],
      show_attach_example_properties: false,
      property_search: "",
      has_user_typed: false,
      sample_prompts: []
    };

    this.buildList = this.buildList.bind(this);
    this.getCoordinatesFromBounds = this.getCoordinatesFromBounds.bind(this);
    this.executeBuild = this.executeBuild.bind(this);

    this.attachExampleProperties = this.attachExampleProperties.bind(this);
    this.setPropertyPrompt = this.setPropertyPrompt.bind(this);

    this.setAISettings = this.setAISettings.bind(this);
    this.saveAISettings = this.saveAISettings.bind(this);
    this.setPrompt = this.setPrompt.bind(this);

    this.getCurrentBuildingList = this.getCurrentBuildingList.bind(this);
    this.getPriceForList = this.getPriceForList.bind(this);
  }

  componentDidMount() {
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });

    this.props.getChatPrompts({
      token: this.props.token,
      chat_type: "map",
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          sample_prompts: [...this.state.sample_prompts, ...results.prompts]
        });
      }
    });

    this.getCurrentBuildingList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.active_list) !==
        JSON.stringify(prevProps.active_list) &&
      this.props.active_list?.id == this.state.list?.id
    ) {
      this.setState({ list: this.props.active_list });
    }

    if (this.state.list?.building !== prevState.list?.building) {
      this.props.refreshMap();
      this.props.getSourceOfTruth({
        token: this.props.token,
        no_loading: true
      });
    }

    if (
      this.state.variance !== prevState.variance ||
      this.state.show_ai_search !== prevState.show_ai_search
    ) {
      this.saveAISettings();
    }

    if (
      this.state.show_ai_search &&
      this.state.show_ai_search !== prevState.show_ai_search
    ) {
      this.getCurrentBuildingList();
    }
  }

  getCurrentBuildingList() {
    this.setState(
      {
        loading_current_building_list: true
      },
      () => {
        this.props.getCurrentAIBuildingList({
          token: this.props.token,
          onLoading: () => {},
          onError: () => {
            this.setState({
              loading_current_building_list: false
            });
          },
          onSuccess: results => {
            this.setState({
              loading_current_building_list: false
            });
          }
        });
      }
    );
  }

  setAISettings(prop, value) {
    this.setState({ [prop]: value });
  }

  componentWillUnmount() {
    clearInterval(this._save_interval);
  }

  saveAISettings() {
    clearInterval(this._save_interval);
    this._search_interval = setTimeout(() => {
      this.props.updateUserSettings({
        token: this.props.token,
        type: "ai_list_builder_settings",
        value: {
          variance: this.state.variance,
          show_ai_search: this.state.show_ai_search
        }
      });
    }, 750);
  }

  getPriceForList() {
    let ai_list_builder_price = 2;
    if (
      this.props.source_of_truth?.subscription?.metadata?.ai_list_builder_price
    ) {
      ai_list_builder_price =
        this.props.source_of_truth?.subscription?.metadata
          ?.ai_list_builder_price;
    }
    return this.props.properties_on_map_count * ai_list_builder_price;
  }

  setPrompt(prompt, attached_properties = []) {
    this.setState({ prompt, attached_properties });
  }

  setPropertyPrompt(attached_properties) {
    if (!this.state.has_user_typed) {
      let prompt = "";
      if (attached_properties?.length > 0) {
        for (let i = 0; i < this.state.attached_properties.length; i++) {
          const p = this.state.attached_properties[i];
          const formatted_address = formatAddress({
            address: {
              address: p.property_address,
              address2: p.property_address2,
              city: p.property_address_city,
              state: p.property_address_state,
              zip: p.property_address_zip,
              latitude: p.latitude,
              longitude: p.longitude
            }
          });
          if (prompt.length === 0) {
            prompt = "Find properties similar to " + formatted_address?.line1;
          } else if (i == this.state.attached_properties.length - 1) {
            prompt += " and " + formatted_address?.line1;
          } else {
            prompt += ", " + formatted_address?.line1;
          }
        }
        prompt += ".";
      }

      this.setState({ prompt });
    }
  }

  attachExampleProperties(property) {
    //if there is already 3 do not add
    if (this.state.attached_properties.length >= 3) {
      return;
    }

    this.setState(
      {
        attached_properties: [...this.state.attached_properties, property]
      },
      () => {
        this.setPropertyPrompt(this.state.attached_properties);
      }
    );
  }
  removeExampleProperty(property_id) {
    this.setState(
      {
        attached_properties: this.state.attached_properties.filter(
          p => p.id != property_id
        )
      },
      () => {
        this.setPropertyPrompt(this.state.attached_properties);
      }
    );
  }

  getCoordinatesFromBounds() {
    const { bounds } = this.props;

    //get drawing coordinates from the area bounds and return as an array similar to the drawing coordinates
    if (
      bounds &&
      !!bounds?.westLng &&
      !!bounds?.eastLng &&
      !!bounds?.northLat &&
      !!bounds?.southLat
    ) {
      const coordinates = [
        {
          longitude: bounds?.westLng,
          latitude: bounds?.northLat
        },
        {
          longitude: bounds?.eastLng,
          latitude: bounds?.northLat
        },
        {
          longitude: bounds?.eastLng,
          latitude: bounds?.southLat
        },
        {
          longitude: bounds?.westLng,
          latitude: bounds?.southLat
        },
        {
          longitude: bounds?.westLng,
          latitude: bounds?.northLat
        }
      ];
      return coordinates;
    }

    return [];
  }

  buildList() {
    const { source_of_truth, all_feature_metadata } = this.props;

    const total_number_of_leads_current_limit = parseInt(
      source_of_truth?.subscription?.metadata?.total_number_of_leads
    );
    let total_number_of_leads_count = parseInt(
      source_of_truth?.current_limits?.total_number_of_leads?.amount
    );
    if (
      total_number_of_leads_current_limit !== "unlimited" &&
      total_number_of_leads_count >= total_number_of_leads_current_limit
    ) {
      const monthly_limit_end_date =
        source_of_truth?.subscription?.monthly_limit_end_date;

      let feature_upsell_info = null;
      for (let i = 0; i < all_feature_metadata?.length; i++) {
        if (
          all_feature_metadata[i].slug === "total_number_of_leads" &&
          all_feature_metadata[i].unlock_limit <=
            total_number_of_leads_count + this.props.properties_on_map_count &&
          (all_feature_metadata[i].unlock_limit >
            feature_upsell_info?.unlock_limit ||
            feature_upsell_info == null)
        ) {
          feature_upsell_info = all_feature_metadata[i];
        }
      }

      this.props.pushSidePanel({
        slug: "unlock_feature_modal",
        overlay: true,
        data: {
          feature_upsell_info,
          renderContent: () => {
            return (
              <Wrapper style={{ padding: 25 }}>
                <Copy>
                  You have{" "}
                  <Bold>
                    {numberWithCommas(total_number_of_leads_count)} leads
                  </Bold>{" "}
                  leads managed on your account. Adding these{" "}
                  <Bold>
                    {numberWithCommas(this.props.properties_on_map_count)}
                  </Bold>{" "}
                  properties will exceed your limit of{" "}
                  <Bold>
                    {numberWithCommas(total_number_of_leads_current_limit)}
                  </Bold>
                  . Upgrade to continue.
                </Copy>
              </Wrapper>
            );
          },
          buttonText: "Upgrade And Build",
          confirmText: "Confirm Upgrade?",
          onSuccess: () => {
            this.executeBuild();
          }
        }
      });
    } else {
      this.executeBuild();
    }
  }

  executeBuild() {
    let sl = JSON.parse(JSON.stringify(this.props.search_locations));
    sl = sl.map(item => {
      item.coordinates = null;
      return item;
    });

    this.props.startBuildingListv2({
      token: this.props.token,
      title: "My List",
      type:
        this.state.show_ai_search &&
        (!!this.state.prompt || this.state.attached_properties.length > 0)
          ? "ai_list_builder"
          : "build_list_v2",
      list_filters: this.props.applied_highlight,
      location_type:
        this.props.drawing_coordinates &&
        this.props.drawing_coordinates?.length > 2
          ? "draw"
          : this.props.zoom < 13
          ? "zip"
          : "view_area",
      zip: this.props.zip,
      city: this.props.city,
      fips: this.props.fips,
      state: this.props.state,
      drawing_coordinates:
        this.props.drawing_coordinates &&
        this.props.drawing_coordinates?.length > 2
          ? this.props.drawing_coordinates
          : this.getCoordinatesFromBounds(),
      estimated_count: this.props.properties_on_map_count,
      property_flags: this.props.property_flags
        .map(({ value }) => value)
        .join(","),
      property_types: this.props.property_types
        .map(({ value }) => value)
        .join(","),
      property_flags_and_or: this.props.property_flags_and_or,
      value_range_min: this.props.value_range_min,
      value_range_max: this.props.value_range_max,
      price_type: this.props.price_type,
      beds_min: this.props.beds_min,
      baths_min: this.props.baths_min,
      use_beds_exact: this.props.use_beds_exact,
      search_locations: sl,
      prompt: this.state.show_ai_search ? this.state.prompt : null,
      variance: this.state.show_ai_search ? this.state.variance : null,
      attached_property_ids: this.state.show_ai_search
        ? this.state.attached_properties.map(p => p.id).join(",")
        : null,
      use_vision: this.state.show_ai_search ? true : false,
      onLoading: () => {
        this.props.updateMainState({
          start_building: true
        });
      },
      onError: error => {
        this.props.updateMainState({
          start_building: false
        });
      },
      onSuccess: results => {
        if (results?.list?.is_ai_list) {
          this.props.aiListBuildStart({
            list: results.list,
            chunk_size: results?.chunk_size
          });
          if (results.list?.building == 0) {
            this.props.refreshMap();
          }
          this.props.updateMainState({
            start_building: false
          });
        } else {
          this.setState(
            {
              list: results?.list,
              editing_title: results.list?.title
            },
            () => {
              this.props.setActiveList(results.list);
              if (results.list?.building == 0) {
                this.props.refreshMap();
              }

              this.props.updateMainState({
                start_building: false
              });
            }
          );
          this.props.popAllSidePanels();
        }
      }
    });
  }

  render() {
    const { isMobile, colors, source_of_truth } = this.props;
    const { list } = this.state;

    let progress = Math.ceil(
      (parseInt(this.props.active_list_progress) /
        parseInt(this.state.list?.estimated_count)) *
        100
    );
    if (progress > 100) {
      progress = 100;
    }

    return (
      <>
        <Wrapper
          style={{
            width: "100%",
            height: "100%",
            position: "relative"
          }}
        >
          <>
            {this.state.loading_current_building_list &&
            !this.props.ai_list_building ? (
              <Wrapper
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative"
                }}
              >
                <Card
                  style={{
                    margin: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    height: 85
                  }}
                >
                  <SpinWrapper text="Loading" />
                  <Wrapper style={{ height: 15 }} />
                </Card>
              </Wrapper>
            ) : this.props.ai_list_building && this.state.show_ai_search ? (
              <BuildingList refreshMap={this.props.refreshMap} />
            ) : list && list?.id ? (
              <Card style={{ margin: 0 }}>
                <Wrapper
                  style={{
                    backgroundColor: colors.card_color,
                    width: "100%",
                    height: 85,
                    justifyContent: "center"
                  }}
                >
                  <Wrapper
                    className={"list-builder-progress-bar"}
                    style={{
                      backgroundColor: colors.success_color_muted,
                      width:
                        this.state.list?.building == 1
                          ? progress + "%"
                          : "100%",
                      height: 85
                    }}
                  />
                </Wrapper>

                <Wrapper
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    justifyContent: "center"
                  }}
                >
                  <Row>
                    {this.state.list?.building == 1 ? (
                      <Wrapper style={{ paddingLeft: 25 }}>
                        <Spin size={"small"} />
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ paddingLeft: 25 }}>
                        {this.state.list?.lead_count > 0 ? (
                          <Icon
                            icon={"check-circle"}
                            color={colors.success_color}
                          />
                        ) : (
                          <Icon icon={"warning"} color={colors.orange_color} />
                        )}
                      </Wrapper>
                    )}
                    <Wrapper
                      style={{ paddingRight: 25, paddingLeft: 25, flex: 1 }}
                    >
                      <Copy style={{ color: colors.actionable_text_color }}>
                        <Bold>{this.state.list?.title}</Bold>
                      </Copy>
                      <Copy style={{ fontSize: 12 }}>
                        {this.state.list?.is_ai_list ? (
                          <>
                            {this.state.list?.building == 1 ? (
                              <>
                                {"Analyzing " +
                                  numberWithCommas(
                                    this.props.active_list_progress
                                  ) +
                                  " of "}
                                <Bold>
                                  {numberWithCommas(
                                    this.state.list?.estimated_count
                                  )}
                                </Bold>
                                {this.state.list?.estimated_count == 1
                                  ? " property. "
                                  : " properties. "}
                                {this.props.active_list_passed_counter > 0 ? (
                                  <>
                                    {"Found "}
                                    <Bold>
                                      {numberWithCommas(
                                        this.props.active_list_passed_counter
                                      )}
                                    </Bold>
                                    {this.props.active_list_passed_counter == 1
                                      ? " property that matched your prompt and filters."
                                      : " properties that matched your prompt and filters."}
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {"Build Complete. Found "}
                                <Bold>
                                  {numberWithCommas(
                                    this.state.list?.lead_count
                                  )}
                                </Bold>
                                {this.state.list?.lead_count == 1
                                  ? " property matching your prompt and filters."
                                  : " properties matching your prompt and filters."}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.list?.building == 1 ? (
                              <>
                                Building{" "}
                                {numberWithCommas(
                                  this.props.active_list_progress
                                )}{" "}
                                of{" "}
                                {numberWithCommas(
                                  this.state.list?.estimated_count
                                )}{" "}
                                {this.state.list?.estimated_count == 1
                                  ? "property"
                                  : " properties"}
                              </>
                            ) : (
                              <>
                                Build complete for{" "}
                                {numberWithCommas(this.state.list?.lead_count)}{" "}
                                {this.state.list?.lead_count == 1
                                  ? "property"
                                  : " properties"}
                              </>
                            )}
                          </>
                        )}
                      </Copy>
                      {this.state.list?.is_ai_list &&
                      this.state.list?.building == 1 ? (
                        <Copy style={{ fontSize: 12 }}>
                          This can take a while. We'll send you an email when
                          it's ready.
                        </Copy>
                      ) : null}
                    </Wrapper>
                    {this.state.list?.lead_count > 0 ||
                    this.state.list?.building == 1 ? (
                      <InlineButton
                        onPress={() => {
                          this.props.addListToListTabs(this.state.list);
                          this.props.tabNavigation({ slug: "leads" });
                        }}
                        disabled={
                          (this.state.list?.building == 1 &&
                            this.state.list?.is_ai_list &&
                            this.props.active_list_passed_counter === 0) ||
                          (!this.state.list?.is_ai_list &&
                            this.props.active_list_progress === 0 &&
                            this.state.list?.building == 1)
                        }
                      >
                        {this.state.list?.building == 1
                          ? "View List So Far"
                          : "View List"}
                      </InlineButton>
                    ) : null}

                    <IconButton
                      onPress={() => {
                        this.setState({
                          list: null,
                          editing_title: ""
                        });
                      }}
                      tooltipPlacement={"top"}
                      tooltip={"Dismiss"}
                      icon={"close"}
                    />
                  </Row>
                </Wrapper>
              </Card>
            ) : (
              <Card>
                <Wrapper>
                  <Row>
                    <Wrapper
                      style={{
                        padding: 25,
                        paddingTop: 0,
                        paddingBottom: 0,
                        flex: 1,
                        height: 85,
                        justifyContent: "center"
                      }}
                    >
                      <Row>
                        {this.props.properties_on_map_count_loading ? (
                          <Wrapper style={{ marginRight: 5 }}>
                            <Spin size={"small"} />
                          </Wrapper>
                        ) : (
                          <Copy style={{ marginRight: 5 }}>
                            <Bold>
                              {numberWithCommas(
                                this.props.properties_on_map_count
                              )}
                            </Bold>
                          </Copy>
                        )}
                        <Copy>
                          {this.props.list_view_type === "contacts"
                            ? this.props.properties_on_map_count == 1
                              ? " contact in "
                              : " contacts in "
                            : this.props.properties_on_map_count == 1
                            ? " property in "
                            : " properties in "}
                          {this.props.search_locations?.length == 0 ? (
                            <>
                              <Bold
                                style={{
                                  color: this.props.colors.actionable_text_color
                                }}
                              >
                                {this.props.drawing_coordinates &&
                                this.props.drawing_coordinates?.length > 2
                                  ? "drawn area"
                                  : this.props.zoom < 13
                                  ? !!this.props.zip
                                    ? this.props.zip
                                    : "zip code"
                                  : "viewing area"}
                              </Bold>
                              {this.props.checkIfThereAreFilters()
                                ? " matching filters."
                                : "."}
                            </>
                          ) : null}
                        </Copy>
                        {this.props.search_locations?.length > 0 ? (
                          <>
                            {this.props.search_locations.map((location, i) => {
                              return (
                                <InlineButton
                                  key={"location_" + i}
                                  style={{
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    borderColor: colors.active_color,
                                    borderStyle: "solid",
                                    margin: 0,
                                    marginLeft: 5,
                                    paddingRight: 5,
                                    paddingTop: 0,
                                    paddingBottom: 0
                                  }}
                                  selected={true}
                                  onPress={() => {
                                    this.props.updateLatLng({
                                      longitude: location?.longitude,
                                      latitude: location?.latitude,
                                      heading: 0,
                                      pitch: 0,
                                      zoom: 11,
                                      coordinates: location?.coordinates
                                    });
                                  }}
                                >
                                  <Row>
                                    <Copy>{location.label}</Copy>
                                    <IconButton
                                      icon={"close"}
                                      button_type={"small"}
                                      style={{
                                        margin: 0
                                      }}
                                      innerStyle={{}}
                                      onPress={() => {
                                        //remove location from this.state.search_locations by i
                                        this.props.updateMapFilters({
                                          search_locations:
                                            this.props.search_locations.filter(
                                              (l, index) => {
                                                return index != i;
                                              }
                                            )
                                        });
                                      }}
                                    />
                                  </Row>
                                </InlineButton>
                              );
                            })}
                          </>
                        ) : null}
                      </Row>
                      {this.props.main_map_location?.zoom < 12 &&
                      this.props.search_locations?.length === 0 &&
                      this.props.user?.has_routes ? (
                        <Row>
                          <Copy style={{ fontSize: 12 }}>
                            Zoom in to see driven routes.
                          </Copy>
                        </Row>
                      ) : null}
                    </Wrapper>

                    {this.props.user.team_clearance_level > 0 ? (
                      this.props.source_of_truth?.subscription?.metadata
                        .list_builder ? (
                        <ConfirmInlineButton
                          require_confirm={true}
                          button_type="full"
                          icon={"playlist-add"}
                          confirm_text={
                            this.state.show_ai_search &&
                            (!!this.state.prompt ||
                              this.state.attached_properties.length > 0)
                              ? "Confirm Build With AI Vision for " +
                                renderPrice(this.getPriceForList()) +
                                "?"
                              : "Confirm Build"
                          }
                          confirm_icon="check"
                          loading_text={
                            (!!this.state.prompt ||
                              this.state.attached_properties?.length > 0) &&
                            this.state.show_ai_search
                              ? "Starting AI"
                              : "Building"
                          }
                          loading={this.props.start_building}
                          disabled={
                            this.props.properties_on_map_count_loading ||
                            (this.props.drawing &&
                              this.props.drawing_coordinates.length <= 2) ||
                            this.props.select_drive_start ||
                            this.props.properties_on_map_count == 0 ||
                            ((this.props.variance == 0 || !this.state.prompt) &&
                              this.state.show_ai_search)
                          }
                          tooltipPlacement={"top"}
                          tooltip={
                            (this.props.variance == 0 || !this.state.prompt) &&
                            this.state.show_ai_search
                              ? "Enter an AI prompt to build a list of properties matching your prompt and filters."
                              : ""
                          }
                          onPress={this.buildList}
                          primary={true}
                        >
                          {this.state.show_ai_search
                            ? "Build With AI Vision"
                            : "Build List"}
                        </ConfirmInlineButton>
                      ) : null
                    ) : (
                      <Wrapper style={{ minHeight: 85 }} />
                    )}
                  </Row>
                </Wrapper>
                {this.state.show_ai_search ? (
                  <>
                    <Wrapper>
                      <Row>
                        <Wrapper style={{ flex: 1 }}>
                          <InputBox
                            style={{ paddingLeft: 25 }}
                            selected_background_color="transparent"
                            hover_background_color="transparent"
                            renderLeft={() => {
                              return <AlmaOrb />;
                            }}
                            placeholder={
                              !this.state.prompt
                                ? "What kind of properties are you looking for?"
                                : ""
                            }
                            value={this.state.prompt}
                            onChange={value => {
                              this.setState({
                                prompt: value,
                                has_user_typed: !!value ? true : false
                              });
                            }}
                            numberOfLines={1}
                            autogrow={true}
                            maxHeight={300}
                            type={"multiline"}
                          />
                        </Wrapper>
                        {/*!!this.state.prompt &&
                        this.state.attached_properties?.length === 0 ? (
                          <IconButton
                            onPress={() => {
                              this.setState({
                                prompt: "",
                                attached_properties: []
                              });
                            }}
                            tooltipPlacement={"top"}
                            tooltip={"Clear"}
                            icon={"close"}
                            icon_color={colors.light_text_color}
                          />
                        ) : null*/}
                        {this.state.attached_properties.length > 0 ? (
                          <Row style={{ flexWrap: "wrap", maxWidth: 200 }}>
                            {this.state.attached_properties.map(
                              (property, i) => {
                                const formatted_address = formatAddress({
                                  address: {
                                    address: property?.property_address,
                                    address2: property?.property_address2,
                                    address_city:
                                      property?.property_address_city,
                                    address_state:
                                      property?.property_address_state,
                                    address_zip: property?.property_address_zip,
                                    latitude: property?.location?.latitude,
                                    longitude: property?.location?.longitude
                                  }
                                });
                                return (
                                  <InlineButton
                                    key={"property_" + i}
                                    style={{
                                      borderRadius: 5,
                                      borderWidth: 1,
                                      borderColor: colors.active_color,
                                      borderStyle: "solid",
                                      margin: 0,
                                      marginLeft: 5,
                                      paddingRight: 5,
                                      paddingTop: 0,
                                      paddingBottom: 0
                                    }}
                                    selected={true}
                                    noPress={true}
                                  >
                                    <Row>
                                      <Copy> {formatted_address.line1}</Copy>
                                      <IconButton
                                        tooltip={"Remove Property"}
                                        tooltipPlacement={"top"}
                                        icon={"close"}
                                        button_type={"small"}
                                        style={{
                                          margin: 0
                                        }}
                                        innerStyle={{}}
                                        onPress={() => {
                                          //remove location from this.state.search_locations by i
                                          this.removeExampleProperty(
                                            property.id
                                          );
                                        }}
                                      />
                                    </Row>
                                  </InlineButton>
                                );
                              }
                            )}
                          </Row>
                        ) : null}

                        {!!this.state.prompt ? (
                          <InlineButton
                            onPress={() => {
                              this.setState({
                                prompt: "",
                                attached_properties: [],
                                has_user_typed: false
                              });
                            }}
                            style={{ marginRight: 0 }}
                          >
                            Clear
                          </InlineButton>
                        ) : null}

                        <AttachExampleProperty
                          attachExampleProperties={this.attachExampleProperties}
                          removeExampleProperty={this.removeExampleProperty}
                          attached_properties={this.state.attached_properties}
                          disabled={this.state.attached_properties.length >= 3}
                        />
                        <AISettings
                          variance={this.state.variance}
                          setAISettings={this.setAISettings}
                          include_contact_data={this.state.include_contact_data}
                          include_street_view={this.state.include_street_view}
                          include_satellite_view={
                            this.state.include_satellite_view
                          }
                        />
                        <PopoverMenu
                          show={this.state.show_helper}
                          no_swipe={true}
                          no_cancel={false}
                          popover_dismiss={"Dismiss"}
                          onShow={s => {
                            this.setState({
                              show_helper: s
                            });
                          }}
                          popover_width={450}
                          popoverPlacement={"bottom"}
                          tooltip={"What is AI Vision?"}
                          tooltipPlacement={"top"}
                          renderComponent={options => {
                            return (
                              <IconButton
                                pressedIn={
                                  options.hovering || options.pressedIn
                                }
                                noPress={true}
                                icon="help"
                                icon_color={colors.light_text_color}
                                style={{ marginLeft: 0 }}
                              />
                            );
                          }}
                          menu_items={null}
                          renderMenu={() => {
                            return (
                              <Wrapper>
                                <Wrapper
                                  style={{
                                    padding: 25,
                                    alignSelf: "stretch",
                                    position: "relative"
                                  }}
                                >
                                  <Row style={{ marginBottom: 10 }}>
                                    <Title>
                                      AI Vision does the driving for you.
                                    </Title>
                                  </Row>

                                  <Copy style={{ marginBottom: 10 }}>
                                    Analyze all the properties in this area that
                                    match your selected filters with a text
                                    prompt.
                                  </Copy>
                                  <Copy style={{ marginBottom: 10 }}>
                                    Our AI takes the street view and satellite
                                    image of the property (along with out world
                                    class data) and uses it to give every
                                    property in the area a score between 0-100.
                                  </Copy>
                                  <Copy style={{ marginBottom: 10 }}>
                                    If the score is above your{" "}
                                    <Bold>required matching score</Bold> (set in
                                    settings), the property will be added to
                                    your list.
                                  </Copy>
                                </Wrapper>

                                <CloseButton
                                  onPress={() => {
                                    this.setState({
                                      show_helper: false
                                    });
                                  }}
                                />
                              </Wrapper>
                            );
                          }}
                        />
                      </Row>
                    </Wrapper>

                    {!this.state.prompt &&
                    this.state.sample_prompts?.length > 0 ? (
                      <Row>
                        <Wrapper style={{ flex: 1 }}>
                          <Row style={{ justifyContent: "center" }}>
                            {this.state.sample_prompts.map(p => {
                              return (
                                <InlineButton
                                  buttonContainerStyle={{
                                    alignSelf: "stretch"
                                  }}
                                  onPress={() => {
                                    this.setState({
                                      prompt: p.content,
                                      has_user_typed: true
                                    });
                                  }}
                                  style={
                                    this.props.desktopMobile ||
                                    this.props.device === "mobile"
                                      ? {
                                          borderWidth: 1,
                                          borderColor: colors.border_color,
                                          borderStyle: "solid",
                                          borderRadius: 15,
                                          marginRight: 0,
                                          borderBottomLeftRadius: 0,
                                          flex: 1,
                                          alignSelf: "stretch"
                                        }
                                      : {
                                          borderWidth: 1,
                                          borderColor: colors.border_color,
                                          borderStyle: "solid",
                                          borderRadius: 15,
                                          marginRight: 0,
                                          borderBottomLeftRadius: 0,
                                          maxWidth: 500
                                        }
                                  }
                                >
                                  {p.display_content}
                                </InlineButton>
                              );
                            })}

                            <MorePrompts setPrompt={this.setPrompt} />
                          </Row>
                        </Wrapper>
                      </Row>
                    ) : null}
                    {!!this.state.prompt ||
                    this.state.attached_properties.length > 0 ? (
                      <Wrapper style={{ marginTop: 10, marginBottom: 20 }}>
                        <Row style={{ justifyContent: "center" }}>
                          <Copy
                            style={{
                              fontSize: 12,
                              textAlign: "center"
                            }}
                          >
                            Building this list with AI will cost{" "}
                            <Bold>{renderPrice(this.getPriceForList())}</Bold>.
                          </Copy>

                          <PopoverMenu
                            show={this.state.show_price_helper}
                            no_swipe={true}
                            no_cancel={false}
                            popover_dismiss={"Dismiss"}
                            onShow={s => {
                              this.setState({
                                show_price_helper: s
                              });
                            }}
                            popover_width={450}
                            popoverPlacement={"bottom"}
                            renderComponent={options => {
                              return (
                                <InlineButton
                                  pressedIn={
                                    options.hovering || options.pressedIn
                                  }
                                  noPress={true}
                                  style={{
                                    padding: 5,
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                    borderRadius: 5
                                  }}
                                  textColor={colors.actionable_text_color}
                                  textStyle={{ fontSize: 12 }}
                                >
                                  Why are you charging me?
                                </InlineButton>
                              );
                            }}
                            menu_items={null}
                            renderMenu={() => {
                              return (
                                <Wrapper>
                                  <Wrapper
                                    style={{
                                      padding: 25,
                                      alignSelf: "stretch",
                                      position: "relative"
                                    }}
                                  >
                                    <Row style={{ marginBottom: 10 }}>
                                      <Title>Why are you charging me?</Title>
                                    </Row>

                                    <Copy style={{ marginBottom: 10 }}>
                                      Using AI to analyze each property is
                                      resource and time intensive.
                                    </Copy>
                                    <Copy style={{ marginBottom: 10 }}>
                                      We charge{" "}
                                      <Bold>
                                        {this.props.source_of_truth
                                          ?.subscription?.metadata
                                          ?.ai_list_builder_price || 2}
                                        {this.props.source_of_truth
                                          ?.subscription?.metadata
                                          ?.ai_list_builder_price == 1
                                          ? " cent"
                                          : " cents"}
                                      </Bold>{" "}
                                      per property to cover the cost of running
                                      the AI and getting all the necessary data
                                      to make a complete analysis.
                                    </Copy>
                                    <Copy style={{ marginBottom: 10 }}>
                                      Not every list needs to be built with AI.
                                      You can using our set of over 700 filters
                                      to find the properties you are looking
                                      for.
                                    </Copy>
                                  </Wrapper>

                                  <CloseButton
                                    onPress={() => {
                                      this.setState({
                                        show_price_helper: false
                                      });
                                    }}
                                  />
                                </Wrapper>
                              );
                            }}
                          />
                        </Row>
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ marginTop: 10, marginBottom: 20 }}>
                        <Row style={{ justifyContent: "center" }}>
                          <Copy
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                              marginRight: 5,
                              color: colors.light_text_color
                            }}
                          ></Copy>
                        </Row>
                      </Wrapper>
                    )}
                  </>
                ) : null}
              </Card>
            )}
          </>

          {!list &&
          (this.props.user?.team_owner == 1 ||
            this.props.user?.team_clearance_level > 0) ? (
            <Wrapper
              className={!this.state.show_ai_search ? "sparkle-effect" : ""}
              style={{
                position: "absolute",
                bottom: -7,
                height: 26,
                width: !this.state.show_ai_search ? 200 : 200,
                left: 0,
                right: 0,
                margin: "auto",
                backgroundColor: !this.state.show_ai_search
                  ? colors.active_color_muted
                  : colors.card_color,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: !this.state.show_ai_search
                  ? colors.active_color
                  : colors.border_color,
                borderRadius: 30,
                justifyContent: "center"
              }}
            >
              {this.state.show_ai_search ? (
                <InlineButton
                  icon_color={colors.text_color}
                  disabled={false}
                  onPress={() => {
                    this.setState({
                      show_ai_search: !this.state.show_ai_search
                    });
                  }}
                  style={{
                    height: 26,
                    width: 190,
                    borderRadius: 30,
                    margin: 0,
                    padding: 0,
                    paddingLeft: 10,
                    paddingRight: 0,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  hover_color={colors.hover_color}
                  noCopy={true}
                >
                  <Row>
                    <Copy
                      style={{
                        marginRight: 5,
                        fontSize: 12,
                        color: colors.text_color
                      }}
                    >
                      Back To Basic Builder
                    </Copy>
                    <Icon icon={"keyboard-arrow-down"} size={14} />
                  </Row>
                </InlineButton>
              ) : (
                <InlineButton
                  icon_color={colors.text_color}
                  disabled={false}
                  onPress={() => {
                    this.setState({
                      show_ai_search: !this.state.show_ai_search
                    });
                  }}
                  style={{
                    height: 26,
                    width: 200,
                    borderRadius: 30,
                    margin: 0,
                    padding: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  innerStyle={{
                    padding: 0,
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                  hover_color={colors.hover_color}
                  noCopy={true}
                >
                  <Row>
                    <AlmaOrb size={10} />
                    <Copy
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        fontSize: 12,
                        color: colors.actionable_text_color
                      }}
                    >
                      <Bold>Try AI Vision Builder</Bold>
                    </Copy>
                    <Icon icon={"keyboard-arrow-up"} size={14} />
                  </Row>
                </InlineButton>
              )}
            </Wrapper>
          ) : null}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  property_map,
  list,
  billing
}) => {
  const { token, user } = auth;
  const { main_map_location } = property_map;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { ai_list_building, active_list_progress, active_list } = list;
  const { source_of_truth, all_feature_metadata } = billing;
  return {
    token,
    user,
    colors,
    device,
    isMobile,

    source_of_truth,
    all_feature_metadata,
    main_map_location,
    ai_list_building,
    active_list,
    active_list_progress
  };
};

export default connect(mapStateToProps, {
  startBuildingListv2,
  setActiveList,
  addListToListTabs,
  tabNavigation,
  getSourceOfTruth,
  pushSidePanel,
  showErrorMessage,
  popAllSidePanels,
  getChatPrompts,
  updateUserSettings,
  aiListBuildStart,
  getCurrentAIBuildingList
})(ListBuilder);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Bold,
  Card,
  Copy,
  Row,
  Spin,
  Title,
  Wrapper,
  Icon
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  CircleButton,
  IconButton,
  PopoverMenu,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import {
  callPhoneNumber,
  displayIntercom,
  formatUsPhone,
  numberWithCommas,
  openEmail,
  toTitleCase,
  checkIfUserHasMetadata
} from "app/NativeActions";
import PropertyFlags from "./PropertyFlags";
import MLSPhotos from "./MLSPhotos";
import moment from "moment";

import LinkedContact from "../LinkedContact";
import LinkedCompany from "../LinkedCompany";

class EstimatedValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_value_description: false
    };

    this.formatBathsAndBeds = this.formatBathsAndBeds.bind(this);
    this.getLinkedItemFromTransaction =
      this.getLinkedItemFromTransaction.bind(this);
  }

  formatBathsAndBeds(number = null) {
    if (!!number) {
      let num = parseFloat(number);

      if (Number.isInteger(num)) {
        return num; // Return the number as is if it's already an integer
      } else {
        return num?.toFixed(1); // Round to one decimal place if there's a decimal part
      }
    }
    return 0;
  }

  getLinkedItemFromTransaction(transaction_id, matched_party) {
    const { property } = this.props;
    if (checkIfUserHasMetadata("company_data")) {
      if (property?.transaction_linked_companies) {
        const transaction_linked_companies =
          property?.transaction_linked_companies;

        for (let i = 0; i < transaction_linked_companies?.length; i++) {
          if (
            transaction_linked_companies[i]?.transaction_id == transaction_id &&
            transaction_linked_companies[i]?.matched_party == matched_party
          ) {
            return {
              type: "company",
              data: transaction_linked_companies[i]
            };
          }
        }
      }

      if (property?.transaction_linked_contacts) {
        const transaction_linked_contacts =
          property?.transaction_linked_contacts;

        let matching_contacts = [];

        for (let i = 0; i < transaction_linked_contacts?.length; i++) {
          if (
            transaction_linked_contacts[i]?.transaction_id == transaction_id &&
            transaction_linked_contacts[i]?.matched_party == matched_party
          ) {
            matching_contacts.push(transaction_linked_contacts[i]);
          }
        }

        if (matching_contacts.length > 0) {
          return {
            type: "contact",
            data: matching_contacts
          };
        }
      }
    }

    return null;
  }

  renderEstimatedValuePopover() {
    const { property, colors } = this.props;

    return (
      <PopoverMenu
        show={this.state.show_value_description}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show_value_description: s
          });
        }}
        popover_width={450}
        popoverPlacement={"bottom"}
        popover_title={"Estimated Value"}
        includeCloseButton={true}
        tooltip={"How did you get this?"}
        tooltipPosition={"top"}
        renderComponent={options => {
          return (
            <IconButton
              pressedIn={options.hovering || options.pressedIn}
              noPress={true}
              icon="help"
              icon_color={colors.light_text_color}
              button_type={"small"}
              style={{ marginRight: 0, marginLeft: -5 }}
            />
          );
        }}
        menu_items={null}
        renderMenu={() => {
          return (
            <>
              <Wrapper
                style={{
                  padding: 25,
                  alignSelf: "stretch"
                }}
              >
                <Row>
                  <Wrapper style={{ flex: 1 }}>
                    <Title
                      style={{
                        color: colors.actionable_text_color
                      }}
                    >
                      <Bold>
                        {"$" + numberWithCommas(property.EstimatedValue)}
                      </Bold>
                    </Title>
                    <Copy
                      style={{
                        color: colors.light_text_color,
                        fontSize: 12
                      }}
                    >
                      Estimated Value
                    </Copy>
                  </Wrapper>
                  <Wrapper style={{ marginRight: 10 }}>
                    <Row>
                      <Copy
                        style={{
                          color: colors.actionable_text_color,
                          textAlign: "right"
                        }}
                      >
                        <Bold>{property.vConfidenceScore + "%"}</Bold>
                      </Copy>
                      <Copy
                        style={{
                          color: colors.light_text_color,
                          fontSize: 12,
                          textAlign: "right",
                          marginLeft: 5
                        }}
                      >
                        Confidence Score
                      </Copy>
                    </Row>
                  </Wrapper>
                </Row>
                <Wrapper style={{ paddingTop: 25 }}>
                  <Copy>
                    {/*write a description explaining how we got the value*/}
                    DealMachine estimates property value using AI-powered
                    algorithms that analyze comprehensive property data, recent
                    sales of comparable properties, and local market trends,
                    providing a continuously updated and estimated market value
                    for each property.
                  </Copy>
                </Wrapper>
              </Wrapper>
              <Wrapper
                style={{
                  alignSelf: "stretch"
                }}
              >
                <BottomNavBar style={{ padding: 25 }}>
                  <Row
                    style={{
                      justifyContent: "space-between"
                    }}
                  >
                    <Wrapper>
                      <Copy
                        style={{
                          color: colors.actionable_text_color
                        }}
                      >
                        <Bold>
                          {"$" + numberWithCommas(property.vLowValue)}
                        </Bold>
                      </Copy>
                      <Copy
                        style={{
                          color: colors.light_text_color,
                          fontSize: 12
                        }}
                      >
                        Est. Low Value
                      </Copy>
                    </Wrapper>
                    <Icon
                      style={{
                        marginRight: 10,
                        marginLeft: 10
                      }}
                      icon={"arrow-forward"}
                    />

                    <Wrapper>
                      <Copy
                        style={{
                          color: colors.actionable_text_color
                        }}
                      >
                        <Bold>
                          {"$" + numberWithCommas(property.vHighValue)}
                        </Bold>
                      </Copy>
                      <Copy
                        style={{
                          color: colors.light_text_color,
                          fontSize: 12
                        }}
                      >
                        Est. High Value
                      </Copy>
                    </Wrapper>
                  </Row>
                </BottomNavBar>
              </Wrapper>
            </>
          );
        }}
      />
    );
  }
  render() {
    const { property, user, owner_info, colors } = this.props;

    let display_value = !!property?.EstimatedValue
      ? "$" + numberWithCommas(property?.EstimatedValue)
      : "--";
    let estimated_value = !!property?.EstimatedValue
      ? "$" + numberWithCommas(property?.EstimatedValue)
      : null;
    let display_value_label = "Est. Value";
    let show_estimated_value = false;

    let description = "";
    if (!!property?.market_status?.value) {
      if (
        property.market_status?.value.startsWith("Active") ||
        property.market_status?.value.startsWith("Contingent") ||
        property.market_status?.value.startsWith("Pending")
      ) {
        if (property.mls?.current_listing_price) {
          show_estimated_value = true;
          display_value_label = "Listed Price";
          display_value =
            "$" + numberWithCommas(property.mls?.current_listing_price);
        }
      } else if (property?.market_status?.value.startsWith("Sold")) {
        if (property.mls?.sold_price) {
          show_estimated_value = true;

          display_value_label = "Sale Price";

          display_value = "$" + numberWithCommas(property.mls?.sold_price);
        }
        if (property?.mls.sold_date || property.saledate) {
          if (
            !!property?.mls.sold_date &&
            (moment(property?.saledate) > moment(property?.mls?.sold_date) ||
              !property?.saledate)
          ) {
            description =
              "Sold on " +
              moment(property?.mls.sold_date).format("MMM Do, YYYY");
          } else {
            description =
              "Sold on " + moment(property?.saledate).format("MMM Do, YYYY");
          }
        }
      }
    }

    let auction_location = "";

    if (!!property?.foreclosure_data?.TrusteeFirstNameAndMiddleInitial) {
      auction_location = toTitleCase(
        property?.foreclosure_data?.TrusteeFirstNameAndMiddleInitial
      );
    }

    if (!!property?.foreclosure_data?.TrusteeLastNameORCorporationName) {
      if (!!auction_location) {
        auction_location +=
          " " +
          toTitleCase(
            property?.foreclosure_data?.TrusteeLastNameORCorporationName
          );
      } else {
        auction_location = toTitleCase(
          property?.foreclosure_data?.TrusteeLastNameORCorporationName
        );
      }
    }

    if (!!property?.foreclosure_data?.AuctionLocation) {
      if (!!auction_location) {
        auction_location +=
          " - " + toTitleCase(property?.foreclosure_data?.AuctionLocation);
      } else {
        auction_location = toTitleCase(
          property?.foreclosure_data?.AuctionLocation
        );
      }
    }

    if (!!property?.foreclosure_data?.AuctionCity) {
      if (!!auction_location) {
        auction_location +=
          ", " + toTitleCase(property?.foreclosure_data?.AuctionCity);
      } else {
        auction_location = toTitleCase(property?.foreclosure_data?.AuctionCity);
      }
    }

    let real_estate_agent = this.getLinkedItemFromTransaction(
      property?.mls?.listing_tracking_id,
      "transaction_real_estate_agent"
    );

    if (!real_estate_agent) {
      real_estate_agent = this.getLinkedItemFromTransaction(
        property?.mls?.listing_tracking_id,
        "transaction_real_estate_agent_company"
      );
    }

    if (this.props.property_loading && !property.mls) {
      return <SpinWrapper text={"Loading"} />;
    }

    return (
      <>
        <Wrapper style={{ padding: 15, paddingBottom: 10 }}>
          <Row style={{ marginBottom: 25 }}>
            <Wrapper style={{ flex: 1 }}>
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <>
                    <Row>
                      <Title style={{ color: colors.actionable_text_color }}>
                        <Bold>{display_value}</Bold>
                      </Title>

                      <Row>
                        <Copy
                          style={{
                            color: colors.light_text_color,
                            fontSize: 12,
                            marginLeft: 10
                          }}
                        >
                          {display_value_label}
                        </Copy>
                        {!show_estimated_value &&
                        !!property.vLowValue &&
                        !!property.vHighValue
                          ? this.renderEstimatedValuePopover()
                          : null}
                      </Row>
                    </Row>
                  </>
                </Wrapper>
                <Wrapper>
                  <Row>
                    {this.formatBathsAndBeds(property?.total_bedrooms) > 0 ? (
                      <Wrapper style={{ paddingLeft: 10 }}>
                        <Copy>
                          <Bold>
                            {this.formatBathsAndBeds(property?.total_bedrooms)}
                          </Bold>{" "}
                          bds
                        </Copy>
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ paddingLeft: 10 }}>
                        <Copy>-- bds</Copy>
                      </Wrapper>
                    )}

                    {this.formatBathsAndBeds(property?.total_baths) > 0 ? (
                      <Wrapper style={{ paddingLeft: 10 }}>
                        <Copy>
                          <Bold>
                            {this.formatBathsAndBeds(property?.total_baths)}
                          </Bold>{" "}
                          ba
                        </Copy>
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ paddingLeft: 10 }}>
                        <Copy>-- ba</Copy>
                      </Wrapper>
                    )}

                    {property?.building_square_feet > 0 ? (
                      <Wrapper style={{ paddingLeft: 10 }}>
                        <Copy>
                          <Bold>
                            {numberWithCommas(property.building_square_feet)}
                          </Bold>{" "}
                          sqft
                        </Copy>
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ paddingLeft: 10 }}>
                        <Copy>-- sqft</Copy>
                      </Wrapper>
                    )}
                  </Row>
                </Wrapper>
              </Row>
            </Wrapper>
          </Row>

          <PropertyFlags property={property} colors={colors} />
        </Wrapper>

        {property?.foreclosure_data &&
        property?.foreclosure_data?.AuctionDate &&
        moment(property?.foreclosure_data?.AuctionDate).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD") ? (
          <SelectItem
            noPress={true}
            select_type={"none"}
            selected={true}
            selected_background_color={colors.orange_color_muted}
            label={"Upcoming auction:"}
            description={auction_location}
            renderRight={() => {
              return (
                <Row>
                  {!!property?.foreclosure_data?.TrusteeTelephoneNumber ? (
                    <CircleButton
                      tooltip={
                        "Call Trustee: " +
                        formatUsPhone(
                          property?.foreclosure_data?.TrusteeTelephoneNumber
                        )
                      }
                      onPress={() => {
                        callPhoneNumber(
                          property?.foreclosure_data?.TrusteeTelephoneNumber
                        );
                      }}
                      icon={"phone"}
                    />
                  ) : null}
                </Row>
              );
            }}
          >
            {moment(property?.foreclosure_data?.AuctionDate).format(
              "MMM Do, YYYY"
            )}
            {!!property?.foreclosure_data?.AuctionTime
              ? " at " + property?.foreclosure_data?.AuctionTime
              : ""}
          </SelectItem>
        ) : property?.is_hoa_foreclosure &&
          property?.hoa_auction_date &&
          moment(property?.hoa_auction_date).format("YYYY-MM-DD") >
            moment().format("YYYY-MM-DD") ? (
          <SelectItem
            noPress={true}
            select_type={"none"}
            selected={true}
            selected_background_color={colors.orange_color_muted}
            label={"Upcoming auction:"}
            renderRight={() => {
              return (
                <Row>
                  {!!property?.hoa_trustee_attorney_phone ? (
                    <CircleButton
                      tooltip={
                        "Call Trustee: " +
                        formatUsPhone(property?.hoa_trustee_attorney_phone)
                      }
                      onPress={() => {
                        callPhoneNumber(property?.hoa_trustee_attorney_phone);
                      }}
                      icon={"phone"}
                    />
                  ) : null}
                </Row>
              );
            }}
          >
            {moment(property?.hoa_auction_date).format("MMM Do, YYYY")}
          </SelectItem>
        ) : null}

        {!!property.mls?.current_listing_price || !!property.mls?.sold_price ? (
          <>
            <Row
              style={{
                padding: 25,
                paddingTop: 20,
                paddingBottom: 0
              }}
            >
              {property?.mls?.status_sub_type == "Coming Soon" ||
              property?.mls?.status_sub_type == "Under Contract" ||
              //property?.mls?.status_sub_type == "Price Change" ||
              property?.mls?.status_sub_type == "Back on Market" ? (
                <Copy
                  style={{
                    marginRight: 15,
                    color: colors.orange_color,
                    fontSize: 12
                  }}
                >
                  {property?.mls?.status_sub_type}
                </Copy>
              ) : null}
              {!!property?.mls?.last_listing_price_change_amount ? (
                <>
                  {property?.mls?.last_listing_price_change_amount > 0 ? (
                    <Copy
                      style={{
                        marginRight: 15,
                        color: colors.orange_color,
                        fontSize: 12
                      }}
                    >
                      {"Price increase: $" +
                        numberWithCommas(
                          property?.mls?.last_listing_price_change_amount
                        )}
                    </Copy>
                  ) : (
                    <Copy
                      style={{
                        marginRight: 15,
                        color: colors.orange_color,
                        fontSize: 12
                      }}
                    >
                      {"Price cut: $" +
                        numberWithCommas(
                          Math.Abs(
                            property?.mls?.last_listing_price_change_amount
                          )
                        )}
                    </Copy>
                  )}
                </>
              ) : null}
              {!!property?.mls?.sold_date ? (
                <Copy
                  style={{
                    marginRight: 15,
                    fontSize: 12
                  }}
                >
                  Sold on{" "}
                  <Bold>
                    {moment(property?.mls.sold_date).format("MMM Do, YYYY")}
                  </Bold>
                </Copy>
              ) : null}
              {show_estimated_value ? (
                <Row style={{ marginRight: 15 }}>
                  <Copy style={{ fontSize: 12 }}>
                    Est. Value:{" "}
                    <Bold>
                      {!!property?.EstimatedValue
                        ? "$" + numberWithCommas(property?.EstimatedValue)
                        : "--"}
                    </Bold>
                  </Copy>
                  {!!property.vLowValue &&
                  !!property.vHighValue &&
                  show_estimated_value
                    ? this.renderEstimatedValuePopover()
                    : null}
                </Row>
              ) : null}
            </Row>
            {!!property.mls?.listing_office_name ||
            !!property.mls?.listing_agent_name ? (
              <SelectItem
                select_type={"profile_pic"}
                noPress={true}
                label={"Listing by:"}
                subtext={
                  !!property.mls?.listing_agent_name
                    ? toTitleCase(property.mls?.listing_office_name)
                    : ""
                }
                user={
                  !!property.mls?.listing_agent_name
                    ? {
                        firstname:
                          property.mls?.listing_agent_name?.split(" ")[0],
                        lastname:
                          property.mls?.listing_agent_name?.split(" ")[1],
                        email: property.mls?.listing_agent_email,
                        image: null
                      }
                    : {
                        firstname: property.mls?.listing_office_name,
                        lastname: "",
                        email: property.mls?.listing_office_email,
                        image: null
                      }
                }
                renderRight={() => {
                  return (
                    <Row>
                      {!!property?.mls?.listing_agent_phone ||
                      !!property?.mls?.listing_office_phone ? (
                        <CircleButton
                          tooltip={
                            !!property?.mls?.listing_agent_phone
                              ? "Call Agent: " +
                                property?.mls?.listing_agent_phone
                              : "Call Office: " +
                                property?.mls?.listing_office_phone
                          }
                          onPress={() => {
                            if (!!property?.mls?.listing_agent_phone) {
                              callPhoneNumber(
                                property?.mls?.formatted_listing_agent_phone
                              );
                            } else {
                              callPhoneNumber(
                                property?.mls?.formatted_listing_office_phone
                              );
                            }
                          }}
                          icon={"phone"}
                        />
                      ) : null}

                      {!!property?.mls?.listing_agent_email ||
                      !!property?.mls?.listing_office_email ? (
                        <CircleButton
                          tooltip={
                            !!property?.mls?.listing_agent_email
                              ? "Email Agent: " +
                                property?.mls?.listing_agent_email
                              : "Email Office: " +
                                property?.mls?.listing_office_email
                          }
                          onPress={() => {
                            if (!!property?.mls?.listing_agent_email) {
                              openEmail({
                                email: property?.mls?.listing_agent_email
                              });
                            } else {
                              openEmail({
                                email: property?.mls?.listing_agent_email
                              });
                            }
                          }}
                          icon={"alternate-email"}
                        />
                      ) : null}
                    </Row>
                  );
                }}
                renderContent={() => {
                  return (
                    <>
                      <Wrapper>
                        <Copy
                          style={{
                            fontSize: 12,
                            color: colors.light_text_color
                          }}
                        >
                          Listing agent:
                        </Copy>
                        {real_estate_agent &&
                        real_estate_agent?.type === "contact" ? (
                          <LinkedContact
                            {...this.props}
                            contacts={real_estate_agent?.data}
                          />
                        ) : real_estate_agent &&
                          real_estate_agent?.type === "company" ? (
                          <LinkedCompany
                            {...this.props}
                            company={real_estate_agent?.data}
                          />
                        ) : (
                          <Copy>
                            <Bold>
                              {" "}
                              {!!property.mls?.listing_agent_name
                                ? property.mls?.listing_agent_name
                                : property.mls?.listing_office_name}
                            </Bold>
                          </Copy>
                        )}

                        <Copy>
                          {property.mls?.days_on_market == 1
                            ? "1 day on market"
                            : property.mls?.days_on_market + " days on market"}
                        </Copy>
                      </Wrapper>
                    </>
                  );
                }}
              ></SelectItem>
            ) : null}
          </>
        ) : null}
        <MLSPhotos property={property} {...this.props} />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device } = native;
  return {
    token,
    user,
    colors,
    device
  };
};

export default connect(mapStateToProps, {})(EstimatedValue);

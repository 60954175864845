import {
  TRIGGER_LOGOUT,
  SET_ACTIVE_DIALER_CONTACT,
  UPDATE_DIALER_CONVERSATION,
  UPDATE_DIALER_CONVERSATION_MESSAGE,
  REMOVE_DIALER_CONVERSATION_MESSAGE,
  SET_DIALER_CONVERSATION,
  UPDATE_ACTIVE_CALL,
  RESET_ACTIVE_CALL,
  SET_CURRENT_CALL_SESSION,
  SET_REJECTED_CALL,
  UPDATE_CALLED_NUMBERS,
  UPDATE_QUEUED_NUMBERS,
  SET_FOLLOW_UP_QUEUE_COUNT,
  UPDATE_CALL_SESSION_COUNT,
  TRIGGER_FOLLOW_UP_QUEUE_REFRESH
} from "app/DealMachineCore/types";

import API from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = API.create();

export const getTelnyxToken = ({
  token,
  force_new = false,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getTelnyxToken({
        token,
        force_new
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getTwilioToken = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getTwilioToken({
        token
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const triggerFollowUpQueueRefresh = toggle => {
  return {
    type: TRIGGER_FOLLOW_UP_QUEUE_REFRESH,
    payload: toggle
  };
};

export const updateCallSessionCount = call_session_count => {
  return {
    type: UPDATE_CALL_SESSION_COUNT,
    payload: call_session_count
  };
};

export const updateActiveCall = active_call => {
  return {
    type: UPDATE_ACTIVE_CALL,
    payload: active_call
  };
};

export const updateCalledNumbers = called_numbers => {
  return {
    type: UPDATE_CALLED_NUMBERS,
    payload: called_numbers
  };
};
export const updateQueuedNumbers = queued_numbers => {
  return {
    type: UPDATE_QUEUED_NUMBERS,
    payload: {
      queued_numbers: queued_numbers,
      reset_queue: true
    }
  };
};

export const resetActiveCall = () => {
  return {
    type: RESET_ACTIVE_CALL
  };
};

export const setActiveDialerContact = ({
  contact,
  queued_numbers = null,
  current_conversation = null,
  active_call = null
}) => {
  return {
    type: SET_ACTIVE_DIALER_CONTACT,
    payload: { contact, queued_numbers, current_conversation, active_call }
  };
};

export const setDialerConversation = ({
  call_id,
  current_conversation = null,
  call_results = null,
  call_note = null
}) => {
  return {
    type: SET_DIALER_CONVERSATION,
    payload: {
      call_id,
      current_conversation,
      call_results,
      call_note
    }
  };
};

export const setRejectedCall = call_id => {
  return {
    type: SET_REJECTED_CALL,
    payload: call_id
  };
};

export const updateDialerConversation = ({
  type,
  subtype,
  url,
  message,
  complete,
  id
}) => {
  return {
    type: UPDATE_DIALER_CONVERSATION,
    payload: { type, subtype, url, message, complete, id }
  };
};
export const updateDialerConversationMessage = message => {
  return {
    type: UPDATE_DIALER_CONVERSATION_MESSAGE,
    payload: message
  };
};

export const removeDialerConversationMessage = message => {
  return {
    type: REMOVE_DIALER_CONVERSATION_MESSAGE,
    payload: message
  };
};

export const dialerConversationCleanUp = ({
  token,
  contact_id,
  property_id,
  message = "",
  conversation = "",
  type = "inbound",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .dialerConversationCleanUp({
        token,
        contact_id,
        property_id,
        message,
        conversation,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const dialerConversationPredict = ({
  token,
  contact_id,
  property_id,
  conversation = "",
  type = "inbound",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .dialerConversationPredict({
        token,
        contact_id,
        property_id,
        conversation,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const dialerConversationSummarize = ({
  token,
  call_id,
  contact_id,
  property_id,
  answered_by,
  conversation = "",
  type = "inbound",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();

    api
      .dialerConversationSummarize({
        token,
        call_id,
        contact_id,
        property_id,
        answered_by,
        conversation,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const phoneCall = ({
  token,
  type,
  property_id = null,
  contact_id = null,
  call_session_id = null,
  call_id = null,
  provider_id = null,
  provider = null,
  to_phone_number = null,
  from_phone_number = null,
  transcription = null,
  phone_owner = null,
  phone_type = null,
  do_not_call_flag = null,
  call_results,
  edited_note = null,
  practice_mode = false,
  dialer_provider = "telnyx",
  error_message = null,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .phoneCall({
        token,
        property_id,
        contact_id,
        call_session_id,
        call_id,
        provider_id,
        provider,
        type,
        to_phone_number,
        from_phone_number,
        transcription,
        phone_owner,
        phone_type,
        do_not_call_flag,
        call_results,
        edited_note,
        practice_mode,
        dialer_provider,
        error_message
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getPhoneCall = ({
  token,
  type,
  call_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getPhoneCall({
        token,
        type,
        call_id
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);

          if (response.data.results?.call_session) {
            dispatch({
              type: SET_CURRENT_CALL_SESSION,
              payload: {
                current_call_session: response.data.results.call_session,
                queued_numbers: response.data.results?.queued_numbers
              }
            });
          }
        }
      });
  };
};
export const getContactCallHistory = ({
  token,
  to_phone_number,
  sort_by,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();

    api
      .getPhoneCall({
        token,
        type: "call_history",
        to_phone_number,
        sort_by
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getQueuedNumbers = ({
  token,
  property_id,
  contact_id,
  type,
  exclude_numbers = null,
  include_numbers = null,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getQueuedNumbers({
        token,
        property_id,
        contact_id,
        exclude_numbers,
        include_numbers,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);

          if (
            response.data.results?.queued_numbers &&
            response.data.results?.queued_numbers.length > 0
          ) {
            dispatch({
              type: UPDATE_QUEUED_NUMBERS,
              payload: {
                queued_numbers: response.data.results.queued_numbers,
                reset_queue: false
              }
            });
          }
        }
      });
  };
};

export const getCleanAIData = ({
  token,
  property_id,
  contact_id,
  type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getCleanAIData({
        token,
        property_id,
        contact_id,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const createCallSession = ({
  token,
  select_all,
  total_count,
  new_filters = null,
  filters = {},
  list_id,
  list_history_id,
  search,
  search_type,
  lead_ids,
  property_flags,
  property_flags_and_or,
  call_session_type = "normal",
  follow_up_queue_user,
  follow_up_queue,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .createCallSession({
        token,
        type: "new_call_session",
        select_all,
        total_count,
        new_filters: new_filters ? JSON.stringify(new_filters) : null,
        filters,
        list_id,
        list_history_id,
        search,
        search_type,
        lead_ids,
        property_flags,
        property_flags_and_or,
        call_session_type,
        follow_up_queue_user,
        follow_up_queue
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          if (response.data.results.call_session) {
            dispatch({
              type: SET_CURRENT_CALL_SESSION,
              payload: {
                current_call_session: response.data.results.call_session,
                queued_numbers: response.data.results?.queued_numbers
              }
            });
          }

          onSuccess(response.data.results);
        }
      });
  };
};

export const getCallSession = ({
  token,
  type,
  call_session_id,
  limit,
  begin,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getCallSession({
        token,
        type,
        call_session_id,
        limit,
        begin
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);

          if (type === "get_current_call_session") {
            dispatch({
              type: SET_CURRENT_CALL_SESSION,
              payload: {
                current_call_session: response.data.results.call_session,
                queued_numbers: response.data.results?.queued_numbers
              }
            });
          } else if (type === "get_current_call_session_count") {
            dispatch({
              type: SET_CURRENT_CALL_SESSION,
              payload: {
                current_call_session: response.data.results.call_session
              }
            });
          }
        }
      });
  };
};

export const updateCallSession = ({
  token,
  type,
  call_session_id,
  call_id,
  to_phone_number,
  contact_id,
  property_id,
  skipped_contact_id,
  skipped_property_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .updateCallSession({
        token,
        type,
        call_session_id,
        call_id,
        to_phone_number,
        contact_id,
        property_id,
        skipped_contact_id,
        skipped_property_id
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);

          if (response.data.results?.call_session) {
            dispatch({
              type: SET_CURRENT_CALL_SESSION,
              payload: {
                current_call_session: response.data.results.call_session,
                queued_numbers: response.data.results?.call_session?.completed
                  ? []
                  : response.data.results?.queued_numbers
              }
            });
          }
        }
      });
  };
};

export const getDialerStats = ({
  token,
  type,
  filter_user,
  filter_date,
  filter_start_date,
  filter_end_date,
  search,
  limit,
  begin,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getDialerStats({
        token,
        type,
        filter_user,
        filter_date,
        filter_start_date,
        filter_end_date,
        search,
        limit,
        begin
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);

          if (type === "my_follow_up_queue_count") {
            dispatch({
              type: SET_FOLLOW_UP_QUEUE_COUNT,
              payload: response.data.results?.follow_up_queue_count
            });
          }
        }
      });
  };
};

export const getVoiceClone = ({
  token,
  type = "get_voice_clone_info",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getVoiceClone({
        token,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const updateVoiceClone = ({
  token,
  type,
  dialer_provider,
  call_id,
  property_id,
  contact_id,
  callback_number,
  generated_text,
  recording_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .updateVoiceClone({
        token,
        type,
        call_id,
        dialer_provider,
        property_id,
        contact_id,
        callback_number,
        generated_text,
        recording_id
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};
export const callControl = ({
  token,
  type,
  call_id,
  to_phone_number,
  from_phone_number,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .callControl({
        token,
        type,
        call_id,
        to_phone_number,
        from_phone_number
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Row,
  Copy,
  Card,
  ProgressBar
} from "app/NativeComponents/common";
import { Header1, Header3 } from "app/NativeComponents/common";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  getMarketingOnboardingData,
  checkIfUserHasMetadata,
  manageSubscription,
  pushSidePanel,
  popSidePanel,
  updateMailerTemplate,
  updateLead
} from "app/NativeActions";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import {
  InlineButton,
  GhostButton,
  SpinWrapper,
  OnboardingWrapper
} from "app/NativeComponents/snippets";
import FillOutContactInfoStep from "./FillOutContactInfoStep";

class DesignMailStep extends Component {
  constructor(props) {
    super(props);

    const streetPicFeature = props.all_feature_metadata?.find(
      feature => feature.slug === "street_pic"
    );

    let stripe_price_id_month =
      streetPicFeature?.upsell_stripe_price_id_monthly;
    let stripe_price_id_year =
      streetPicFeature?.upsell_stripe_price_id_annually;

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      loading_mail: false,
      mailer: null,
      lead: null,
      stripe_price_id_month,
      stripe_price_id_year,
      plan_interval: props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month"
    };

    this.loadData = this.loadData.bind(this);
    this.getMail = this.getMail.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/design-mail");
    }
    this.loadData("design_mail_step");

    this.getMail();
  }

  getMail() {
    this.props.getMarketingOnboardingData({
      token: this.props.token,
      type: "design_mail_step",
      onLoading: () => {
        this.setState({ loading_mail: true });
      },
      onError: () => {
        this.setState({ loading_mail: true });
      },
      onSuccess: results => {
        this.setState({
          loading_mail: false,
          mailer: results?.mailer,
          lead: results?.lead
        });
      }
    });
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, contentful_loading, mailer } = this.state;
    const { colors, isMobile, user } = this.props;

    return (
      <Container title={"Let's get in touch!"}>
        <OnboardingWrapper
          renderInner={() => {
            return (
              <Row>
                <InlineButton
                  icon={"arrow-back"}
                  loading={this.state.back_loading}
                  onPress={() => {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "onboarding_stepthrough_step",
                      payload: {
                        onboarding_stepthrough_current_step: "alma_step"
                      },
                      onLoading: () => {
                        this.setState({
                          back_loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          back_loading: false
                        });
                      },
                      onSuccess: () => {
                        this.setState({
                          back_loading: false
                        });
                      },
                      no_loading: true
                    });
                  }}
                >
                  Go Back
                </InlineButton>
              </Row>
            );
          }}
        >
          {this.state.contentful_content ? (
            <>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  <Bold>Let's get in touch!</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  It's time to mail the property owner.
                </Header3>
              </Wrapper>

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 25
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  Tutorial step 4 of 4.
                </Copy>
                <ProgressBar
                  style={{ marginTop: 10 }}
                  color={colors.active_color}
                  backgroundColor={colors.white_color}
                  width={200}
                  progress={4 / 4}
                />
              </Wrapper>

              <Wrapper
                style={
                  this.props.device === "mobile" || this.props.desktopMobile
                    ? {}
                    : { alignSelf: "stretch", flexDirection: "row" }
                }
              >
                <Wrapper
                  style={
                    this.props.device === "mobile" || this.props.desktopMobile
                      ? {}
                      : {
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center"
                        }
                  }
                >
                  {mailer && !this.state.loading_mail ? (
                    <>
                      {this.props.device == "mobile" ||
                      this.props.desktopMobile ? (
                        <Wrapper
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 25
                          }}
                        >
                          <MailerFlipCard
                            mailer={mailer}
                            scale={
                              mailer?.template_type == "postcard6x11"
                                ? 0.25
                                : mailer?.template_type == "postcard6x9"
                                ? 0.3
                                : 0.35
                            }
                            back_scale={
                              mailer?.template_type == "postcard6x11"
                                ? 0.25
                                : mailer?.template_type == "postcard6x9"
                                ? 0.3
                                : 0.35
                            }
                            include_edit_button={
                              !this.props.desktopMobile ||
                              this.props.device === "mobile"
                            }
                            onEditButtonPress={() => {
                              this.props.pushSidePanel({
                                slug: "postcard_designer_widget",
                                overlay: true,
                                focus_modal: true,
                                locked: true,
                                data: {
                                  mailer,
                                  hide_options: true,
                                  template_system_type:
                                    mailer?.template_system_type,
                                  onSuccess: results => {
                                    if (results?.mailer_templates?.length > 0) {
                                      this.setState({
                                        mailer: results?.mailer_templates[0]
                                      });
                                    }
                                  }
                                }
                              });
                            }}
                          />
                        </Wrapper>
                      ) : (
                        <MailerFlipCard
                          mailer={mailer}
                          include_edit_button={true}
                          onEditButtonPress={() => {
                            this.props.pushSidePanel({
                              slug: "postcard_designer_widget",
                              overlay: true,
                              focus_modal: true,
                              locked: true,
                              data: {
                                mailer,
                                hide_options: true,
                                template_system_type:
                                  mailer?.template_system_type,
                                onSuccess: results => {
                                  if (results?.mailer_templates?.length > 0) {
                                    this.setState({
                                      mailer: results?.mailer_templates[0]
                                    });
                                  }
                                }
                              }
                            });
                          }}
                          scale={
                            mailer?.template_type == "postcard6x11"
                              ? 0.5
                              : mailer?.template_type == "postcard6x9"
                              ? 0.6
                              : 0.75
                          }
                          back_scale={
                            mailer?.template_type == "postcard6x11"
                              ? 0.5
                              : mailer?.template_type == "postcard6x9"
                              ? 0.6
                              : 0.75
                          }
                        />
                      )}

                      {this.props.source_of_truth?.subscription
                        ?.billing_source !== "iap" &&
                      !checkIfUserHasMetadata("street_pic") ? (
                        <>
                          {this.props.visitor?.marketing_experiments
                            ?.dealmachine_free === "dealmachine_free" ? (
                            <Wrapper
                              className="sparkle-effect"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 25
                              }}
                            >
                              <InlineButton
                                style={{
                                  borderRadius: 30,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: colors.active_color_muted
                                }}
                                button_type={"full"}
                                selected={checkIfUserHasMetadata("street_pic")}
                                loading={this.state.upgrade_loading}
                                marketing_slug={
                                  "pressed_design_step_upgrade_button"
                                }
                                noPress={checkIfUserHasMetadata("street_pic")}
                                tooltip={
                                  checkIfUserHasMetadata("street_pic")
                                    ? "You already have this included in your trial"
                                    : ""
                                }
                                tooltipPlacement={"top"}
                                onPress={() => {
                                  this.props.pushSidePanel({
                                    slug: "purchase_plan_prompt",
                                    overlay_modal: true,
                                    locked: true,
                                    data: {
                                      title:
                                        "Close More Deals With DealMachine Pro.",
                                      dismiss_button:
                                        "No thanks. Let's continue without street view.",
                                      no_free: true
                                    },
                                    locked: true
                                  });
                                }}
                              >
                                Purchase a Pro plan to view the property image
                                on your postcard.
                              </InlineButton>
                            </Wrapper>
                          ) : (
                            <Wrapper
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 25
                              }}
                            >
                              <InlineButton
                                selected={checkIfUserHasMetadata("street_pic")}
                                loading={this.state.upgrade_loading}
                                marketing_slug={
                                  "pressed_design_step_upgrade_button"
                                }
                                icon={
                                  checkIfUserHasMetadata("street_pic")
                                    ? "check-box"
                                    : "check-box-outline-blank"
                                }
                                noPress={checkIfUserHasMetadata("street_pic")}
                                tooltip={
                                  checkIfUserHasMetadata("street_pic")
                                    ? "You already have this included in your trial"
                                    : ""
                                }
                                style={{
                                  backgroundColor: checkIfUserHasMetadata(
                                    "street_pic"
                                  )
                                    ? "transparent"
                                    : colors.orange_color_muted
                                }}
                                tooltipPlacement={"top"}
                                onPress={() => {
                                  this.props.manageSubscription({
                                    token: this.props.token,
                                    type: "add_to_subscription",
                                    event_slug: "onboarding_design_step",
                                    stripe_price_id:
                                      this.state.plan_interval == "year"
                                        ? this.state.stripe_price_id_year
                                        : this.state.stripe_price_id_month,
                                    no_messaging: true,
                                    onLoading: () => {
                                      this.setState({
                                        upgrade_loading: true
                                      });
                                    },
                                    onError: () => {
                                      this.setState({
                                        upgrade_loading: false
                                      });
                                    },
                                    onSuccess: () => {
                                      this.setState(
                                        {
                                          upgrade_loading: false,
                                          mailer: null
                                        },
                                        () => {
                                          this.getMail();
                                        }
                                      );
                                    }
                                  });
                                }}
                              >
                                Upgrade to Pro to view the property image on
                                your postcard.
                              </InlineButton>
                            </Wrapper>
                          )}
                        </>
                      ) : null}
                    </>
                  ) : this.state.loading_mail ? (
                    <SpinWrapper text={"Loading..."} />
                  ) : null}
                </Wrapper>
                {!user?.team_name || !user?.team_phone || !user?.address ? (
                  <FillOutContactInfoStep getMail={this.getMail} />
                ) : (
                  <Wrapper
                    style={
                      this.props.device === "desktop" &&
                      !this.props.desktopMobile
                        ? { width: 450 }
                        : {}
                    }
                  >
                    <Card>
                      <Wrapper style={{ padding: 25, paddingBottom: 15 }}>
                        <Header3 style={{ marginBottom: 10 }}>
                          <Bold>Why should I send a postcard?</Bold>
                        </Header3>

                        <Copy style={{ marginBottom: 10 }}>
                          Sending a postcard to the property owner allows them
                          to get in touch with you if they are interested in
                          talking to you.
                        </Copy>
                        <Copy style={{ marginBottom: 10 }}>
                          Once they've contacted you, you can set up an
                          appointment to move forward!
                        </Copy>
                        <Copy style={{ marginBottom: 10 }}>
                          We put together a postcard for you. You can swap with
                          another and change your contact information.
                        </Copy>
                        <Copy>
                          Once you're satisfied, just click{" "}
                          <Bold>"Start Mail"</Bold> and your postcard will be on
                          it's way!
                        </Copy>
                      </Wrapper>

                      <Wrapper style={{ flex: 1 }}>
                        <GhostButton
                          button_type={"full"}
                          loading={this.state.mail_loading}
                          onPress={() => {
                            this.props.updateLead({
                              token: this.props.token,
                              deal_ids: this.state.lead?.deal?.id,
                              campaign_id: "repeating_" + mailer.id + "_0_0",
                              type: "start_mailers",
                              onLoading: () => {
                                this.setState({ mail_loading: true });
                              },
                              onError: () => {
                                this.setState({ mail_loading: false });
                              },
                              onSuccess: results => {
                                if (
                                  this.props.source_of_truth?.subscription
                                    ?.billing_source === "iap" ||
                                  this.props.source_of_truth?.subscription
                                    ?.metadata?.list_builder
                                ) {
                                  let next_step =
                                    this.props.visitor?.marketing_experiments
                                      ?.dealmachine_free ===
                                      "dealmachine_free" &&
                                    !checkIfUserHasMetadata("people_data") &&
                                    this.props.user?.finished_onboarding != 1
                                      ? "select_plan_option_step"
                                      : "final_step_stage_one";

                                  this.props.updateUser({
                                    token: this.props.token,
                                    type: "onboarding_stepthrough_step",
                                    payload: {
                                      onboarding_stepthrough_current_step:
                                        next_step
                                    },
                                    onLoading: () => {},
                                    onError: () => {},
                                    onSuccess: () => {},
                                    no_loading: true
                                  });
                                } else {
                                  const next_step =
                                    this.props.visitor?.marketing_experiments
                                      ?.dealmachine_free ===
                                      "dealmachine_free" &&
                                    !checkIfUserHasMetadata("people_data") &&
                                    this.props.user?.finished_onboarding != 1
                                      ? "select_plan_option_step"
                                      : "final_step_stage_one";
                                  this.props.updateUser({
                                    token: this.props.token,
                                    type: "onboarding_stepthrough_step",
                                    payload: {
                                      onboarding_stepthrough_current_step:
                                        next_step
                                    },
                                    onLoading: () => {},
                                    onError: () => {},
                                    onSuccess: () => {},
                                    no_loading: true
                                  });
                                }
                              }
                            });
                          }}
                          style={{
                            marginLeft: 20,
                            marginRight: 20
                          }}
                        >
                          Start Mail
                        </GhostButton>
                        <Wrapper style={{ padding: 25, paddingTop: 15 }}>
                          <Copy
                            style={{
                              fontSize: 12,
                              color: colors.light_text_color
                            }}
                          >
                            Don't worry. This one's on us. You can always cancel
                            this later.
                          </Copy>
                        </Wrapper>
                        <InlineButton
                          button_type={"full"}
                          loading={this.state.skip_loading}
                          onPress={() => {
                            const next_step =
                              this.props.visitor?.marketing_experiments
                                ?.dealmachine_free === "dealmachine_free" &&
                              !checkIfUserHasMetadata("people_data") &&
                              this.props.user?.finished_onboarding != 1
                                ? "select_plan_option_step"
                                : "final_step_stage_one";
                            this.props.updateUser({
                              token: this.props.token,
                              type: "onboarding_stepthrough_step",
                              payload: {
                                onboarding_stepthrough_current_step: next_step
                              },

                              onLoading: () => {
                                this.setState({ skip_loading: true });
                              },
                              onError: () => {
                                this.setState({ skip_loading: false });
                              },
                              onSuccess: () => {
                                this.setState({ skip_loading: false });
                              },
                              no_loading: true
                            });
                          }}
                        >
                          Skip For Now
                        </InlineButton>
                      </Wrapper>
                    </Card>
                    <Row style={{ justifyContent: "space-between" }}>
                      <InlineButton
                        disabled={this.state.mail_loading}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "select_design_modal",
                            overlay: true,
                            focus_modal: true,
                            data: {
                              title: "Select A Design",
                              onSuccess: mailer_template_id => {
                                this.props.updateMailerTemplate({
                                  token: this.props.token,
                                  type: "set_default_mailer_template",
                                  mailer_template_id: mailer_template_id,
                                  onLoading: () => {},
                                  onError: () => {},
                                  onSuccess: results => {
                                    this.getMail();
                                    this.props.popSidePanel();
                                  }
                                });
                              }
                            }
                          });
                        }}
                        primary={false}
                      >
                        Switch Design
                      </InlineButton>
                      <InlineButton
                        disabled={this.state.mail_loading}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "signature_onboarding",
                            overlay: true,
                            data: {
                              onSuccess: () => {
                                this.getMail();
                                this.props.popSidePanel();
                              }
                            }
                          });
                        }}
                        primary={false}
                      >
                        Edit Contact Information
                      </InlineButton>
                    </Row>
                  </Wrapper>
                )}
              </Wrapper>
            </>
          ) : null}
          <Wrapper style={{ height: 40 }} />
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth, all_feature_metadata } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { signup_funnel, visitor } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    desktopMobile,
    device,
    initialRedirect,
    source_of_truth,
    all_feature_metadata,
    signup_funnel,
    visitor
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  getMarketingOnboardingData,
  manageSubscription,
  pushSidePanel,
  popSidePanel,
  updateMailerTemplate,
  updateLead
})(DesignMailStep);

import React, { Component } from "react";

import { SelectItem } from "app/NativeComponents/snippets";

import { renderPrice, numberWithCommas } from "app/NativeActions";

import moment from "moment";

class Invoice extends Component {
  renderDescription(description) {
    if (description && description != "") {
      return description;
    }
    return "";
  }

  render() {
    const { invoice } = this.props;

    const PRODUCT_METADATA = {
      postcard: { title: "Standard Postcard", icon: "crop_7_5" },
      postcard4x6: { title: "Postcard Size 4×6", icon: "crop_7_5" },
      lob_postcard_4_6: { title: "Postcard Size 4×6", icon: "crop_7_5" },
      postcard6x9: { title: "Postcard Size 6×9", icon: "crop_landscape" },
      postcard6x11: { title: "Postcard Size 6×11", icon: "crop_3_2" },
      handwritten: { title: "Handwritten Letter", icon: "history_edu" },
      "skip-trace": { title: "Skip-Trace", icon: "search" },
      "skip-trace-person": {
        title: "Person Skip Trace",
        icon: "person_search"
      },
      "skip-trace-corporate": {
        title: "Corporate Skip Trace",
        icon: "corporate_fare"
      },
      "dialer-minutes": {
        title: "Dialer Minutes",
        icon: "call"
      },
      "ai-voicemails": {
        title: "AI Voicemails",
        icon: "call"
      },
      "ai-vision-builder": {
        title: "AI Vision Builder",
        icon: "visibility"
      }
    };

    let invoice_date_created = moment(invoice.date_created_utc)
      .local()
      .format("ddd, MMM Do, YYYY");
    let invoice_description = "";
    let invoice_icon = "";
    let invoice_subtext = "";

    if (
      invoice.item === null ||
      !PRODUCT_METADATA.hasOwnProperty(invoice.item) ||
      parseInt(invoice.quantity) === 0
    )
      return null;
    switch (invoice.product_name) {
      case "mail":
        invoice_description =
          "Sent " +
          numberWithCommas(invoice.quantity) +
          " of " +
          PRODUCT_METADATA[invoice.item]["title"] +
          ": " +
          renderPrice(invoice.cost_total_cents_paid);
        invoice_icon = PRODUCT_METADATA[invoice.item]["icon"];
        invoice_subtext = invoice.description;
        break;

      case "skip-trace":
        invoice_description =
          "Performed " +
          numberWithCommas(invoice.quantity) +
          " of " +
          PRODUCT_METADATA[invoice.item]["title"] +
          ": " +
          renderPrice(invoice.cost_total_cents_paid);
        invoice_icon = PRODUCT_METADATA[invoice.item]["icon"];
        break;

      case "dialer-minutes":
        invoice_description =
          "Called " +
          numberWithCommas(invoice.quantity) +
          " minutes of " +
          PRODUCT_METADATA[invoice.item]["title"] +
          ": " +
          renderPrice(invoice.cost_total_cents_paid);
        invoice_icon = PRODUCT_METADATA[invoice.item]["icon"];
        break;
      case "ai-voicemails":
        invoice_description =
          "Dropped " +
          numberWithCommas(invoice.quantity) +
          " " +
          PRODUCT_METADATA[invoice.item]["title"] +
          ": " +
          renderPrice(invoice.cost_total_cents_paid);
        invoice_icon = PRODUCT_METADATA[invoice.item]["icon"];
        break;
      case "ai-vision-builder":
        invoice_description =
          "Analyzed " +
          numberWithCommas(invoice.quantity) +
          " properties with AI Vision: " +
          renderPrice(invoice.cost_total_cents_paid);
        invoice_icon = PRODUCT_METADATA[invoice.item]["icon"];
        break;

      default:
        invoice_description =
          "Spent: " + renderPrice(invoice.cost_total_cents_paid);
        invoice_icon = "attach_money";
        break;
    }

    return (
      <SelectItem
        select_type={"icon"}
        select_icon={null /* {isMobile ? null : invoice_icon} */}
        icon={null}
        noPress={true}
        subtext={this.renderDescription(invoice_subtext)}
        description={this.renderDescription(invoice_description)}
      >
        {invoice_date_created}
      </SelectItem>
    );
  }
}

export default Invoice;

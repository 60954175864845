import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  InternalImage,
  Spinner,
  Copy,
  Title,
  Row,
  Bold,
  Wrapper,
  Card,
  StatusBarStyle,
  Header1,
  Header3
} from "app/NativeComponents/common";
import {
  InlineButton,
  SelectItem,
  OnboardingNavbar,
  CloseButton
} from "app/NativeComponents/snippets";
import DisplayPlans from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/DisplayPlans";
import DisplayAddons from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/DisplayAddons";

import {
  loadOnboardingText,
  loadSpecialPlanSelect,
  getCorrectSpecial,
  showSuccess,
  checkIfUserHasMetadata,
  openUrl,
  renderPrice
} from "app/NativeActions";
class PayloadBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan_interval:
        props.qualified_lead &&
        (this.props.signup_funnel !== "use_iap" ||
          this.props.platform !== "ios")
          ? "year"
          : "month",
      hide_annual_toggle: false,
      contentful_content: null,
      contentful_loading: true,
      selected_list_builder: false,
      view_normal: false,
      selected_stripe_id: null,
      selected_stripe_id_year: null,
      has_special: false
    };

    this.loadData = this.loadData.bind(this);
    this.getPricingPage = this.getPricingPage.bind(this);
    this.onTogglePlanInterval = this.onTogglePlanInterval.bind(this);
    this.selectProPlan = this.selectProPlan.bind(this);
    this.renderInner = this.renderInner.bind(this);
  }

  componentDidMount() {
    if (
      (!!this.props.user_info?.special || !!this.props.special) &&
      !this.props.no_special
    ) {
      this.getSpecialPlan();
    } else {
      this.getPricingPage();
    }

    if (this.props.device == "desktop" && !this.props.prompt) {
      this.props.startRedirect("/onboarding/select-a-plan");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.visitor?.marketing_experiments) !==
      JSON.stringify(this.props.visitor?.marketing_experiments)
    ) {
      if (
        (!!this.props.user_info?.special || !!this.props.special) &&
        !this.props.no_special
      ) {
        this.getSpecialPlan();
      } else {
        this.getPricingPage();
      }
    }
  }

  getSpecialPlan() {
    let special = !!this.props.special
      ? this.props.special
      : this.props.user_info?.special;

    this.props.getCorrectSpecial({
      token: this.props.token,
      special,
      onLoading: () => {
        this.setState({
          contentful_loading: true
        });
      },
      onError: () => {
        this.getPricingPage();
      },
      onSuccess: results => {
        if (!!results.special && results.special !== "select_a_plan") {
          special = results?.special;
          loadSpecialPlanSelect(special)
            .then(data => {
              if (data && data.items) {
                if (data.items.length > 0) {
                  const contentful_content = data.items[0]
                    ? data.items[0].fields
                      ? data.items[0].fields
                      : null
                    : null;
                  if (
                    !!contentful_content.select_a_plan_slug &&
                    contentful_content.select_a_plan_slug !== "select_a_plan"
                  ) {
                    this.setState(
                      {
                        has_special: true
                      },
                      () => {
                        this.loadData(contentful_content.select_a_plan_slug);
                      }
                    );
                  } else {
                    this.getPricingPage();
                  }
                } else {
                  this.getPricingPage();
                }
              } else {
                this.getPricingPage();
              }
            })
            .catch(err => {
              this.getPricingPage();
            });
        } else {
          this.getPricingPage();
        }
      }
    });
  }

  getPricingPage() {
    if (!!this.props.user?.promo_pricing_contentful_slug) {
      this.loadData(this.props.user?.promo_pricing_contentful_slug);
    } else {
      if (
        this.props.visitor?.marketing_experiments?.dealmachine_free ===
        "dealmachine_free"
      ) {
        this.loadData(
          !!this.props.contentful_slug
            ? this.props.contentful_slug
            : "select_a_plan_dealmachine_free"
        );
      } else {
        this.loadData(
          !!this.props.contentful_slug
            ? this.props.contentful_slug
            : "select_a_plan"
        );
      }
    }
  }

  onTogglePlanInterval() {
    this.setState({
      plan_interval: this.state.plan_interval == "month" ? "year" : "month"
    });
  }
  selectProPlan() {
    if (this.state.contentful_content?.contentItems) {
      for (
        let i = 0;
        i < this.state.contentful_content?.contentItems.length;
        i++
      ) {
        const plan = this.state.contentful_content?.contentItems[i]?.fields;

        if (plan?.featured === true) {
          this.setState({
            selected_stripe_id: plan.stripePriceIdMonthly,
            selected_stripe_id_year: plan.stripePriceIdYearly
          });
          break;
        }
      }
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState(
              {
                contentful_content: data.items[0]
                  ? data.items[0].fields
                    ? data.items[0].fields
                    : null
                  : null,
                contentful_loading: false
              },
              () => {
                if (!!this.state.contentful_content?.annual_toggle) {
                  this.setState({
                    plan_interval:
                      this.state.contentful_content?.annual_toggle ==
                        "year_only" ||
                      this.state.contentful_content?.annual_toggle == "year"
                        ? "year"
                        : this.state.contentful_content?.annual_toggle ==
                            "month_only" ||
                          this.state.contentful_content?.annual_toggle ==
                            "month"
                        ? "month"
                        : "month" || this.state.plan_interval,
                    hide_annual_toggle:
                      this.state.contentful_content?.annual_toggle ==
                        "year_only" ||
                      this.state.contentful_content?.annual_toggle ==
                        "month_only"
                        ? true
                        : false
                  });
                }

                if (
                  this.props.visitor?.marketing_experiments
                    ?.dealmachine_free === "dealmachine_free" &&
                  this.props.selected_pro
                ) {
                  if (this.state.contentful_content?.contentItems) {
                    for (
                      let i = 0;
                      i < this.state.contentful_content?.contentItems.length;
                      i++
                    ) {
                      const plan =
                        this.state.contentful_content?.contentItems[i]?.fields;

                      if (plan?.featured === true) {
                        this.setState({
                          selected_stripe_id: plan.stripePriceIdMonthly,
                          selected_stripe_id_year: plan.stripePriceIdYearly
                        });
                        break;
                      }
                    }
                  }
                }
              }
            );
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  renderInner() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, desktopMobile } = this.props;

    const annual_toggle_text = !!contentful_content?.annual_toggle_text
      ? contentful_content?.annual_toggle_text
      : "Save 17% with annual";

    console.log(this.state.plan_interval);

    return (
      <>
        {this.state.contentful_content ? (
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 25
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                <Bold>
                  {!!this.props.title
                    ? this.props.title
                    : contentful_content?.title}
                </Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                {contentful_content?.subtitle}
              </Header3>
            </Wrapper>

            <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
              <Row>
                {(this.props.platform !== "ios" ||
                  this.props.signup_funnel !== "use_iap") &&
                (!this.state.hide_annual_toggle || this.state.view_normal) &&
                !this.state.selected_stripe_id ? (
                  <Wrapper
                    className={
                      this.state.plan_interval == "year"
                        ? "colorful-sparkle-border"
                        : "sparkle-effect"
                    }
                    style={{ borderRadius: 30, margin: 5 }}
                  >
                    <InlineButton
                      iconColor={
                        this.state.plan_interval == "year"
                          ? colors.success_color
                          : null
                      }
                      icon={
                        this.state.plan_interval == "year"
                          ? "toggle-on"
                          : "toggle-off"
                      }
                      style={{
                        margin: 0,
                        backgroundColor:
                          this.state.plan_interval == "month"
                            ? colors.active_color_muted
                            : colors.success_color_muted
                      }}
                      onPress={this.onTogglePlanInterval}
                    >
                      <Bold>{annual_toggle_text}</Bold>
                    </InlineButton>
                  </Wrapper>
                ) : null}
                {this.state.has_special &&
                !this.state.selected_stripe_id &&
                !this.props.source_of_truth?.has_subscription ? (
                  <InlineButton
                    onPress={() => {
                      if (!this.state.view_normal) {
                        this.setState(
                          {
                            view_normal: true,
                            plan_interval: this.props.qualified_lead
                              ? "year"
                              : "month"
                          },
                          () => {
                            this.getPricingPage();
                          }
                        );
                      } else {
                        this.setState(
                          {
                            view_normal: false,
                            plan_interval:
                              this.state.contentful_content?.annual_toggle ==
                                "year_only" ||
                              this.state.contentful_content?.annual_toggle ==
                                "year"
                                ? "year"
                                : "month"
                          },
                          () => {
                            this.getSpecialPlan();
                          }
                        );
                      }
                    }}
                  >
                    {!this.state.view_normal
                      ? "😐 View our normal plans."
                      : "🎁 View our special plans."}
                  </InlineButton>
                ) : null}
              </Row>
            </Wrapper>

            {this.props.source_of_truth?.has_subscription &&
            !checkIfUserHasMetadata("free_plan") ? (
              <DisplayAddons
                contentful_content={this.state.contentful_content}
                manageSubscription={this.props.manageSubscription}
                plan_interval={this.state.plan_interval}
                onSuccess={() => {
                  this.props.showSuccess(
                    "Successfully purchased your plan!",
                    "Success!"
                  );
                  this.props.popSidePanel();
                }}
              />
            ) : (
              <DisplayPlans
                platform={this.props.platform}
                force_select_plan={
                  this.props.visitor?.marketing_experiments
                    ?.dealmachine_free === "dealmachine_free"
                }
                contentful_content={this.state.contentful_content}
                selected_list_builder={this.state.selected_list_builder}
                needs_lists={this.props.needs_lists}
                needs_dialer={this.props.needs_dialer}
                plan_interval={this.state.plan_interval}
                signup_funnel={this.props.signup_funnel}
                onSelectListBuilder={selected_list_builder => {
                  this.setState({
                    selected_list_builder
                  });
                }}
                onPurchase={this.props.onPurchase}
                selected_stripe_id={this.state.selected_stripe_id}
                selected_stripe_id_year={this.state.selected_stripe_id_year}
                onPlanSelection={({ stripe_id, stripe_id_year }) => {
                  this.setState({
                    selected_stripe_id: stripe_id,
                    selected_stripe_id_year: stripe_id_year
                  });
                }}
                annual_toggle_text={annual_toggle_text}
                hide_annual_toggle={this.state.hide_annual_toggle}
                onTogglePlanInterval={this.onTogglePlanInterval}
                selectProPlan={this.selectProPlan}
                manageSubscription={this.props.manageSubscription}
                startRedirect={this.props.startRedirect}
                prompt={this.props.prompt}
                popSidePanel={this.props.popSidePanel}
                no_free={this.props.no_free}
              />
            )}

            {this.props.platform == "ios" &&
            this.props.signup_funnel == "use_iap" ? (
              <Wrapper
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 25
                }}
              >
                <InlineButton
                  button_type={"full"}
                  onPress={this.props.restorePurchases}
                  //loading={this.props.restore_loading}
                >
                  Restore Purchases
                </InlineButton>
              </Wrapper>
            ) : !!contentful_content?.description ? (
              <Wrapper
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 25
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  {contentful_content.description}
                </Copy>
              </Wrapper>
            ) : null}
          </>
        ) : null}
        {!!this.props.dismiss_button && this.state.contentful_content ? (
          <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
            <InlineButton onPress={this.props.popSidePanel}>
              {this.props.dismiss_button}
            </InlineButton>
          </Wrapper>
        ) : null}
        <Wrapper style={{ height: 40 }} />
      </>
    );
  }

  render() {
    if (this.props.prompt) {
      return (
        <Container style={{ backgroundColor: "transparent" }}>
          <KeyboardView style={{ flex: 1 }}>{this.renderInner()}</KeyboardView>

          <CloseButton onPress={this.props.popSidePanel} />
        </Container>
      );
    }

    return (
      <Container>
        <StatusBarStyle statusBarStyle={"dark-content"} />

        <KeyboardView style={{ flex: 1 }}>
          <OnboardingNavbar />
          {this.renderInner()}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, billing }) => {
  const { token, user, init, loading } = auth;
  const { source_of_truth } = billing;

  const { colors } = settings;

  return {
    token,
    user,
    init,
    colors,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  getCorrectSpecial,
  showSuccess
})(PayloadBody);

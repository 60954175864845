import React, { Component } from "react";

import {
  Bold,
  Wrapper,
  Copy,
  Title,
  Icon,
  Row
} from "app/NativeComponents/common";
import { store } from "app/store";
import { numberWithCommas } from "app/NativeActions";

class StatBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const colors = store.getState().settings.colors;
    const {
      selected,
      title,
      number,
      prev_number,
      prev_title,
      tooltip,
      format
    } = this.props;

    return (
      <Wrapper
        style={{
          flex: 1,
          height: 55,
          backgroundColor: selected ? colors.active_color_muted : "transparent",
          borderRadius: 5,
          paddingRight: 25,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Row>
          <Copy style={{ textAlign: "center", fontSize: 12 }}>{title}</Copy>
        </Row>

        <Title
          style={{
            color: colors.active_color,
            fontSize: 22,

            textAlign: "center"
          }}
        >
          <Bold>
            {!!number
              ? format === "percent"
                ? number + "%"
                : numberWithCommas(number)
              : "--"}
          </Bold>
        </Title>
      </Wrapper>
    );
  }
}

export default StatBlock;

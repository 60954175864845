import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Copy,
  Bold,
  InnerCopy,
  Icon
} from "app/NativeComponents/common";
import {
  CircleButton,
  SelectItem,
  IconButton,
  InlineButton
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  copyToClipBoard,
  openEmail,
  checkIfUserHasMetadata
} from "app/NativeActions";
import { store } from "app/store";

class EmailItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copy_success: false
    };
  }

  componentWillUnmount() {
    clearInterval(this.copy_success_timeout);
  }

  render() {
    const { email } = this.props;
    const { copy_success } = this.state;
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;

    return (
      <SelectItem
        select_type={"none"}
        noPress={true}
        label={""}
        renderRight={() => {
          return (
            <>
              {checkIfUserHasMetadata("free_plan") &&
              !checkIfUserHasMetadata("people_data") ? (
                <InlineButton
                  icon={"visibility"}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      locked: true,
                      data: {
                        title:
                          "Get Unlimited Phone Numbers With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without phone numbers.",
                        no_free: true
                      },
                      locked: true
                    });
                  }}
                >
                  Reveal
                </InlineButton>
              ) : (
                <Row style={device === "desktop" ? { paddingRight: 15 } : {}}>
                  <IconButton
                    icon={copy_success ? "check" : "content-copy"}
                    backgroundColor={copy_success ? colors.success_color : null}
                    icon_color={copy_success ? colors.white_text_color : null}
                    tooltip={
                      copy_success
                        ? "Copied: " + email.email.toLowerCase()
                        : "Copy: " + email.email.toLowerCase()
                    }
                    tooltipPlacement="top"
                    onPress={() => {
                      copyToClipBoard(email.email.toLowerCase());
                      clearInterval(this.copy_success_timeout);
                      let $this = this;
                      this.copy_success_timeout = $this.setState(
                        {
                          copy_success: true
                        },
                        () => {
                          setTimeout(() => {
                            $this.setState({
                              copy_success: false
                            });
                          }, 3000);
                        }
                      );
                    }}
                  />
                  <IconButton
                    tooltipPlacement="top"
                    tooltip={"Email: " + email.email.toLowerCase()}
                    onPress={() => {
                      openEmail({ email: email.email.toLowerCase() });
                    }}
                    icon={"alternate-email"}
                  />
                </Row>
              )}
            </>
          );
        }}
        renderContent={() => {
          return (
            <Wrapper style={{ flex: 1 }}>
              <Row>
                {!!email?.verification_code ? (
                  <Wrapper
                    style={{ paddingRight: 10 }}
                    tooltip={email?.verification}
                  >
                    <Icon
                      color={
                        !!email?.verification_code &&
                        parseInt(email?.verification_code) >= 4
                          ? colors.success_color
                          : colors.orange_color_muted
                      }
                      size={14}
                      icon={"circle"}
                    />
                  </Wrapper>
                ) : null}

                <Copy>
                  <Bold>{email.email.toLowerCase()}</Bold>
                </Copy>
              </Row>
            </Wrapper>
          );
        }}
      ></SelectItem>
    );
  }
}
const mapStateToProps = ({ auth, route, native }) => {
  const { token, user } = auth;
  const { current_route } = route;
  const { isMobile } = native;
  return {
    token,
    user,
    current_route,
    isMobile
  };
};

export default connect(mapStateToProps, {
  pushSidePanel
})(EmailItem);

import React, { Component } from "react";
import { connect } from "react-redux";

import { FocusModal } from "app/NativeComponents/snippets";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Signature from "./Signature";
import MainBody from "./MainBody";
import SelectDesign from "./SelectDesign";
import {
  uploadFile,
  setModal,
  toggleModal,
  showSuccess,
  showErrorMessage,
  updateMailerTemplate,
  getMailerDesignTemplates,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  updateUserSettings,
  formatUsPhone
} from "app/NativeActions";

class MailerDesigner extends Component {
  constructor(props) {
    super(props);

    let card_width = 600;
    let card_height = 408;
    let card_width_back = 600;
    let card_height_back = 408;
    let scale = 1;
    let scale_back = 1;

    switch (props.mailer?.template_type) {
      case "handwritten":
        card_width = 375;
        card_height = 508;
        card_width_back = 600;
        card_height_back = 434;

        scale = 1;
        scale_back = 1;
        if (props.isMobile) {
          scale = 0.6;
          scale_back = 0.6;
        }
        break;

      case "postcard":
      default:
        card_width = 600;
        card_height = 408;
        card_width_back = 600;
        card_height_back = 408;
        if (props.isMobile) {
          scale = 0.5;
          scale_back = 0.5;
        }
        break;

      case "postcard6x9":
        card_width = 888;
        card_height = 600;
        card_width_back = 888;
        card_height_back = 600;
        scale = 0.8;
        scale_back = 0.8;

        if (props.isMobile) {
          scale = 0.45;
          scale_back = 0.35;
        }
        break;
      case "postcard6x11":
        card_width = 1080;
        card_height = 600;
        card_width_back = 1080;
        card_height_back = 600;
        scale = 0.65;
        scale_back = 0.65;

        if (props.isMobile) {
          scale = 0.35;
          scale_back = 0.35;
        }

        break;
    }

    this.state = {
      front_layers: props.mailer?.front_layers ? props.mailer.front_layers : [],
      back_layers: props.mailer?.front_layers ? props.mailer.back_layers : [],
      bracket_variables: props.mailer?.bracket_variables || [],
      template: {
        template_system_type: "design_json",
        template_type: props.mailer?.template_type,
        template_title: props.mailer?.template_title || "",
        signature: props.mailer?.signature
          ? props.mailer?.signature
          : {
              id: "no_signature_id",
              title: "",
              sign_off: "Sincerely, ",
              address: props.user?.address || "",
              address2: props.user?.address2 || "",
              city: props.user?.address_city || "",
              state: props.user?.address_state || "",
              zip: props.user?.address_zip || "",
              name: props.user?.name || "",
              signature_other_name: props.user?.name || "",
              other_contact: "",
              disclaimer: "",
              signature_company: props.user?.company,
              phone: !!props.user?.default_call_tracking_number
                ? props.user?.default_call_tracking_number
                : formatUsPhone(props.user?.team_phone),
              call_tracking_number_id: !!props.user
                ?.default_call_tracking_number_id
                ? props.user?.default_call_tracking_number_id
                : 0,
              signature_phone_type: !!props.user
                ?.default_call_tracking_number_id
                ? "call_tracking"
                : "normal",
              email: props.user?.team_email,
              signature_image: props.user?.team_image
            }
      },
      template_id: props.mailer?.id,
      show_design_templates:
        props.mailer?.id == "new_template" &&
        !props.mailer?.cloned &&
        props.mailer?.template_type != "handwritten"
          ? true
          : false,
      variables: {},
      selected_layer: null,
      side_tab: "front",
      card_width,
      card_height,
      scale,
      card_width_back,
      card_height_back,
      scale_back,
      select_signature: false,
      grid: false,
      show_text_safe: true,
      mobile_tab: "layers",
      saving: false,
      advanced_editing_mode: this.props.user.user_settings.advanced_editing
        ? this.props.user.user_settings.advanced_editing
        : false
    };

    this.createNewLayer = this.createNewLayer.bind(this);
    this.selectLayer = this.selectLayer.bind(this);
    this.updateLayer = this.updateLayer.bind(this);
    this.toggleSide = this.toggleSide.bind(this);
    this.toggleGrid = this.toggleGrid.bind(this);
    this.toggleTextSafe = this.toggleTextSafe.bind(this);
    this.toggleMobileTab = this.toggleMobileTab.bind(this);

    this.onZoom = this.onZoom.bind(this);
    this.reorderLayers = this.reorderLayers.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.importCode = this.importCode.bind(this);

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.toggleSignatures = this.toggleSignatures.bind(this);
    this.toggleDesignTemplates = this.toggleDesignTemplates.bind(this);
    this.selectDesignTemplate = this.selectDesignTemplate.bind(this);

    this.updateSignature = this.updateSignature.bind(this);
    this.updateFullSignature = this.updateFullSignature.bind(this);

    this.saveMailerTemplate = this.saveMailerTemplate.bind(this);

    this.removeLayer = this.removeLayer.bind(this);
    this.toggleAdvancedEditingMode = this.toggleAdvancedEditingMode.bind(this);

    this.moveLayerAbove = this.moveLayerAbove.bind(this);
    this.moveLayerBelow = this.moveLayerBelow.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    const { template } = this.state;

    if (template.signature) {
      //set the signature so it updates the bracket variables on a newly created template
      this.updateBracketVariablesWithSignature(template.signature);
    }

    this.props.getMailerDesignTemplates({
      token: this.props.token,
      template_type: this.props.mailer?.template_type || "postcard"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.front_layers?.length !== prevState.front_layers?.length ||
        this.state.back_layers?.length !== prevState.back_layers?.length) &&
      !this.props.isMobile
    ) {
      clearInterval(this._scale_interval);
      this._scale_interval = setTimeout(() => {
        this.setState({
          scale: !this.props.isMobile ? 1 : this.state.scale
        });
      }, 100);
    }

    if (
      JSON.stringify(this.state.template?.signature) !==
      JSON.stringify(prevState.template?.signature)
    ) {
      this.updateBracketVariablesWithSignature(this.state.template?.signature);
    }
  }
  componentWillUnmount() {
    clearInterval(this._scale_interval);
  }

  updateBracketVariablesWithSignature(signature) {
    const { bracket_variables } = this.state;
    if (signature) {
      this.setState({
        bracket_variables: bracket_variables.map(bracket => {
          if (bracket.slug == "{{signature_name}}") {
            return {
              ...bracket,
              value: signature.name
            };
          } else if (bracket.slug == "{{signature_image}}") {
            return {
              ...bracket,
              value: signature.signature_image
            };
          } else if (bracket.slug == "{{signature_sign_off}}") {
            return {
              ...bracket,
              value: signature.sign_off
            };
          } else if (bracket.slug == "{{signature_phone}}") {
            return {
              ...bracket,
              value: formatUsPhone(signature.phone)
            };
          } else if (bracket.slug == "{{signature_email}}") {
            return {
              ...bracket,
              value: signature.email
            };
          } else if (bracket.slug == "{{return_address}}") {
            return {
              ...bracket,
              value: signature.address
            };
          } else if (bracket.slug == "{{return_address2}}") {
            return {
              ...bracket,
              value: signature.address2
            };
          } else if (bracket.slug == "{{return_address_city}}") {
            return {
              ...bracket,
              value: signature.city
            };
          } else if (bracket.slug == "{{return_address_state}}") {
            return {
              ...bracket,
              value: signature.state
            };
          } else if (bracket.slug == "{{return_address_zip}}") {
            return {
              ...bracket,
              value: signature.zip
            };
          } else if (bracket.slug == "{{disclosure}}") {
            return {
              ...bracket,
              value: signature.disclaimer
            };
          }

          return bracket;
        })
      });
    }
  }

  createNewLayer() {
    const { side_tab, front_layers, back_layers } = this.state;

    let highest_layer_number = 1;
    let layer_array = [];
    if (side_tab == "front") {
      layer_array = Array.from(front_layers);
    } else {
      layer_array = Array.from(back_layers);
    }
    for (let i = 0; i < layer_array.length; i++) {
      const layer_elements = layer_array[i].id.split("_");
      const id_number = layer_elements[2];
      if (parseInt(id_number) >= highest_layer_number) {
        highest_layer_number = parseInt(id_number) + 1;
      }
    }

    const layer_object = {
      layer_type: "text",
      id:
        side_tab == "front"
          ? "front_layer_" + highest_layer_number
          : "back_layer_" + highest_layer_number,
      side: side_tab,
      x: 0,
      y: 0,
      height: 100,
      width: 200,
      title:
        side_tab == "front"
          ? "Front Layer #" + highest_layer_number
          : "Back Layer #" + highest_layer_number,
      backgroundType: "color",
      background: "transparent",
      padding_top: 0,
      padding_left: 0,
      padding_bottom: 0,
      padding_right: 0,
      content:
        side_tab == "front"
          ? "My new front layer #" + (front_layers.length + 1)
          : "My new back #" + (back_layers.length + 1),
      text_align: "center",
      text_color: "#000000",
      font_size: 12,
      line_height: 12,
      font_family: "Open Sans",
      font_family_variants: [
        "300",
        "300italic",
        "regular",
        "italic",
        "600",
        "600italic",
        "700",
        "700italic",
        "800",
        "800italic"
      ],
      font_family_files: {
        300: "http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf",
        600: "http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirk-VeJoCqeDjg.ttf",
        700: "http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf",
        800: "http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN8rs-VeJoCqeDjg.ttf",
        "300italic":
          "http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKWyV-hsKKKTjrPW.ttf",
        regular:
          "http://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf",
        italic:
          "http://fonts.gstatic.com/s/opensans/v18/mem6YaGs126MiZpBA-UFUJ0ef8xkA76a.ttf",
        "600italic":
          "http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKXGUehsKKKTjrPW.ttf",
        "700italic":
          "http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKWiUOhsKKKTjrPW.ttf",
        "800italic":
          "http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKW-U-hsKKKTjrPW.ttf"
      },
      image_fit: "cover",
      image_type: "upload_new_image",
      image_url: "",

      border_style: "none",
      border_color: "#000000",
      border_width: 0,
      border_radius: 0,
      locked: false
    };

    this.setState({
      front_layers:
        side_tab == "front" ? [...front_layers, layer_object] : front_layers,
      back_layers:
        side_tab == "back" ? [...back_layers, layer_object] : back_layers,
      selected_layer: layer_object
    });
  }
  selectLayer(layer) {
    const { selected_layer } = this.state;
    this.setState({
      selected_layer:
        layer && selected_layer && layer.id == selected_layer.id ? null : layer,
      scale: !this.props.isMobile ? 1 : this.state.scale
    });
  }

  toggleSignatures(select_signature) {
    this.setState({
      select_signature
    });
  }

  updateSignature({ prop, value }) {
    this.setState({
      template: {
        ...this.state.template,
        signature: {
          ...this.state.template?.signature,
          [prop]: value
        }
      }
    });
  }
  updateFullSignature(signature) {
    this.setState({
      template: {
        ...this.state.template,
        signature: signature
      }
    });
  }

  toggleMobileTab(mobile_tab) {
    this.setState({ mobile_tab });
  }
  toggleGrid(grid) {
    this.setState({
      grid
    });
  }
  toggleTextSafe(show_text_safe) {
    this.setState({
      show_text_safe
    });
  }

  toggleDesignTemplates(show_design_templates) {
    this.setState({
      show_design_templates
    });
  }

  toggleAdvancedEditingMode(advanced_editing_mode) {
    this.setState({
      advanced_editing_mode
    });
    this.props.updateUserSettings({
      token: this.props.token,
      type: "advanced_editing",
      value: advanced_editing_mode
    });
  }

  selectDesignTemplate(design_template) {
    this.setState({
      front_layers: design_template.front_layers,
      back_layers: design_template.back_layers,
      show_design_templates: false
    });
  }

  importCode({ json_string, onSuccess = () => {}, onError = () => {} }) {
    try {
      let import_object = JSON.parse(json_string);

      if (
        import_object.front_layers &&
        import_object.back_layers &&
        import_object.template_info
      ) {
        if (import_object.template_info.id) {
          delete import_object.template_info.id;
        }

        this.setState({
          template: {
            ...this.state.template,
            ...import_object.template_info,
            template_type: this.state.template?.template_type || "postcard" // keeps the same template type
          },
          front_layers: import_object.front_layers,
          back_layers: import_object.back_layers,
          show_design_templates: false
        });

        onSuccess();
      } else {
        onError("Please enter valid DealMachine Template JSON");
      }
    } catch (e) {
      onError("Please enter valid JSON");
    }
  }

  updateLayer({ prop, value, layer = null }) {
    const { selected_layer, front_layers, back_layers, side_tab } = this.state;
    if (selected_layer) {
      this.setState({
        selected_layer: layer
          ? layer
          : {
              ...selected_layer,
              [prop]: value
            },
        front_layers:
          side_tab == "front"
            ? front_layers.map(l => {
                if (l.id == selected_layer.id) {
                  return layer
                    ? layer
                    : {
                        ...l,
                        [prop]: value
                      };
                }
                return l;
              })
            : front_layers,
        back_layers:
          side_tab == "back"
            ? back_layers.map(l => {
                if (l.id == selected_layer.id) {
                  return layer
                    ? layer
                    : {
                        ...l,
                        [prop]: value
                      };
                }
                return l;
              })
            : back_layers
      });
    } else if (layer) {
      this.setState({
        front_layers:
          side_tab == "front"
            ? front_layers.map(l => {
                if (l.id == layer.id) {
                  return {
                    ...l,
                    [prop]: value
                  };
                }
                return l;
              })
            : front_layers,
        back_layers:
          side_tab == "back"
            ? back_layers.map(l => {
                if (l.id == layer.id) {
                  return {
                    ...l,
                    [prop]: value
                  };
                }
                return l;
              })
            : back_layers
      });
    }
  }

  removeLayer(layer = null) {
    const { front_layers, back_layers, side_tab } = this.state;
    if (layer) {
      this.setState({
        front_layers:
          side_tab == "front"
            ? front_layers.filter(({ id }) => id !== layer.id)
            : front_layers,
        back_layers:
          side_tab == "back"
            ? back_layers.filter(({ id }) => id !== layer.id)
            : back_layers
      });
    }
  }

  reorderLayers({ previousIndex, nextIndex }) {
    const { front_layers, back_layers, side_tab } = this.state;
    const reordered_layers =
      side_tab == "front" ? Array.from(front_layers) : Array.from(back_layers);

    const [removed] = reordered_layers.splice(previousIndex, 1);
    reordered_layers.splice(nextIndex, 0, removed);

    this.setState({
      front_layers: side_tab == "front" ? reordered_layers : front_layers,
      back_layers: side_tab == "back" ? reordered_layers : back_layers
    });
  }

  onZoom(scale) {
    if (scale != 1) {
      this.setState({ scale, selected_layer: null });
    } else {
      this.setState({ scale });
    }
  }

  toggleSide(side_tab) {
    this.setState({ side_tab });
  }

  updateTemplate({ prop, value }) {
    const { template, bracket_variables } = this.state;

    this.setState({
      template: {
        ...template,
        [prop]: value
      }
    });
    if (prop == "signature") {
      const signature = value;
      this.updateBracketVariablesWithSignature(signature);
    }
  }

  checkIfNeedsToSave() {
    const { template_id, template, front_layers, back_layers } = this.state;
    const { mailer } = this.props;
    if (
      template_id == "new_template" ||
      template?.template_title != mailer?.template_title ||
      JSON.stringify(template?.signature) !=
        JSON.stringify(mailer?.signature) ||
      JSON.stringify(front_layers) !== JSON.stringify(mailer?.front_layers) ||
      JSON.stringify(back_layers) !== JSON.stringify(mailer?.back_layers)
    ) {
      return true;
    }

    return false;
  }

  saveMailerTemplate() {
    const { template, template_id, front_layers, back_layers } = this.state;

    this.props.updateMailerTemplate({
      token: this.props.token,
      type:
        template_id !== "new_template"
          ? "update_mailer_template"
          : "create_mailer_template",
      mailer_template_id: template_id,
      template_title: template.template_title,
      template_system_type: "design_json",
      template_type: template.template_type,

      signature_sign_off: template?.signature?.sign_off,
      return_address: template?.signature?.address,
      return_address2: template?.signature?.address2,
      return_address_city: template?.signature?.city,
      return_address_state: template?.signature?.state,
      return_address_zip: template?.signature?.zip,
      signature_name: template?.signature?.name,
      signature_phone: template?.signature?.phone,
      call_tracking_number_id: template?.signature?.call_tracking_number_id,
      signature_email: template?.signature?.email,
      signature_image: template?.signature?.signature_image,
      front_layers: JSON.stringify(front_layers),
      back_layers: JSON.stringify(back_layers),
      signature_id: "no_signature_id",
      onLoading: () => {
        this.setState({ saving: true });
      },
      onError: () => {
        this.setState({ saving: false });
      },
      onSuccess: results => {
        this.setState({ saving: false });
        if (this.props.onSuccess) {
          this.props.onSuccess(results);
        }
        this.props.popSidePanel();
      }
    });
  }

  onDeleteSuccess() {
    if (this.props.onDeleteSuccess) {
      this.props.onDeleteSuccess(this.props.mailer?.id);
    }
  }

  moveLayerAbove({ index }) {
    const { front_layers, back_layers, side_tab } = this.state;
    const reordered_layers =
      side_tab == "front"
        ? Array.from(front_layers).reverse()
        : Array.from(back_layers).reverse();
    if (index - 1 >= 0) {
      const [removed] = reordered_layers.splice(index, 1);
      reordered_layers.splice(index - 1, 0, removed);

      this.setState({
        front_layers:
          side_tab == "front" ? reordered_layers.reverse() : front_layers,
        back_layers:
          side_tab == "back" ? reordered_layers.reverse() : back_layers
      });
    }
  }

  moveLayerBelow({ index }) {
    const { front_layers, back_layers, side_tab } = this.state;
    const reordered_layers =
      side_tab == "front"
        ? Array.from(front_layers).reverse()
        : Array.from(back_layers).reverse();

    if (index + 1 <= reordered_layers.length) {
      const [removed] = reordered_layers.splice(index, 1);
      reordered_layers.splice(index + 1, 0, removed);

      this.setState({
        front_layers:
          side_tab == "front" ? reordered_layers.reverse() : front_layers,
        back_layers:
          side_tab == "back" ? reordered_layers.reverse() : back_layers
      });
    }
  }

  render() {
    const { isMobile, colors, device } = this.props;
    let blayer = [];
    const {
      template,
      template_id,
      side_tab,
      front_layers,
      back_layers,
      selected_layer,
      card_width,
      card_height,
      scale,
      card_width_back,
      card_height_back,
      show_design_templates,
      select_signature,
      bracket_variables,
      grid,
      show_text_safe,
      mobile_tab
    } = this.state;
    if (back_layers === null) {
      blayer = [];
    } else {
      blayer = back_layers;
    }

    return (
      <FocusModal
        title="Postcard Designer"
        onClose={() => {
          this.props.popSidePanel();
        }}
        show_side_bar={show_design_templates ? false : true}
        renderSideBar={() => {
          if (select_signature) {
            return (
              <Signature
                signature={template?.signature}
                toggleSignatures={this.toggleSignatures}
                updateSignature={this.updateSignature}
                updateFullSignature={this.updateFullSignature}
                template_type={template.template_type}
              />
            );
          }
          return (
            <>
              {isMobile && !selected_layer ? (
                <Navbar
                  colors={colors}
                  device={device}
                  isMobile={isMobile}
                  platform={this.props.platform}
                  isIphoneX={this.props.isIphoneX}
                  token={this.props.token}
                  updateTemplate={this.updateTemplate}
                  showSuccess={this.props.showSuccess}
                  showErrorMessage={this.props.showErrorMessage}
                  front_layers={front_layers}
                  back_layers={blayer}
                  template={template}
                  template_id={template_id}
                  importCode={this.importCode}
                  toggleModal={this.props.toggleModal}
                  setModal={this.props.setModal}
                  checkIfNeedsToSave={this.checkIfNeedsToSave}
                  toggleSignatures={this.toggleSignatures}
                  show_design_templates={show_design_templates}
                  toggleDesignTemplates={this.toggleDesignTemplates}
                  saveMailerTemplate={this.saveMailerTemplate}
                  updateMailerTemplate={this.props.updateMailerTemplate}
                  mobile_tab={mobile_tab}
                  toggleMobileTab={this.toggleMobileTab}
                  saving={this.state.saving}
                  popSidePanel={this.props.popSidePanel}
                  pushSidePanel={this.props.pushSidePanel}
                  replaceSidePanel={this.props.replaceSidePanel}
                  onDeleteSuccess={this.onDeleteSuccess}
                  source_of_truth={this.props.source_of_truth}
                  onSuccess={this.props.onSuccess}
                  hide_options={this.props.hide_options}
                />
              ) : null}

              <Sidebar
                {...this.props}
                template={template}
                template_type={template.template_type}
                side_tab={side_tab}
                front_layers={front_layers}
                back_layers={blayer}
                selected_layer={selected_layer}
                bracket_variables={bracket_variables}
                card_width={card_width}
                card_height={card_height}
                card_width_back={card_width_back}
                card_height_back={card_height_back}
                toggleSide={this.toggleSide}
                selectLayer={this.selectLayer}
                updateLayer={this.updateLayer}
                removeLayer={this.removeLayer}
                createNewLayer={this.createNewLayer}
                toggleAdvancedEditingMode={this.toggleAdvancedEditingMode}
                advanced_editing_mode={this.state.advanced_editing_mode}
                toggleSignatures={this.toggleSignatures}
                moveLayerAbove={this.moveLayerAbove}
                moveLayerBelow={this.moveLayerBelow}
              />
            </>
          );
        }}
        renderMainView={() => {
          if (show_design_templates) {
            return (
              <SelectDesign
                template_type={template?.template_type}
                selectDesignTemplate={this.selectDesignTemplate}
                toggleSignatures={this.toggleSignatures}
              />
            );
          }
          return (
            <>
              {!isMobile ? (
                <>
                  <Navbar
                    colors={colors}
                    device={device}
                    isMobile={isMobile}
                    platform={this.props.platform}
                    isIphoneX={this.props.isIphoneX}
                    token={this.props.token}
                    updateTemplate={this.updateTemplate}
                    showSuccess={this.props.showSuccess}
                    showErrorMessage={this.props.showErrorMessage}
                    front_layers={front_layers}
                    back_layers={blayer}
                    template={template}
                    template_id={template_id}
                    importCode={this.importCode}
                    toggleModal={this.props.toggleModal}
                    setModal={this.props.setModal}
                    checkIfNeedsToSave={this.checkIfNeedsToSave}
                    toggleSignatures={this.toggleSignatures}
                    show_design_templates={show_design_templates}
                    toggleDesignTemplates={this.toggleDesignTemplates}
                    saveMailerTemplate={this.saveMailerTemplate}
                    updateMailerTemplate={this.props.updateMailerTemplate}
                    mobile_tab={mobile_tab}
                    toggleMobileTab={this.toggleMobileTab}
                    saving={this.state.saving}
                    popSidePanel={this.props.popSidePanel}
                    pushSidePanel={this.props.pushSidePanel}
                    replaceSidePanel={this.props.replaceSidePanel}
                    onDeleteSuccess={this.onDeleteSuccess}
                    source_of_truth={this.props.source_of_truth}
                    onSuccess={this.props.onSuccess}
                    hide_options={this.props.hide_options}
                  />

                  <MainBody
                    {...this.props}
                    template={template}
                    template_type={template.template_type}
                    isMobile={isMobile}
                    front_elements={front_layers}
                    back_elements={blayer}
                    selected_element={selected_layer}
                    side_tab={side_tab}
                    toggleSide={this.toggleSide}
                    card_width={card_width}
                    card_height={card_height}
                    card_width_back={card_width_back}
                    card_height_back={card_height_back}
                    scale={scale}
                    onZoom={this.onZoom}
                    updateLayer={this.updateLayer}
                    bracket_variables={bracket_variables}
                    grid={grid}
                    toggleGrid={this.toggleGrid}
                    show_text_safe={show_text_safe}
                    toggleTextSafe={this.toggleTextSafe}
                    toggleAdvancedEditingMode={this.toggleAdvancedEditingMode}
                    advanced_editing_mode={this.state.advanced_editing_mode}
                  />
                </>
              ) : null}
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer, billing }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX, window_width, window_height } =
    native;
  const { colors } = settings;
  const { template_type_info } = mailer;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    template_type_info,
    source_of_truth,
    window_width,
    window_height
  };
};

export default connect(mapStateToProps, {
  uploadFile,
  setModal,
  toggleModal,
  showSuccess,
  showErrorMessage,
  updateMailerTemplate,
  getMailerDesignTemplates,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  updateUserSettings
})(MailerDesigner);

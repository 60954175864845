import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Copy,
  Title,
  Bold,
  Row,
  Wrapper,
  KeyboardView,
  ProfilePic,
  AlmaOrb,
  Icon
} from "app/NativeComponents/common";
import {
  CircleButton,
  IconButton,
  InlineButton,
  InputBox,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  formatUsPhone,
  renderDate,
  popSidePanel,
  pushSidePanel,
  phoneCall,
  pauseAudio,
  getPhoneCall,
  setActiveDialerContact,
  playAudio,
  killAudio
} from "app/NativeActions";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

class CallLog extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      loading_call: false,
      call: panel_data.call,
      playing_audio: false,
      edit_note: false,
      edit_note_text: null
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.props.getPhoneCall({
      token: this.props.token,
      call_id: this.state.call?.call_id,
      type: "get_phone_call",
      onLoading: () => {
        this.setState({
          loading_call: true
        });
      },
      onError: () => {
        this.setState({
          loading_call: false
        });
      },
      onSuccess: results => {
        if (results?.phone_call) {
          this.setState({
            loading_call: false,
            call: results?.phone_call
          });
        } else {
          this.setState({
            loading_call: false
          });
        }
      }
    });
  }

  componentWillUnmount() {
    killAudio({ audio: this._audio, use_track: true });
  }

  render() {
    const { colors } = this.props;

    const { call } = this.state;
    const property = call?.property;

    const current_conversation = call?.transcription;

    //check if there are any messages in the conversation

    let conversation_has_content = false;
    if (
      current_conversation &&
      call?.call_format !== "sms" &&
      current_conversation?.length > 0
    ) {
      for (let i = 0; i < current_conversation?.length; i++) {
        if (!!current_conversation[i].message) {
          conversation_has_content = true;
          break;
        }
      }
    }

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={
            call?.call_format === "sms"
              ? call?.call_type === "outgoing"
                ? "Outgoing SMS"
                : "Incoming SMS"
              : call?.call_type === "outgoing"
              ? "Outgoing Call"
              : "Incoming Call"
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <SelectItem
            noPress={true}
            select_type={"icon"}
            select_icon={
              call?.call_format === "sms"
                ? "sms"
                : call?.call_type === "outgoing"
                ? "call-made"
                : "call-received"
            }
            renderRight={() => {
              return (
                <>
                  {(this.props.user?.team_owner == 1 ||
                    this.props.user?.can_send_message == 1) &&
                  this.props.device !== "mobile" ? (
                    <CircleButton
                      icon={"phone"}
                      tooltip={"Call Again"}
                      tooltipPlacement={"top"}
                      onPress={() => {
                        this.props.setActiveDialerContact({
                          contact: {
                            ...call.contact,
                            selected_phone: {
                              number: call.to_phone_number,
                              type: call.phone_type,
                              do_not_call_flag: call.do_not_call_flag,
                              owner: call.phone_owner
                            },
                            associated_lead: call.property,
                            follow_up_queue: true
                          }
                        });
                      }}
                    />
                  ) : null}
                </>
              );
            }}
            renderContent={() => {
              return (
                <Wrapper style={{ flex: 1 }}>
                  <Row>
                    <Wrapper style={{ marginRight: 10 }}>
                      <Row>
                        <Copy style={{ marginBottom: 5, marginRight: 10 }}>
                          <Bold>
                            {call?.contact
                              ? call?.contact?.full_name
                              : "Unknown Contact"}
                          </Bold>
                        </Copy>

                        <Copy
                          style={{ color: colors.active_color, fontSize: 12 }}
                        >
                          {call?.skipped ? "Skipped" : call?.call_results_label}
                        </Copy>
                      </Row>

                      <Row>
                        <Copy>{formatUsPhone(call?.to_phone_number)}</Copy>
                      </Row>
                    </Wrapper>
                  </Row>

                  {call?.call_format === "sms" ? (
                    <>
                      {call?.call_type === "outgoing" ? (
                        <>
                          <Copy
                            style={{
                              fontSize: 12,
                              color: colors.light_text_color
                            }}
                          >
                            SMS by{" "}
                            {call.user_firstname + " " + call.user_lastname} on{" "}
                            {renderDate(call.date_created)}
                          </Copy>
                        </>
                      ) : (
                        <>
                          <Copy
                            style={{
                              fontSize: 12,
                              color: colors.light_text_color
                            }}
                          >
                            SMS forwarded to{" "}
                            {call.user_firstname + " " + call.user_lastname} on{" "}
                            {renderDate(call.date_created)}
                          </Copy>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {call?.call_type === "outgoing" ? (
                        <>
                          {!!call?.from_phone_number ? (
                            <Copy
                              style={{
                                fontSize: 12,
                                color: colors.light_text_color
                              }}
                            >
                              Called by{" "}
                              {call.user_firstname + " " + call.user_lastname}{" "}
                              from {formatUsPhone(call?.from_phone_number)} on{" "}
                              {renderDate(call.date_created)}.
                            </Copy>
                          ) : (
                            <Copy
                              style={{
                                fontSize: 12,
                                color: colors.light_text_color
                              }}
                            >
                              Called by{" "}
                              {call.user_firstname + " " + call.user_lastname}{" "}
                              on {renderDate(call.date_created)}.
                            </Copy>
                          )}

                          {!!call.call_duration ? (
                            <Copy
                              style={{
                                fontSize: 12,
                                color: colors.light_text_color
                              }}
                            >
                              Total duration: {call.call_duration}
                            </Copy>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <Copy
                            style={{
                              fontSize: 12,
                              color: colors.light_text_color
                            }}
                          >
                            Call forwarded to{" "}
                            {call.user_firstname + " " + call.user_lastname} on{" "}
                            {renderDate(call.date_created)}
                          </Copy>
                          {!!call.call_duration ? (
                            <Copy
                              style={{
                                fontSize: 12,
                                color: colors.light_text_color
                              }}
                            >
                              Total duration: {call.call_duration}
                            </Copy>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </Wrapper>
              );
            }}
          />
          {property ? (
            <LeadItem
              small_mode={true}
              property={property}
              dialer_mode={false}
              no_value={true}
              label={"Called about:"}
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "property",
                  focus_side_panel: true,
                  overlay: true,
                  id: property?.property_id,
                  data: {
                    property,
                    expanded: true
                  }
                });
              }}
            />
          ) : null}

          {!!call?.note ? (
            <Wrapper style={{ position: "relative" }}>
              {this.state.edit_note ? (
                <>
                  <Row style={{ justifyContent: "space-between" }}>
                    <SmallLabel>
                      {call?.call_format === "sms"
                        ? "Edit message summary:"
                        : "Edit call summary:"}
                    </SmallLabel>

                    <Row>
                      <InlineButton
                        icon={"check"}
                        disabled={
                          !this.state.edit_note_text ||
                          this.state.edit_note_text == call.note
                        }
                        onPress={() => {
                          const new_note = this.state.edit_note_text;
                          this.props.phoneCall({
                            token: this.props.token,
                            type: "update_call_note",
                            edited_note: new_note,
                            call_id: call?.call_id,
                            onLoading: () => {
                              this.setState({
                                edit_note: false,
                                edit_note_text: null,
                                call: {
                                  ...call,
                                  note: new_note
                                }
                              });
                            },
                            onSuccess: () => {
                              const panel_data =
                                this.props.device == "mobile"
                                  ? this.props?.route?.params?.panel_data
                                  : this.props.panel_data;

                              if (panel_data.onNoteUpdate) {
                                panel_data.onNoteUpdate(new_note);
                              }
                            }
                          });
                        }}
                      >
                        Save Note
                      </InlineButton>

                      <IconButton
                        icon={"close"}
                        tooltip={"Cancel"}
                        tooltipPlacement={"top"}
                        onPress={() => {
                          this.setState({
                            edit_note: false,
                            edit_note_text: null
                          });
                        }}
                      />
                    </Row>
                  </Row>

                  <InputBox
                    autoFocus={true}
                    numberOfLines={10}
                    name="edit_note"
                    disabled={false}
                    returnKeyType="done"
                    placeholder={
                      !!this.state.edit_note_text
                        ? ""
                        : call?.call_format === "sms"
                        ? "Enter your message summary here."
                        : "Enter your call summary here."
                    }
                    background_color={"transparent"}
                    onFocus={() => {}}
                    onChange={value => {
                      this.setState({
                        edit_note_text: value
                      });
                    }}
                    onSubmitEditing={() => {}}
                    blurOnSubmit={true}
                    value={this.state.edit_note_text}
                    innerStyle={{ padding: 25, paddingTop: 0, margin: 0 }}
                    type="multiline"
                    autogrow={true}
                  />
                </>
              ) : (
                <>
                  <Row style={{ justifyContent: "space-between" }}>
                    <SmallLabel>
                      {call?.call_format === "sms"
                        ? "Message summary:"
                        : "Call summary:"}
                    </SmallLabel>
                    <InlineButton
                      icon={"edit-note"}
                      onPress={() => {
                        this.setState({
                          edit_note: true,
                          edit_note_text: call.note
                        });
                      }}
                    >
                      Edit Note
                    </InlineButton>
                  </Row>
                  <Wrapper style={{ padding: 25, paddingTop: 0 }}>
                    <Copy>{call.note}</Copy>
                  </Wrapper>
                </>
              )}
            </Wrapper>
          ) : null}

          {call?.call_format === "sms" ? (
            <>
              <SmallLabel>Message:</SmallLabel>
              <Row
                style={{
                  justifyContent:
                    call?.call_type !== "outgoing" ? "flex-start" : "flex-end"
                }}
              >
                {call?.call_type === "incoming" ? (
                  <ProfilePic
                    size={30}
                    firstname={call?.contact?.given_name}
                    lastname={call?.contact?.surname}
                    email={call?.contact?.email_address_1}
                    image={null}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                ) : null}

                <Wrapper
                  style={{
                    backgroundColor:
                      call?.call_type === "incoming"
                        ? colors.background_color
                        : colors.active_color_muted,
                    padding: 15,
                    paddingRight: 15,
                    paddingLeft: 15,
                    borderRadius: 5,
                    borderBottomRightRadius:
                      call?.call_type !== "outgoing" ? 5 : 0,
                    borderBottomLeftRadius:
                      call?.call_type !== "outgoing" ? 0 : 5,
                    margin: 5,
                    marginRight: call?.call_type !== "outgoing" ? 40 : 0,
                    marginLeft: call?.call_type === "outgoing" ? 40 : 0
                  }}
                >
                  <Copy>{call.transcription}</Copy>
                </Wrapper>

                {call?.call_type === "outgoing" ? (
                  <ProfilePic
                    size={30}
                    firstname={call?.user_firstname}
                    lastname={call?.user_lastname}
                    email={call?.user_email}
                    image={call?.user_image}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                ) : null}
              </Row>
            </>
          ) : conversation_has_content &&
            current_conversation &&
            current_conversation?.length > 0 ? (
            <>
              <SmallLabel>Conversation:</SmallLabel>

              {!!current_conversation && current_conversation?.length > 0
                ? current_conversation.map((message, i) => {
                    if (!!message.message) {
                      return (
                        <Row
                          key={"conversation_" + i}
                          style={{
                            justifyContent:
                              message?.type !== "inbound"
                                ? "flex-start"
                                : "flex-end",
                            marginRight: message?.type !== "inbound" ? 5 : 0,
                            marginLeft: message?.type === "inbound" ? 5 : 0
                          }}
                        >
                          {message?.type === "outbound" ? (
                            <ProfilePic
                              size={30}
                              firstname={call?.contact?.given_name}
                              lastname={call?.contact?.surname}
                              email={call?.contact?.email_address_1}
                              image={null}
                              style={{ marginRight: 5, marginLeft: 5 }}
                            />
                          ) : null}

                          <Wrapper
                            style={{
                              position: "relative",
                              backgroundColor:
                                message?.type === "outbound"
                                  ? colors.background_color
                                  : colors.active_color_muted,
                              padding: 15,
                              paddingRight:
                                message?.subtype === "dropped_voicemail"
                                  ? 55
                                  : 15,
                              paddingLeft: 15,
                              borderRadius: 5,
                              paddingTop: 15,
                              borderBottomRightRadius:
                                message?.type !== "inbound" ? 5 : 0,
                              borderBottomLeftRadius:
                                message?.type !== "inbound" ? 0 : 5,
                              margin: 5,
                              marginRight: message?.type !== "inbound" ? 40 : 0,
                              marginLeft: message?.type === "inbound" ? 40 : 0
                            }}
                          >
                            {message?.subtype === "dropped_voicemail" ? (
                              <Wrapper
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0
                                }}
                              >
                                <IconButton
                                  icon={
                                    this.state.playing_audio
                                      ? "pause"
                                      : "play-arrow"
                                  }
                                  tooltip={
                                    this.state.playing_audio
                                      ? "Pause Recording"
                                      : "Play Recording"
                                  }
                                  tooltipPlacement={"top"}
                                  onPress={() => {
                                    //play message.url an mp3 file

                                    if (this.state.playing_audio) {
                                      this.setState(
                                        {
                                          playing_audio:
                                            !this.state.playing_audio
                                        },
                                        () => {
                                          pauseAudio({
                                            audio: this._audio,
                                            use_track: true
                                          });
                                        }
                                      );
                                    } else {
                                      this.setState(
                                        {
                                          playing_audio:
                                            !this.state.playing_audio
                                        },
                                        () => {
                                          playAudio({
                                            url_file: message.url,
                                            onEnd: () => {
                                              this.setState({
                                                playing_audio: false
                                              });
                                            },
                                            onPrepared: audio => {
                                              this._audio = audio;
                                            }
                                          });
                                        }
                                      );
                                    }
                                  }}
                                />
                              </Wrapper>
                            ) : null}

                            {message?.subtype === "dropped_voicemail" ? (
                              <Row>
                                <Wrapper style={{ flex: 1 }}>
                                  <Copy style={{ fontSize: 12 }}>
                                    <Bold>Dropped voicemail:</Bold>
                                  </Copy>
                                </Wrapper>
                              </Row>
                            ) : null}

                            <Copy>{message.message}</Copy>

                            <Copy></Copy>
                          </Wrapper>

                          {message?.type === "inbound" ? (
                            <ProfilePic
                              size={30}
                              firstname={call?.user_firstname}
                              lastname={call?.user_lastname}
                              email={call?.user_email}
                              image={call?.user_image}
                              style={{ marginRight: 5, marginLeft: 5 }}
                            />
                          ) : null}
                        </Row>
                      );
                    }
                    return null;
                  })
                : null}
            </>
          ) : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getPhoneCall,
  phoneCall,
  setActiveDialerContact
})(CallLog);

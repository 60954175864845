import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Row,
  Button,
  Bold,
  Spin
} from "app/NativeComponents/common";
import { GhostButton, UpsellButton } from "app/NativeComponents/snippets";
import { checkIfUserHasMetadata, pushSidePanel } from "app/NativeActions";
class UnlockButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { property, phone, contact, row_height, toggle_lead_images, user } =
      this.props;
    const { loading } = this.state;

    return checkIfUserHasMetadata("free_plan") &&
      user?.team_clearance_level > 0 &&
      !property?.deal?.updating?.is_updating ? (
      <Wrapper
        className="deal-preview"
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          width: toggle_lead_images ? "100%" : "auto",
          margin: toggle_lead_images ? 0 : 10,
          height: toggle_lead_images ? "100%" : row_height,
          alignItems: "flex-end",
          justifyContent: "center"
        }}
      >
        <GhostButton
          icon={"visibility"}
          loading={loading}
          onPress={() => {
            this.props.pushSidePanel({
              slug: "purchase_plan_prompt",
              overlay_modal: true,
              locked: true,
              data: {
                title: "Get Unlimited Phone Numbers With DealMachine Pro.",
                dismiss_button:
                  "No thanks. Let me continue without phone numbers.",
                no_free: true
              },
              locked: true
            });
          }}
          primary={true}
          style={{
            margin: 0
          }}
          innerStyle={{
            padding: 5,
            paddingRight: 10,
            paddingLeft: 10
          }}
        >
          Reveal
        </GhostButton>
      </Wrapper>
    ) : null;
  }
}

const mapStateToProps = ({ auth, settings, filter, dialer }) => {
  const { token, user } = auth;
  const { dark_mode } = settings;
  const { toggle_lead_images } = filter;
  const { current_call_session } = dialer;
  return {
    token,
    user,
    dark_mode,
    toggle_lead_images,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  pushSidePanel
})(UnlockButton);

import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar,
  Scroll
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class Liens extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, refresh: false, offset: 0 };

    this.renderLien = this.renderLien.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState({ refresh: true }, () => {
        this.setState({ refresh: false });
      });
    }
  }

  renderLien(lien) {
    const { search } = this.props;

    let lien_amount = 0;
    if (!!lien?.amount_due && lien?.amount_due > 0) {
      lien_amount = lien?.amount_due;
    } else if (!!lien?.previous_amount_due && lien?.previous_amount_due > 0) {
      lien_amount = lien?.previous_amount_due;
    }

    let party_1_names = "";
    let party_2_names = "";
    let party_3_names = "";
    let party_4_names = "";

    if (!!lien?.Party1_Name1_FullName) {
      party_1_names += lien?.Party1_Name1_FullName;
    }
    if (!!lien?.Party1_Name2_FullName) {
      party_1_names += ", " + lien?.Party1_Name2_FullName;
    }
    if (!!lien?.Party1_Name3_FullName) {
      party_1_names += ", " + lien?.Party1_Name3_FullName;
    }
    if (!!lien?.Party1_Name4_FullName) {
      party_1_names += ", " + lien?.Party1_Name4_FullName;
    }

    if (!!lien?.Party2_Name1_FullName) {
      party_2_names += lien?.Party2_Name1_FullName;
    }
    if (!!lien?.Party2_Name2_FullName) {
      party_2_names += ", " + lien?.Party2_Name2_FullName;
    }
    if (!!lien?.Party2_Name3_FullName) {
      party_2_names += ", " + lien?.Party2_Name3_FullName;
    }
    if (!!lien?.Party2_Name4_FullName) {
      party_2_names += ", " + lien?.Party2_Name4_FullName;
    }

    if (!!lien?.Party3_Name1_FullName) {
      party_3_names += lien?.Party3_Name1_FullName;
    }
    if (!!lien?.Party3_Name2_FullName) {
      party_3_names += ", " + lien?.Party3_Name2_FullName;
    }
    if (!!lien?.Party3_Name3_FullName) {
      party_3_names += ", " + lien?.Party3_Name3_FullName;
    }
    if (!!lien?.Party3_Name4_FullName) {
      party_3_names += ", " + lien?.Party3_Name4_FullName;
    }

    if (!!lien?.Party4_Name1_FullName) {
      party_4_names += lien?.Party4_Name1_FullName;
    }
    if (!!lien?.Party4_Name2_FullName) {
      party_4_names += ", " + lien?.Party4_Name2_FullName;
    }
    if (!!lien?.Party4_Name3_FullName) {
      party_4_names += ", " + lien?.Party4_Name3_FullName;
    }
    if (!!lien?.Party4_Name4_FullName) {
      party_4_names += ", " + lien?.Party4_Name4_FullName;
    }

    return (
      <Card label={lien?.label} style={{ paddingTop: 10, flex: 1 }}>
        <Row
          style={
            this.props.expanded
              ? {
                  flexDirection: "row",
                  alignItems: "stretch",
                  flexWrap: "wrap"
                }
              : { flexDirection: "column", alignItems: "stretch" }
          }
        >
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={search}
            item={lien?.doc_category}
            label={"Document type:"}
            format={"text"}
            hiddenFromNonSubs={true}
            helper={"lien_doc_category"}
          />
          {!!lien?.date_of_death || !!lien?.previous_date_of_death ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={
                  !!lien?.date_of_death
                    ? lien?.date_of_death
                    : lien?.previous_date_of_death
                }
                label={"Date of death:"}
                format={"date"}
                hiddenFromNonSubs={true}
                helper={"date_of_death"}
              />
              {!!lien_amount ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={lien_amount}
                  label={"Lien amount:"}
                  format={"money"}
                  hiddenFromNonSubs={true}
                  helper={"lien_amount"}
                />
              ) : null}
            </>
          ) : !!lien?.date_of_divorce || !!lien?.previous_date_of_divorce ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={
                  !!lien?.date_of_divorce
                    ? lien?.date_of_divorce
                    : lien?.previous_date_of_divorce
                }
                label={"Date of divorce:"}
                format={"date"}
                hiddenFromNonSubs={true}
                helper={"date_of_divorce"}
              />
              {!!lien_amount ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={lien_amount}
                  label={"Lien amount:"}
                  format={"money"}
                  hiddenFromNonSubs={true}
                  helper={"lien_amount"}
                />
              ) : null}
            </>
          ) : !!lien_amount ? (
            <InformationItem
              style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
              search={search}
              item={lien_amount}
              label={"Lien amount:"}
              format={"money"}
              hiddenFromNonSubs={true}
              helper={"lien_amount"}
            />
          ) : null}

          {this.state.more_info || !!search ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={lien?.doc_title}
                label={"Document title:"}
                format={"text"}
                hiddenFromNonSubs={true}
                helper={"lien_doc_title"}
              />

              {!!lien?.lien_number ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={lien?.lien_number}
                  label={"Document number:"}
                  format={"number"}
                  hiddenFromNonSubs={true}
                  helper={"lien_document_number"}
                />
              ) : null}
              {!!lien?.filing_date ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={
                    !!lien?.filing_date
                      ? lien?.filing_date
                      : lien?.previous_filing_date
                  }
                  label={"Filing date:"}
                  format={"date"}
                  hiddenFromNonSubs={true}
                  helper={"lien_filing_date"}
                />
              ) : null}

              {!!party_1_names ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={party_1_names}
                  label={lien?.DocTypeInfo?.Roll1 || "Roll 1:"}
                  format={"text"}
                  hiddenFromNonSubs={true}
                />
              ) : null}

              {!!party_2_names ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={party_2_names}
                  label={lien?.DocTypeInfo?.Roll2 || "Roll 2:"}
                  format={"text"}
                  hiddenFromNonSubs={true}
                />
              ) : null}

              {!!party_3_names ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={party_3_names}
                  label={lien?.DocTypeInfo?.Roll3 || "Roll 3:"}
                  format={"text"}
                  hiddenFromNonSubs={true}
                />
              ) : null}

              {!!party_4_names ? (
                <InformationItem
                  style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                  search={search}
                  item={party_4_names}
                  label={lien?.DocTypeInfo?.Roll4 || "Roll 4:"}
                  format={"text"}
                  hiddenFromNonSubs={true}
                />
              ) : null}
            </>
          ) : null}
        </Row>

        {!search && this.state.more_info ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: false
                },
                () => {
                  if (this.props.device === "desktop") {
                    this.props.scrollToSection({
                      id: "lien-information",
                      offset: this.state.offset
                    });
                  } else {
                    this._interval = setTimeout(() => {
                      this.props.scrollToSection({
                        id: "lien-information",
                        offset: this.state.offset
                      });
                    }, 50);
                  }
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-up"}
          >
            Less Info
          </InlineButton>
        ) : !search ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: true
                },
                () => {
                  this.props.scrollToSection({
                    id: "lien-information",
                    offset: this.state.offset
                  });
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-down"}
          >
            More Info
          </InlineButton>
        ) : null}
      </Card>
    );
  }

  render() {
    const { property, search, colors } = this.props;
    const liens = property?.lien_data;

    if (!this.state.refresh && liens && liens.length > 0) {
      return (
        <>
          {"Liens".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          !search ? (
            <Wrapper
              id={"lien-information"}
              style={{ marginTop: 10 }}
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;
                this.setState({
                  offset: y
                });
              }}
            >
              {liens.length == 1 ? (
                <>{this.renderLien(liens[0])}</>
              ) : liens.length > 0 ? (
                <>
                  {this.props.expanded ? (
                    <>
                      <Scroll horizontal={true} style={{ width: 650 }}>
                        {liens.map((lien, index) => {
                          return (
                            <Wrapper
                              key={"mort_" + 1}
                              style={{
                                minWidth: 450,
                                maxWidth: 450,
                                width: "100%"
                              }}
                            >
                              {this.renderLien(lien)}
                            </Wrapper>
                          );
                        })}
                      </Scroll>
                    </>
                  ) : (
                    <Wrapper
                      style={
                        this.props.device === "mobile"
                          ? { marginTop: 10 }
                          : { marginBottom: 40, marginTop: 10 }
                      }
                    >
                      <Carousel
                        items={liens}
                        centerMode={true}
                        infinite={true}
                        dots={this.props.device === "mobile"}
                        renderItem={obj => {
                          const lien = obj?.item;
                          return (
                            <Wrapper
                              style={{ marginRight: 10, marginLeft: 10 }}
                            >
                              {this.renderLien(lien)}
                            </Wrapper>
                          );
                        }}
                        include_pagination={true}
                        dotColor={colors.text_color}
                      />
                    </Wrapper>
                  )}
                </>
              ) : null}
            </Wrapper>
          ) : null}
        </>
      );
    }
    return null;
  }
}

export default Liens;

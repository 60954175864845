import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Wrapper,
  Card,
  KeyboardView,
  Copy,
  Bold,
  Row,
  ProgressBar,
  Container
} from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  BottomNavBar,
  InlineButton,
  SelectItem,
  SearchBar,
  InlineTabs,
  UpsellButton,
  SmallLabel
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  setExportModal,
  popSidePanel,
  popAllSidePanels,
  updateLead,
  dismissMobileKeyboard,
  numberWithCommas,
  getSourceOfTruth,
  pushSidePanel,
  renderDate,
  getExportActualCount,
  updateUserSettings,
  checkIfUserHasMetadata
} from "app/NativeActions";
import ListView from "./ListView";

class ExportLeads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      list_columns: props.list_settings
        ? props.list_settings.user_list_columns
          ? props.list_settings.user_list_columns.filter(
              ({ main_column }) => main_column !== true
            )
          : []
        : [],
      selected_columns: props.export_modal
        ? props.export_modal?.selected_columns
        : [],
      selected_leads: props.export_modal
        ? props.export_modal.selected_leads
        : [],
      page: "export_lists",
      column_search: "",
      emails: props.user.email,
      include_all_columns: true,
      export_type: !checkIfUserHasMetadata("people_data")
        ? "properties"
        : !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.export_type
        : "contacts",
      include_likely_owners: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.include_likely_owners
        : true,
      include_family: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.include_family
        : true,
      include_likely_renters: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.include_likely_renters
        : false,
      include_potential_property_owners: !!props.user?.user_settings
        ?.export_settings
        ? props.user?.user_settings?.export_settings
            ?.include_potential_property_owners
        : false,
      export_actual_count: 0,
      export_actual_count_loading: true,
      scrub_dnc: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.scrub_dnc
        : false,
      scrub_landline: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.scrub_landline
        : false,
      scrub_wireless: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.scrub_wireless
        : false,
      deduplicate: !!props.user?.user_settings?.export_settings
        ? props.user?.user_settings?.export_settings?.deduplicate
        : true
    };

    this._emails = React.createRef();

    this.executeExport = this.executeExport.bind(this);
  }

  componentDidMount() {
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });

    this.props.getExportActualCount({
      token: this.props.token,
      select_all: this.props.export_modal?.select_all,
      total_count: this.props.export_modal?.total_count,
      new_filters: this.props.export_modal?.new_filters,
      filters: this.props.export_modal?.filters,
      property_flags: this.props.export_modal?.property_flags,
      property_flags_and_or: this.props.export_modal?.property_flags_and_or,
      using_old_filters: this.props.export_modal?.using_old_filters,
      search: this.props.export_modal?.search,
      search_type: this.props.export_modal?.search_type,
      list_id: this.props.export_modal?.list_id,
      list_history_id: this.props.export_modal?.list_history_id,
      deal_ids: this.props.export_modal?.deal_ids,
      onError: () => {
        this.setState({
          export_actual_count_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          export_actual_count: results.total_count_not_yet_exported,
          export_actual_count_loading: false
        });
      }
    });
  }

  checkListItem(item) {
    if (item.main_column !== true) {
      this.setState({
        list_columns: this.state.list_columns.map(column => {
          if (column.slug === item.slug) {
            return {
              ...column,
              show: !column.show
            };
          }
          return column;
        })
      });
    }
  }

  updateListItemFormat(item, format) {
    if (item.main_column !== true) {
      this.setState({
        list_columns: this.state.list_columns.map(column => {
          if (column.slug === item.slug) {
            return {
              ...column,
              column_format: format
            };
          }
          return column;
        })
      });
    }
  }

  reorderItems({ previousIndex, nextIndex }) {
    const { list_columns } = this.state;
    const reordered_items = JSON.parse(JSON.stringify(list_columns));

    const [removed] = reordered_items.splice(previousIndex, 1);
    reordered_items.splice(nextIndex, 0, removed);

    this.setState({
      list_columns: reordered_items
    });
  }

  toggleCreateList(page) {
    this.setState({
      page
    });
  }

  confirmUpdate() {
    dismissMobileKeyboard();

    const { source_of_truth, all_feature_metadata } = this.props;

    let count = parseInt(
      source_of_truth?.current_limits?.number_of_leads_exported?.amount
    );

    let total_to_export = parseInt(this.state.export_modal?.total_count);
    if (parseInt(this.state.export_actual_count) > 0) {
      total_to_export = parseInt(this.state.export_actual_count);
    }

    if (
      source_of_truth?.subscription?.metadata?.export_limit !== "unlimited" &&
      count + total_to_export >
        source_of_truth?.subscription?.metadata?.export_limit
    ) {
      let feature_upsell_info = null;

      for (let i = 0; i < all_feature_metadata?.length; i++) {
        if (
          all_feature_metadata[i].slug == "export_limit" &&
          all_feature_metadata[i].unlock_limit <= count + total_to_export &&
          (all_feature_metadata[i].unlock_limit >
            feature_upsell_info?.unlock_limit ||
            feature_upsell_info == null)
        ) {
          feature_upsell_info = all_feature_metadata[i];
        }
      }

      this.props.pushSidePanel({
        slug: "unlock_feature_modal",
        overlay: true,
        data: {
          feature_upsell_info,
          renderContent: () => {
            return (
              <Wrapper style={{ padding: 25 }}>
                <Copy>
                  You have exported <Bold>{numberWithCommas(count)} leads</Bold>{" "}
                  this billing cycle. Exporting these{" "}
                  <Bold>{numberWithCommas(total_to_export)}</Bold> leads will
                  exceed your monthly limit of{" "}
                  <Bold>
                    {numberWithCommas(
                      source_of_truth?.subscription?.metadata?.export_limit
                    )}
                  </Bold>
                  . Upgrade to continue. Your account will reset on{" "}
                  <Bold>
                    {renderDate(
                      source_of_truth?.subscription?.monthly_limit_end_date,
                      true
                    )}
                  </Bold>
                  .
                </Copy>
              </Wrapper>
            );
          },
          buttonText: "Upgrade And Export",
          confirmText: "Confirm Upgrade?",
          onSuccess: () => {
            this.executeExport();
          }
        }
      });
    } else {
      this.executeExport();
    }
  }

  executeExport() {
    if (this.props.export_modal.modalAction) {
      this.props.export_modal.modalAction({
        emails: this.state.emails,
        selected_leads: this.state.selected_leads,
        selected_columns: !this.state.include_all_columns
          ? this.state.list_columns.filter(({ show }) => show == true)
          : "",
        include_all_columns: this.state.include_all_columns,
        export_type: this.state.export_type,
        include_likely_owners: this.state.include_likely_owners,
        include_family: this.state.include_family,
        include_likely_renters: this.state.include_likely_renters,
        include_potential_property_owners:
          this.state.include_potential_property_owners,
        scrub_dnc: this.state.scrub_dnc,
        scrub_landline: this.state.scrub_landline,
        scrub_wireless: this.state.scrub_wireless,
        deduplicate: this.state.deduplicate,
        export_file_name:
          "dealmachine-" +
          this.state.export_type +
          "-" +
          moment().format("YYYY-MM-DD-HHmmss"),
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });

          this.props.updateUserSettings({
            token: this.props.token,
            type: "export_settings",
            value: {
              export_type: this.state.export_type,
              include_likely_owners: this.state.include_likely_owners,
              include_family: this.state.include_family,
              include_likely_renters: this.state.include_likely_renters,
              include_potential_property_owners:
                this.state.include_potential_property_owners,
              scrub_dnc: this.state.scrub_dnc,
              scrub_landline: this.state.scrub_landline,
              scrub_wireless: this.state.scrub_wireless,
              deduplicate: this.state.deduplicate,
              export_file_name:
                "dealmachine-" +
                this.state.export_type +
                "-" +
                moment().format("YYYY-MM-DD-HHmmss")
            }
          });

          this.props.popAllSidePanels();
        }
      });
    }
  }

  clearAll() {
    this.props.setListModal({
      ...this.props.export_modal,
      selected_lists: []
    });
  }

  handleBack() {
    dismissMobileKeyboard();

    if (this.props.export_modal) {
      if (this.props.export_modal.cancelAction) {
        this.props.export_modal.cancelAction();
      }
    }
    this.props.popSidePanel();
  }

  checkSelectedCount() {
    const { list_columns } = this.state;
    let counter = 0;
    for (let i = 0; i < list_columns.length; i++) {
      if (list_columns[i].show) {
        counter++;
      }
    }
    return counter;
  }

  render() {
    const filename_timestamp =
      "dealmachine-" +
      this.state.export_type +
      "-" +
      moment().format("YYYY-MM-DD-HH:mm:ss");
    const { colors, source_of_truth } = this.props;
    const current_limit = parseInt(
      source_of_truth?.subscription?.metadata?.export_limit
    );

    let count = parseInt(
      source_of_truth?.current_limits?.number_of_leads_exported?.amount
    );
    if (count > current_limit) {
      count = current_limit;
    }

    if (this.props.export_modal) {
      return (
        <Container>
          <NewHeader
            title={this.props.export_modal.title}
            subtitle={this.props.export_modal.description}
            leftButton={{
              icon: this.props.device === "desktop" ? "close" : "arrow-back",
              onPress: () => {
                this.handleBack();
              }
            }}
          />

          <InputBox
            input_ref={this._emails}
            autoFocus={false}
            name="emails"
            disabled={false}
            returnKeyType="done"
            placeholder={
              !!this.state.emails
                ? "Email address to send export file"
                : "Enter emails separated by a comma"
            }
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                emails: value
              });
            }}
            blurOnSubmit={true}
            value={this.state.emails}
            input_type="text"
            require_confirm={true}
            has_changed={
              !!this.state.emails &&
              (this.checkSelectedCount() > 0 ||
                this.state.include_all_columns) &&
              (this.state.export_type === "properties" ||
                this.state.include_likely_owners ||
                this.state.include_family ||
                this.state.include_likely_renters ||
                this.state.include_potential_property_owners) &&
              !this.state.export_actual_count_loading
            }
            submit_button_primary={true}
            submit_button_title={"Submit Export"}
            onSubmit={() => this.confirmUpdate()}
            loading={this.state.loading}
          />

          {source_of_truth?.subscription?.metadata &&
          source_of_truth?.subscription?.metadata?.export_limit !==
            "unlimited" ? (
            <>
              <Wrapper
                style={{
                  padding: 25
                }}
              >
                <ProgressBar
                  color={this.props.colors.success_color}
                  width={200}
                  progress={
                    parseInt(count) > 0
                      ? parseInt(count) / parseInt(current_limit)
                      : 0
                  }
                />
                <Copy style={{ fontSize: 10 }}>
                  {"You've exported " +
                    numberWithCommas(count) +
                    " out of " +
                    numberWithCommas(current_limit) +
                    " leads this month. Duplicate exports of the same lead within the billing cycle won't count towards this total. You'll be automatically upgraded if you exceed your limit. Your account will reset on " +
                    renderDate(
                      source_of_truth?.subscription?.monthly_limit_end_date,
                      true
                    ) +
                    "."}
                </Copy>
              </Wrapper>
            </>
          ) : null}

          <Wrapper>
            <SmallLabel>Select your export type:</SmallLabel>
            <Row
              style={{ paddingRight: 25, paddingLeft: 25, paddingBottom: 25 }}
            >
              <InlineButton
                icon={
                  !checkIfUserHasMetadata("people_data")
                    ? "lock"
                    : this.state.export_type === "contacts"
                    ? "radio-button-checked"
                    : "radio-button-unchecked"
                }
                onPress={() => {
                  if (
                    checkIfUserHasMetadata("free_plan") &&
                    !checkIfUserHasMetadata(" ")
                  ) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      locked: true,
                      data: {
                        title:
                          "Export Phone Numbers & Contacts With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Pro.",
                        no_free: true
                      },
                      locked: true
                    });
                  } else {
                    this.setState({ export_type: "contacts" });
                  }
                }}
                selected={this.state.export_type === "contacts"}
                style={
                  this.state.export_type === "contacts"
                    ? {
                        borderColor: colors.active_color,
                        borderWidth: 1,
                        borderStyle: "solid",
                        marginLeft: 0
                      }
                    : { marginLeft: 0 }
                }
                tooltip={
                  "Your export will include each associated contact as one line. This will not include all the information for each property."
                }
                tooltipPlacement={"top"}
              >
                Contacts
              </InlineButton>
              <InlineButton
                icon={
                  this.state.export_type === "properties"
                    ? "radio-button-checked"
                    : "radio-button-unchecked"
                }
                onPress={() => this.setState({ export_type: "properties" })}
                selected={this.state.export_type === "properties"}
                style={
                  this.state.export_type === "properties"
                    ? {
                        borderColor: colors.active_color,
                        borderWidth: 1,
                        borderStyle: "solid",
                        marginLeft: 0
                      }
                    : { marginLeft: 0 }
                }
                tooltip={
                  "Your export will include each property as one line. This will not include all the information for each contact associated with a property."
                }
                tooltipPlacement={"top"}
              >
                Properties
              </InlineButton>
            </Row>
          </Wrapper>

          {this.state.export_type == "properties" ? (
            <>
              <SelectItem
                selected={this.state.include_all_columns}
                onPress={() => {
                  this.setState({
                    include_all_columns: !this.state.include_all_columns
                  });
                }}
                select_type="toggle"
                description={"Disable in order to select custom properties."}
              >
                Include all lead properties in the export.
              </SelectItem>
              {!this.state.include_all_columns ? (
                <>
                  <KeyboardView style={{ flex: 1 }}>
                    <Wrapper
                      style={{
                        padding: 25,
                        margin: 10,
                        borderRadius: 5,
                        backgroundColor: colors.active_color_muted
                      }}
                    >
                      <Copy>
                        Select which fields you want to export and we'll send
                        you an exported file of your leads to the provied email
                        addresses.
                      </Copy>
                    </Wrapper>

                    <Row>
                      <Wrapper style={{ flex: 1 }}>
                        <SearchBar
                          style={{ margin: 10, marginRight: 0 }}
                          title="Search"
                          value={this.state.column_search}
                          onChange={value =>
                            this.setState({ column_search: value })
                          }
                        />
                      </Wrapper>
                      {this.checkSelectedCount() ==
                      this.state.list_columns.length ? (
                        <InlineButton
                          onPress={() => {
                            this.setState({
                              list_columns: this.state.list_columns.map(
                                column => {
                                  return {
                                    ...column,
                                    show: false
                                  };
                                }
                              )
                            });
                          }}
                        >
                          Clear All
                        </InlineButton>
                      ) : (
                        <InlineButton
                          onPress={() => {
                            this.setState({
                              list_columns: this.state.list_columns.map(
                                column => {
                                  return {
                                    ...column,
                                    show: true
                                  };
                                }
                              )
                            });
                          }}
                        >
                          Select All
                        </InlineButton>
                      )}
                    </Row>

                    <ListView
                      {...this.props}
                      column_search={this.state.column_search}
                      reorderItems={this.reorderItems.bind(this)}
                      list_columns={this.state.list_columns}
                      checkListItem={this.checkListItem.bind(this)}
                      updateListItemFormat={this.updateListItemFormat.bind(
                        this
                      )}
                    />
                  </KeyboardView>
                </>
              ) : null}
            </>
          ) : (
            <>
              <KeyboardView style={{ flex: 1 }}>
                <SmallLabel>Associated contacts to include:</SmallLabel>

                <Row
                  style={{
                    paddingRight: 25,
                    paddingLeft: 25,
                    paddingBottom: 25,
                    flexWrap: "wrap"
                  }}
                >
                  <InlineButton
                    icon={
                      this.state.include_likely_owners
                        ? "check-box"
                        : "check-box-outline-blank"
                    }
                    onPress={() =>
                      this.setState({
                        include_likely_owners: !this.state.include_likely_owners
                      })
                    }
                    selected={this.state.include_likely_owners}
                    style={
                      this.state.include_likely_owners
                        ? {
                            borderColor: colors.active_color,
                            borderWidth: 1,
                            borderStyle: "solid",
                            marginLeft: 0
                          }
                        : { marginLeft: 0 }
                    }
                    tooltip={
                      "The contact's address and name match the property's mailing address and owner listed in the county records."
                    }
                    tooltipPlacement={"top"}
                  >
                    Likely Property Owners
                  </InlineButton>

                  {checkIfUserHasMetadata("company_data") ? (
                    <InlineButton
                      icon={
                        this.state.include_potential_property_owners &&
                        this.state.include_likely_owners
                          ? "check-box"
                          : "check-box-outline-blank"
                      }
                      onPress={() =>
                        this.setState({
                          include_potential_property_owners:
                            !this.state.include_potential_property_owners
                        })
                      }
                      disabled={!this.state.include_likely_owners}
                      selected={
                        this.state.include_potential_property_owners &&
                        this.state.include_likely_owners
                      }
                      style={
                        this.state.include_potential_property_owners
                          ? {
                              borderColor: colors.active_color,
                              borderWidth: 1,
                              borderStyle: "solid",
                              marginLeft: 0
                            }
                          : { marginLeft: 0 }
                      }
                      tooltip={
                        !this.state.include_likely_owners
                          ? 'You must have "Likely Property Owners" enabled to include portential property owners.'
                          : "When we can't determine which contact is the property owner, we will include all contacts that are likely to be the property owner."
                      }
                      tooltipPlacement={"top"}
                    >
                      Potential Property Owners
                    </InlineButton>
                  ) : null}

                  <InlineButton
                    icon={
                      this.state.include_family
                        ? "check-box"
                        : "check-box-outline-blank"
                    }
                    onPress={() =>
                      this.setState({
                        include_family: !this.state.include_family
                      })
                    }
                    selected={this.state.include_family}
                    style={
                      this.state.include_family
                        ? {
                            borderColor: colors.active_color,
                            borderWidth: 1,
                            borderStyle: "solid",
                            marginLeft: 0
                          }
                        : { marginLeft: 0 }
                    }
                    tooltip={
                      "The contact's last name matches the property owner's name listed in the county records."
                    }
                    tooltipPlacement={"top"}
                  >
                    Family Members
                  </InlineButton>

                  <InlineButton
                    icon={
                      this.state.include_likely_renters
                        ? "check-box"
                        : "check-box-outline-blank"
                    }
                    onPress={() =>
                      this.setState({
                        include_likely_renters:
                          !this.state.include_likely_renters
                      })
                    }
                    selected={this.state.include_likely_renters}
                    style={
                      this.state.include_likely_renters
                        ? {
                            borderColor: colors.active_color,
                            borderWidth: 1,
                            borderStyle: "solid",
                            marginLeft: 0
                          }
                        : { marginLeft: 0 }
                    }
                    tooltip={
                      "The contact's name doesn't align with the listed property owner's, and they don't appear to be related. Additionally, their living address is different from the listed mailing address, suggesting they are a renter."
                    }
                    tooltipPlacement={"top"}
                  >
                    Contacts Likely Renting
                  </InlineButton>
                </Row>

                <SelectItem
                  selected={this.state.scrub_dnc}
                  onPress={() => {
                    this.setState({
                      scrub_dnc: !this.state.scrub_dnc
                    });
                  }}
                  select_type="toggle"
                  description={
                    'Exclude contact phone numbers on the "Do Not Call" list.'
                  }
                >
                  Scrub DNC
                </SelectItem>
                <SelectItem
                  selected={this.state.scrub_landline}
                  onPress={() => {
                    this.setState({
                      scrub_landline: !this.state.scrub_landline
                    });
                  }}
                  select_type="toggle"
                  description={"Exclude contact landline phone numbers."}
                >
                  Scrub Landline
                </SelectItem>
                <SelectItem
                  selected={this.state.scrub_wireless}
                  onPress={() => {
                    this.setState({
                      scrub_wireless: !this.state.scrub_wireless
                    });
                  }}
                  select_type="toggle"
                  description={"Exclude contact wireless phone numbers."}
                >
                  Scrub Wireless
                </SelectItem>
                <SelectItem
                  selected={this.state.deduplicate}
                  onPress={() => {
                    this.setState({
                      deduplicate: !this.state.deduplicate
                    });
                  }}
                  select_type="toggle"
                  description={
                    "If a phone number or email address is already listed for another contact (usually in the same family) only include the first one."
                  }
                >
                  Deduplicate Contacts
                </SelectItem>
              </KeyboardView>
            </>
          )}
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, lead, filter, billing }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { export_modal } = lead;
  const { source_of_truth, all_feature_metadata } = billing;
  const { list_settings } = filter;

  return {
    token,
    user,
    isMobile,
    platform,
    device,
    colors,
    export_modal,
    list_settings,
    source_of_truth,
    all_feature_metadata
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setExportModal,
  popSidePanel,
  popAllSidePanels,
  updateLead,
  getSourceOfTruth,
  pushSidePanel,
  getExportActualCount,
  updateUserSettings
})(ExportLeads);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from "app/NativeComponents/common";
import { store } from "app/store";

class NativeListBuilderMap extends Component {
  constructor(props) {
    super(props);

    this.updateCenter = this.updateCenter.bind(this);
    this.updateBounds = this.updateBounds.bind(this);

    this.drawingCreated = this.drawingCreated.bind(this);
    this.onDraw = this.onDraw.bind(this);
    this.updateZoomProperties = this.updateZoomProperties.bind(this);
  }
  onDraw(drawing_coordinates) {
    this.props.onDraw(drawing_coordinates);
  }
  updateCenter(coordinate) {
    this.props.updateCenter(coordinate);
  }
  updateBounds(map) {
    if (map) {
      const bounds = map.getBounds();
      const coordinate = map.getCenter();

      this.props.updateBounds({
        westLng: bounds._sw.lng,
        southLat: bounds._sw.lat,
        eastLng: bounds._ne.lng,
        northLat: bounds._ne.lat,
        centerLat: coordinate.lat,
        centerLng: coordinate.lng
      });
    }
  }
  drawingCreated() {
    this.props.drawingCreated();
  }

  updateZoomProperties({ zoom }) {
    if (zoom < this.props.minZoom) {
      //this.drawControl.draw.changeMode("simple_select");
      //this.drawControl.draw.deleteAll();
      //this.props.toggleDrawing(false);

      this.drawControl.draw.changeMode("static");
    } else if (zoom > this.props.maxZoom) {
      //this.drawControl.draw.changeMode("simple_select");
      //this.drawControl.draw.deleteAll();
      //this.props.toggleDrawing(false);

      this.drawControl.draw.changeMode("static");
    } else if (this.props.drawing) {
      if (this.props.drawing_coordinates?.length > 0) {
        this.drawControl.draw.changeMode("simple_select");
      } else {
        this.drawControl.draw.changeMode("draw_polygon");
      }
    }

    this.props.updateZoomProperties({ zoom });
  }

  updateLeadsOnMapSource(map) {
    let data = [];
    let leads_data = [];
    const { leads_on_map } = this.props;
    for (let i = 0; i < leads_on_map.length; i++) {
      leads_data.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(leads_on_map[i].longitude),
            parseFloat(leads_on_map[i].latitude)
          ]
        }
      });
    }
    const source_data = {
      type: "FeatureCollection",
      features: leads_data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("leads_on_map")) {
        map.addSource("leads_on_map", source);
      } else {
        map.getSource("leads_on_map").setData(source_data);
      }
    }
  }

  updateListsOnMapSource(map) {
    let data = [];

    for (let i = 0; i < this.props.lists_on_map.length; i++) {
      let coords = [];
      for (let j = 0; j < this.props.lists_on_map[i].coordinates.length; j++) {
        coords.push([
          this.props.lists_on_map[i].coordinates[j].longitude,
          this.props.lists_on_map[i].coordinates[j].latitude
        ]);
      }
      data.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coords]
        }
      });
    }
    const source_data = {
      type: "FeatureCollection",
      features: data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("lists_on_map")) {
        map.addSource("lists_on_map", source);
      } else {
        map.getSource("lists_on_map").setData(source_data);
      }
    }
  }

  updateHighlightsOnMapSource(map) {
    let data = [];

    for (let i = 0; i < this.props.highlights_on_map.length; i++) {
      let coords = [];
      if (this.props.highlights_on_map[i].coordinates) {
        for (
          let j = 0;
          j < this.props.highlights_on_map[i].coordinates.length;
          j++
        ) {
          if (this.props.highlights_on_map[i].coordinates[j]) {
            for (
              let k = 0;
              k < this.props.highlights_on_map[i].coordinates[j].length;
              k++
            ) {
              coords.push([
                this.props.highlights_on_map[i].coordinates[j][k].longitude,
                this.props.highlights_on_map[i].coordinates[j][k].latitude
              ]);
            }
          }
        }
      }
      data.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coords]
        }
      });
    }
    const source_data = {
      type: "FeatureCollection",
      features: data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("highlights_on_map")) {
        map.addSource("highlights_on_map", source);
      } else {
        map.getSource("highlights_on_map").setData(source_data);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drawing !== this.props.drawing) {
      if (this.drawControl) {
        if (this.props.drawing) {
          this.drawControl.draw.changeMode("draw_polygon");
        } else {
          this.drawControl.draw.changeMode("simple_select");
          this.drawControl.draw.deleteAll();
        }
      }
    }

    if (this._map?.state?.map) {
      const map = this._map.state.map;

      if (prevProps.show_lists_on_map !== this.props.show_lists_on_map) {
        if (!this.props.show_lists_on_map) {
          map.getSource("lists_on_map").setData({
            type: "FeatureCollection",
            features: []
          });
        } else {
          this.updateListsOnMapSource(map);
        }
      }
      if (
        JSON.stringify(prevProps.lists_on_map) !==
        JSON.stringify(this.props.lists_on_map)
      ) {
        if (this.props.show_leads_on_map) {
          this.updateListsOnMapSource(map);
        }
      }

      if (prevProps.show_leads_on_map !== this.props.show_leads_on_map) {
        if (!this.props.show_leads_on_map) {
          map.getSource("leads_on_map").setData({
            type: "FeatureCollection",
            features: []
          });
        } else {
          this.updateLeadsOnMapSource(map);
        }
      }

      if (
        JSON.stringify(prevProps.leads_on_map) !==
        JSON.stringify(this.props.leads_on_map)
      ) {
        if (this.props.show_leads_on_map) {
          this.updateLeadsOnMapSource(map);
        }
      }

      if (
        prevProps.show_highlights_on_map !== this.props.show_highlights_on_map
      ) {
        if (!this.props.show_highlights_on_map) {
          map.getSource("highlights_on_map").setData({
            type: "FeatureCollection",
            features: []
          });
        } else {
          this.updateHighlightsOnMapSource(map);
        }
      }
      if (
        JSON.stringify(prevProps.highlights_on_map) !==
        JSON.stringify(this.props.highlights_on_map)
      ) {
        if (this.props.highlights_on_map) {
          this.updateHighlightsOnMapSource(map);
        }
      }

      if (
        (prevProps.latitude !== this.props.latitude ||
          prevProps.longitude !== this.props.longitude ||
          prevProps.zoom !== this.props.zoom) &&
        !isNaN(this.props.longitude) &&
        !isNaN(this.props.latitude)
      ) {
        map.flyTo({
          center: [this.props.longitude, this.props.latitude],
          zoom: this.props.zoom
        });
      }

      if (prevProps.map_type !== this.props.map_type) {
        if (this.props.map_type == "satellite") {
          map.setStyle(
            "mapbox://styles/dealmachine/cm48lzqp9002k01qo2km1hsz6?optimize=true"
          );
        } else {
          const dark_mode = store.getState().settings.dark_mode;
          if (dark_mode == "dark_mode") {
            map.setStyle(
              "mapbox://styles/dealmachine/cm47dri0x00zj01qqd9qodyt3?optimize=true"
            );
          } else {
            map.setStyle(
              "mapbox://styles/dealmachine/cm47adykc00ty01qr4v17bqmu?optimize=true"
            );
          }
        }
      }
    }
  }

  render() {
    const { latitude, longitude } = this.props;
    return (
      <Wrapper
        style={{
          alignSelf: "stretch",
          flex: "1",
          position: "relative",
          overflow: "hidden"
        }}
      >
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;

  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {})(NativeListBuilderMap);

import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Scroll } from "app/NativeComponents/common";
import {
  IconButton,
  SearchBar,
  PopoverMenu,
  AttentionBox,
  SpinWrapper,
  List
} from "app/NativeComponents/snippets";

import { getLeads } from "app/NativeActions";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

class AttachExampleProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_attach_example_properties: false,
      property_search: "",
      properties: [],
      begin: 0,
      limit: 25,
      properties_loading: false,
      properties_loaded_all: false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData({ load_type: "refresh" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.property_search !== this.state.property_search) {
      this.triggerSearch();
    }

    if (
      prevState.show_attach_example_properties !==
        this.state.show_attach_example_properties &&
      this.state.show_attach_example_properties
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  triggerSearch() {
    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      this.getData({
        load_type: "refresh"
      });
    }, 750);
  }

  getData({ load_type = "refresh" }) {
    let new_state = {};

    switch (load_type) {
      case "refresh":
        new_state = {
          properties: [],
          begin: 0,
          properties_loaded_all: false
        };
        break;
    }

    this.setState(new_state, () => {
      this.props.getLeads({
        token: this.props.token,
        begin: this.state.begin,
        limit: this.state.limit,
        search: this.state.property_search,
        search_type: "quick_address",
        noredux: true,
        onLoading: () => {
          this.setState({ properties_loading: true });
        },
        onError: () => {
          this.setState({ properties_loading: false });
        },
        onSuccess: results => {
          this.setState({
            properties_loading: false,
            properties: [...this.state.properties, ...results?.properties],
            properties_loaded_all:
              results?.properties?.length < this.state.limit,
            begin: this.state.begin + this.state.limit
          });
        }
      });
    });
  }

  render() {
    const { isMobile, colors, source_of_truth } = this.props;

    return (
      <>
        <PopoverMenu
          show={this.state.show_attach_example_properties}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.setState({
              show_attach_example_properties: s,
              property_search: ""
            });
          }}
          includeCloseButton={true}
          popover_title={"Attach Example Properties"}
          popover_width={450}
          popoverSheetTop={"75%"}
          popover_height={450}
          disabled={this.props.disabled}
          renderComponent={({ hovering }) => {
            return (
              <IconButton
                noPress={true}
                pressedIn={
                  this.state.show_attach_example_properties || hovering
                }
                disabled={this.props.disabled}
                icon={"add-home"}
                tooltip={
                  this.props.disabled
                    ? "You can only attach 3 example properties"
                    : "Attach Example Properties"
                }
                tooltipPlacement={"top"}
                icon_color={colors.actionable_text_color}
                style={{ marginRight: 0, marginLeft: 0 }}
              />
            );
          }}
          renderMenu={() => {
            return (
              <>
                <Wrapper
                  style={{
                    alignSelf: "stretch"
                  }}
                >
                  <AttentionBox
                    title="Attach example properties"
                    description="You can attach example properties to help the AI understand your search and find properties similar to them."
                  />
                  <SearchBar
                    title="Search Leads"
                    value={this.state.property_search}
                    onChange={value => {
                      this.setState({ property_search: value });
                    }}
                    style={{ margin: 10 }}
                  />
                </Wrapper>
                {this.state.properties_loading &&
                this.state.properties.length == 0 ? (
                  <Wrapper style={{ flex: 1 }}>
                    <SpinWrapper text="Loading Properties..." />
                  </Wrapper>
                ) : (
                  <List
                    rowNumber={1}
                    style={{ flex: 1 }}
                    items={this.state.properties.filter(
                      p =>
                        !this.props.attached_properties.some(
                          ap => ap.id === p.id
                        )
                    )}
                    infiniteScroll={true}
                    itemStructure={({ item, index }) => {
                      return (
                        <LeadItem
                          key={"lead_" + item.property_id}
                          property={item}
                          onPress={() => {
                            this.setState(
                              {
                                show_attach_example_properties: false,
                                property_search: ""
                              },
                              () => {
                                this.props.attachExampleProperties(item);
                              }
                            );
                          }}
                        />
                      );
                    }}
                    canRefresh={false}
                    onRefresh={() => {
                      this.getData({
                        load_type: "refresh"
                      });
                    }}
                    is_refreshing={false}
                    canLoadMore={
                      !this.state.properties_loaded_all &&
                      !this.state.properties_loading &&
                      this.state.properties.length > 0
                    }
                    isLoadingMore={
                      this.props.activity_properties_loading &&
                      this.state.leads.length > 0
                    }
                    onLoadMore={() => {
                      if (
                        !this.state.properties_loaded_all &&
                        this.state.properties_loading != true
                      ) {
                        this.getData({
                          load_type: "load_more"
                        });
                      }
                    }}
                  />
                )}
              </>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile
  };
};

export default connect(mapStateToProps, { getLeads })(AttachExampleProperty);

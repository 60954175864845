export const GET_LISTS = "get_lists";
export const REFRESH_LISTS = "refresh_lists";
export const GET_LISTS_FAIL = "get_lists_fail";
export const GET_LISTS_SUCCESS = "get_lists_success";
export const LOAD_MORE_LISTS = "load_more_lists";
export const LOAD_MORE_LISTS_SUCCESS = "load_more_lists_success";
export const RELOAD_LISTS = "reload_lists";

export const CREATE_LIST = "create_list";
export const CREATE_LIST_FAIL = "create_list_fail";
export const CREATE_LIST_SUCCESS = "create_list_success";

export const UPDATE_LIST = "update_list";
export const UPDATE_LIST_FAIL = "update_list_fail";
export const UPDATE_LIST_SUCCESS = "update_list_success";

export const REMOVE_LIST = "remove_list";
export const REMOVE_LIST_FAIL = "remove_list_fail";
export const REMOVE_LIST_SUCCESS = "remove_list_success";

export const SET_LIST_MODAL = "set_list_modal";
export const RESET_LIST_MODAL = "reset_list_modal";
export const TOGGLE_LIST_MODAL = "toggle_list_modal";

export const UPDATE_LIST_SEARCH = "update_list_search";

export const UPDATE_USER_LIST_SETTINGS = "update_user_list_settings";
export const UPDATE_USER_LIST_SETTINGS_FAIL = "update_user_list_settings_fail";
export const UPDATE_USER_LIST_SETTINGS_SUCCESS =
  "update_user_list_settings_success";

export const LIST_COUNT = "list_count";
export const LIST_COUNT_FAIL = "list_count_fail";
export const LIST_COUNT_SUCCESS = "list_count_success";
export const BUILD_LIST = "build_list";
export const BUILD_LIST_FAIL = "build_list_fail";
export const BUILD_LIST_SUCCESS = "build_list_success";

export const SET_ACTIVE_LIST = "set_active_list";
export const GET_SINGLE_LIST_ITEM = "get_single_list_item";
export const GET_SINGLE_LIST_ITEM_SUCCESS = "get_single_list_item_success";
export const REFRESH_SINGLE_LIST_ITEM = "refresh_single_list_item";

export const EDIT_ACTIVE_LIST = "edit_active_list";

export const UPDATE_NEW_COLUMN_WIDTH = "update_new_column_width";

export const RELOAD_LIST = "reload_list";

export const ADD_LIST_TO_LIST_TABS = "add_list_to_list_tabs";
export const REMOVE_LIST_FROM_LIST_TABS = "remove_list_from_list_tab";
export const SET_ACTIVE_LIST_TAB = "set_active_list_tab";
export const REORDER_LIST_TABS = "reorder_list_tabs";

export const TOGGLE_LIST_LIBRARY = "toggle_list_library";
export const SET_EDIT_LIST_INFO = "set_edit_list_info";
export const TOGGLE_NEW_LIST = "toggle_new_list";
export const GET_PINNED_LISTS_SUCCESS = "get_pinned_lists_success";

export const UPDATE_LIST_BUILDER_PROGRESS = "update_list_builder_progress";
export const GET_LIST_BUILDER_TEMPLATES_SUCCESS =
  "get_list_builder_templates_success";

export const UPDATE_LIST_BUILDER_LOCATION = "update_list_builder_location";

export const AI_LIST_BUILD_SCAN = "ai_list_build_scan";
export const COMPLETE_AI_LIST_BUILDER = "complete_ai_list_builder";
export const AI_LIST_BUILD_START = "ai_list_build_start";
export const REMOVE_AI_LIST_BUILD_SCAN = "remove_ai_list_build_scan";
export const ADD_AI_LIST_BUILD_SCAN_FROM_QUEUE =
  "add_ai_list_build_scan_from_queue";
export const GET_CURRENT_AI_LIST_BUILD = "get_current_ai_list_build";
export const DISMISS_AI_LIST_BUILDER = "dismiss_ai_list_builder";

import React, { Component } from "react";
import { connect } from "react-redux";
import PusherService from "app/Router/PusherService";
import { getRoutes, getRoutesForLiveEvent } from "app/NativeActions";
import {
  Wrapper,
  Container,
  QRCodeWrapper,
  Card,
  KeyboardView,
  Row,
  Copy
} from "app/NativeComponents/common";
import { SelectItem } from "app/NativeComponents/snippets";

const queryString = require("query-string");

class LiveEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      style_loaded: false,
      routes: [],
      promo_codes: null
    };

    this.initPush = this.initPush.bind(this);
    this.getInitRoutes = this.getInitRoutes.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.onStyleLoad = this.onStyleLoad.bind(this);
    this.updateDriversOnMap = this.updateDriversOnMap.bind(this);
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location?.search);
    if (!!parsed?.promo) {
      this.setState({
        promo_codes: parsed?.promo.split(",")
      });
    } else if (!!parsed?.promos) {
      this.setState({
        promo_codes: parsed?.promos.split(",")
      });
    }
  }

  getInitRoutes() {
    this.props.getRoutesForLiveEvent({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        if (results?.routes) {
          if (results?.routes && results?.routes?.length > 0) {
            let starting_routes = [];
            for (let i = 0; i < results?.routes.length; i++) {
              if (
                !this.state.promo_codes ||
                this.state.promo_codes?.includes(results?.routes[i].promo_code)
              ) {
                starting_routes.push(results?.routes[i]);
              }
            }

            this.setState({
              routes: starting_routes
            });
          }
        }
      }
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const map = this._map?.state?.map;

    if (map && JSON.stringify(prevState.routes) !== JSON.stringify(this.state.routes)) {
      this.updateDriversOnMap(map);
    }

    let seenUserIds = new Set();

    let unique_routes = this.state.routes?.filter(route => {
      if (seenUserIds.has(route.user_id)) { // Assuming each route object has a user_id property
        return false; // Exclude from the filtered result
      }
      seenUserIds.add(route.user_id);
      return true; // Include in the filtered result
    });


    if (JSON.stringify(unique_routes) !== JSON.stringify(this.state.routes)) {
      this.setState({
        routes: unique_routes?.sort(
            (a, b) => b.properties_added - a.properties_added
        )
      });
    }
  }

  initPush() {
    if (this._pusher) {
      this._pusher.allChannels().forEach(channel => {
        this._pusher.unsubscribe(channel.name);
      });
    }

    this._pusher = PusherService.getPusher();
    this._analytics_channel = this._pusher.subscribe("analytics-dealmachine");
    //all new sms message functions
    this._analytics_channel.bind("add_lead_from_map", data =>
      this.handleEvent(data, "add_lead_from_map")
    );

    /*
      this._channel.bind("skip_trace", data =>
          this.handleEvent(data, "skip_trace")
      );
      */
    this._analytics_channel.bind("start_route", data =>
      this.handleEvent(data, "start_route")
    );

    this._analytics_channel.bind("track_route", data =>
      this.handleEvent(data, "track_route")
    );

    this._analytics_channel.bind("end_route", data =>
      this.handleEvent(data, "end_route")
    );
  }

  handleEvent(data, type) {
    switch (type) {
      case "add_lead_from_map":
        let new_routes_on_map = JSON.parse(JSON.stringify(this.state.routes));
        for (let j = 0; j < new_routes_on_map?.length; j++) {
          if (data.team == this.state.routes[j].team_id) {
            new_routes_on_map[j].properties_added =
              parseInt(new_routes_on_map[j].properties_added) + 1;
          }
        }

        this.setState({
          routes: new_routes_on_map?.sort(
            (a, b) => b.properties_added - a.properties_added
          )
        });

        break;

      case "start_route":
      case "track_route":
        this.props.getRoutes({
          token: this.props.token,
          route_id: data.route_id,
          onLoading: () => {},
          onError: () => {},
          onSuccess: results => {
            let new_routes_on_map = JSON.parse(
              JSON.stringify(this.state.routes)
            );
            let found_route = false;
            if (results?.routes && results?.routes.length > 0) {
              const new_route = results?.routes[0];
              if (
                !this.state.promo_codes ||
                this.state.promo_codes?.includes(new_route.promo_code)
              ) {
                for (let j = 0; j < new_routes_on_map?.length; j++) {
                  if (new_route.id == this.state.routes[j].id) {
                    found_route = true;
                    new_routes_on_map[j] = new_route;
                  }
                }
                if (!found_route) {
                  new_routes_on_map.push(new_route);
                }
                this.setState({
                  routes: new_routes_on_map?.sort(
                    (a, b) => b.properties_added - a.properties_added
                  )
                });
              }
            }
          }
        });

        break;

      case "end_route":
        //remove that route!
        this.setState({
          routes: new_routes_on_map?.filter(({ id }) => id != data.route_id)
        });
        break;

      default:
        break;
    }
  }

  onStyleLoad() {
    this.setState({
      style_loaded: true
    });
    this.getInitRoutes();
    this.initPush();
  }
  updateDriversOnMap(map) {
    if (map) {
      let route_data = [];
      const { routes } = this.state;

      for (let i = 0; i < routes?.length; i++) {
        if (routes[i].coordinates?.length > 0) {
          let image_initials = "";
          if (
            routes[i].user_firstname?.length > 0 &&
            routes[i].user_lastname?.length > 0
          ) {
            image_initials =
              routes[i].user_firstname[0] + routes[i].user_lastname[0];
          } else if (routes[i].user_firstname?.length > 1) {
            image_initials =
              routes[i].user_firstname[0] + routes[i].user_firstname[1];
          }

          image_initials = image_initials.toUpperCase();

          const properties = {
            label: image_initials
          };

          const last_coordinate = [
            routes[i].coordinates[0][0]?.longitude,
            routes[i].coordinates[0][0]?.latitude
          ];

          route_data.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: last_coordinate
            },
            properties
          });
        }
      }
      const source_data = {
        type: "FeatureCollection",
        features: route_data
      };
      const source = {
        type: "geojson",
        data: source_data
      };
      if (!map.getSource("routes_on_map")) {
        map.addSource("routes_on_map", source);
      } else {
        map.getSource("routes_on_map").setData(source_data);
      }
    } else if (map) {
      clearTimeout(this._route_timeot);
      this._route_timeot = setTimeout(() => {
        this.updateDriversOnMap(map);
      }, 500);
    }
  }

  render() {
    return (
      <div className="dm-app-wrapper animated fadeIn deal-full-app">
        <Container>
          <Row style={{ alignSelf: "stretch", flex: 1 }}>
            <Wrapper
              style={{
                alignSelf: "stretch",
                flex: "1",
                position: "relative",
                overflow: "hidden"
              }}
            >
            </Wrapper>

            <Wrapper
              style={{
                width: 400,
                alignSelf: "stretch",
                background: this.props.colors?.card_color,
                height: "100vh",
                overflow: "hidden"
              }}
            >
              <KeyboardView style={{ flex: 1 }}>
                {this.state.routes &&
                  this.state.routes.map((item, i) => {
                    return (
                      <SelectItem
                        noPress={true}
                        select_type="profile_pic"
                        key={"drive_" + i}
                        user={{
                          image: item.user_image,
                          firstname: item.user_firstname,
                          lastname: item.user_lastname,
                          email: item.user_email
                        }}
                        renderRight={() => {
                          return (
                            <Wrapper
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                paddingRight: 25
                              }}
                            >
                              <Copy
                                style={{
                                  fontSize: 10,
                                  textAlign: "center",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                Leads added:
                              </Copy>
                              <Copy
                                style={{
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item?.properties_added || 0}
                              </Copy>
                            </Wrapper>
                          );
                        }}
                      >
                        {item?.driver_name}
                      </SelectItem>
                    );
                  })}
              </KeyboardView>
            </Wrapper>

            {this.state.promo_codes && this.state.promo_codes?.length > 0 ? (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                  left: 0
                }}
              >
                <Card style={{ padding: 25 }}>
                  <QRCodeWrapper
                    size={100}
                    style={{
                      height: 100,
                      maxWidth: 100,
                      width: 100,
                      borderRadius: 0
                    }}
                    viewBox={`256 256 256 256`}
                    value={
                      "https://app.dealmachine.com/sign-up?fpr=" +
                      this.state.promo_codes[0]?.toUpperCase()
                    }
                  />
                </Card>
              </Wrapper>
            ) : null}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;
  const { source_of_truth } = billing;
  const { colors } = settings;
  const { isMobile, signUpMobile, device, platform } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    signUpMobile,
    device,
    platform,
    source_of_truth
  };
};

export default connect(mapStateToProps, { getRoutes, getRoutesForLiveEvent })(
  LiveEvent
);

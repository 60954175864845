import React, { Component } from "react";
import { connect } from "react-redux";
import { Map as MapGL, Source, Layer, useControl } from "react-map-gl";

import DrawControl from "./DrawControl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { store } from "app/store";

const StaticMode = require("@mapbox/mapbox-gl-draw-static-mode");

class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this._moveTimeout = null;
    this._zoomTimeout = null;
    this.state = {
      features: {}
    };
  }

  sendCoordinates(features) {
    let drawing_coordinates = [];

    if (features && features.length > 0) {
      const geometry = features[0].geometry;
      if (geometry && geometry.coordinates.length > 0) {
        const coordinates = geometry.coordinates[0];

        for (let i = 0; i < coordinates.length; i++) {
          drawing_coordinates.push({
            latitude: coordinates[i][1],
            longitude: coordinates[i][0]
          });
        }
      }
    }

    if (drawing_coordinates.length > 2) {
      this.props.onDraw(drawing_coordinates);
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.dark_mode !== this.props.dark_mode ||
      prevProps.drawing !== this.props.drawing
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      user,
      scrollZoom = true,
      latitude,
      longitude,
      drawing = false
    } = this.props;
    const colors = store.getState().settings.colors;
    const dark_mode = store.getState().settings.dark_mode;

    const onDrawCreate = ({ features }) => {
      this.props.drawingCreated();
      this.sendCoordinates(features);
    };
    const onClick = (e) => {
      if(e?.features) {
        this.props.onCompClick(e);
      }
    };
    const onDrawUpdate = ({ features }) => {
      this.sendCoordinates(features);
    };
    const onStyleLoad = map => {
      this.props.onStyleLoad(map);
      getCenter(map);
    };
    const getCenter = map => {
      if (map) {
        if (map?.getCenter) {
          const coordinate = map?.getCenter();
          this.props.updateCenter({
            latitude: coordinate?.lat,
            longitude: coordinate?.lng
          });
        }

        this.props.updateBounds(this.mapRef.current);
      }
    };

    const calculateZoom = map => {
      if (map) {
        const zoom = map;
        this.props.updateZoomProperties({
          zoom: zoom
        });

        // this.props.updateBounds(this.mapRef);
      }
    };

    return (
      <MapGL
        ref={this.mapRef}
        mapboxAccessToken="pk.eyJ1IjoiZGVhbG1hY2hpbmUiLCJhIjoiY2pyczBqMXQ4MDRiMDQzcnhldmpzbnZsYyJ9.YGxxBHBvL31H6K1EBMuIog"
        mapStyle={
          this.props.map_type == "satellite"
            ? "mapbox://styles/dealmachine/cm48lzqp9002k01qo2km1hsz6?optimize=true"
            : dark_mode == "dark_mode"
            ? "mapbox://styles/dealmachine/cm47dri0x00zj01qqd9qodyt3?optimize=true"
            : "mapbox://styles/dealmachine/cm47adykc00ty01qr4v17bqmu?optimize=true"
        }
        style={{
          width: "100%",
          height: "100%",
          position: "relative"
        }}
        maxZoom={parseInt(this.props.maxZoom)}
        minZoom={parseInt(this.props.minZoom)}
        initialViewState={{
          longitude: parseFloat(this.props.starting_lng),
          latitude: parseFloat(this.props.starting_lat),
          zoom: parseInt(this.props.starting_zoom)
        }}
        scrollZoom={scrollZoom}
        scrollWheelZoom={scrollZoom}
        onZoomEnd={calculateZoom}
        onMoveEnd={getCenter}
        onDragEnd={getCenter}
        //onZoomStart={this.props.hidePopover}
        onDragStart={this.props.hidePopover}
        onClick={onClick}
        interactiveLayerIds={["comps_on_map_symbol","comps_on_map_circle"]}
        onLoad={event => {
          if (this.props.onRef) {
            this.props.onRef(this.mapRef.current);
          }

          const map = this.mapRef.current;
          onStyleLoad(map);
          const mapper = map.getMap();
          mapper.loadImage("/assets/images/active_pin.png", (error, image) => {
            if (error) {
              console.log("ERROR ON LOADING IMAGE", error);
              return;
            }
            mapper.addImage("lead", image);
          });
          mapper.loadImage("/assets/images/orange_pin.png", (error, image) => {
            if (error) return;
            mapper.addImage("active_lead", image);
          });
        }}

      >
        <Source
          id="comps_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            type="symbol"
            minzoom={4}
            id="comps_on_map_symbol"
            layout={{
              //"icon-image": "lead",
              //"icon-size": 0.3,
              //"icon-anchor": "bottom",
              "text-field": "{property_label}",
              "text-size": 12,
              "text-anchor": "top",
              "text-justify": "center",
              "text-offset": [0, 0.5]
            }}
            paint={{
              "text-color":
                this.props.map_type == "satellite"
                  ? colors.white_text_color
                  : colors.text_color,
              "text-halo-width": 3,
              "text-halo-color":
                dark_mode == "dark_mode" || this.props.map_type == "satellite"
                  ? "rgba(0, 0, 0, 0.5)"
                  : "rgba(255, 255, 255, 0.75)"
            }}
          />
          <Layer
            type="circle"
            minzoom={4}
            id="comps_on_map_circle"
            paint={{
              "circle-color": [
                "match",
                ["get", "active_status"],
                "active",
                colors.orange_color_muted,
                colors.active_color_muted
              ],
              "circle-radius": 3,
              "circle-stroke-width": 1,
              "circle-stroke-color": [
                "match",
                ["get", "active_status"],
                "active",
                colors.orange_color,
                colors.actionable_text_color
              ]
            }}
          />
        </Source>

        <Source
          id="active_lead_on_map"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: []
          }}
        >
          <Layer
            type="symbol"
            minzoom={4}
            id="active_lead_on_map_symbold"
            layout={{
              "icon-image": "lead",
              "icon-size": 0.3,
              "icon-anchor": "bottom"
            }}
          />
        </Source>
        {drawing ? (
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            defaultMode="draw_polygon"
            onCreate={onDrawCreate}
            onUpdate={onDrawUpdate}
            minZoom={this.props.minZoom}
            maxZoom={this.props.maxZoom}
            styles={[
              {
                id: "gl-draw-line",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "LineString"],
                  ["!=", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-dasharray": [0.2, 2],
                  "line-width": 2
                }
              },
              // polygon fill
              {
                id: "gl-draw-polygon-fill",
                type: "fill",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["!=", "mode", "static"]
                ],
                paint: {
                  "fill-color": colors.actionable_text_color,
                  "fill-outline-color": colors.actionable_text_color,
                  "fill-opacity": 0.1
                }
              },
              // polygon mid points
              {
                id: "gl-draw-polygon-midpoint",
                type: "circle",
                filter: [
                  "all",
                  ["==", "$type", "Point"],
                  ["==", "meta", "midpoint"]
                ],
                paint: {
                  "circle-radius": 3,
                  "circle-color": colors.actionable_text_color
                }
              },
              {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["!=", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-dasharray": [0.2, 2],
                  "line-width": 2
                }
              },
              // vertex point halos
              {
                id: "gl-draw-polygon-and-line-vertex-halo-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"]
                ],
                paint: {
                  "circle-radius": 5,
                  "circle-color": colors.actionable_text_color
                }
              },
              // vertex points
              {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"]
                ],
                paint: {
                  "circle-radius": 3,
                  "circle-color": colors.actionable_text_color
                }
              },

              // INACTIVE (static, already drawn)
              // line stroke
              {
                id: "gl-draw-line-static",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "LineString"],
                  ["==", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-width": 3
                }
              },
              // polygon fill
              {
                id: "gl-draw-polygon-fill-static",
                type: "fill",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["==", "mode", "static"]
                ],
                paint: {
                  "fill-color": colors.actionable_text_color,
                  "fill-outline-color": colors.actionable_text_color,
                  "fill-opacity": 0.1
                }
              },
              // polygon outline
              {
                id: "gl-draw-polygon-stroke-static",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["==", "mode", "static"]
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": colors.actionable_text_color,
                  "line-width": 2
                }
              }
            ]}
          />
        ) : null}
      </MapGL>
    );
  }
}

export default Map;

import React, { Component } from "react";
import { connect } from "react-redux";
import Map from "./Map";
import { store } from "app/store";
import { isPointInPolygon, getBounds, getDistance } from "geolib";

import { Wrapper, Copy, Bold } from "app/NativeComponents/common";
import { PopoverMenu, InlineTabs } from "app/NativeComponents/snippets";
import {
  toggleMapMoving,
  addDeal,
  replaceSidePanel,
  pushSidePanel,
  checkIfUserHasMetadata,
  numberWithCommas,
  renderDate,
  popSidePanel
} from "app/NativeActions";
import { nextOnboardingStep } from "app/DealMachineCore/actions";

import moment from "moment";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import {
  checkIfCoordinatesAreInProperty,
  findClosestProperty
} from "./MapActions";

class NativeMainMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      style_load: false,
      resize_refresh: false,
      remounted:false
    };

    this.onMapClick = this.onMapClick.bind(this);
    this.updateCenter = this.updateCenter.bind(this);
    this.updateBounds = this.updateBounds.bind(this);
    this.updateZoomProperties = this.updateZoomProperties.bind(this);
    this.hidePopover = this.hidePopover.bind(this);
    this.refreshMap = this.refreshMap.bind(this);
    this.onStyleLoad = this.onStyleLoad.bind(this);

    this.drawingCreated = this.drawingCreated.bind(this);
    this.onDraw = this.onDraw.bind(this);
  }

  onDraw(drawing_coordinates) {
    this.props.onDraw(drawing_coordinates);
  }
  drawingCreated() {
    this.props.drawingCreated();
  }
  componentDidMount() {
    this.setState({remounted:true});
  }

  componentWillUnmount() {
    clearInterval(this._map_refresh_interval);
    clearTimeout(this._route_timeout);
    clearTimeout(this._current_route_timeout);
    clearTimeout(this._property_timeot);
  }

  refreshMap() {

    if (this._map && this.state.style_load) {
      const map = this._map;
      this.updatePropertiesOnMapSource(map);
      this.updateSearchLocationsOnMapSource(map);
      this.updateRoutesOnMapSource(map);
      this.updateCurrentRoute(map);
      this.updateStreetViewMarker(map);
    }
  }

  onStyleLoad() {
    this.setState({
      style_load: true
    });
  }

  onMapClick(map) {
    if (!this.props.drawing) {
      this.props.togglePopover(false);

      const coordinates = {
        latitude: map.lngLat.lat,
        longitude: map.lngLat.lng
      };
      let property = null;
      const { map_properties, source_of_truth, all_feature_metadata } =
        this.props;
      const selected_properties = checkIfCoordinatesAreInProperty(
        coordinates,
        map_properties
      );

      if (selected_properties.length > 0) {
        property = selected_properties[0];
      } else {
        property = findClosestProperty(coordinates, map_properties);
      }

      //if property was not found lets find the closest property to the location that was clicked

      if (property && !property.deal && this.props.map_mode === "tap_to_add") {
        this.props.addDeal({
          token: this.props.token,
          property: property,
          address: property.property_address,
          address2: property.property_address2,
          city: property.property_address_city,
          state: property.property_address_state,
          zip: property.property_address_zip,
          onError: error => {
            const upsell_info = error?.upsell_info;
            this.props.pushSidePanel({
              slug: "unlock_feature_modal",
              overlay: true,
              data: {
                feature_upsell_info: upsell_info.feature_metadata,
                renderContent: () => {
                  return (
                    <Wrapper style={{ padding: 25 }}>
                      <Copy>
                        You have added{" "}
                        <Bold>
                          {numberWithCommas(upsell_info.current_count)} leads
                        </Bold>{" "}
                        Adding this property will exceed your limit of{" "}
                        <Bold>
                          {numberWithCommas(
                            upsell_info.subscription.metadata
                              .total_number_of_leads
                          )}
                        </Bold>
                        . Permanently delete a lead or upgrade to continue.
                      </Copy>
                    </Wrapper>
                  );
                },
                buttonText: "Upgrade And Add Lead",
                confirmText: "Confirm Upgrade?",
                onSuccess: () => {
                  this.props.popSidePanel();
                  this.props.addDeal({
                    token: this.props.token,
                    property: property,
                    address: property.property_address,
                    address2: property.property_address2,
                    city: property.property_address_city,
                    state: property.property_address_state,
                    zip: property.property_address_zip
                  });
                }
              }
            });
          }
        });
      } else {
        this.props.togglePopover(true, {
          coordinates,
          point: {
            x: map.point.x,
            y: map.point.y
          },
          property,
          selected_properties: selected_properties
        });
      }

      this.props.onMapPress(property);
    }
  }

  hidePopover() {
    this.props.togglePopover(false);
  }
  updateCenter(coordinate) {
    this.props.updateCenter(coordinate);
    this.hidePopover();
  }
  updateBounds(map) {
    if (map) {
      if (map?.getBounds && map?.getCenter) {
        const bounds = map?.getBounds();
        const coordinate = map?.getCenter();
        this.props.updateBounds({
          westLng: bounds?._sw?.lng,
          southLat: bounds?._sw?.lat,
          eastLng: bounds?._ne?.lng,
          northLat: bounds?._ne?.lat,
          centerLat: coordinate?.lat,
          centerLng: coordinate?.lng
        });

        this.hidePopover();
      }
    }
  }

  updateZoomProperties({ zoom }) {
    if (zoom < this.props.minZoom) {
      //this.drawControl.draw.changeMode("simple_select");
      //this.drawControl.draw.deleteAll();
      //this.props.toggleDrawing(false);
      //this.drawControl.draw.changeMode("static");
    } else if (zoom > this.props.maxZoom) {
      //this.drawControl.draw.changeMode("simple_select");
      //this.drawControl.draw.deleteAll();
      //this.props.toggleDrawing(false);
      //this.drawControl.draw.changeMode("static");
    } else if (this.props.drawing) {
      if (this.props.drawing_coordinates?.length > 0) {
        // this.drawControl.draw.changeMode("simple_select");
      } else {
        // this.drawControl.draw.changeMode("draw_polygon");
      }
    }

    this.props.updateZoomProperties({ zoom });
    this.hidePopover();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dark_mode !== prevProps.dark_mode) {
      this.refreshMap();
    }

    if (prevProps.drawing !== this.props.drawing) {
      if (this.drawControl) {
        if (this.props.drawing) {
          this.drawControl.draw.changeMode("draw_polygon");
        } else {
          this.drawControl.draw.changeMode("simple_select");
          this.drawControl.draw.deleteAll();
        }
      }
    }

    if (
      this.state.refresh_map &&
      this.state.refresh_map !== prevState.refresh_map
    ) {
      this.setState({
        style_load: false
      });
    } else if (this._map && this.state.style_load) {
      const map = this._map;

      if (
        (this.props.window_width !== prevProps.window_width ||
          this.props.window_height !== prevProps.window_height) &&
        !this.state.resize_refresh
      ) {
        this.setState(
          {
            resize_refresh: true,
            style_load: false
          },
          () => {
            this.setState(
              {
                resize_refresh: false
              },
              () => {
                this.refreshMap();
              }
            );
          }
        );
      } else {
        if (
          this.props.latitude &&
          this.props.longitude &&
          (prevProps.latitude !== this.props.latitude ||
            prevProps.longitude !== this.props.longitude)
        ) {
          this.hidePopover();
          map.flyTo({
            center: [this.props.longitude, this.props.latitude],
            bearing: this.props.heading || 0,
            zoom: this.props.zoom,
            essential: true,
            duration: 1000
          });
        } else if (
          this.props.latitude &&
          this.props.longitude &&
          prevProps.heading !== this.props.heading
        ) {
          this.hidePopover();

          map.flyTo({
            center: [this.props.longitude, this.props.latitude],
            bearing: this.props.heading || 0,
            essential: true,
            duration: 1000
            //pitch: pitch
          });
        } else if (prevProps.zoom !== this.props.zoom) {
          map.flyTo({
            zoom: this.props.zoom,
            essential: true,
            duration: 1000
          });
        } else if (this.props.refresh_map !== prevProps.refresh_map) {
          this.updateStreetViewMarker(map);
          map.flyTo({
            center: [this.props.longitude, this.props.latitude],
            bearing: this.props.heading || 0,
            essential: true,
            duration: 1000
          });
        }
        const mapStyle = map.getMap();
        if (prevProps.map_type !== this.props.map_type) {
          if (this.props.map_type == "satellite") {
            mapStyle.setStyle(
              "mapbox://styles/dealmachine/cm48lzqp9002k01qo2km1hsz6?optimize=true"
            );
          } else {
            const dark_mode = store.getState().settings.dark_mode;
            if (dark_mode == "dark_mode") {
              mapStyle.setStyle(
                "mapbox://styles/dealmachine/cm47dri0x00zj01qqd9qodyt3?optimize=true"
              );
            } else {
              mapStyle.setStyle(
                "mapbox://styles/dealmachine/cm47adykc00ty01qr4v17bqmu?optimize=true"
              );
            }
          }

          clearInterval(this._map_refresh_interval);
          this._map_refresh_interval = setTimeout(() => {
            this.refreshMap();
          }, 500);
        }

        if (
          JSON.stringify(prevProps.map_properties) !==
          JSON.stringify(this.props.map_properties) || this.state.remounted
        ) {
          this.updatePropertiesOnMapSource(map);
          if(this.state.remounted) {
            this.setState({remounted:false});
          }
        }

        if (
          JSON.stringify(prevProps.search_locations) !==
          JSON.stringify(this.props.search_locations)
        ) {
          this.updateSearchLocationsOnMapSource(map);
          /*
          let all_coords = [];
          for (let k = 0; k < this.props.search_locations.length; k++) {
            const location = this.props.search_locations[k];

            for (let i = 0; i < location?.coordinates?.length; i++) {
              const polygon = location?.coordinates[i];
              for (let j = 0; j < polygon?.length; j++) {
                all_coords.push({
                  latitude: polygon[j]?.latitude,
                  longitude: polygon[j]?.longitude
                });
              }
            }
            if (all_coords?.length > 0) {
              const new_bounds = getBounds(all_coords);
              if (new_bounds) {
                map.fitBounds([
                  [
                    parseFloat(new_bounds?.minLng) - 0.001,
                    parseFloat(new_bounds?.minLat) - 0.001
                  ], // southwestern corner of the bounds
                  [
                    parseFloat(new_bounds?.maxLng) + 0.001,
                    parseFloat(new_bounds?.maxLat) + 0.001
                  ] // northeastern corner of the bounds
                ]);
              }
            }
          }
          */
        }

        if (
          JSON.stringify(prevProps.routes_on_map) !==
            JSON.stringify(this.props.routes_on_map) ||
          prevProps.show_routes_on_map != this.props.show_routes_on_map
        ) {
          this.updateRoutesOnMapSource(map);
        }

        if (
          JSON.stringify(prevProps.current_route_section) !==
            JSON.stringify(this.props.current_route_section) ||
          JSON.stringify(prevProps.current_route) !==
            JSON.stringify(this.props.current_route)
        ) {
          this.updateCurrentRoute(map);
        }

        if (
          JSON.stringify(prevProps.street_view_coords) !==
          JSON.stringify(this.props.street_view_coords)
        ) {
          this.updateStreetViewMarker(map);
        }

        //fly to route
        if (
          this.props.active_route &&
          (this.props.active_route?.id != prevProps.active_route?.id ||
            this.props.active_route?.route_type !=
              prevProps.active_route?.route_type ||
            this.props.refresh_map !== prevProps.refresh_map)
        ) {
          let all_coords = [];
          for (
            let i = 0;
            i < this.props.active_route?.coordinates?.length;
            i++
          ) {
            for (
              let j = 0;
              j < this.props.active_route?.coordinates[i]?.length;
              j++
            ) {
              all_coords.push(this.props.active_route?.coordinates[i][j]);
            }
          }
          if (all_coords && all_coords.length > 0) {
            const new_bounds = getBounds(all_coords);
            // map.flyTo({
            //   center: [
            //     this.props.active_route?.start_coordinates?.longitude,
            //     this.props.active_route?.start_coordinates?.latitude
            //   ],
            //   bearing: 0,
            //   zoom: this.props.zoom,
            //essential: true,
            //duration: 1000
            // });
            map.fitBounds(
              [
                [
                  parseFloat(new_bounds.minLng) - 0.001,
                  parseFloat(new_bounds.minLat) - 0.001
                ], // southwestern corner of the bounds
                [
                  parseFloat(new_bounds.maxLng) + 0.001,
                  parseFloat(new_bounds.maxLat) + 0.001
                ] // northeastern corner of the bounds
              ],
              { linear: false, duration: 1000 }
            );
            /*
            const bounds = coordinates.reduce(function (bounds, coord) {
              return bounds.extend(coord);
            }, new ReactMapboxGl.LngLatBounds(coordinates[0], coordinates[1]));
            map.fitBounds(coordinates);
            */
          }

          this.updatePropertiesOnMapSource(map);
          this.updateRoutesOnMapSource(map);
        } else if (!this.props.active_route && prevProps.active_route) {
          this.updatePropertiesOnMapSource(map);
          this.updateRoutesOnMapSource(map);
        }
      }
    }
  }

  updateSearchLocationsOnMapSource(map) {
    const { search_locations } = this.props;
    let locations_data = [];
    for (let i = 0; i < search_locations.length; i++) {
      let coords = [];
      if (
        search_locations[i].coordinates &&
        search_locations[i].coordinates?.length > 0
      ) {
        for (let j = 0; j < search_locations[i].coordinates.length; j++) {
          if (search_locations[i].coordinates[j]) {
            for (
              let k = 0;
              k < search_locations[i].coordinates[j].length;
              k++
            ) {
              coords.push([
                search_locations[i].coordinates[j][k].longitude,
                search_locations[i].coordinates[j][k].latitude
              ]);
            }
          }
        }
      }
      locations_data.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coords]
        }
      });
    }

    const locations_source_data = {
      type: "FeatureCollection",
      features: locations_data
    };
    const locations_source = {
      type: "geojson",
      data: locations_source_data
    };
    if (!map.getSource("locations_on_map")) {
      map.addSource("locations_on_map", locations_source);
    } else {
      map.getSource("locations_on_map").setData(locations_source_data);
    }
  }

  updatePropertiesOnMapSource(map) {
    let data = [];
    let properties_data = [];
    let border_data = [];
    let all_border_data = [];
    let mls_data = [];
    const { search_locations } = this.props;

    let coordinates_array = [];
    if (search_locations && search_locations.length > 0) {
      for (let i = 0; i < search_locations.length; i++) {
        for (let j = 0; j < search_locations[i].coordinates.length; j++) {
          let coordinates = [];

          if (search_locations[i].coordinates[j]) {
            for (
              let k = 0;
              k < search_locations[i].coordinates[j].length;
              k++
            ) {
              coordinates.push({
                latitude: parseFloat(
                  search_locations[i].coordinates[j][k].latitude
                ),
                longitude: parseFloat(
                  search_locations[i].coordinates[j][k].longitude
                )
              });
            }

            coordinates_array.push(coordinates);
          }
        }
      }
    }

    const { map_properties } = this.props;
    for (let i = 0; i < map_properties.length; i++) {
      let in_search_coordinates = true;

      /*
      if (coordinates_array.length > 0) {
        for (let i = 0; i < coordinates_array.length; i++) {
          if (
            isPointInPolygon(
              {
                latitude: parseFloat(map_properties[i].location?.latitude),
                longitude: parseFloat(map_properties[i].location?.longitude)
              },
              coordinates_array[i]
            )
          ) {
            in_search_coordinates = true;
          }
        }
      } else {
        in_search_coordinates = true;
      }
      */

      if (in_search_coordinates) {
        if (!map_properties[i].property_label) {
          map_properties[i].property_label =
            map_properties[i][
              this.props.main_map_location?.property_label_type
            ];
        }

        let market_status = "";
        let price = 0;
        let property_label = map_properties[i].property_label;
        if (map_properties[i].market_status?.value.startsWith("Active")) {
          market_status = "Active";
          price = map_properties[i].mls.current_listing_price;
        } else if (
          map_properties[i].market_status?.value.startsWith("Contingent")
        ) {
          market_status = "Contingent";
          price = map_properties[i].mls.current_listing_price;
        } else if (map_properties[i].market_status?.value.startsWith("Sold")) {
          market_status = "Sold";
          price = map_properties[i].mls.sold_price;
        }
        /*
        if (
          !!market_status &&
          (!map_properties[i].deal || map_properties[i].deal?.removed)
        ) {
          property_label = "--";
          let num = parseInt(price);
          if (num >= 1000000) {
            property_label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
          } else if (num >= 1000) {
            property_label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
          }
        }
        */

        const properties = {
          property_label: property_label,
          market_status,
          active_status: map_properties[i].active
            ? map_properties[i].viewing
              ? "active_viewing"
              : map_properties[i].highlighted
              ? "active_highlighted"
              : "active"
            : map_properties[i].viewing
            ? "viewing"
            : map_properties[i].highlighted
            ? "highlighted"
            : "false",
          lead_status:
            map_properties[i].deal &&
            (!map_properties[i].deal?.removed ||
              this.props.active_route?.id == map_properties[i].deal?.route_id)
              ? map_properties[i].active
                ? "active_lead"
                : map_properties[i].viewing
                ? "viewing_lead"
                : "lead"
              : "false"
        };
        properties_data.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(map_properties[i].location?.longitude),
              parseFloat(map_properties[i].location?.latitude)
            ]
          },
          properties
        });
        let coords = [];
        if (map_properties[i].coordinates) {
          for (let j = 0; j < map_properties[i].coordinates.length; j++) {
            if (map_properties[i].coordinates[j]) {
              for (
                let k = 0;
                k < map_properties[i].coordinates[j].length;
                k++
              ) {
                coords.push([
                  map_properties[i].coordinates[j][k].longitude,
                  map_properties[i].coordinates[j][k].latitude
                ]);
              }
            }
          }
        }
        if (map_properties[i].viewing || map_properties[i].highlighted) {
          border_data.push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [coords]
            },

            properties
          });
        } else {
          all_border_data.push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [coords]
            },

            properties
          });
        }
      }
    }

    //source the properties
    const source_data = {
      type: "FeatureCollection",
      features: properties_data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("properties_on_map")) {
        map.addSource("properties_on_map", source);
      } else {
        map.getSource("properties_on_map").setData(source_data);
      }

      //source the border
      const border_source_data = {
        type: "FeatureCollection",
        features: border_data
      };
      const border_source = {
        type: "geojson",
        data: border_source_data
      };
      if (!map.getSource("borders_on_map")) {
        map.addSource("borders_on_map", border_source);
      } else {
        map.getSource("borders_on_map").setData(border_source_data);
      }

      const all_border_source_data = {
        type: "FeatureCollection",
        features: all_border_data
      };
      const all_border_source = {
        type: "geojson",
        data: all_border_source_data
      };
      if (!map.getSource("all_borders_on_map")) {
        map.addSource("all_borders_on_map", all_border_source);
      } else {
        map.getSource("all_borders_on_map").setData(all_border_source_data);
      }
    } else if (map) {
      clearTimeout(this._property_timeot);
      this._property_timeot = setTimeout(() => {
        this.updatePropertiesOnMapSource(map);
      }, 500);
    }
  }

  updateRoutesOnMapSource(map) {
    let data = [];
    let active_route_data = [];
    const { routes_on_map } = this.props;
    for (let i = 0; i < routes_on_map.length; i++) {
      let coords = [];
      if (routes_on_map[i].coordinates) {
        for (let j = 0; j < routes_on_map[i].coordinates.length; j++) {
          if (routes_on_map[i].coordinates[j]) {
            coords.push([
              routes_on_map[i].coordinates[j].longitude,
              routes_on_map[i].coordinates[j].latitude
            ]);
          }
        }
      }

      if (
        !this.props.active_route ||
        (this.props.active_route?.id == routes_on_map[i].id &&
          this.props.active_route?.route_type == routes_on_map[i].route_type)
      ) {
        const diff = moment().diff(
          moment(routes_on_map[i].date_created),
          "days"
        );
        let route_status = "";
        if (diff > 365 && diff < 730) {
          route_status = "tier_3";
        } else if (diff > 182 && diff < 365) {
          route_status = "tier_2";
        } else if (diff < 182) {
          route_status = "tier_1";
        }
        if (
          this.props.active_route?.id == routes_on_map[i].id &&
          this.props.active_route?.route_type == routes_on_map[i].route_type
        ) {
          active_route_data.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: coords
            },
            properties: {
              active: "true",
              number_of_days: diff,
              route_status: route_status
            }
          });
        } else if (
          this.props.show_routes_on_map &&
          checkIfUserHasMetadata("route_tracking")
        ) {
          data.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: coords
            },
            properties: {
              active: "false",
              number_of_days: diff,
              route_status: route_status
            }
          });
        }
      }
    }

    //source the properties
    const source_data = {
      type: "FeatureCollection",
      features: data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("routes_on_map")) {
        map.addSource("routes_on_map", source);
      } else {
        map.getSource("routes_on_map").setData(source_data);
      }

      const active_route_source_data = {
        type: "FeatureCollection",
        features: active_route_data
      };
      const active_route_source = {
        type: "geojson",
        data: active_route_source_data
      };
      if (!map.getSource("active_route_on_map")) {
        map.addSource("active_route_on_map", active_route_source);
      } else {
        map.getSource("active_route_on_map").setData(active_route_source_data);
      }
    } else if (map) {
      clearTimeout(this._route_timeout);
      this._route_timeout = setTimeout(() => {
        this.updateRoutesOnMapSource(map);
      }, 500);
    }
  }

  updateStreetViewMarker(map) {
    let data = [];
    if (
      this.props.street_view_coords?.latitude &&
      this.props.street_view_coords?.latitude
    ) {
      data.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(this.props.street_view_coords?.longitude),
            parseFloat(this.props.street_view_coords?.latitude)
          ]
        }
      });
    }

    //source the properties
    const source_data = {
      type: "FeatureCollection",
      features: data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("street_view_marker")) {
        map.addSource("street_view_marker", source);
      } else {
        map.getSource("street_view_marker").setData(source_data);
      }
    }
  }

  updateStreetViewLine(map) {
    let data = [];
    if (this.props.street_view_line) {
      data.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: this.props.street_view_line
        }
      });
    }

    //source the properties
    const source_data = {
      type: "FeatureCollection",
      features: data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("street_view_line")) {
        map.addSource("street_view_line", source);
      } else {
        map.getSource("street_view_line").setData(source_data);
      }
    }
  }

  updateCurrentRoute(map) {
    const { current_route_section, current_route } = this.props;
    let current_route_coordinates = [];

    if (current_route) {
      if (current_route_section) {
        if (current_route_section.coordinates) {
          current_route_coordinates.push(current_route_section.coordinates);
        }
      }
      if (current_route.route_sections) {
        if (current_route.route_sections.length > 0) {
          for (let i = 0; i < current_route.route_sections.length; i++) {
            if (current_route_coordinates.length == 0) {
              current_route_coordinates.push(current_route.route_sections[i]);
            } else if (
              current_route_coordinates[current_route_coordinates.length - 1]
            ) {
              const last_coordinates =
                current_route_coordinates[current_route_coordinates.length - 1];
              const next_coordinates =
                current_route.route_sections[i].coordinates;

              if (last_coordinates.length > 0 && next_coordinates.length > 0) {
                const last_latlng = last_coordinates[0];
                const next_latlng =
                  next_coordinates[next_coordinates.length - 1];

                if (getDistance(last_latlng, next_latlng) <= 75) {
                  current_route_coordinates[
                    current_route_coordinates.length - 1
                  ] = current_route.route_sections[i].coordinates.concat(
                    current_route_coordinates[
                      current_route_coordinates.length - 1
                    ]
                  );
                } else {
                  current_route_coordinates.push(
                    current_route.route_sections[i].coordinates
                  );
                }
              } else if (next_coordinates.length > 0) {
                current_route_coordinates.push(
                  current_route.route_sections[i].coordinates
                );
              }
            }
          }
        }
      }
    }

    let data = [];
    for (let i = 0; i < current_route_coordinates.length; i++) {
      let coords = [];
      for (let j = 0; j < current_route_coordinates[i].length; j++) {
        coords.push([
          parseFloat(current_route_coordinates[i][j].longitude),
          parseFloat(current_route_coordinates[i][j].latitude)
        ]);
      }
      data.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coords
        }
      });
    }

    const source_data = {
      type: "FeatureCollection",
      features: data
    };
    const source = {
      type: "geojson",
      data: source_data
    };
    if (map && map.isStyleLoaded()) {
      if (!map.getSource("current_route_on_map")) {
        map.addSource("current_route_on_map", source);
      } else {
        map.getSource("current_route_on_map").setData(source_data);
      }
    } else if (map) {
      clearTimeout(this._current_route_timeout);
      this._current_route_timeout = setTimeout(() => {
        this.updateCurrentRoute(map);
      }, 500);
    }
  }

  render() {
    const { latitude, longitude, refresh_map = false, drawing } = this.props;
    const { resize_refresh } = this.state;

    const selected_properties = this.props.popover_info?.selected_properties
      ? this.props.highlight
        ? this.props.popover_info?.selected_properties?.filter(property => {
            return property.highlighted == 1 ? property : null;
          })
        : this.props.popover_info?.selected_properties
      : [];

    if (!refresh_map && !resize_refresh) {
      return (
        <Wrapper
          style={{
            alignSelf: "stretch",
            flex: "1",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <Map
            onRef={map => {
              this._map = map;
              if (this.props.setMapRef) {
                this.props.setMapRef(map);
              }
            }}
            onDrawRef={drawControl => {
              this.drawControl = drawControl;
            }}
            drawing={drawing}
            drawingCreated={this.drawingCreated}
            onDraw={this.onDraw}
            onStyleLoad={this.onStyleLoad}
            starting_lat={latitude}
            starting_lng={longitude}
            starting_zoom={this.props.zoom}
            minZoom={4}
            maxZoom={20}
            updateCenter={this.updateCenter}
            updateBounds={this.updateBounds}
            updateZoomProperties={this.updateZoomProperties}
            map_type={this.props.map_type}
            dark_mode={this.props.dark_mode}
            colors={this.props.colors}
            onMapClick={this.onMapClick}
            hidePopover={this.hidePopover}
            toggleMapMoving={this.props.toggleMapMoving}
            mapIsMoving={this.props.mapIsMoving}
          />
          {this.props.show_popover && this.props.popover_info ? (
            <Wrapper
              style={{
                position: "absolute",
                top: this.props.popover_info?.point?.y || 0,
                left: this.props.popover_info?.point?.x || 0
              }}
            >
              <PopoverMenu
                show={this.props.show_popover}
                no_swipe={true}
                no_cancel={true}
                allow_multiple={true}
                hideWithOutsideClick={false}
                onShow={s => {
                  this.props.togglePopover(false);
                }}
                containerStyle={{ zIndex: 1 }}
                popover_width={this.props.isMobile ? "100%" : 450}
                popover_height={
                  selected_properties?.length > 1 &&
                  !this.props.select_drive_start
                    ? 450
                    : null
                }
                //popoverPlacement={"right"}
                renderComponent={options => {
                  return null;
                }}
                renderMenu={() => {
                  return this.props.renderPropertyPopover(
                    this.props.popover_info
                  );
                }}
                menu_items={null}
              />
            </Wrapper>
          ) : null}
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({
  auth,
  property_map,
  settings,
  route,
  onboarding,
  native,
  billing
}) => {
  const { token, user } = auth;
  const { steps, currentStep, isOnboardingActive } = onboarding;
  const {
    street_view_coords,
    street_view_line,
    map_properties,
    map_mode,
    main_map_location,
    mapIsMoving
  } = property_map;
  const { source_of_truth, all_feature_metadata } = billing;
  const { dark_mode, colors } = settings;
  const { isMobile, window_height, window_width } = native;
  const { active_route, track_route, current_route, current_route_section } =
    route;
  return {
    token,
    user,
    dark_mode,
    colors,
    street_view_coords,
    street_view_line,
    active_route,
    track_route,
    current_route,
    current_route_section,
    map_properties,
    map_mode,
    main_map_location,
    steps,
    currentStep,
    isOnboardingActive,
    isMobile,
    window_height,
    window_width,
    mapIsMoving,
    source_of_truth,
    all_feature_metadata
  };
};

export default connect(mapStateToProps, {
  toggleMapMoving,
  addDeal,
  nextOnboardingStep,
  replaceSidePanel,
  pushSidePanel,
  popSidePanel
})(NativeMainMap);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DropzoneButton,
  KeyboardView,
  Wrapper
} from "app/NativeComponents/common";
import {
  AttentionBox,
  InlineButton,
  InputBox,
  SelectBox,
  SelectItem
} from "app/NativeComponents/snippets";
import {
  uploadFile,
  pushSidePanel,
  getCallTrackingNumbers,
  checkIfUserHasMetadata
} from "app/NativeActions";
class Signature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state_focus: false,
      call_tracking_focus: false,
      call_tracking_numbers_loading: false,
      call_tracking_numbers: [],
      signature_phone_type:
        !!props.signature?.call_tracking_number_id &&
        props.signature?.call_tracking_number_id != 0
          ? "call_tracking"
          : "normal"
    };

    this._sign_off = React.createRef();
    this._name = React.createRef();
    this._address = React.createRef();
    this._address2 = React.createRef();
    this._city = React.createRef();
    this._state = React.createRef();
    this._zip = React.createRef();
    this._email = React.createRef();
    this._phone = React.createRef();

    this.updateSignaturePhoneWithCallTracking =
      this.updateSignaturePhoneWithCallTracking.bind(this);
    this.getCallTrackingNumbers = this.getCallTrackingNumbers.bind(this);
    this.onCallTrackNumbersChanged = this.onCallTrackNumbersChanged.bind(this);
  }

  componentDidMount() {
    this.getCallTrackingNumbers();
  }

  getCallTrackingNumbers() {
    this.props.getCallTrackingNumbers({
      token: this.props.token,
      type: "get_call_tracking_numbers",
      onLoading: () => {
        this.setState({ call_tracking_numbers_loading: true });
      },
      onError: error => {
        this.setState({ call_tracking_numbers_loading: false });
      },
      onSuccess: results => {
        this.setState({
          call_tracking_numbers_loading: false,
          call_tracking_numbers: results?.call_tracking_numbers
        });
      }
    });
  }

  onCallTrackNumbersChanged() {
    this.getCallTrackingNumbers();
  }

  updateSignaturePhoneWithCallTracking(call_tracking_number_id) {
    //get the call tracking number from the array
    const call_tracking_number = this.state.call_tracking_numbers.find(
      item => item.id === call_tracking_number_id
    );

    this.props.updateFullSignature({
      ...this.props.signature,
      phone: call_tracking_number.phone_number,
      call_tracking_number_id: call_tracking_number_id
    });
  }

  render() {
    if (this.props.signature) {
      let state_data = [];
      for (let i = 0; i < this.props.states.length; i++) {
        state_data.push({
          key: i,
          label: this.props.states[i].name,
          value: this.props.states[i].abbr
        });
      }

      const {
        sign_off,
        name,
        address,
        address2,
        city,
        state,
        zip,
        email,
        signature_phone_type,
        phone,
        call_tracking_number_id,
        signature_image
      } = this.props.signature;

      const call_tracking =
        checkIfUserHasMetadata("call_tracking") && !this.props.legacyTemplate;

      return (
        <>
          <InlineButton
            icon={"keyboard-arrow-left"}
            onPress={() => {
              this.props.toggleSignatures(false);
            }}
          >
            Return To Layers
          </InlineButton>

          <AttentionBox
            title={"Signature"}
            description={
              "This is the contact information they will use to reach you if interested."
            }
          />

          <KeyboardView style={{ flex: 1 }}>
            <InputBox
              autoFocus={false}
              input_ref={this._sign_off}
              name="sign_off"
              returnKeyType="next"
              placeholder="Sign Off"
              onChange={value => {
                this.props.updateSignature({ prop: "sign_off", value: value });
              }}
              onSubmitEditing={() => {
                this._name.current.focus();
              }}
              blurOnSubmit={false}
              value={sign_off?.toString()}
              type="text"
            />
            <InputBox
              autoFocus={false}
              input_ref={this._name}
              name="name"
              returnKeyType="next"
              placeholder="Name"
              onChange={value => {
                this.props.updateSignature({ prop: "name", value: value });
              }}
              onSubmitEditing={() => {
                this._address.current.focus();
              }}
              blurOnSubmit={false}
              value={name?.toString()}
              type="text"
            />

            <InputBox
              autoFocus={false}
              input_ref={this._address}
              name="address"
              returnKeyType="next"
              placeholder="Address"
              onChange={value => {
                this.props.updateSignature({ prop: "address", value: value });
              }}
              onSubmitEditing={() => {
                this._address2.current.focus();
              }}
              blurOnSubmit={false}
              value={address?.toString()}
              type="text"
            />

            <InputBox
              autoFocus={false}
              input_ref={this._address2}
              name="address"
              returnKeyType="next"
              placeholder="Address Line 2"
              onChange={value => {
                this.props.updateSignature({ prop: "address2", value: value });
              }}
              onSubmitEditing={() => {
                this._city.current.focus();
              }}
              blurOnSubmit={false}
              value={address2?.toString()}
              type="text"
            />

            <InputBox
              autoFocus={false}
              input_ref={this._city}
              name="city"
              returnKeyType="next"
              placeholder="City"
              onChange={value => {
                this.props.updateSignature({ prop: "city", value: value });
              }}
              onSubmitEditing={() => {
                this.setState({
                  state_focus: true
                });
              }}
              blurOnSubmit={false}
              value={city.toString()}
              type="text"
            />

            <SelectBox
              select_ref={this._state}
              items={state_data}
              value={state}
              placeholder="State"
              show={this.state.state_focus}
              onFocus={() => {
                this.setState({ state_focus: true });
              }}
              onBlur={() => {
                this.setState({ state_focus: false });
              }}
              onSelect={item => {
                this.props.updateSignature({ prop: "state", value: item });
              }}
              onSubmitEditing={() => {
                this._zip.current.focus();
              }}
            />

            <InputBox
              autoFocus={false}
              input_ref={this._zip}
              name="zip"
              returnKeyType="next"
              placeholder="Zip Code"
              onChange={value => {
                this.props.updateSignature({ prop: "zip", value: value });
              }}
              onSubmitEditing={() => {
                this._email.current.focus();
              }}
              blurOnSubmit={false}
              value={zip?.toString()}
              type="text"
            />

            <InputBox
              input_ref={this._email_address_input}
              name="email_address"
              returnKeyType="next"
              placeholder="Email Address"
              onChange={value => {
                this.props.updateSignature({ prop: "email", value: value });
              }}
              onSubmitEditing={() => {
                if (this.state.signature_phone_type === "call_tracking") {
                  this.setState({
                    call_tracking_focus: true
                  });
                } else {
                  if (this._phone?.current) {
                    if (this.props.device == "desktop") {
                      this._phonet.current.getInputDOMNode().focus();
                    } else {
                      this._phone.current._inputElement.focus();
                    }
                  }
                }
              }}
              blurOnSubmit={false}
              value={email?.toString()}
              type="text"
            />

            {call_tracking ? (
              <Wrapper
                style={{
                  margin: 5,
                  borderRadius: 5,
                  borderColor: this.props.colors?.border_color,
                  borderWidth: 1,
                  borderStyle: "solid"
                }}
              >
                <SelectItem
                  selected={this.state.signature_phone_type === "call_tracking"}
                  onPress={() => {
                    if (checkIfUserHasMetadata("free_plan")) {
                      this.props.pushSidePanel({
                        slug: "purchase_plan_prompt",
                        overlay_modal: true,
                        data: {
                          title: "Track your mail With DealMachine Pro.",
                          dismiss_button:
                            "No thanks. Let me continue without tracking mail.",
                          no_free: true
                        },
                        locked: true
                      });
                    } else {
                      this.setState(
                        {
                          signature_phone_type:
                            this.state.signature_phone_type === "call_tracking"
                              ? "normal"
                              : "call_tracking"
                        },
                        () => {
                          if (
                            this.state.signature_phone_type === "call_tracking"
                          ) {
                            this.props.updateFullSignature({
                              ...this.props.signature,
                              phone:
                                this.props.user?.default_call_tracking_number,
                              call_tracking_number_id:
                                this.props.user?.default_call_tracking_number_id
                            });
                          } else {
                            this.props.updateFullSignature({
                              ...this.props.signature,
                              phone: this.props.user?.team_phone,
                              call_tracking_number_id: 0
                            });
                          }
                        }
                      );
                    }
                  }}
                  select_type="toggle"
                  description={
                    "Use one of your call tracking numbers to forward calls to your phone. This allows DealMachine to track ROI for you and transcribe your conversations automatically."
                  }
                >
                  Track ROI & Transcribe Conversations
                </SelectItem>

                {this.state.signature_phone_type === "call_tracking" ? (
                  <>
                    {this.state.call_tracking_numbers_loading ? (
                      <></>
                    ) : this.state.call_tracking_number === 0 ? (
                      <SelectItem
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "call_tracking",
                            overlay: true,
                            data: {
                              onCallTrackNumbersChanged:
                                this.onCallTrackNumbersChanged
                            }
                          });
                        }}
                        icon={"keyboard-arrow-right"}
                        select_type="none"
                      >
                        You haven't set up a call tracking number yet. Click
                        here to set one up.
                      </SelectItem>
                    ) : (
                      <>
                        <SelectBox
                          items={this.state.call_tracking_numbers}
                          value={call_tracking_number_id}
                          placeholder="Call Tracking Number"
                          show={this.state.call_tracking_focus}
                          icon_right={"unfold-more"}
                          onFocus={() => {
                            this.setState({ call_tracking_focus: true });
                          }}
                          onBlur={() => {
                            this.setState({ call_tracking_focus: false });
                          }}
                          onSelect={item => {
                            this.updateSignaturePhoneWithCallTracking(item);
                          }}
                          onSubmitEditing={() => {
                            this._zip.current.focus();
                          }}
                        />
                        <InlineButton
                          button_type={"full"}
                          icon_right={"keyboard-arrow-right"}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "call_tracking",
                              overlay: true,
                              data: {
                                onCallTrackNumbersChanged:
                                  this.onCallTrackNumbersChanged
                              }
                            });
                          }}
                        >
                          Manage Numbers
                        </InlineButton>
                      </>
                    )}
                  </>
                ) : (
                  <InputBox
                    input_ref={this._phone}
                    name="phone"
                    autoCompleteType={"tel"}
                    mask_type={"cel-phone"}
                    returnKeyType="done"
                    blurOnSubmit={true}
                    autoCapitalize="none"
                    keyboardType="numeric"
                    placeholder="Phone Number"
                    onChange={value =>
                      this.props.updateSignature({
                        prop: "phone",
                        value: value
                      })
                    }
                    value={phone?.toString()}
                    validationRequired={true}
                    type="text"
                    mask={"(999) 999-9999"}
                    onSubmitEditing={() => {}}
                  />
                )}
              </Wrapper>
            ) : (
              <InputBox
                input_ref={this._phone}
                name="phone"
                autoCompleteType={"tel"}
                mask_type={"cel-phone"}
                returnKeyType="done"
                blurOnSubmit={true}
                autoCapitalize="none"
                keyboardType="numeric"
                placeholder="Phone Number"
                onChange={value =>
                  this.props.updateSignature({ prop: "phone", value: value })
                }
                value={phone?.toString()}
                validationRequired={true}
                type="text"
                mask={"(999) 999-9999"}
                onSubmitEditing={() => {}}
              />
            )}

            <SelectItem
              select_type={"none"}
              noPress={true}
              renderRight={() => {
                return (
                  <DropzoneButton
                    accept="image/jpeg, image/png"
                    maxSize={5242880}
                    onDrop={acceptedFiles => {
                      this.setState({
                        uploading: true
                      });
                      this.props.uploadFile({
                        acceptedFiles: acceptedFiles[0],
                        token: this.props.token,
                        onSuccess: results => {
                          this.props.updateSignature({
                            prop: "signature_image",
                            value: results?.file
                          });

                          this.setState({
                            uploading: false
                          });
                        },
                        onError: error => {
                          this.setState({
                            uploading: false
                          });
                        }
                      });
                    }}
                    renderComponent={options => {
                      return (
                        <InlineButton
                          icon="file-upload"
                          noPress={true}
                          pressedIn={options.hovering}
                          primary={false}
                          upload_type="photos"
                          loading={this.state.uploading}
                        >
                          Upload Photo
                        </InlineButton>
                      );
                    }}
                  ></DropzoneButton>
                );
              }}
              innerStyle={{ lineBreak: "anywhere" }}
              label={"Signature Image"}
            >
              {signature_image || "Upload an image for your signature."}
            </SelectItem>
          </KeyboardView>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX } = native;
  const { colors, states } = settings;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    states
  };
};

export default connect(mapStateToProps, {
  uploadFile,
  pushSidePanel,
  getCallTrackingNumbers
})(Signature);

import { Copy, Input, Wrapper, Card, Row } from "app/NativeComponents/common";
import {
  InputBox,
  Select,
  SelectBox,
  InlineButton
} from "app/NativeComponents/snippets";
import React, { Component } from "react";

import { store } from "app/store";
import { updateMarketingUser } from "app/NativeActions";

const colors = store.getState().settings.colors;

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deal_focus: false,
      referral_code: !!props.defaultPromoText ? true : false
    };

    this._email_input = React.createRef();
    this._password_input = React.createRef();
    this._firstname_input = React.createRef();
    this._lastname_input = React.createRef();
    this._promo_input = React.createRef();
    this._phone_input = React.createRef();

    /*

    this._company_input = React.createRef();

    this._email_input = React.createRef();
    this._deals_select_input = React.createRef();
    this._promo_input = React.createRef();
    this._city_input = React.createRef();


   */
  }

  renderPromo() {
    const {
      affiliate_partner,
      dealfinder,
      defaultPromoText,
      handleChange,
      promoText,
      register,
      user_info
    } = this.props;

    if (
      user_info.signup_type != "team_invite" &&
      !dealfinder &&
      !affiliate_partner &&
      this.props.signup_funnel != "use_iap"
    ) {
      if (this.state.referral_code) {
        return (
          <InputBox
            input_ref={this._promo_input}
            name="promo"
            returnKeyType="done"
            blurOnSubmit={true}
            autoCapitalize="characters"
            keyboardType="default"
            placeholder="Referral Code"
            onChange={value => handleChange(value)}
            onSubmitEditing={() => register()}
            value={
              defaultPromoText && promoText == "" ? defaultPromoText : promoText
            }
            type="text"
            className="uppercase"
            autoComplete="off"
          />
        );
      }
      return (
        <InlineButton
          onPress={() => {
            this.setState({ referral_code: true });
          }}
        >
          Do you have a referral code?
        </InlineButton>
      );
    }
  }

  render() {
    const {
      affiliate_partner,
      authFieldChanged,
      city,
      company,
      dealfinder,
      defaultPromoText,
      email,
      errors,
      firstname,
      handleChange,
      handleValidation,
      lastname,
      no_container,
      password,
      phone,
      promoText,
      register,
      user_info,
      successfulValidations
    } = this.props;

    return (
      <Wrapper>
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <InputBox
              input_ref={this._firstname_input}
              name="firstname"
              returnKeyType="next"
              blurOnSubmit={false}
              autoCapitalize="words"
              keyboardType="default"
              placeholder="First Name"
              error={errors["firstname"]}
              success={successfulValidations.firstname}
              validationRequired={true}
              onChange={value => {
                authFieldChanged({ prop: "firstname", value });
              }}
              onBlur={ev => {
                handleValidation("firstname", firstname);
              }}
              value={firstname}
              onSubmitEditing={() => {
                if (this._lastname_input?.current) {
                  this._lastname_input.current.focus();
                }
              }}
              autoComplete={"name-given"}
              textContentType={"givenName"}
              autoComplete={"given-name"}
              type="text"
            />
          </Wrapper>
          <Wrapper style={{ flex: 1 }}>
            <InputBox
              input_ref={this._lastname_input}
              name="lastname"
              returnKeyType="next"
              blurOnSubmit={false}
              autoCapitalize="words"
              keyboardType="default"
              placeholder="Last Name"
              error={errors["lastname"]}
              success={successfulValidations.lastname}
              validationRequired={true}
              onChange={value => {
                authFieldChanged({ prop: "lastname", value });
              }}
              onBlur={ev => {
                handleValidation("lastname", lastname);
              }}
              value={lastname}
              autoComplete={"name-family"}
              textContentType={"familyName"}
              autoComplete={"family-name"}
              onSubmitEditing={() => {
                if (this._email_input?.current) {
                  this._email_input.current.focus();
                }
              }}
              type="text"
            />
          </Wrapper>
        </Row>
        <InputBox
          input_ref={this._email_input}
          name="email"
          autoCompleteType={"email"}
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          keyboardType="email-address"
          placeholder="Email Address"
          error={errors["email"]}
          success={successfulValidations.email}
          validationRequired={true}
          editable={user_info.signup_type == "team_invite" ? false : true}
          onChange={value => {
            authFieldChanged({ prop: "email", value });
          }}
          onBlur={ev => {
            handleValidation("email", email);
          }}
          value={email && email != "" ? email : user_info.email}
          type="text"
          autoComplete={"email"}
          textContentType={"emailAddress"}
          autoComplete={"email"}
          onSubmitEditing={() => {
            if (this._phone_input?.current) {
              if (this.props.device == "desktop") {
                this._phone_input.current.getInputDOMNode().focus();
              } else {
                this._phone_input.current.focus();
              }
            }
          }}
        />
        {this.props.visitor?.marketing_experiments?.dealmachine_free ===
        "dealmachine_free" ? (
          <InputBox
            input_ref={this._phone_input}
            name="phone"
            autoCompleteType={"tel"}
            mask_type={"cel-phone"}
            returnKeyType="next"
            blurOnSubmit={true}
            autoCapitalize="none"
            keyboardType="numeric"
            placeholder="Phone Number"
            error={errors["phone"]}
            success={successfulValidations.phone}
            validationRequired={true}
            onChange={value => {
              authFieldChanged({ prop: "phone", value });
            }}
            onBlur={ev => {
              handleValidation("phone", phone);
            }}
            value={phone ? phone : ""}
            type="text"
            mask={"(999) 999-9999"}
            onSubmitEditing={() => {
              if (this._password_input?.current) {
                this._password_input.current.focus();
              }
            }}
          />
        ) : null}
        <InputBox
          input_ref={this._password_input}
          name="password"
          autoCompleteType={"password"}
          returnKeyType={"done"}
          blurOnSubmit={user_info.signup_type == "team_invite" ? true : false}
          keyboardType="default"
          placeholder="Password"
          secureTextEntry
          onChange={value => {
            authFieldChanged({ prop: "password", value });
          }}
          onBlur={ev => {
            handleValidation("password", password);
          }}
          error={errors["password"]}
          success={successfulValidations.password}
          validationRequired={true}
          value={password}
          type="password"
          autoComplete={"password-new"}
          textContentType={"newPassword"}
          autoComplete={"new-password"}
          onSubmitEditing={() => {
            if (
              this.state.referral_code &&
              user_info.signup_type !== "team_invite"
            ) {
              if (this._promo_input?.current) {
                this._promo_input.current.focus();
              }
            } else {
              register();
            }
          }}
        />

        {this.renderPromo()}
      </Wrapper>
    );
  }
}

export default Inputs;
